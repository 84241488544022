import { useState, useEffect, useContext } from "react";
import { getRandomColors, handleCreateWorksheet } from "../../worksheet/create-worksheet/createWorksheet";
import { randInt, selectRand } from "../../worksheet/create-worksheet/problems/general";
import { borderList } from "../../worksheet/create-worksheet/tcBorders";
import { genBorderTest, mixUSCheck } from "../worksheetTestValues";
import { AuthContext } from "./auth-context";
import { WSContext } from "./ws-context"
import { useHttpClient } from "../../shared/hooks/http-hook";

export const initialValues = {
  concept: "",
  specify: {},//{numbers:{}, probType:{}Application and/or Algorithm, probStyle: '' (Set Up or Horizontal), level:'1', quantity:1, ac:false}
  isChecked: false,
  key: null
};

export const initialGenValues = {
  order: false,
  columns: true, //true = columns
  savePaper: false,
  header: {name: true, date: true},
  styles: {},
  title: "",
  activity:"Worksheet",
  // style:"Worksheet",
  questionStyle: "Worksheet",
  key: null,
  ac: true,
  maxQuantity:36,
  quantity: 0,
  premade: false,
  directions: ''
};

let callbackFunction = () => {};

const WSStore = (props) => {
  const [userSelection, setUserSelection] = useState([]); //mixUSCheck or []
  const [generalSelection, setGeneralSelection] = useState(initialGenValues); //genBorderTest or initialGenValues
  const [inputState, setInputState] = useState(initialValues);
  const [createdWorksheetState, setCreatedWorksheetState] = useState([])
  const [editing, setEditing] = useState(-1);
  const [purchase, setPurchase] = useState(false);
  const [execute, setExecute] = useState(null);
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  useEffect(() => {
    if(execute === 'handleDuplicateNotCopy' || execute === 'handleDuplicateCopy') {
      setExecute(null);
      callbackFunction();
      resetCallbackFunction();
    }
  }, [execute]);

  const resetCallbackFunction = () => {
    callbackFunction = () => {};
  }

  const setMainState = (input, selection, general) => {
    input && setInputState(input)
    selection && setUserSelection(selection)
    general && setGeneralSelection(general)
  }
  const resetMainState = (state) => {
    const tempGen = JSON.parse(JSON.stringify(initialGenValues))
    const tempInputState = JSON.parse(JSON.stringify(initialValues))
    setEditing(-1);

    state==='input' && setInputState(tempInputState)
    state==='selection' && setUserSelection([])
    state==='general' && setGeneralSelection(tempGen)
  }

  const handleClearSelections = () => {
    const tempGen = JSON.parse(JSON.stringify(initialGenValues))
    const tempInputState = JSON.parse(JSON.stringify(initialValues))
    setUserSelection([]);
    setGeneralSelection(tempGen);
    setInputState(tempInputState);
    setCreatedWorksheetState([]);
    setEditing(-1);
    localStorage.removeItem('ws')
    localStorage.removeItem('gs')
    localStorage.removeItem('us')

  };

  const handleSpecifyInput = (e) => {
    const { name, value, checked, type } = e.target;
    const {maxQuantity, quantity} = generalSelection
    let tempInput = JSON.parse(JSON.stringify(inputState));
    if (name==='quantity'){
      let remainingQuantity = maxQuantity - quantity
      tempInput.specify[name]=parseInt(value);
      if (editing > -1){
        remainingQuantity = maxQuantity - (quantity - userSelection[editing]?.specify.quantity)
      }

      if (value > remainingQuantity && editing===-1 && maxQuantity >= quantity){ //prevents user from adding too many questions.
        tempInput.specify[name]=parseInt(remainingQuantity);
      }

    } else if (name==='concept'){
      tempInput[name]=value;
      localStorage.setItem("concept", value); //set concept in case they refresh page

    } else if (type==='radio'){
      tempInput.specify[name]=value;

    } else if (type==='checkbox'){
      tempInput.specify[name]={...tempInput.specify[name], [value]:checked};

    }
    if(name === 'ac' && checked) {
      tempInput.specify['ac'] = true
    } else if(name === 'ac') {
      delete tempInput.specify['ac'];
    }
    setInputState(tempInput);

  }

  const createSVGBorder = (newWS, gs) => {
  
    let genSel = gs ? gs : generalSelection
    const styles = genSel.styles
    let newColors = styles?.colors?.mainDark && !newWS ? styles.colors : getRandomColors() 
    let styleNum = randInt(0,2) //helps randomize

    if (!newColors.sec || styleNum === 0){ //make it random whether or not there is a secondary color
      newColors.sec = '#fff'
      newColors.secLight = '#fff'
      newColors.secDark = '#fff'
    }

    let border = styles?.border && !newWS ? borderList.filter(border=>{
      return border.name===styles.border.name
      })[0] : selectRand(borderList)    
    let tempGS = {...genSel}
    // newColors = styles.colors //forcing colors for creating samples/images
    tempGS.styles = {...tempGS.styles, border, colors:newColors, styleNum}
    setGeneralSelection(tempGS)
    localStorage.setItem('gs', JSON.stringify({...tempGS}))
    //verifies first if user has selected a specific border- otherwise selects a random one. 
    return tempGS.styles
  }

  const trackGeneratedWorksheets = async (gs, us) => {

    try {
      let data = {
        premade: gs.premade,
        activity: gs.activity,
        questionStyle: gs.questionStyle,
        userSelection: us ? us : userSelection,
        date: new Date().toLocaleString(),
      };

      if (auth.user){
        data.user = auth.user.userId
      }
      await sendRequest(
        `/api/worksheets/generated-worksheet`,
        "POST",
        JSON.stringify(data),
        {
          Authorization: auth.token,
          "Content-Type": "application/json",
        }
      );

    } catch (err) {
    }
  };

  const handleDuplicate = (handle, us, generalSelection, worksheetCopy) => {
    setUserSelection(us);
    setGeneralSelection(generalSelection);
    localStorage.setItem('us', JSON.stringify([...us]))
    localStorage.setItem('gs', JSON.stringify({...generalSelection}))

    if (handle==='copy'){
      const tempWSCopy = JSON.parse(JSON.stringify(worksheetCopy))
      tempWSCopy.copy = true
      localStorage.setItem('ws', JSON.stringify(tempWSCopy))
      setPurchase(true)
      callbackFunction = () => {
        setCreatedWorksheetState(tempWSCopy);
      }
      setExecute('handleDuplicateCopy');
    } else {
      if (generalSelection.activity === 'Worksheet' && auth.isLoggedIn) {
        setPurchase(true)
      }

      let styles = createSVGBorder(true, generalSelection)
      generalSelection.styles = styles
      callbackFunction = () => {
        let createdWorksheet = handleCreateWorksheet(us, generalSelection);
        localStorage.setItem('ws', JSON.stringify(createdWorksheet))
        setCreatedWorksheetState(createdWorksheet);

        trackGeneratedWorksheets(generalSelection, us);
      }
      setExecute('handleDuplicateNotCopy');
    }
  }


  const contextValues = {
    resetMainState,
    setMainState,
    handleClearSelections,

    handleSpecifyInput,
    handleDuplicate,
    trackGeneratedWorksheets,
    userSelection,
    generalSelection,
    setGeneralSelection,
    setUserSelection,
    inputState,
    setInputState,

    purchase,
    editing,
    setEditing,
    setPurchase,
    createdWorksheetState,
    setCreatedWorksheetState,
    createSVGBorder
  }
  return <WSContext.Provider value={contextValues}>{props.children}</WSContext.Provider>
  
}

export default WSStore