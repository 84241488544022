import {answerChoicesKey, fixNum, randInt, selectRand, shuffleArray} from './general'

const smallAns = (num, gs) => {
  let numLen = String(num).split('.').join('').length
  let TC = gs.questionStyle === 'Task Cards'
  let WS = gs.questionStyle === 'Worksheet' && gs.columns 
  return (TC && numLen > 4) || (WS && numLen >= 4) 
}
const lineBreak = (num, gs) => {
  let numLen = String(num).split('.').join('').length
  let TC = gs.questionStyle === 'Task Cards'
  let WS = gs.questionStyle === 'Worksheet' && gs.columns 
  let lBreak = (TC  && numLen > 5) || 
    (WS && numLen > 6) ? '\n' : '' //ensure numbers break if needed without hyphen
  return lBreak
}
const lineBreak2 = (num, gs) => {
  let numLen = String(num).split('.').join('').length
  let TC = gs.questionStyle === 'Task Cards'
  let WS = gs.questionStyle === 'Worksheet' && gs.columns
  let lBreak = (WS && (numLen === 6 || numLen === 5)) //|| (TC && numLen === 5) 
  ? '\n' : ''
  return lBreak
}
export const order = (us, gs, numFunc) => { // Write L - G
  const [num1, num2, num3, num4, num5] = numFunc(us, gs).order.sort((a,b)=> {return a-b})
  let answer = `${fixNum(num1)} < ${fixNum(num2)} < ${fixNum(num3)} < ${fixNum(num4)} < ${fixNum(num5)}`

  let questionText = us.specify.ac ? 
    selectRand(['Which shows the numbers ordered from least to greatest?',   'Which correctly shows the numbers in order from least to greatest?', 
      'Which list shows the numbers ordered smallest to largest?']) 
    : selectRand([`Write the following numbers in order from least to greatest.`, 
      'List the numbers below in order from least to greatest.', 'Write the values in order from smallest to largest.'])

  let wrong = shuffleArray([ 
    `${fixNum(num5)} < ${fixNum(num4)} < ${fixNum(num3)} < ${fixNum(num2)} < ${fixNum(num1)}`,
    `${fixNum(num2)} < ${fixNum(num1)} < ${fixNum(num3)} < ${fixNum(num4)} < ${fixNum(num5)}`,
    `${fixNum(num1)} < ${fixNum(num2)} < ${fixNum(num3)} < ${fixNum(num5)} < ${fixNum(num4)}`,
    `${fixNum(num1)} < ${fixNum(num2)} < ${fixNum(num4)} < ${fixNum(num3)} < ${fixNum(num5)}`,
    `${fixNum(num1)} < ${fixNum(num2)} < ${fixNum(num5)} < ${fixNum(num4)} < ${fixNum(num3)}`,
  ])
  let wrongDisplay = shuffleArray([
    `${fixNum(num1)} < ${fixNum(num3)} < ${fixNum(num5)} < ${fixNum(num4)} < ${fixNum(num2)}`,
    `${fixNum(num1)} < ${fixNum(num5)} < ${fixNum(num4)} < ${fixNum(num2)} < ${fixNum(num3)}`,
    `${fixNum(num1)} < ${fixNum(num4)} < ${fixNum(num3)} < ${fixNum(num5)} < ${fixNum(num2)}`,

    `${fixNum(num2)} < ${fixNum(num5)} < ${fixNum(num3)} < ${fixNum(num1)} < ${fixNum(num4)}`,
    `${fixNum(num2)} < ${fixNum(num3)} < ${fixNum(num5)} < ${fixNum(num4)} < ${fixNum(num1)}`,
    `${fixNum(num2)} < ${fixNum(num1)} < ${fixNum(num4)} < ${fixNum(num3)} < ${fixNum(num5)}`,
    `${fixNum(num2)} < ${fixNum(num4)} < ${fixNum(num1)} < ${fixNum(num5)} < ${fixNum(num3)}`,

    `${fixNum(num3)} < ${fixNum(num2)} < ${fixNum(num5)} < ${fixNum(num4)} < ${fixNum(num1)}`,
    `${fixNum(num3)} < ${fixNum(num4)} < ${fixNum(num1)} < ${fixNum(num5)} < ${fixNum(num4)}`,
    `${fixNum(num3)} < ${fixNum(num1)} < ${fixNum(num4)} < ${fixNum(num2)} < ${fixNum(num5)}`,
    `${fixNum(num3)} < ${fixNum(num5)} < ${fixNum(num2)} < ${fixNum(num1)} < ${fixNum(num4)}`,

    `${fixNum(num4)} < ${fixNum(num5)} < ${fixNum(num1)} < ${fixNum(num2)} < ${fixNum(num3)}`,
    `${fixNum(num4)} < ${fixNum(num1)} < ${fixNum(num3)} < ${fixNum(num5)} < ${fixNum(num2)}`,
    `${fixNum(num4)} < ${fixNum(num3)} < ${fixNum(num2)} < ${fixNum(num1)} < ${fixNum(num5)}`,
    `${fixNum(num4)} < ${fixNum(num2)} < ${fixNum(num5)} < ${fixNum(num3)} < ${fixNum(num1)}`,

    `${fixNum(num5)} < ${fixNum(num1)} < ${fixNum(num3)} < ${fixNum(num4)} < ${fixNum(num2)}`,
    `${fixNum(num5)} < ${fixNum(num2)} < ${fixNum(num4)} < ${fixNum(num3)} < ${fixNum(num1)}`,
    `${fixNum(num5)} < ${fixNum(num3)} < ${fixNum(num1)} < ${fixNum(num2)} < ${fixNum(num4)}`,
    `${fixNum(num5)} < ${fixNum(num2)} < ${fixNum(num3)} < ${fixNum(num1)} < ${fixNum(num4)}`,


  ])
  let prob = wrongDisplay[0].split('<')
  var AC = answerChoicesKey(answer, wrong[0], wrong[1], wrong[2])
  let orderedLength = [num1, num2, num3, num4, num5].sort((a, b) => String(a).length - String(b).length)

  var problem = {prob: questionText + '\n' + prob[0] + prob[1]+ prob[2] + lineBreak(num5, gs) + prob[3] + lineBreak2(num5, gs) + prob[4], 
      answerChoices: AC,
      correctAnswer: answer,smallAnswers: smallAns(orderedLength[4], gs)}
  return problem
}
export const orderAC = (us, gs, numFunc) => { // Which is L - G
  const [num1, num2, num3, num4, num5] = numFunc(us, gs).order.sort((a,b)=> {return a-b})

  let questionText = selectRand(['Which of the following shows the numbers ordered from least to greatest?', 'Which of the following lists of numbers are in order from least to greatest?', 'Which shows the values written in order from smallest to largest?'])

  let wrong = shuffleArray([ 
    `${fixNum(num5)} < ${fixNum(num4)} < ${fixNum(num3)} < ${fixNum(num2)} < ${fixNum(num1)}`,
    `${fixNum(num2)} < ${fixNum(num1)} < ${fixNum(num3)} < ${fixNum(num4)} < ${fixNum(num5)}`,
    `${fixNum(num1)} < ${fixNum(num2)} < ${fixNum(num3)} < ${fixNum(num5)} < ${fixNum(num4)}`,
    `${fixNum(num1)} < ${fixNum(num2)} < ${fixNum(num4)} < ${fixNum(num3)} < ${fixNum(num5)}`,
    `${fixNum(num1)} < ${fixNum(num2)} < ${fixNum(num5)} < ${fixNum(num4)} < ${fixNum(num3)}`,
])
  let answer = `${fixNum(num1)} < ${fixNum(num2)} < ${fixNum(num3)} < ${fixNum(num4)} < ${fixNum(num5)}`
  var AC = answerChoicesKey(answer, wrong[0], wrong[1], wrong[2])
  let orderedLength = [num1, num2, num3, num4, num5].sort((a, b) => String(a).length - String(b).length)
  var problem = {prob: questionText,
      answerChoices: AC,
      correctAnswer: answer,smallAnswers: smallAns(orderedLength[4], gs)}
  return problem
}

export const order2 = (us, gs, numFunc) => { // Write G - L
  const [num1, num2, num3, num4, num5] = numFunc(us, gs).order.sort((a,b)=> {return b-a})
  let answer = `${fixNum(num1)} > ${fixNum(num2)} > ${fixNum(num3)} > ${fixNum(num4)} > ${fixNum(num5)}`

  let questionText = us.specify.ac ? 
    selectRand(['Which correctly lists the numbers from greatest to least?',   'Which shows the numbers in order from greatest to least?', 
      'Which list shows the numbers correctly ordered largest to smallest?']) 
    : selectRand([`Write the following numbers in order from greatest to least.`, 
      'List the numbers below in order from greatest to least.', 'Write the values in order from largest to smallest.'])

  let wrong = shuffleArray([ //wrong to use for answer choices
    `${fixNum(num5)} > ${fixNum(num4)} > ${fixNum(num3)} > ${fixNum(num2)} > ${fixNum(num1)}`,
    `${fixNum(num2)} > ${fixNum(num1)} > ${fixNum(num3)} > ${fixNum(num4)} > ${fixNum(num5)}`,
    `${fixNum(num1)} > ${fixNum(num2)} > ${fixNum(num3)} > ${fixNum(num5)} > ${fixNum(num4)}`,
    `${fixNum(num1)} > ${fixNum(num2)} > ${fixNum(num4)} > ${fixNum(num3)} > ${fixNum(num5)}`,
    `${fixNum(num1)} > ${fixNum(num2)} > ${fixNum(num5)} > ${fixNum(num4)} > ${fixNum(num3)}`,
])
  let wrongDisplay = shuffleArray([
    `${fixNum(num1)} > ${fixNum(num4)} > ${fixNum(num2)} > ${fixNum(num5)} > ${fixNum(num3)}`,
    `${fixNum(num1)} > ${fixNum(num5)} > ${fixNum(num3)} > ${fixNum(num2)} > ${fixNum(num4)}`,
    `${fixNum(num1)} > ${fixNum(num3)} > ${fixNum(num4)} > ${fixNum(num2)} > ${fixNum(num5)}`,

    `${fixNum(num2)} > ${fixNum(num4)} > ${fixNum(num3)} > ${fixNum(num5)} > ${fixNum(num1)}`,
    `${fixNum(num2)} > ${fixNum(num1)} > ${fixNum(num3)} > ${fixNum(num4)} > ${fixNum(num5)}`,
    `${fixNum(num2)} > ${fixNum(num5)} > ${fixNum(num4)} > ${fixNum(num1)} > ${fixNum(num3)}`,
    `${fixNum(num2)} > ${fixNum(num3)} > ${fixNum(num5)} > ${fixNum(num4)} > ${fixNum(num1)}`,

    `${fixNum(num3)} > ${fixNum(num4)} > ${fixNum(num5)} > ${fixNum(num2)} > ${fixNum(num1)}`,
    `${fixNum(num3)} > ${fixNum(num2)} > ${fixNum(num5)} > ${fixNum(num1)} > ${fixNum(num4)}`,
    `${fixNum(num3)} > ${fixNum(num5)} > ${fixNum(num1)} > ${fixNum(num4)} > ${fixNum(num2)}`,
    `${fixNum(num3)} > ${fixNum(num1)} > ${fixNum(num4)} > ${fixNum(num5)} > ${fixNum(num2)}`,

    `${fixNum(num4)} > ${fixNum(num5)} > ${fixNum(num2)} > ${fixNum(num3)} > ${fixNum(num1)}`,
    `${fixNum(num4)} > ${fixNum(num1)} > ${fixNum(num3)} > ${fixNum(num2)} > ${fixNum(num5)}`,
    `${fixNum(num4)} > ${fixNum(num2)} > ${fixNum(num5)} > ${fixNum(num1)} > ${fixNum(num3)}`,
    `${fixNum(num4)} > ${fixNum(num3)} > ${fixNum(num1)} > ${fixNum(num5)} > ${fixNum(num2)}`,

    `${fixNum(num5)} > ${fixNum(num2)} > ${fixNum(num3)} > ${fixNum(num4)} > ${fixNum(num1)}`,
    `${fixNum(num5)} > ${fixNum(num3)} > ${fixNum(num4)} > ${fixNum(num1)} > ${fixNum(num2)}`,
    `${fixNum(num5)} > ${fixNum(num1)} > ${fixNum(num3)} > ${fixNum(num2)} > ${fixNum(num4)}`,
    `${fixNum(num5)} > ${fixNum(num1)} > ${fixNum(num4)} > ${fixNum(num2)} > ${fixNum(num3)}`,
  ])
  let prob = wrongDisplay[0].split('>')
  var AC = answerChoicesKey(answer, wrong[0], wrong[1], wrong[2])
  let orderedLength = [num1, num2, num3, num4, num5].sort((a, b) => String(a).length - String(b).length)

  var problem = {prob: questionText + '\n' + prob[0] + prob[1]+ prob[2] + lineBreak(num1, gs) + prob[3]+ lineBreak2(num1, gs) + prob[4], 
      answerChoices: AC,
      correctAnswer: answer,smallAnswers: smallAns(orderedLength[4], gs)}
  return problem
}
export const orderAC2 = (us, gs, numFunc) => { // Which is G - L
  const [num1, num2, num3, num4, num5] = numFunc(us, gs).order.sort((a,b)=> {return b-a})

  let questionText = selectRand(['Which of the following shows the numbers ordered from greatest to least?', 'Which of the following lists are in order from greatest to least?', 'Which shows the values written in order from largest to smallest?'])

  let wrong = shuffleArray([ 
    `${fixNum(num5)} > ${fixNum(num4)} > ${fixNum(num3)} > ${fixNum(num2)} > ${fixNum(num1)}`,
    `${fixNum(num2)} > ${fixNum(num1)} > ${fixNum(num3)} > ${fixNum(num4)} > ${fixNum(num5)}`,
    `${fixNum(num1)} > ${fixNum(num2)} > ${fixNum(num3)} > ${fixNum(num5)} > ${fixNum(num4)}`,
    `${fixNum(num1)} > ${fixNum(num2)} > ${fixNum(num4)} > ${fixNum(num3)} > ${fixNum(num5)}`,
    `${fixNum(num1)} > ${fixNum(num2)} > ${fixNum(num5)} > ${fixNum(num4)} > ${fixNum(num3)}`,
])
  let answer = `${fixNum(num1)} > ${fixNum(num2)} > ${fixNum(num3)} > ${fixNum(num4)} > ${fixNum(num5)}`
  var AC = answerChoicesKey(answer, wrong[0], wrong[1], wrong[2])
  let orderedLength = [num1, num2, num3, num4, num5].sort((a, b) => String(a).length - String(b).length)

  var problem = {prob: questionText,
      answerChoices: AC,
      correctAnswer: answer, smallAnswers: smallAns(orderedLength[4], gs)}
  return problem
}
// export const orderWholeAC2 = (us, gs) => { // G - L
//   const [num1, num2, num3, num4, num5] = wholeNumbers(us, gs).numList.sort((a,b)=> {return b-a})

//   let questionText = selectRand(['Which of the following shows the numbers NOT ordered from least to greatest?', 'Which of the following lists of numbers are NOT in order from least to greatest?', 'Which shows the values NOT written in order from smallest to largest?'])

//   let wrong = shuffleArray([ `${fixNum(num5)} < ${fixNum(num4)} < ${fixNum(num3)} < ${fixNum(num2)} < ${fixNum(num1)}`,
//                 `${fixNum(num2)} < ${fixNum(num1)} < ${fixNum(num3)} < ${fixNum(num4)} < ${fixNum(num5)}`,
//                 `${fixNum(num1)} < ${fixNum(num2)} < ${fixNum(num3)} < ${fixNum(num5)} < ${fixNum(num4)}`,
//                 `${fixNum(num1)} < ${fixNum(num2)} < ${fixNum(num4)} < ${fixNum(num3)} < ${fixNum(num5)}`,
//                 `${fixNum(num1)} < ${fixNum(num2)} < ${fixNum(num5)} < ${fixNum(num4)} < ${fixNum(num3)}`,
// ])
//   let answer = `${fixNum(num1)} < ${fixNum(num2)} < ${fixNum(num3)} < ${fixNum(num4)} < ${fixNum(num5)}`
//   var AC = answerChoicesKey(answer, wrong[0], wrong[1], wrong[2])

//   var problem = {prob: questionText,
//       answerChoices: AC,
//       correctAnswer: answer}
//   return problem
// }

export const orderAC3 = (us, gs, numFunc) => { // Which is NOT L - G
  const [num1A, num2A, num3A, num4A, num5A] = numFunc(us, gs).order.sort((a,b)=> {return a-b})
  const controlDigits = String(num5A).length
  const [num1B, num2B, num3B, num4B, num5B] = numFunc(us, gs, controlDigits).order.sort((a,b)=> {return a-b})
  const [num1C, num2C, num3C, num4C, num5C] = numFunc(us, gs, controlDigits).order.sort((a,b)=> {return a-b})
  const [num1D, num2D, num3D, num4D, num5D] = numFunc(us, gs, controlDigits).order.sort((a,b)=> {return a-b})

  let questionText = selectRand(['Which list shows the numbers NOT ordered from least to greatest?', 'Which of the following lists are NOT in order from least to greatest?', 'Which shows the values NOT written in order from smallest to largest?'])

  let wrong = [ `${fixNum(num1B)} < ${fixNum(num2B)} < ${fixNum(num3B)} < ${fixNum(num4B)} < ${fixNum(num5B)}`,
                `${fixNum(num1C)} < ${fixNum(num2C)} < ${fixNum(num3C)} < ${fixNum(num4C)} < ${fixNum(num5C)}`,
                `${fixNum(num1D)} < ${fixNum(num2D)} < ${fixNum(num3D)} < ${fixNum(num4D)} < ${fixNum(num5D)}`,
]
  let answer = selectRand([ 
    `${fixNum(num5A)} < ${fixNum(num4A)} < ${fixNum(num3A)} < ${fixNum(num2A)} < ${fixNum(num1A)}`,
    `${fixNum(num5A)} < ${fixNum(num4A)} < ${fixNum(num3A)} < ${fixNum(num2A)} < ${fixNum(num1A)}`,
    `${fixNum(num1A)} < ${fixNum(num2A)} < ${fixNum(num3A)} < ${fixNum(num5A)} < ${fixNum(num4A)}`, 
    `${fixNum(num1A)} < ${fixNum(num2A)} < ${fixNum(num4A)} < ${fixNum(num3A)} < ${fixNum(num5A)}`,  
    `${fixNum(num1A)} < ${fixNum(num2A)} < ${fixNum(num4A)} < ${fixNum(num3A)} < ${fixNum(num5A)}`,  
    `${fixNum(num1A)} < ${fixNum(num3A)} < ${fixNum(num5A)} < ${fixNum(num4A)} < ${fixNum(num2A)}`,  
])
  var AC = answerChoicesKey(answer, wrong[0], wrong[1], wrong[2])
  let orderedLength = [num1A, num2A, num3A, num4A, num5A].sort((a, b) => String(a).length - String(b).length)

  var problem = {prob: questionText,
      answerChoices: AC,
      correctAnswer: answer,smallAnswers: smallAns(orderedLength[4], gs)}
  return problem
}
export const orderAC4 = (us, gs, numFunc) => { // Which is NOT G - L
  const [num1A, num2A, num3A, num4A, num5A] = numFunc(us, gs).order.sort((a,b)=> {return b-a})
  const controlDigits = String(num5A).length

  const [num1B, num2B, num3B, num4B, num5B] = numFunc(us, gs, controlDigits).order.sort((a,b)=> {return b-a})
  const [num1C, num2C, num3C, num4C, num5C] = numFunc(us, gs, controlDigits).order.sort((a,b)=> {return b-a})
  const [num1D, num2D, num3D, num4D, num5D] = numFunc(us, gs, controlDigits).order.sort((a,b)=> {return b-a})

  let questionText = selectRand(['Which of the following shows the numbers NOT ordered from greatest to least?', 'Which of the following lists are NOT in order from greatest to least?', 'Which shows the values NOT written in order from largest to smallest?'])

  let wrong = [ `${fixNum(num1B)} > ${fixNum(num2B)} > ${fixNum(num3B)} > ${fixNum(num4B)} > ${fixNum(num5B)}`,
                `${fixNum(num1C)} > ${fixNum(num2C)} > ${fixNum(num3C)} > ${fixNum(num4C)} > ${fixNum(num5C)}`,
                `${fixNum(num1D)} > ${fixNum(num2D)} > ${fixNum(num3D)} > ${fixNum(num4D)} > ${fixNum(num5D)}`,
                ]
  let answer = selectRand([ 
    `${fixNum(num5A)} > ${fixNum(num4A)} > ${fixNum(num3A)} > ${fixNum(num2A)} > ${fixNum(num1A)}`,
    `${fixNum(num5A)} > ${fixNum(num4A)} > ${fixNum(num3A)} > ${fixNum(num2A)} > ${fixNum(num1A)}`,
    `${fixNum(num1A)} > ${fixNum(num2A)} > ${fixNum(num3A)} > ${fixNum(num5A)} > ${fixNum(num4A)}`, 
    `${fixNum(num1A)} > ${fixNum(num2A)} > ${fixNum(num4A)} > ${fixNum(num3A)} > ${fixNum(num5A)}`,  
    `${fixNum(num1A)} > ${fixNum(num2A)} > ${fixNum(num4A)} > ${fixNum(num3A)} > ${fixNum(num5A)}`,  
    `${fixNum(num1A)} > ${fixNum(num3A)} > ${fixNum(num5A)} > ${fixNum(num4A)} > ${fixNum(num2A)}`,  
                            ])
  var AC = answerChoicesKey(answer, wrong[0], wrong[1], wrong[2])
  let orderedLength = [num1A, num2A, num3A, num4A, num5A].sort((a, b) => String(a).length - String(b).length)

  var problem = {prob: questionText,
      answerChoices: AC,
      correctAnswer: answer, smallAnswers: smallAns(orderedLength[4], gs)}
  return problem
}