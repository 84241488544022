import { specAlg, specify, specAC } from "../helper"

export const MultiplyDecimals = (props) => {
  const {probStyle, createLink, ac} = props
  const hz = probStyle === 'Horizontal'
  const [c1] = ['Multiplying Decimals']
  const [dig1_1, dig2_1, dig3_1, dig4_1, dig2_2, dig3_2] = ['1 by 1 digit', '2 by 1 digit', '3 by 1 digit', '4 by 1 digit', '2 by 2 digit', '3 by 2 digit']

  const title = (mod1, mod2) => {
    return `Multiplying ${mod1} Digit Decimals`
  }

  const createSpec = (spec1) => {
    let specC1 = [specify('numbers', spec1), specify('probStyle', probStyle), specAlg, specAC(ac)]

    return {[c1]: specC1}
  }

  return (<>
    <div className="buttonGroup">
      <div className="concept-topic">Algorithms</div>
      {createLink([c1], title('1 by 1'), '', createSpec([dig1_1]), hz ? 12 : 18)}
      {createLink([c1], title('2 by 1'), '', createSpec([dig2_1]), hz ? 12 : 18)}
      {createLink([c1], title('1 by 1 & 2 by 1'), '', createSpec([dig1_1, dig2_1]), hz ? 12 : 18)}
      {createLink([c1], title('3 by 1'), '', createSpec([dig3_1]), hz ? 12 : 18)}
      {createLink([c1], title('3 by 1 & 4 by 1'), '', createSpec([dig3_1, dig4_1]), hz ? 12 : 18)}
      {createLink([c1], title('4 by 1'), '', createSpec([dig4_1]), hz ? 12 : 18)}
      {createLink([c1], title('2 by 2'), '', createSpec([dig2_2]), 18)}
      {createLink([c1], title('2 by 2 & 3 by 2'), '', createSpec([dig3_2, dig2_2]), hz ? 12 : 18)}
      {createLink([c1], title('3 by 2'), '', createSpec([dig3_2]), hz ? 12 : 18)}
    </div>
    </>
  )
}