import React, { useCallback, useState } from "react";
import { useHistory } from "react-router";
import { AuthContext } from "./auth-context";

const AuthStore = (props) => {
  const [token, setToken] = useState(null);
  const [user, setUser] = useState(null);
  const history = useHistory();

  const login = useCallback((data) => {
    setToken(data.token);
    setUser(data.user);
    localStorage.setItem(
      "userData",
      JSON.stringify({
        ...data,
      })
    );
    // history.push('/');
  }, []);

  const logout = useCallback(() => {
    setToken(null);
    setUser(null);
    localStorage.removeItem("userData");
    history.push("/");
  }, []);

  const unsetWelcome = useCallback(() => {
    if (user) {
      setUser({ ...user, showWelcome: false });
    }
  }, []);

  const updateUser = useCallback((data) => {
    if (localStorage && localStorage.getItem("userData")) {
      let session = JSON.parse(localStorage.getItem("userData"));
      session.user = data;
      localStorage.setItem(
        "userData",
        JSON.stringify({
          ...session,
        })
      );
      setUser(data);
    }
  }, []);

  const contextValues = {
    isLoggedIn: !!token,
    token: token,
    user: user,
    login: login,
    logout: logout,
    updateUser: updateUser,
    unsetWelcome: unsetWelcome,
  };
  return (
    <AuthContext.Provider value={contextValues}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthStore;
