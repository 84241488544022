import React, { useContext, useState } from 'react';
import { TextField, Select, InputLabel, FormControl, FormHelperText, Menu, MenuItem} from "@material-ui/core";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from '../../app/context/auth-context';
import Modal from "../../shared/components/UIElements/Modal";
import Button from "../../shared/components/FormElements/Button";
import { WSContext } from '../../app/context/ws-context';

const ReportIssue = (props) => {
  const auth = useContext(AuthContext);
  const ws = useContext(WSContext);
  const [supportTicket, setSupportTicket] = useState({issue:null, message:null})
  const [error, setError] = useState({issue:null, message:null})
  const { isLoading, sendRequest, clearError } = useHttpClient();
  const [message, setMessage] = useState(null) 
  const onChangeSupport = (e) => {
    const tempSupportTicket = JSON.parse(JSON.stringify(supportTicket))
    const {name, value} = e.target
    tempSupportTicket[name] = value
    setSupportTicket(tempSupportTicket)
  }
  const submitSupportTicket = async () => {
    const tempError = {}
    if (!supportTicket.issue){
      tempError.issue = true
    }
    if (!supportTicket.message){
      tempError.message = true
    }
    if (tempError.message || tempError.issue){
      setError(tempError)
      return
    }
    try {
      const wsData = !props.purchased ? {generalSelection: ws.generalSelection, userSelection: ws.userSelection, worksheetCopy: ws.createdWorksheetState} : {}
      await sendRequest(
        `/api/worksheets/support-ticket`,
        "POST",
        JSON.stringify({
          ...supportTicket, 
          user: auth.user?.userId, 
          wid: props.id,
          ...wsData
        }),
        {
          'Content-Type': 'application/json'
        }
      );
      setMessage("Your ticket has been submitted")
      setSupportTicket({issue:null, message:null})
    } catch (err) {
      setMessage("Your ticket could not be submitted at this time. Please try again later")

    }
  };
  return(
    <Modal show={props.supportTicketModal} onCancel={(e)=>{e.stopPropagation(); props.setSupportTicketModal(false);  setMessage(null)}}
    header="Report Worksheet Issue"
    footerClass="worksheet-item__modal-actions"
    className="support-ticket"
    footer={!message ? <Button danger onClick={submitSupportTicket}>SUBMIT TICKET</Button> : 
      <Button danger onClick={()=>{ props.setSupportTicketModal(false); setMessage(null)}}>OKAY</Button> }
  >
  {!message ? 

    <div className="main-section">
      <p> {props.purchased && ws.generalSelection.activiy !== 'Worksheet' ?         
        `Please select a category that most closely represents the issue with the worksheet, and describe in as much detail as possible what the problem is. 
        If the issue is with a specific question or page please specify that as well. We will verify your request and refund your token if necessary, and once we are able to fix the issue 
        we will notify you so that you also have the option of recreating the worksheet.` : 

        `We greatly appreciate your help in improving our worksheet generator! Please let us know if you notice anything off 
        about one of our generated worksheets. Please provide as much detail as possible including question or page numbers. 
        Thank you again, we will reach out once we are able to fix the issue!`} </p>
    <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
      
      <InputLabel id="demo-simple-select-standard-label">Issue Category</InputLabel>
      <Select
        labelId="demo-simple-select-standard-label"
        id="demo-simple-select-standard"
        value={supportTicket.issue}
        name="issue"
        onChange={onChangeSupport}
        MenuProps={{ disableScrollLock: true }}
      >
        <MenuItem value='stylingIssue'>Styling Issue</MenuItem>
        <MenuItem value='answerKey'>Answer Key Error</MenuItem>
        <MenuItem value='problems'>Problems do not match selections</MenuItem>
        <MenuItem value='answerChoices'>Repeat/bad answer choices</MenuItem>
        <MenuItem value='other'>Other</MenuItem>
      </Select>
      {error.issue && <FormHelperText className="error">Please select a category that most closely represents your issue</FormHelperText>}

    </FormControl>

      <TextField variant="filled" id="filled-basic" placeholder={'The issue with the worksheet is...'} required className="support-message"
        label={<span className="">Issue description</span>}
        name="message" type="textarea"
        value={supportTicket.message}
        onChange={onChangeSupport}
        minRows={4}
        maxRows={10}
        rows={10}
        multiline
        // onBlur={onBlur}
        maxLength={1500} autoFocus
        InputProps={{
          inputProps: { 
            type:'text',
            maxLength:1500
          }
        }}
      />
      {error.message && <FormHelperText className="error">Please describe your issue in as much detail as possible to help us identify and fix the issue</FormHelperText>}

    </div>: <div className="main-section"><div>{message}</div></div>
      }
  </Modal>
  )
}

export default ReportIssue;