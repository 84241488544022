import { specify } from "../helper"

export const ComparingWholeNumbers = (props) => {
  const {createLink, ac} = props
  const [c1] = ['Comparing Whole Numbers']
  const [dig1, dig2, dig3, dig4, dig5, dig6, dig7] = ['1 digit numbers', '2 digit numbers', '3 digit numbers', '4 digit numbers', '5 digit numbers', '6 digit numbers', '7 digit numbers']
  const [com, order] = ['Comparing', 'Ordering']
  const title = (mod1, mod2) => {
    return `${mod1} ${mod2} Digit Whole Numbers`
  }
  const showNoAC = ac === false || ac === 'allowed'
  const showAC = ac === true || ac === 'allowed'
  const createSpec = (spec1, spec2, spec3=false) => {
    let specC1 = [specify('numbers', spec1), specify('probType', spec2), specify('ac', ac === true ? true : ac === false ? false : spec3)]
    return {[c1]: specC1}
  }

  return (<>
    {showNoAC && <div className="buttonGroup">
      <div className="concept-topic">Comparing Whole Numbers {showAC && '(No answer choices)'}</div>
      {createLink([c1], title(com, '1'), '', createSpec([dig1], [com]), 12)}
      {createLink([c1], title(com, '1-2'), '', createSpec([dig1, dig2], [com]), 12)}
      {createLink([c1], title(com, '2'), '', createSpec([dig2], [com]), 12)}
      {createLink([c1], title(com, '2-3'), '', createSpec([dig2, dig3], [com]), 12)}
      {createLink([c1], title(com, '3'), '', createSpec([dig3], [com]), 12)}
      {createLink([c1], title(com, '3-4'), '', createSpec([dig3, dig4], [com]), 12)}
      {createLink([c1], title(com, '4'), '', createSpec([dig4], [com]), 12)}
      {createLink([c1], title(com, '4-5'), '', createSpec([dig4, dig5], [com]), 12)}
      {createLink([c1], title(com, '5'), '', createSpec([dig5], [com]), 12)}
      {createLink([c1], title(com, '5-6'), '', createSpec([dig5, dig6], [com]), 12)}
      {createLink([c1], title(com, '6'), '', createSpec([dig6], [com]), 12)}
      {createLink([c1], title(com, '6-7'), '', createSpec([dig6, dig7], [com]), 12)}
      {createLink([c1], title(com, '7'), '', createSpec([dig7], [com]), 12)}
    </div>}
    {showAC && <div className="buttonGroup">
      <div className="concept-topic">Comparing Whole Numbers {showNoAC && '(Answer choices)'}</div>
      {createLink([c1], title(com, '1'), '', createSpec([dig1], [com], true), 16)}
      {createLink([c1], title(com, '1-2'), '', createSpec([dig1, dig2], [com], true), 16)}
      {createLink([c1], title(com, '2'), '', createSpec([dig2], [com], true), 16)}
      {createLink([c1], title(com, '2-3'), '', createSpec([dig2, dig3], [com], true), 16)}
      {createLink([c1], title(com, '3'), '', createSpec([dig3], [com], true), 16)}
      {createLink([c1], title(com, '3-4'), '', createSpec([dig3, dig4], [com], true), 16)}
      {createLink([c1], title(com, '4'), '', createSpec([dig4], [com], true), 16)}
      {createLink([c1], title(com, '4-5'), '', createSpec([dig4, dig5], [com], true), 16)}
      {createLink([c1], title(com, '5'), '', createSpec([dig5], [com], true), 16)}
      {createLink([c1], title(com, '5-6'), '', createSpec([dig5, dig6], [com], true), 16)}
      {createLink([c1], title(com, '6'), '', createSpec([dig6], [com], true), 16)}
      {createLink([c1], title(com, '6-7'), '', createSpec([dig6, dig7], [com], true), 16)}
      {createLink([c1], title(com, '7'), '', createSpec([dig7], [com], true), 16)}
    </div>}
    {showNoAC && <div className="buttonGroup">
      <div className="concept-topic">Ordering Whole Numbers {showAC && '(No answer choices)'}</div>
      {createLink([c1], title(order, '1'), '', createSpec([dig1], [order]), 24)}
      {createLink([c1], title(order, '1-2'), '', createSpec([dig1, dig2], [order]), 24)}
      {createLink([c1], title(order, '2'), '', createSpec([dig2], [order]), 24)}
      {createLink([c1], title(order, '2-3'), '', createSpec([dig2, dig3], [order]), 24)}
      {createLink([c1], title(order, '3'), '', createSpec([dig3], [order]), 24)}
      {createLink([c1], title(order, '3-4'), '', createSpec([dig3, dig4], [order]), 24)}
      {createLink([c1], title(order, '4'), '', createSpec([dig4], [order]), 24)}
      {createLink([c1], title(order, '4-5'), '', createSpec([dig4, dig5], [order]), 24)}
      {createLink([c1], title(order, '5'), '', createSpec([dig5], [order]), 24)}
      {createLink([c1], title(order, '5-6'), '', createSpec([dig5, dig6], [order]), 24)}
      {createLink([c1], title(order, '6'), '', createSpec([dig6], [order]), 24)}
      {createLink([c1], title(order, '6-7'), '', createSpec([dig6, dig7], [order]), 24)}
      {createLink([c1], title(order, '7'), '', createSpec([dig7], [order]), 24)}
    </div>}
    {showAC && <div className="buttonGroup">
      <div className="concept-topic">Ordering Whole Numbers {showNoAC && '(Answer choices)'} </div>
      {createLink([c1], title(order, '1'), '', createSpec([dig1], [order], true), 18)}
      {createLink([c1], title(order, '1-2'), '', createSpec([dig1, dig2], [order], true), 18)}
      {createLink([c1], title(order, '2'), '', createSpec([dig2], [order], true), 18)}
      {createLink([c1], title(order, '2-3'), '', createSpec([dig2, dig3], [order], true), 18)}
      {createLink([c1], title(order, '3'), '', createSpec([dig3], [order], true), 18)}
      {createLink([c1], title(order, '3-4'), '', createSpec([dig3, dig4], [order], true), 18)}
      {createLink([c1], title(order, '4'), '', createSpec([dig4], [order], true), 18)}
      {createLink([c1], title(order, '4-5'), '', createSpec([dig4, dig5], [order], true), 12)}
      {createLink([c1], title(order, '5'), '', createSpec([dig5], [order], true), 12)}
      {createLink([c1], title(order, '5-6'), '', createSpec([dig5, dig6], [order], true), 12)}
      {createLink([c1], title(order, '6'), '', createSpec([dig6], [order], true), 12)}
      {createLink([c1], title(order, '6-7'), '', createSpec([dig6, dig7], [order], true), 12)}
      {createLink([c1], title(order, '7'), '', createSpec([dig7], [order], true), 12)}
    </div>}
    {showNoAC && <div className="buttonGroup">
      <div className="concept-topic">Comparing & Ordering Whole Numbers {showAC && '(No answer choices)'}</div>
      {createLink([c1], title(com+' & '+order, '1'), '', createSpec([dig1], [order, com]), 24)}
      {createLink([c1], title(com+' & '+order, '1-2'), '', createSpec([dig1, dig2], [order, com]), 24)}
      {createLink([c1], title(com+' & '+order, '2'), '', createSpec([dig2], [order, com]), 24)}
      {createLink([c1], title(com+' & '+order, '2-3'), '', createSpec([dig2, dig3], [order, com]), 24)}
      {createLink([c1], title(com+' & '+order, '3'), '', createSpec([dig3], [order, com]), 24)}
      {createLink([c1], title(com+' & '+order, '3-4'), '', createSpec([dig3, dig4], [order, com]), 24)}
      {createLink([c1], title(com+' & '+order, '4'), '', createSpec([dig4], [order, com]), 24)}
      {createLink([c1], title(com+' & '+order, '4-5'), '', createSpec([dig4, dig5], [order, com]), 24)}
      {createLink([c1], title(com+' & '+order, '5'), '', createSpec([dig5], [order, com]), 24)}
      {createLink([c1], title(com+' & '+order, '5-6'), '', createSpec([dig5, dig6], [order, com]), 24)}
      {createLink([c1], title(com+' & '+order, '6'), '', createSpec([dig6], [order, com]), 24)}
      {createLink([c1], title(com+' & '+order, '6-7'), '', createSpec([dig6, dig7], [order, com]), 24)}
      {createLink([c1], title(com+' & '+order, '7'), '', createSpec([dig7], [order, com]), 24)}
    </div>}
    {showAC && <div className="buttonGroup">
      <div className="concept-topic">Comparing & Ordering Whole Numbers {showNoAC && '(Answer choices)'}</div>
      {createLink([c1], title(com+' & '+order, '1'), '', createSpec([dig1], [order, com], true), 18)}
      {createLink([c1], title(com+' & '+order, '1-2'), '', createSpec([dig1, dig2], [order, com], true), 18)}
      {createLink([c1], title(com+' & '+order, '2'), '', createSpec([dig2], [order, com], true), 18)}
      {createLink([c1], title(com+' & '+order, '2-3'), '', createSpec([dig2, dig3], [order, com], true), 18)}
      {createLink([c1], title(com+' & '+order, '3'), '', createSpec([dig3], [order, com], true), 18)}
      {createLink([c1], title(com+' & '+order, '3-4'), '', createSpec([dig3, dig4], [order, com], true), 18)}
      {createLink([c1], title(com+' & '+order, '4'), '', createSpec([dig4], [order, com], true), 12)}
      {createLink([c1], title(com+' & '+order, '4-5'), '', createSpec([dig4, dig5], [order, com], true), 12)}
      {createLink([c1], title(com+' & '+order, '5'), '', createSpec([dig5], [order, com], true), 12)}
      {createLink([c1], title(com+' & '+order, '5-6'), '', createSpec([dig5, dig6], [order, com], true), 12)}
      {createLink([c1], title(com+' & '+order, '6'), '', createSpec([dig6], [order, com], true), 12)}
      {createLink([c1], title(com+' & '+order, '6-7'), '', createSpec([dig6, dig7], [order, com], true), 12)}
      {createLink([c1], title(com+' & '+order, '7'), '', createSpec([dig7], [order, com], true), 12)}
    </div>}
    </>
  )
}

