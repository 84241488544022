import {Text,  View,  StyleSheet, Image} from "@react-pdf/renderer";
import { fixNum, randInt, selectRand, shuffleArray } from "../create-worksheet/problems/general";
import Table from '../create-worksheet/problems/Table';
import wwLogo from '../../app-files/images/newlogo.png'
import { isTooLight } from "../../app-files/images/LogoIcon";

export const createBingo=(generalSelection, qaList, bgAnswerKey) => {
  const {BGQuantity, title, activity, styles, questionStyle} = generalSelection
  const {colors} = styles
  const TTB = activity === 'Tic Tac Bingo'
  let newAnswerKey = !TTB ? [...bgAnswerKey]: [...bgAnswerKey, ...bgAnswerKey]
  const mainColor = !isTooLight(colors.mainDark) ? colors.mainDark : colors.sec !== '#fff' && !isTooLight(colors.sec) ? colors.sec : '#522b52' //'#4b4b4b'
  const secColor = colors.secDark !== '#fff' && !isTooLight(colors.secDark) ? colors.secDark : !isTooLight(colors.main) ? colors.main : '#696969'

  let [rowLength, bingoCardLength] =
          newAnswerKey.length <= 9 ? [3, 9] : 
          newAnswerKey.length <= 16 ? [4, 16] : 
          newAnswerKey.length <= 25 ? [5, 25] : [6, 36];

  const tstyles = StyleSheet.create({
    table: {
        display: "flex",
        justifyContent:'center',
        alignItems:'center',
        width: '100%',
        borderRadius: '5'
    },
    tcTable: {
      width: '695' //675
    },
    row: {
        flexDirection: "row",
    },
    title:{
      padding: 10
    },
    icon:{
      height:40,
      width:100
    },
    cardNumber:{
      display:'flex',
      flexDirection:'column',
      alignItems:'center',
      justifyContent: 'center',
      fontFamily: 'Rum Raisin',
      borderRadius: 200,
      width:80,
      height:80,
      backgroundColor: secColor,
      color:'white',
      padding:'10 0',
      fontSize:18
    },
    bingoHeader:{
      display:'flex',
      flexDirection:'row',
      justifyContent:'space-around',
      alignItems:'center',
    },
    bingoTitle:{
      fontSize:55,
      color: mainColor,
      padding:'0',
      fontFamily:'Wendy One',
      width:200,
      textAlign:'center',
    },
    partnerBingoTitle:{
      fontSize:40,
      color: mainColor,
      padding:'0',
      fontFamily:'Wendy One',
      width:200,
      textAlign:'center',
    },
    bingoRowWrap: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',

    },
    bingoWrapper:{
      border: `8 solid ${styles.colors.mainLight}`,
      margin: '10 10',
      padding:'0 0 0 0',
      maxWidth: '400',
      maxHeight: '570'
    }
  });
  const cell_style = (row_index, col_index, data, direction, activity, questionStyle, gs) => {
    const large = gs.quantity > 25 
    const medium = gs.quantity > 16
    let cellWidth = 100/rowLength
    let width = row_index <= 1 ? '100%' : `${cellWidth}%`
    let height = row_index === 0 ? '130' : row_index === 1 ? 'auto': `${375/rowLength}` //533 is about length of worksheet- so divide that by number of rows to keep the bingo card square
    let backgroundColor = row_index===1 ? '#3c3a3c' : 'white'
    let fontFamily = row_index===1 ? 'Wendy One' : 'Acme' //Rum Raisin or Tenali
    let fontSize = row_index===1 ? '18' : data.length > 6 && large ? '12' : data.length > 7 && medium ? '14' : '16'
    let color = row_index===1 ? 'white' : 'black'
    let outerBorder = 'none'
    let borderLeft = col_index === 0 ? outerBorder : '1px solid black';
    let borderRight = col_index === rowLength-1 || row_index <= 1 ? outerBorder : '0'
    let borderTop = row_index === 0 ? outerBorder : '1px solid black';
    let borderBottom = row_index === rowLength+1 ? outerBorder : '0'
    let padding = row_index===1 ? '15' : '0'
    
    return {
        display:'flex', flexDirection:'row', alignItems:'center',justifyContent:'center', 
        backgroundColor, fontFamily, fontSize, color,
        width, height, padding, borderLeft, borderRight, borderTop, borderBottom
    }
  }
    

  ///// Add free spaces to board if emptyCount
  let emptyCount = bingoCardLength-newAnswerKey.length;
  if (!TTB && emptyCount){
    newAnswerKey.push('Free')
    emptyCount--
  } else if (TTB && emptyCount){
    if (emptyCount >= 2){
      newAnswerKey.push('Free','Free')
      emptyCount-=2
    } else {
      newAnswerKey.push('Free')
      emptyCount--
    }
  }

  //fixes answer if answer is a nested string
  const getAnswer = (answer) => {
    if (typeof answer==='string' || typeof answer==='number'){
      return answer
    }
    let newAnswer = answer
    if (!answer.props){
      return answer
    }
    //if answer is a string, split answer in case it is a letter choice answer, in which case we only want to use the letter on the board.
    if (typeof answer?.props?.children === 'string'){
      newAnswer = answer.props.children.split(' ')
      return newAnswer?.length > 1 && newAnswer[1] !== '' ? newAnswer[1] : newAnswer[0] // 0 - number answer, 1- letter
    } else if (typeof answer?.props?.children?.props?.children === 'string'){
      newAnswer = answer?.props?.children?.props?.children.split(' ')
      return newAnswer?.length > 1 && newAnswer[1] !== '' ? newAnswer[1] : newAnswer[0] // 0 - number answer, 1- letter
    } else if (typeof answer?.props?.children?.props?.children === 'object' &&  typeof answer?.props?.children?.props?.children[1]?.props?.children === 'string'){
      newAnswer = answer?.props?.children?.props?.children[1].props.children.split(' ')
      return newAnswer?.length > 1 && newAnswer[1] !== '' ? newAnswer[1] : newAnswer[0] // 0 - number answer, 1- letter
    }
    return answer // must be a specified answer (fraction/table where it is already fixed)
  }

  let tempAnswerList = shuffleArray([...qaList])
  let tempAnswerKey = shuffleArray([...bgAnswerKey])

  // in case the user puts a smaller amount of questions (Such as 2) and there are not enough questions to create wrongs based on, repeat the right answers to fill the board
  while(emptyCount > tempAnswerKey.length) {
    for (let ans of tempAnswerKey) {
      let repeatAnswer = getAnswer(ans)
      newAnswerKey.push(repeatAnswer)
      emptyCount--
      
      if (TTB && emptyCount > tempAnswerKey.length) {
        newAnswerKey.push(repeatAnswer)
        emptyCount--
      }
    }
  }

  while(emptyCount > 0){
    //only want a small amount of wrong answer on the board so that winning is still possible on each board size. 
    //if over a certain amount, add a copy of a correct answer.
    let bgEmptyCheck = (emptyCount > 4 && bingoCardLength === 36) || (emptyCount > 3 && bingoCardLength === 25) || 
    (emptyCount > 2 && bingoCardLength === 16) || (emptyCount > 1 && bingoCardLength === 9)
    let ttbEmptyCheck = (emptyCount > 2 && bingoCardLength === 36) || (emptyCount > 1)

    if ((TTB && ttbEmptyCheck) || (!TTB && bgEmptyCheck)) {
        let repeatAnswer = getAnswer(tempAnswerKey[emptyCount-1])
        newAnswerKey.push(repeatAnswer)
        emptyCount--
        if (TTB && emptyCount > 0){ //repeat for Tic Tac
          newAnswerKey.push(repeatAnswer)
          emptyCount--
        }

      } else {
        let fakeAnswer = getAnswer(tempAnswerList[emptyCount-1].wrong)
        // let fakeLetter = selectRand(['A','B','C','D','F','G','H','J'])
        if (typeof fakeAnswer === 'object'){
          newAnswerKey.push(fakeAnswer)
          emptyCount--
          if (TTB && emptyCount > 0){ //repeat for Tic Tac
            newAnswerKey.push(fakeAnswer)
            emptyCount--
          }

        } else {
          // const letter = fakeAnswer.match(/[A-J]/g);
          newAnswerKey.push(fakeAnswer)
          emptyCount--
          if (TTB && emptyCount > 0){ //repeat for Tic Tac
            newAnswerKey.push(fakeAnswer)
            emptyCount--
          }
        }
      }
  }


  let shuffledAnswerKeys = []

  let fixedAnswers = newAnswerKey.map(answer => {
    if (typeof answer ==='number'){
      return fixNum(answer)
    } else if (typeof answer === 'string'){
      return answer
    } else {
      return getAnswer(answer)
    }
  })
  //creates shuffled arrays of answers to use to create boards.
  for (let i=0; i<BGQuantity; i++){
    shuffledAnswerKeys.push(shuffleArray(fixedAnswers))
  }

  let bingoArrays = []
  for (let akIdx = 0; akIdx<shuffledAnswerKeys.length;akIdx++){
    let bingoCard = [];
    for (let i=0; i<newAnswerKey.length-1;i+=rowLength){
      let bingoRow = [];
      for (let x=i; x<i+rowLength;x++){
        let answer = shuffledAnswerKeys[akIdx][x]
        bingoRow.push(answer)
      }
      bingoCard.push(bingoRow);
    }
    bingoArrays.push(bingoCard);
  }
  // let wsBingoWrapper = questionStyle === 'Worksheet' && {margin: '-50 0 0 135', minHeight:'725', maxWidth: '570'}
// 
  let wsBingoWrapper = questionStyle === 'Worksheet' && {margin: '-50 10 0 10', minHeight:'570', maxWidth: '400'}
  let bingoTables = []
  const createBingoCard = (bingoCard, idx) => {
    return(<View style={[tstyles.bingoWrapper, wsBingoWrapper]} wrap={false} >
      {Table([
        [<View style={tstyles.bingoHeader}>
          <Image style={tstyles.icon} src={wwLogo}/>
          <View>
              {!TTB?
                <Text style={tstyles.bingoTitle }>Bingo</Text>
              : <Text style={tstyles.partnerBingoTitle }>Tic Tac Bingo</Text>
            }
          </View>
          <View style={tstyles.cardNumber}>
            <Text>Card</Text>
            <Text style={{fontSize: '30'}}>{idx+1}</Text>
          </View>

        </View>],
        [title],...bingoCard], cell_style, tstyles, '', 'vertical', activity, questionStyle, generalSelection)}</View>)
  }
  for (let i = 0; i < bingoArrays.length; i+=2){
    let bingoCards = [createBingoCard(bingoArrays[i], i)]

    if (i+1 < bingoArrays.length){
      bingoCards.push(createBingoCard(bingoArrays[i+1], i+1))
    }
    
    bingoTables.push(<View wrap={false} style={[tstyles.bingoRowWrap, questionStyle === 'Worksheet' && {height: '550', width: '820', transform: 'translateX(-110) translateY(185'}]}>{bingoCards}</View>)

  }

  return bingoTables;
}