import { specAC, specHZ, specify } from "../helper"

export const DecimalEquations = (props) => {
  const {createLink, ac} = props
  const c1 = 'Decimal Equations'
  const [add, sub, mult, div, dig2, dig3, dig4] = ['Addition', 'Subtraction', 'Multiplication', 'Division', '2 digits', '3 digits', '4 digits']
  const [dig1_3h, dig3_4h, dig4_5h, dig4_5Th] = ['1-3 digits to the hundredths', '3-4 digits to the hundredths', '4-5 digits to the hundredths', '4-5 digits to the thousandths']
  const [dig1_1, dig2_1, dig3_1, dig4_1, dig2_2, dig3_2, dig4_2, dig1d, dig1w, dig2d, dig2w] = ['1 by 1 digit', '2 by 1 digit', '3 by 1 digit', '4 by 1 digit', '2 by 2 digit', '3 by 2 digit', '4 by 2 digit', '1 digit decimal', '1 digit whole number', '2 digit decimal', '2 digit whole number']
  const title = (mod1, mod2, mod3) => {
    if (mod1 === 'Addition' || mod1 === 'Subtraction' || mod1 === 'Addition & Subtraction'){
      
      return `Decimal ${mod1} Equations with ${mod2}`
    } else  {
      return `Decimal ${mod2} ${mod1} Equations`
    }
    
  }

  const createSpec = (spec1, spec2, spec3, spec4, spec5) => {
    let specAS = [specify('operation', spec1), specAC(ac)]
    if (spec2?.length > 0)
      specAS.push(specify('asNumbers', spec2))
    if (spec3?.length > 0)
      specAS.push(specify('multiply', spec3))
    if (spec4?.length > 0)
      specAS.push(specify('dividend', spec4))
    if (spec5?.length > 0)
      specAS.push(specify('divisor', spec5))

    return {[c1]: specAS}
  }

  return (<>
    <div className="buttonGroup">
      <div className="concept-topic">Decimal Addition Equations (10 = x + 2.7)</div>
      {createLink([c1], title(add, dig1_3h), '', createSpec([add], [dig1_3h], []), 18)}
      {createLink([c1], title(add, dig3_4h), '', createSpec([add], [dig3_4h], []), 18)}
      {createLink([c1], title(add, dig4_5h), '', createSpec([add], [dig4_5h], []), 18)}
      {createLink([c1], title(add, dig4_5Th), '', createSpec([add], [dig4_5Th], []), 18)}

    </div>
    <div className="buttonGroup">
      <div className="concept-topic">Decimal Subtraction Equations (10 = x - 2.7)</div>
      {createLink([c1], title(sub, dig1_3h), '', createSpec([sub], [dig1_3h], []), 18)}
      {createLink([c1], title(sub, dig3_4h), '', createSpec([sub], [dig3_4h], []), 18)}
      {createLink([c1], title(sub, dig4_5h), '', createSpec([sub], [dig4_5h], []), 18)}
      {createLink([c1], title(sub, dig4_5Th), '', createSpec([sub], [dig4_5Th], []), 18)}

    </div>
    <div className="buttonGroup">
      <div className="concept-topic">Decimal Addition & Subtraction Equations</div>
      {createLink([c1], title(add+' & '+sub, dig1_3h), '', createSpec([add,sub], [dig1_3h], []), 18)}
      {createLink([c1], title(add+' & '+sub, dig3_4h), '', createSpec([add,sub], [dig3_4h], []), 18)}
      {createLink([c1], title(add+' & '+sub, dig4_5h), '', createSpec([add,sub], [dig4_5h], []), 18)}
      {createLink([c1], title(add+' & '+sub, dig4_5Th), '', createSpec([add,sub], [dig4_5Th], []), 18)}

    </div>
    <div className="buttonGroup">
      <div className="concept-topic">Decimal Division Equations (1.95 = x ÷ 2)</div>
      {createLink([c1], title(div, dig1_1), '', createSpec([div], [], [dig1_1]), 18)}
      {createLink([c1], title(div, dig2_1), '', createSpec([div], [], [dig2_1]), 18)}
      {createLink([c1], title(div, dig3_1), '', createSpec([div], [], [dig3_1]), 18)}
      {createLink([c1], title(div, dig4_1), '', createSpec([div], [], [dig4_1]), 18)}
      {createLink([c1], title(div, dig2_2), '', createSpec([div], [], [dig2_2]), 18)}
      {createLink([c1], title(div, dig3_2), '', createSpec([div], [], [dig3_2]), 18)}

    </div>
    <div className="buttonGroup">
      <div className="concept-topic">Decimal Multiplication Equations (1.95 = 2x)</div>
      <div className="sec-title">The second digit is always a whole number</div>
      <div className="sec"></div>
      {createLink([c1], title(mult, dig2_1), '', createSpec([mult], [], [], [dig2], [dig1w]), 18)}
      {createLink([c1], title(mult, dig3_1), '', createSpec([mult], [], [], [dig3], [dig1w]), 18)}
      {createLink([c1], title(mult, dig4_1), '', createSpec([mult], [], [], [dig4], [dig1w]), 18)}
      {createLink([c1], title(mult, dig3_2), '', createSpec([mult], [], [], [dig3], [dig2w]), 18)}
      {createLink([c1], title(mult, dig4_2), '', createSpec([mult], [], [], [dig4], [dig2w]), 18)}

      <div className="sec-title">Both numbers can be Decimals</div>
      <div className="sec"></div>
      {createLink([c1], title(mult, dig2_1), '', createSpec([mult], [], [], [dig2], [dig1d]), 18)}
      {createLink([c1], title(mult, dig3_1), '', createSpec([mult], [], [], [dig3], [dig1d]), 18)}
      {createLink([c1], title(mult, dig4_1), '', createSpec([mult], [], [], [dig4], [dig1d]), 18)}
      {createLink([c1], title(mult, dig3_2), '', createSpec([mult], [], [], [dig3], [dig2d]), 18)}
      {createLink([c1], title(mult, dig4_2), '', createSpec([mult], [], [], [dig4], [dig2d]), 18)}

    </div>
    <div className="buttonGroup">
      <div className="concept-topic">Decimal Multiplication & Division Equations</div>
      <div className="sec-title">The second digit is always a whole number</div>
      <div className="sec"></div>
      {createLink([c1], title(mult+' & '+div, dig2_1,), '', createSpec([div, mult], [], [dig2_1], [dig2], [dig1w]), 18)}
      {createLink([c1], title(mult+' & '+div, dig3_1,), '', createSpec([div, mult], [], [dig3_1], [dig3], [dig1w]), 18)}
      {createLink([c1], title(mult+' & '+div, dig4_1,), '', createSpec([div, mult], [], [dig4_1], [dig4], [dig1w]), 18)}
      {createLink([c1], title(mult+' & '+div, dig3_2,), '', createSpec([div, mult], [], [dig3_2], [dig3], [dig2w]), 18)}
      <div className="sec-title">Both numbers can be Decimals</div>
      <div className="sec"></div>
      {createLink([c1], title(mult+' & '+div, dig2_1,), '', createSpec([div, mult], [], [dig2_1], [dig2], [dig1d]), 18)}
      {createLink([c1], title(mult+' & '+div, dig3_1,), '', createSpec([div, mult], [], [dig3_1], [dig3], [dig1d]), 18)}
      {createLink([c1], title(mult+' & '+div, dig4_1,), '', createSpec([div, mult], [], [dig4_1], [dig4], [dig1d]), 18)}
      {createLink([c1], title(mult+' & '+div, dig3_2,), '', createSpec([div, mult], [], [dig3_2], [dig3], [dig2d]), 18)}
    </div>

    <div className="buttonGroup">
      <div className="concept-topic">Decimal Equations with All 4 Mixed Operations</div>
      <div className="sec-title">The second digit is always a whole number</div>
      <div className="sec"></div>
      {createLink([c1], title('Mixed Operation', '1-3 digits',), '', createSpec([add, sub, div, mult], [dig1_3h], [dig3_1], [dig3], [dig1w]), 18)}
      {createLink([c1], title('Mixed Operation', '1-4 digits',), '', createSpec([add, sub, div, mult], [dig1_3h, dig3_4h], [dig4_1], [dig4], [dig1w]), 18)}
      {createLink([c1], title('Mixed Operation', '2-4 digits',), '', createSpec([add, sub,div, mult], [dig3_4h], [dig3_2], [dig3], [dig2w]), 18)}
      {createLink([c1], title('Mixed Operation', '3-5 digits',), '', createSpec([add, sub,div, mult], [dig3_4h, dig4_5h, dig4_5Th], [dig3_2], [dig3], [dig2w]), 18)}
      <div className="sec-title">Both numbers can be Decimals</div>
      <div className="sec"></div>
      {createLink([c1], title('Mixed Operation', '1-3 digits',), '', createSpec([add, sub, div, mult], [dig1_3h], [dig3_1], [dig3], [dig1d]), 18)}
      {createLink([c1], title('Mixed Operation', '1-4 digits',), '', createSpec([add, sub, div, mult], [dig1_3h, dig3_4h], [dig4_1], [dig4], [dig1d]), 18)}
      {createLink([c1], title('Mixed Operation', '2-4 digits',), '', createSpec([add, sub, div, mult], [dig3_4h], [dig3_2], [dig3], [dig2d]), 18)}
      {createLink([c1], title('Mixed Operation', '3-5 digits',), '', createSpec([add, sub, div, mult], [dig3_4h, dig4_5h, dig4_5Th], [dig3_2], [dig3], [dig2d]), 18)}
    </div>
    </>
  )
}