import React from 'react'
import {
  Text,
  View,
  StyleSheet,
} from "@react-pdf/renderer";

export const sanitize_block = (block) => {
  if (typeof(block) === 'string' || typeof(block) === "number") {
      return <Text>{block}</Text>
  } else {
      return block
  }
}
const tstyles = StyleSheet.create({
  table: {
    display: "flex",
    alignItems: 'center',
    justifyContent: 'flexEnd',
    paddingTop: 0,
    maxWidth: '100%',
    alignSelf:'flexEnd'
  },
  tableSH:{
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    // width:'640', //took out
  },
  row: {
      flexDirection: "row",
  },

  header: {
    backgroundColor: 'grey'
  },
  text:{
    marginBottom:5,
    alignSelf:'flex-start'
  }
});
const Table = (data, style_function=(() => {}), style={}, text, direction, activity, questionStyle, gs) => {
  let tableStyle = activity === 'Scavenger Hunt'? tstyles.tableSH : tstyles.table
  if (activity === 'Scavenger Hunt') {
    
    tstyles.text.fontSize = 28
    // tstyles.tableSH.minHeight = '1850'
  }
  let bingo = activity === 'Bingo' || activity === 'Tic Tac Bingo'
  return ( 
    <View wrap={false} style={[tableStyle, style.table]}>
    <Text style={tstyles.text}>{text}</Text>
        {data.map((row, row_index) =>
      <View key={row_index} style={tstyles.row}  wrap={false}>
        {row.map( (cell, col_index) =>
                      <View key={col_index} style={[tstyles.cell, style_function(row_index, col_index, bingo && data.length > 5 && data[3].length > 2 ? cell : data, direction, activity, questionStyle, gs)]}> 
                        {sanitize_block(cell)}
                      </View>
                )
        }
      </View>)}
    </View>
    )
}

export default Table