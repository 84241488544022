import React from 'react';

const UserIcon = props => {
  let name = props.name?.split(' ') || ['User']
  let initials = name.map(item=>{
    return item[0]
  })

  return (
    <div className={`user-icon ${props.className}`} style={props.style}>
      <div className="initials">
        {initials}
      </div>
    </div>
  );
};

export default UserIcon;
