import React, { useContext, useState } from "react";
import { AuthContext } from "../../app/context/auth-context";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "../../shared/components/FormElements/Button";
import Input from "../../shared/components/FormElements/Input";
import UserCard from "../UserCard";
import AccountSidebar from "./AccountSidebar";
import { useForm } from "../../shared/hooks/form-hook";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { VALIDATOR_MINLENGTH } from "../../shared/util/validators";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";

const AccountSettings = (props) => {
  const auth = useContext(AuthContext);
  const [processing, setProcessing] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [formState, inputHandler, setFormData] = useForm(
    {
      old: {
        value: "",
        isValid: false,
      },
      password: {
        value: "",
        isValid: false,
      },
      password2: {
        value: "",
        isValid: false,
      },
    },
    false
  );
  const authSubmitHandler = async (event) => {
    event.preventDefault();
    setProcessing(true);
    try {
      await sendRequest(
        `/api/users/new-password-logged`,
        "PUT",
        JSON.stringify({
          token: auth.token,
          userId: auth.user.userId,
          old: formState.inputs.old.value,
          password: formState.inputs.password.value,
        }),
        {
          "Content-Type": "application/json",
        }
      );
      setProcessing(false);
      setIsFinished(true);
    } catch (err) {
      setProcessing(false);
      setIsFinished(false);
    }
  };
  return (
    <div className="settings account">
      <ErrorModal error={error} onClear={clearError} />
      <AccountSidebar page="settings-account" />
      <UserCard title="Change Password">
        <p>Email: {auth.user.email}</p>
        {/* <p>Secondary Email: mvallery822@gmail.com</p> */}
        <Input
          id="old"
          element="input"
          onInput={inputHandler}
          validators={[VALIDATOR_MINLENGTH(6)]}
          label="Old password"
          hideText={true}
          autocomplete="off"
          disabled={processing || isFinished}
        />
        <Input
          id="password"
          element="input"
          onInput={inputHandler}
          validators={[VALIDATOR_MINLENGTH(6)]}
          label="New password"
          hideText={true}
          autocomplete="off"
          disabled={processing || isFinished}
        />
        <Input
          id="password2"
          element="input"
          onInput={inputHandler}
          validators={[VALIDATOR_MINLENGTH(6)]}
          label="Re-enter your password"
          hideText={true}
          autocomplete="off"
          disabled={processing || isFinished}
        />
        <Button
          disabled={
            !formState.isValid ||
            processing ||
            isFinished ||
            formState.inputs.password.value !== formState.inputs.password2.value
          }
          onClick={(e) => {
            authSubmitHandler(e);
          }}
        >
          Change Password
        </Button>
        <div
          style={{
            padding: "15px 0 15px 0",
            display: "flex",
            justifyContent: "center",
            height: "70px",
          }}
        >
          {processing ? <CircularProgress /> : null}
          {isFinished ? (
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{
                  paddingRight: "20px",
                  color: "darkgreen",
                  fontSize: "18px",
                  fontWeight: "bold",
                }}
              >
                PASSWORD CHANGED!
              </div>
            </div>
          ) : null}
        </div>
      </UserCard>
    </div>
  );
};

export default AccountSettings;
