import React, { useState, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";

import Modal from '../../shared/components/UIElements/Modal';
import { WSContext } from "../../app/context/ws-context";
import Button from "../../shared/components/FormElements/Button";
import { TextField } from "@material-ui/core";

const CreateNewVersion = (props) => {
  //use generalSelection & userSelection from props since it may be passed by user worksheets
  const {newVersionModal, setNewVersionModal} = props
  const [titleUpdated, setTitleUpdated] = useState(false)
  const ws = useContext(WSContext)
  const history = useHistory()
  const location = useLocation()

  const onChangeTitle = (e) => { 
    setTitleUpdated(true)
    const { name, value } = e.target;
    let tempGS = {...props.generalSelection}
    tempGS[name] = value
    ws.setGeneralSelection(tempGS)
    localStorage.setItem('gs', JSON.stringify({...tempGS}))
  };

  const getTitle = () => { //gets previous title and adds number to it if we find a v- as the last part of the title
    let title = props.generalSelection.title.split(' ')
    let lastPart = title[title.length-1].split('-')

    // assignment has a version assigned/ dash number v-___
    if (lastPart.length > 1 && lastPart[0] === 'v' && Number(lastPart[1]) > 0) {
      let number = Number(lastPart[1]) + 1
      title[title.length-1] = 'v-' + number

      // assignment has no number so add a v-___ to end but only if it wont cause title to be too long
    } else if (props.generalSelection.title.length <= 72){
      title.push('v-2')
      
    }

    return title.join(' ')
  }
  const createNewVersion = () => {
    const tempGS = {...props.generalSelection}
    const {title} = ws.generalSelection // if they changed title from onChangeTitle
    setNewVersionModal(false)
    if (!titleUpdated){
      tempGS.title = getTitle()
    } else {
      tempGS.title = title
    }
    if (location.pathname !== '/display-assignment'){
      history.push('/display-assignment')
    }
    ws.setPurchase(false)
    if (props.handleStartPDF){
      props.handleStartPDF("new", null, props.userSelection, tempGS)
    } else{
      ws.handleDuplicate("new", props.userSelection, tempGS);
    }

  }
  return(
    <Modal show={newVersionModal} onCancel={(e)=> {e.stopPropagation(); setNewVersionModal(false); setTitleUpdated(false)}}
    header="Recreate Assignment"
    footerClass="worksheet-item__modal-actions"
    className="new-ws-modal"
    footer={<Button danger onClick={createNewVersion}>CREATE ASSIGNMENT</Button>}
  >
    <div className="ws-title-container new-version">
      <TextField variant="filled" id="filled-basic" placeholder={props.generalSelection.title} required className="doc-title-input"
        label={<span className="">New Assignment Title</span>}
        name="title" type="text"
        value={titleUpdated ? ws.generalSelection.title : getTitle()}
        onChange={onChangeTitle}
        maxLength={75} autoFocus
        InputProps={{
          inputProps: { 
            type:'text',
            maxLength:75
          }
        }}
      />
    </div>
  </Modal>
  )
}

export default CreateNewVersion