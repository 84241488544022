import React, {useEffect} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './app/App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter} from 'react-router-dom';
import { Font } from '@react-pdf/renderer';

// import WendyOne from './app-files/fonts/WendyOne-Regular.ttf';
// import Rancho from './app-files/fonts/Rancho-Regular.ttf';
// import Tenali from './app-files/fonts/TenaliRamakrishna-Regular.ttf';
// import Didact from './app-files/fonts/DidactGothic-Regular.ttf';
// import RumRaisin from './app-files/fonts/RumRaisin-Regular.ttf';
// import Acme from './app-files/fonts/Acme-Regular.ttf'
// Font.register({ family: 'Rancho', src: Rancho, fontStyle: 'normal', fontWeight: 'bold'  })
// Font.register({ family: 'Tenali', src: Tenali, fontStyle: 'normal', fontWeight: 'bold'  })
// Font.register({ family: 'Didact', src: Didact, fontStyle: 'normal', fontWeight: 'bold'  })
// Font.register({ family: 'Rum Raisin', src: RumRaisin, fontStyle: 'normal', fontWeight: 'bold'  })
// Font.register({ family: 'Wendy One', src: WendyOne, fontStyle: 'normal', fontWeight: 'bold'  })
// Font.register({ family: 'Acme', src: Acme, fontStyle: 'normal', fontWeight: 'bold'  })

// const registerFont = () => {
//   Font.register({
//   family: "Montserrat",
//   src:
//   "http://fonts.gstatic.com/s/montserrat/v10/zhcz-_WihjSQC0oHJ9TCYC3USBnSvpkopQaUR-2r7iU.ttf",
//   });
//   };
  
//   useEffect(() => {
//   registerFont();
//   }, []);

ReactDOM.render(
  <BrowserRouter>
  <React.StrictMode>
    <App />
  </React.StrictMode>
  </BrowserRouter>
,
  document.getElementById('root')
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
