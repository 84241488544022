import { randInt, roundDec, shuffleArray, answerChoicesKey, selectRand, randDec, fixNum, removeDup, showQText } from "../general"
import {order, order2, orderAC, orderAC2, orderAC3, orderAC4} from '../ordering'
import {compareSymbol} from '../whole-numbers/co-whole'

const decimalListMaker = (userSelection) => {
  // let x = randInt(1000,9999)
  let x = randDec(1, 9, 3)
  let num1 =  x
  let num2 = roundDec(num1, 2)
  let num3 = roundDec(num1, 1)
  let num4 = roundDec((x+randDec(0.1, 0.8, 2)),3)
  let equalNum2 = num2 + '0'
  let moreNums = removeDup([roundDec(num1+randDec(0.01,0.99,2), 2), roundDec(num1+(randDec(0.1,0.4, 1)), 1), roundDec(num1+randDec(0.1, 0.6, 3), 3),roundDec(num1+randDec(0.1,0.9,1), 3)])
  if (userSelection.specify.placeValues==='Hundredths'){
    // num1 = x/100+randInt(2,9)/100
    num1 = roundDec(num1, 2)
    num2 = roundDec(num1, 1)
    num3 = roundDec(num1+randDec(0.1, 0.4, 1), 1)
    num4 = roundDec(num4+randDec(0.01, 0.08, 2), 2)
    equalNum2 = num2 + '0'
    moreNums = removeDup([roundDec(num1+randDec(0.010, 0.999,3), 2), roundDec(num1/0.9+1, 2), roundDec(x/1000, 2),roundDec(num1+randDec(0.1,0.4,1), 1)])
  }

  //want to ensure that decimals have a variety of place values 1 vs 2 vs 3, but also have one that is randomly selected.
  let xtraNum = selectRand(moreNums)

  let fullDecimalList = [num1, num2, num3, num4, ...moreNums]
  let numList = shuffleArray(fullDecimalList)
  let compareOnly = [[num1, num2], [num1, num2], [num1, num3], [num1, num3], [num1, num4], [num1, num4], [num1, xtraNum], [num2, equalNum2]]
  return {compareOnly:shuffleArray(selectRand(compareOnly)), order: numList}
}



const randWrongSymbol = ['+', '-', 'x', '#', '%']

const randTrueQ = ['Which of the following shows a true comparison?', 'Which shows a true comparison between two numbers?', 'Which shows an accurate comparison between numbers?', 'Which of the following shows a true comparison?']
const randFalseQ = ['Which of the following does NOT show a true comparison?', 'Which does NOT show a true comparison?', 'Which of the comparisons below is NOT true?', 'Which symbol does NOT accurately compare the two numbers?', 'Which comparison is false?', 'Which comparison is NOT accurate?']
let questionArr = [randFalseQ, randTrueQ]

export const compareDec = (userSelection, generalSelection) => {
  const [num1, num2] = decimalListMaker(userSelection, generalSelection).compareOnly
  let {answer, wrongAC} = compareSymbol(num1, num2)
  var AC = answerChoicesKey(answer, wrongAC[0], wrongAC[1], selectRand(randWrongSymbol))
  const questionText = userSelection.specify.ac ? 
    `Which of the following would create a true comparison between the two numbers?` :
    `Write a comparison symbol (<, >, or =) between the two numbers that creates a true comparison.`
  let showQuestionText = showQText(generalSelection, userSelection)

  let prob = showQuestionText ? `${questionText} \n \n \
    \u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0${fixNum(num1)} ____ ${fixNum(num2)}` : `${fixNum(num1)} ____ ${fixNum(num2)}`
  var problem = {prob: prob,
      answerChoices: AC,
      correctAnswer: answer}
  return problem
}

export const compareDecAC = (userSelection, generalSelection) => {
  const [num1A, num2A] = decimalListMaker(userSelection, generalSelection).compareOnly //run each individually to ensure a good different PV b/w each #, others use .order bc it doesn't matter as much.
  const [num1B, num2B] = decimalListMaker(userSelection, generalSelection).compareOnly
  const [num1C, num2C] = decimalListMaker(userSelection, generalSelection).compareOnly
  const [num1D, num2D] = decimalListMaker(userSelection, generalSelection).compareOnly

  let prob = [compareSymbol(num1A, num2A), compareSymbol(num1B, num2B), compareSymbol(num1C, num2C),compareSymbol(num1D, num2D)]
  let answerIdx = randInt(0,3)
  let questIdx = randInt(0,1)
  let questionText = selectRand(questionArr[questIdx])
  const symbol = (idx) => {
    if (idx === answerIdx){
      return questIdx === 0 ? prob[idx].wrong[0] : prob[idx].answer
    } else {
      return questIdx === 0 ? prob[idx].answer : prob[idx].wrong[0] //if looking for false answer - rest will be true;
    }
  }
  let answers = [`${fixNum(num1A)} ${symbol(0)} ${fixNum(num2A)}`, 
                `${fixNum(num1B)} ${symbol(1)} ${fixNum(num2B)}`, 
                `${fixNum(num1C)} ${symbol(2)} ${fixNum(num2C)}`, 
                `${fixNum(num1D)} ${symbol(3)} ${fixNum(num2D)}`]
  let answer = answers[answerIdx]
  answers.splice(answerIdx, 1)
  var AC = answerChoicesKey(answer, answers[0], answers[1], answers[2])

  var problem = {prob: questionText,
      answerChoices: AC,
      correctAnswer: answer}
  return problem
}

export const randCODec = (userSelection, generalSelection) => {
  let {probType, ac} = userSelection.specify
  let probArray = []
  if (probType['Comparing']){
    probArray.push(compareDec)
    if (ac && generalSelection.activity!=='Scavenger Hunt'){
      probArray.push(compareDecAC)
    }
  } 
  if (probType['Ordering']){
    probArray.push(order, order2)
    if (ac && generalSelection.activity!=='Scavenger Hunt'){
      probArray.push(orderAC, orderAC2, orderAC3, orderAC4)
    }
  } 

  var randProb = selectRand(probArray)
  return randProb(userSelection, generalSelection, decimalListMaker)
}