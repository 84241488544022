import {answerChoicesKey, randInt, selectRand, shuffleArray} from '../general.js'
import {cell_style_fraction, ak_cell_style, tstyles, horizontalFractions, divMultFractNumbers, createFraction, akTStyles } from './fract-shared'

import Table from '../Table.js'
import {Text,View,StyleSheet,} from "@react-pdf/renderer";
import { fixImproper } from './mixed-improper.js';
import { simplify } from './simplify.js';
  const { create, all } = require('mathjs')
const config = {
// Default type of number
// Available options: 'number' (default), 'BigNumber', or 'Fraction'
    number: 'Fraction'
}
const math = create(all, config)
const styleFractAnswer = (fract1, fract2, gs) => {
    var answerFract = math.format(math.divide(fract1, fract2)) 
    var splitAnswer = answerFract.split('/')
    var answer = {num:splitAnswer[0], denom:splitAnswer[1]}
    var shAnswerKey = {num:splitAnswer[0], denom:splitAnswer[1]} 
    

    return {answer, shAnswerKey}
}
const getDivFractAnswer = (fract1, fract2, gs) => {
    var answerFract = math.format(math.divide(fract1, fract2)) 
    var extraWrong = math.format(math.multiply(fract1, fract2)) 
  
    var splitAnswer = answerFract.split('/')
    var answer = {num:Number(splitAnswer[0]), denom:Number(splitAnswer[1]), answer:true} 
    var shAnswerKey =  {num:splitAnswer[0], denom:splitAnswer[1]}
    
    var splitWrong = extraWrong.split('/')
    let wrong = []
    let wrongCheck = ({num, denom}) => {
      if (denom !==0){
        let simpFract = simplify(num, denom)
        num = simpFract.num
        denom = simpFract.denom
      }
      let duplicate = false
      for (let i of wrong){
        if ((i.num === num && i.denom === denom) || (answer.num === num && answer.denom === denom)){
          duplicate = true
        }
      }
      if(!duplicate){
          wrong.push({num, denom})
      }
    }
    wrongCheck({num:answer.denom, denom:answer.num})
    wrongCheck({num:answer.num+1, denom:answer.denom})
    wrongCheck({num:answer.num+2, denom:answer.denom+1})
    wrongCheck({num:answer.num+1, denom:answer.denom+1})
    wrongCheck({num:splitWrong[0], denom:splitWrong[1]})

    return {answer, shAnswerKey, wrong:shuffleArray(wrong)}
  }
export const divFract = (userSelection, gs) => {
    var {number1, number2} = divMultFractNumbers(userSelection)

    let [styledNumber1, fract1] = createFraction(number1, gs);
    let [styledNumber2, fract2] = createFraction(number2, gs);
  
    var prob1 = {number1, number2, op:'÷'} 
    var prob2 = {number1:number2, number2:number1, op:'÷'}
    var randProb = [prob1, prob2][randInt(0,1)]

    let {answer, shAnswerKey, wrong} = randProb === prob1 ? getDivFractAnswer(fract1, fract2, gs) : getDivFractAnswer(fract2, fract1, gs)
  var AC = answerChoicesKey(answer, wrong[0], wrong[1], wrong[2])
        
    var problem = {prob: randProb,
        answerChoices: AC,
        correctAnswer: answer, shAnswerKey}
    return problem
}

export const randDivFract = (userSelection, gs) => {
    var probArray = [divFract]
    var randProb = selectRand(probArray)
    return randProb(userSelection, gs)
}