import { specAC, specAlg, specify } from "../helper"

export const MultDivDecimals = (props) => {
  const {probStyle, createLink, ac} = props
  const hz = probStyle === 'Horizontal'
  const [c1, c2] = ['Dividing Decimals', 'Multiplying Decimals']
  const [div1d, div1w, div2d, div2w] = ['1 digit decimal', '1 digit whole number', '2 digit decimal', '2 digit whole number']
  const [dig1_1, dig2_1, dig3_1, dig4_1, dig2_2, dig3_2] = ['1 by 1 digit', '2 by 1 digit', '3 by 1 digit', '4 by 1 digit', '2 by 2 digit', '3 by 2 digit']
  
  const title = (mod1, mod2, mod3) => {
    return `Multiplying & Dividing ${mod1} by ${mod2} Digit Decimals ${mod3}`
  }
  const createSpec = (spec1, spec2, spec3) => {
    let specC1 = [specify('dividend', spec1), specify('divisor', spec2), specify('probStyle', probStyle), specAlg, specAC(ac)]
    let specC2 = [specify('numbers', spec3), specify('probStyle', probStyle), specAlg, specAC(ac)]

    return {[c1]: specC1, [c2]: specC2}
  }
  return (<>
    <div className="buttonGroup">
      <div className="concept-topic">Whole Number Divisor Algorithm</div>
      {createLink([c1, c2], title('1-2', '1', ''), '', createSpec(['2 digits'], [div1w], [dig1_1]), hz ? 12 : 18)}
      {createLink([c1, c2], title('2', '1', ''), '', createSpec(['2 digits'], [div1w], [dig2_1]), hz ? 12 : 18)}
      {createLink([c1, c2], title('3', '1', ''), '', createSpec(['3 digits'], [div1w], [dig3_1]), hz ? 12 : 18)}
      {createLink([c1, c2], title('3-4', '1', ''), '', createSpec(['3 digits', '4 digits'], [div1w], [dig3_1, dig4_1]), hz ? 12 : 18)}
      {createLink([c1, c2], title('4', '1', ''), '', createSpec(['4 digits'], [div1w], [dig4_1]), hz ? 12 : 18)}
      {createLink([c1, c2], title('2', '2', ''), '', createSpec(['2 digits'], [div2w], [dig2_2]), 18)}
      {createLink([c1, c2], title('3', '1-2', ''), '', createSpec(['3 digits'], [div1w, div2w], [dig3_1, dig3_2]), hz ? 12 : 18)}
      {createLink([c1, c2], title('3', '2', ''), '', createSpec(['3 digits'], [div2w], [dig3_2]), hz ? 12 : 18)}
    </div>
    <div className="buttonGroup">
      <div className="concept-topic">Decimal Divisor Algorithm</div>
      {createLink([c1, c2], title('1-2', '1', ''), '', createSpec(['2 digits'], [div1d], [dig1_1]), hz ? 12 : 18)}
      {createLink([c1, c2], title('2', '1', ''), '', createSpec(['2 digits'], [div1d], [dig2_1]), hz ? 12 : 18)}
      {createLink([c1, c2], title('3', '1', ''), '', createSpec(['3 digits'], [div1d], [dig3_1]), hz ? 12 : 18)}
      {createLink([c1, c2], title('3-4', '1', ''), '', createSpec(['3 digits', '4 digits'], [div1d], [dig3_1, dig4_1]), hz ? 12 : 18)}
      {createLink([c1, c2], title('4', '1', ''), '', createSpec(['4 digits'], [div1d], [dig4_1]), hz ? 12 : 18)}
      {createLink([c1, c2], title('2', '2', ''), '', createSpec(['2 digits'], [div2d], [dig2_2]), 18)}
      {createLink([c1, c2], title('3', '1-2', ''), '', createSpec(['3 digits'], [div1d, div2d], [dig3_1, dig3_2]), hz ? 12 : 18)}
      {createLink([c1, c2], title('3', '2', ''), '', createSpec(['3 digits'], [div2d], [dig3_2]), hz ? 12 : 18)}
    </div>
    <div className="buttonGroup">
      <div className="concept-topic">Whole Number & Decimal Divisor Algorithm</div>
      {createLink([c1, c2], title('1-2', '1', ''), '', createSpec(['2 digits'], [div1w, div1d], [dig1_1]), hz ? 12 : 18)}
      {createLink([c1, c2], title('2', '1', ''), '', createSpec(['2 digits'], [div1w, div1d], [dig2_1]), hz ? 12 : 18)}
      {createLink([c1, c2], title('3', '1', ''), '', createSpec(['3 digits'], [div1d, div1w], [dig3_1]), hz ? 12 : 18)}
      {createLink([c1, c2], title('3-4', '1', ''), '', createSpec(['3 digits', '4 digits'], [div1d, div1w], [dig3_1, dig4_1]), hz ? 12 : 18)}
      {createLink([c1, c2], title('4', '1', ''), '', createSpec(['4 digits'], [div1d, div1w], [dig4_1]), hz ? 12 : 18)}
      {createLink([c1, c2], title('2', '2', ''), '', createSpec(['2 digits'], [div2d, div2w], [dig2_2]), hz ? 12 : 18)}
      {createLink([c1, c2], title('3', '1-2', ''), '', createSpec(['3 digits'], [div1d, div1w, div2d, div2w], [dig3_1, dig3_2]), hz ? 12 : 18)}
      {createLink([c1, c2], title('3', '2', ''), '', createSpec(['3 digits'], [div2d, div2w], [dig3_2]), hz ? 12 : 18)}
    </div>
    </>
  )
}