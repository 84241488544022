import { React, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { WSContext } from "../../app/context/ws-context";
import infoIcon from '../../app-files/images/icon-info.svg'
import SwitchPremade from "../components/SwitchPremade";

export const useConceptButton = (concept) => {
  const ws = useContext(WSContext);
  if (ws.generalSelection.activity === 'Scavenger Hunt' && concept.includes('Comparing')){
    return (
        <button name="concept" value={concept} className="concept-button disabled" disabled>
          {concept}
        </button>
      );
  }
  const conceptParam = concept.split(' ').join('-')
  return (
    <Link to={`premade-worksheets/${conceptParam}`}>
      <button type="button" name="concept" value={concept} className="concept-button premade" onClick={(e) => ws.handleSpecifyInput(e)}>
        {concept}
      </button>
    </Link>
  );
};

const PremadeConceptSelection = (props) => {
  const ws = useContext(WSContext)
  const {activity} = ws.generalSelection

  useEffect(()=>{
    window.scrollTo(0, 0);
  }, [])

  const setEditing = ws.setEditing
  useEffect(()=> {
    setEditing(-1);
  }, [setEditing])

  const handleEditActivity = () => {
    props.history.push(`/premade-activity-selection`);
  }

  return (
    <div className="main-container concept-selection-container premade-ws">
      <div className="title-container">
        <h1 className="premade-title">Select Premade Concept 
          <SwitchPremade path="/concept-selection" title="Switch to a custom assignment"/>
          <div className="popover-container">
            <img src={infoIcon} alt="information"></img>
            <div className="popover">Premade assignments are still randomly generated on the spot so that you can create as many versions as you need. The customizations are premade for you to make things faster.</div>
          </div>
        </h1>
        <div className="activity-label">
          <div className="activity-title">Activity: {activity} <button aria-label="change activity" className="trash-button edit-button" onClick={() => {handleEditActivity();}}></button></div>
          <div className="price">{activity === 'Worksheet' ? '(Free)' : '$1.50 or 1 Token'}</div>
        </div>
      </div>

      <div className="concept-container">
        <div className="buttonGroup">
          <div className="concept-topic">Whole Numbers</div>
          <div className="concept-button-container" >
            {useConceptButton("Adding Whole Numbers")}
            {useConceptButton("Subtracting Whole Numbers")}
            {useConceptButton("Adding & Subtracting Whole Numbers")}

            {useConceptButton("Multiplying Whole Numbers")}
            {useConceptButton("Dividing Whole Numbers")}
            {useConceptButton("Multiplying & Dividing Whole Numbers")}
            {useConceptButton("Comparing Whole Numbers")}

          </div>
        </div>
        <div className="buttonGroup">
          <div className="concept-topic">Integers</div>
          <div className="concept-button-container" >
            {useConceptButton("Adding Integers")}
            {useConceptButton("Subtracting Integers")}
            {useConceptButton("Adding & Subtracting Integers")}
            {useConceptButton("Multiplying Integers")}
            {useConceptButton("Dividing Integers")}
            {useConceptButton("Multiplying & Dividing Integers")}
            {useConceptButton("Comparing Integers")}

          </div>
        </div>
        <div className="buttonGroup">
          <div className="concept-topic">Decimals</div>
          <div className="concept-button-container" >
            {useConceptButton("Adding Decimals")}
            {useConceptButton("Subtracting Decimals")}
            {useConceptButton("Adding & Subtracting Decimals")}
            {useConceptButton("Multiplying Decimals")}
            {useConceptButton("Dividing Decimals")}
            {useConceptButton("Multiplying & Dividing Decimals")}
            {useConceptButton("Comparing Decimals")}

          </div>
        </div>
        <div className="buttonGroup">
          <div className="concept-topic">Fraction Operations</div>
          <div className="concept-button-container" >
            {useConceptButton("Adding Fractions")}
            {useConceptButton("Subtracting Fractions")}
            {useConceptButton("Multiplying Fractions")}
            {useConceptButton("Dividing Fractions")}
          </div>
        </div>
        <div className="buttonGroup">
          <div className="concept-topic">Fraction Basics</div>
          <div className="concept-button-container" >
            {useConceptButton("Comparing Fractions")}
            {useConceptButton("Simplifying Fractions")}
            {/* {useConceptButton("GCF & LCM")} */}
            {useConceptButton("Mixed & Improper Fractions")}
          </div>
        </div>
        <div className="buttonGroup">
          <div className="concept-topic">Equations</div>
          <div className="concept-button-container" >
            {useConceptButton("Whole Number Equations")}
            {useConceptButton("Integer Equations")}
            {useConceptButton("Decimal Equations")}
          </div>
        </div>
        <div className="buttonGroup">
          <div className="concept-topic">Other Concepts</div>
          <div className="concept-button-container" >
            {useConceptButton("Order of Operations")}
            {useConceptButton("Input Output Tables")}
            {/* {useConceptButton("Area & Perimeter")} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PremadeConceptSelection;
