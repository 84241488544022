import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "../shared/components/FormElements/Button";
import Input from "../shared/components/FormElements/Input";
import { useForm } from "../shared/hooks/form-hook";
import { useHttpClient } from "../shared/hooks/http-hook";
import { VALIDATOR_MINLENGTH } from "../shared/util/validators";
import UserCard from "./UserCard";
import ErrorModal from "../shared/components/UIElements/ErrorModal";

const ResetPwd = (props) => {
  const location = useLocation();
  const [loaded, setLoaded] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const [valid, setValid] = useState(false);
  const [resetData, setResetData] = useState(null);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const [formState, inputHandler, setFormData] = useForm(
    //(initialInputs,initialInputValidity) returns [newState, inputHandler]
    {
      password: {
        value: "",
        isValid: false,
      },
      password2: {
        value: "",
        isValid: false,
      },
    },
    false
  );
  useEffect(() => {
    initializeData();
  }, []);
  const initializeData = async () => {
    let token = null;
    if (location.search.match(/token=.+&id=.+/gi)) {
      token = location.search.split("token=")[1].split("&")[0];
      let userId = location.search.split("id=")[1];
      const responseData = await sendRequest(
        `/api/users/verify-reset`,
        "POST",
        JSON.stringify({
          token: token,
          userId: userId,
        }),
        {
          "Content-Type": "application/json",
        }
      );
      if (responseData.status === "valid") {
        setValid(true);
      } else {
      }
      setResetData({ token, userId });
    } else {
    }
    setLoaded(true);
  };
  const authSubmitHandler = async (event) => {
    event.preventDefault();
    setProcessing(true);
    try {
      await sendRequest(
        `/api/users/new-password`,
        "PUT",
        JSON.stringify({
          token: resetData.token,
          userId: resetData.userId,
          password: formState.inputs.password.value,
        }),
        {
          "Content-Type": "application/json",
        }
      );
      setProcessing(false);
      setIsFinished(true);
    } catch (err) {
      setProcessing(false);
      setIsFinished(false);
    }
  };
  return (
    <div className="authenticate-container">
      <ErrorModal error={error} onClear={clearError} />
      {!loaded ? (
        <UserCard title="Reset your password">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "30px",
            }}
          >
            <CircularProgress />
          </div>
          <div
            style={{
              padding: "15px 0 15px 0",
              display: "flex",
              justifyContent: "center",
              height: "70px",
            }}
          ></div>
        </UserCard>
      ) : null}
      {!valid && loaded ? (
        <UserCard title="Reset your password">
          <p className="blurb" style={{ textAlign: "center" }}>
            This password reset link is invalid or expired.
          </p>
          <Link to="/auth/forgot-password">
            Click here to create a new password reset request.
          </Link>
          <div
            style={{
              padding: "15px 0 15px 0",
              display: "flex",
              justifyContent: "center",
              height: "70px",
            }}
          ></div>
        </UserCard>
      ) : null}
      {valid && loaded ? (
        <UserCard title="Reset your password">
          <p className="blurb">Please enter your new password</p>
          <Input
            id="password"
            label="New Password"
            element="input"
            validators={[VALIDATOR_MINLENGTH(6)]}
            onInput={inputHandler}
            disabled={processing || isFinished}
            hideText={true}
            autocomplete="off"
          />
          <Input
            id="password2"
            label="Re-enter New Password"
            element="input"
            validators={[VALIDATOR_MINLENGTH(6)]}
            onInput={inputHandler}
            disabled={processing || isFinished}
            hideText={true}
            autocomplete="off"
          />
          <Button
            disabled={
              !formState.isValid ||
              processing ||
              isFinished ||
              formState.inputs.password.value !==
                formState.inputs.password2.value
            }
            onClick={(e) => {
              authSubmitHandler(e);
            }}
          >
            Reset Password
          </Button>
          <div
            style={{
              padding: "15px 0 15px 0",
              display: "flex",
              justifyContent: "center",
              height: "70px",
            }}
          >
            {processing ? <CircularProgress /> : null}
            {isFinished ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    paddingRight: "20px",
                    color: "darkgreen",
                    fontSize: "18px",
                    fontWeight: "bold",
                  }}
                >
                  PASSWORD CHANGED!
                </div>
                <Link to="/login">Log in</Link>
              </div>
            ) : null}
          </div>
        </UserCard>
      ) : null}
    </div>
  );
};

export default ResetPwd;
