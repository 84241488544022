import { specAC, specAlg, specify } from "../helper"

export const MultiplyWholeNumbers = (props) => {
  const {probStyle, createLink, ac} = props
  const [c1] = ['Multiplying Whole Numbers']
  const [dig1_1, dig2_1, dig3_1, dig4_1, dig2_2, dig3_2] = ['1 by 1 digit', '2 by 1 digit', '3 by 1 digit', '4 by 1 digit', '2 by 2 digit', '3 by 2 digit']

  const title = (mod1) => {
    return `Multiplying ${mod1} Digit Whole Numbers`
  }
  const createSpec = (spec1) => {
    let specC1 = [specify('numbers', spec1), specify('probStyle', probStyle), specAlg, specAC(ac)]

    return {[c1]: specC1}
  }

  return (<>
    <div className="buttonGroup">
      <div className="concept-topic">Muliplying Whole Numbers</div>
        {createLink([c1], title('1'), '', createSpec([dig1_1]), 35)}
        {createLink([c1], title('1 by 1 & 2 by 1'), '', createSpec([dig1_1, dig2_1]), 35)}
        {createLink([c1], title('2 by 1'), '', createSpec([dig2_1]), 35)}
        {createLink([c1], title('2 by 1 & 3 by 1'), '', createSpec([dig2_1, dig3_1]), 35)}
        {createLink([c1], title('3 by 1'), '', createSpec([dig3_1]), 35)}
        {createLink([c1], title('3 by 1 & 4 by 1'), '', createSpec([dig3_1, dig4_1]), 35)}
        {createLink([c1], title('4 by 1'), '', createSpec([dig4_1]), 35)}
        {createLink([c1], title('4 by 1 & 2 by 2'), '', createSpec([dig4_1, dig2_2]), 18)}
        {createLink([c1], title('2 by 2'), '', createSpec([dig2_2]), 18)}
        {createLink([c1], title('2 by 2 & 3 by 2'), '', createSpec([dig2_2, dig3_2]), 18)}
        {createLink([c1], title('3 by 2'), '', createSpec([dig3_2]), 18)}

    </div>

    {/* <div className="buttonGroup">
      <div className="concept-topic">Application Problems</div>
        {createLink([c1], 'Multiplying 1 Digit Whole Numbers', '', {[c1]: [{name: 'numbers', value: ['3 digit numbers']}, {name: 'numbers', value: ['1 by 1 digit']}, 32)}
        {createLink([c1], 'Multiplying 2 by 1 Digit Whole Numbers', '', {[c1]: [{name: 'numbers', value: ['3 digit numbers']}, {name: 'numbers', value: ['2 by 1 digit']}, 32)}
    </div> */}
    </>
  )
}

