import { specAC, specAlg, specify } from "../helper"

export const AddSubDecimals = (props) => {
  const {probStyle, createLink, ac} = props
  const hz = probStyle === 'Horizontal'
  const [c1, c2] = ['Adding Decimals', 'Subtracting Decimals']
  const [dig1_3H, dig3_4H, dig4_5H, dig4_5Th] = ['1-3 digits to the hundredths', '3-4 digits to the hundredths', '4-5 digits to the hundredths', '4-5 digits to the thousandths']
  const [ph, samePV, diffPV] = ['Add placeholder zeros', 'Same decimal place values', 'Different decimal place values']
  
  const title = (mod1, mod2) => {
    return `Adding & Subtracting ${mod1} digit Decimals to the ${mod2}`
  }

  const createSpec = (spec1, spec2) => {
    let specAS = [specify('numbers', spec1), specify('placeValues', spec2), specify('probStyle', probStyle), specAlg, specAC(ac)]

    return {[c1]: specAS, [c2]: specAS}
  }
  return (<>
    <div className="buttonGroup">
      <div className="concept-topic">Add & Subtract Decimals (Same place values)</div>
      {createLink([c1, c2], title('1-3', 'hundredths'), '', createSpec([dig1_3H], [samePV]), 18)}
      {createLink([c1, c2], title('3-4', 'hundredths'), '', createSpec([dig3_4H], [samePV]), 18)}
      {createLink([c1, c2], title('4-5', 'hundredths'), '', createSpec([dig4_5H], [samePV]), 18)}
      {createLink([c1, c2], title('4-5', 'thousandths'), '', createSpec([dig4_5Th], [samePV]), 18)}
    </div>

    <div className="buttonGroup">
      <div className="concept-topic">Add & Subtract Decimals (Different place values)</div>
      {createLink([c1, c2], title('1-3', 'hundredths'), '', createSpec([dig1_3H], [diffPV]), 18)}
      {createLink([c1, c2], title('3-4', 'hundredths'), '', createSpec([dig3_4H], [diffPV]), 18)}
      {createLink([c1, c2], title('4-5', 'hundredths'), '', createSpec([dig4_5H], [diffPV]), 18)}
      {createLink([c1, c2], title('4-5', 'thousandths'), '', createSpec([dig4_5Th], [diffPV]), 18)}
    </div>
    <div className="buttonGroup">
      <div className="concept-topic">Add & Subtract Decimals (Any place values)</div>
      {createLink([c1, c2], title('1-3', 'hundredths'), '', createSpec([dig1_3H], [diffPV, samePV]), 18)}
      {createLink([c1, c2], title('3-4', 'hundredths'), '', createSpec([dig3_4H], [diffPV, samePV]), 18)}
      {createLink([c1, c2], title('4-5', 'hundredths'), '', createSpec([dig4_5H], [diffPV, samePV]), 18)}
      {createLink([c1, c2], title('4-5', 'thousandths'), '', createSpec([dig4_5Th], [diffPV, samePV]), 18)}
    </div>
    </>
  )
}