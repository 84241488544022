import { useState, useCallback, useRef, useEffect, useContext } from "react";
import { AuthContext } from "./../../app/context/auth-context";

export const useHttpClient = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const auth = useContext(AuthContext);

  const activeHttpRequests = useRef([]);

  const sendRequest = useCallback(
    async (url, method = "GET", body = null, headers = {}) => {
      setIsLoading(true);
      const httpAbortCtrl = new AbortController();
      activeHttpRequests.current.push(httpAbortCtrl);
      let authHeaders = headers;
      if (localStorage && localStorage.getItem("userData")) {
        const session = JSON.parse(localStorage.getItem("userData"));
        authHeaders.Authorization = session.token;
      }
      try {
        const response = await fetch(url, {
          method,
          body,
          headers: authHeaders,
          signal: httpAbortCtrl.signal,
        });
        const responseData = await response.json();
        activeHttpRequests.current = activeHttpRequests.current.filter(
          (reqCtrl) => reqCtrl !== httpAbortCtrl
        );
        if(response.status === 403 && responseData.message === 'Invalid token.') {
          auth.logout();
        }
        if (!response.ok) {
          throw new Error(responseData.message);
        }
        setIsLoading(false);
        return responseData;
      } catch (err) {

        setError(err.message);
        setIsLoading(false);
        throw err;
      }
    },
    []
  );

  const clearError = () => {
    setError(null);
  };

  const setManualError = manualErrorData => {
    setError(manualErrorData);
  }

  useEffect(() => {
    return () => {
      activeHttpRequests.current.forEach((abortCtrl) => abortCtrl.abort());
    };
  }, []);
  return { isLoading, error, sendRequest, clearError, setManualError };
};
