import React from 'react'
import { Link } from 'react-router-dom'
import downloadIcon from '../../app-files/images/icon-download.svg'

const DirectionsScavengerHunt = () => {
  return(
    <Link  className="directions-link" to="/activity-directions/WorksheetWonderland-ScavengerHunt-ActivityDirections-RecordingSheets.pdf" target="_blank" download>
    <img src={downloadIcon} alt="download"/>Student Directions & Recording Sheets</Link>
  )
}

export default DirectionsScavengerHunt