import { React, useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import GeneralCustomize from "./GeneralCustomize";
import { AuthContext } from "../../app/context/auth-context";

import BackArrow from '../../app-files/images/back-arrow.svg'

import { conceptSpecify, conceptProbStyle, conceptLevel, conceptRadio, conceptAppLevel } from "./conceptInputs";
import { errorValidation } from "./errorValidation";
import { WSContext } from "../../app/context/ws-context";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import SwitchPremade from "../components/SwitchPremade";

const ConceptCustomization = (props) => {
  const auth = useContext(AuthContext);
  const ws = useContext(WSContext);
  let {specify} = ws.inputState

  const [validationList, setValidationList] = useState([])
  let tempValidationList = []
  const createValidationList = (type, name) => {
    tempValidationList.push({type:type, name:name})
  }

  const setConcept = (concept) => {
    let tempInput = JSON.parse(JSON.stringify(ws.inputState));
    tempInput.concept = concept
    ws.setInputState(tempInput)
  }
  
  useEffect(()=>{
    window.scrollTo(0, 0)
  }, [])

  useEffect(()=> {
    setValidationList(tempValidationList)
    errorValidation(specify, tempValidationList)
  },[ws.inputState])

  useEffect(()=> {
    //dismount clear editing
    return ()=> {
      ws.setEditing(-1)
      ws.resetMainState('input')
    }
  }, [])

  if (!ws.inputState.concept && localStorage.getItem('concept')){
    setConcept(localStorage.getItem('concept'))
  }

  const customizeContainer = (func1, func2, func3, func4, func5) =>{
    let conceptSlug = ws.inputState.concept.toLowerCase().split(' ').join('-')

      return (
          <div className="main-container concept-customize">
            <div className='title-container'>
              <h1>
                <Link to='/concept-selection'>
                  <button className="back-btn" aria-label="Go Back" onClick= {()=>ws.resetMainState('input')}><img className="back-arrow" src={BackArrow} alt="back"></img></button>
                </Link>
                {ws.inputState.concept}
                <SwitchPremade path={`/premade-worksheets/${conceptSlug}`} title="View premade assignments"/>
              </h1>
            </div>
            <div className='radio-container'>
                {func1} {func2} {func3} {func4} {func5}
            </div>
            <GeneralCustomize
              validationList = {validationList}
            />
          </div>
      )
  }
  let addSubWhole = ["1 digit numbers", "2 digit numbers", "3 digit numbers", "4 digit numbers", "5 digit numbers", "6 digit numbers", '7 digit numbers']
  let multWhole = ["1 by 1 digit","2 by 1 digit","3 by 1 digit", "4 by 1 digit","2 by 2 digit","3 by 2 digit"]
  let divWhole = ["2 by 1 digit","3 by 1 digit","4 by 1 digit","3 by 2 digit", "4 by 2 digit"]
  let dividend =["2 digits", "3 digits","4 digits"]
  let divisor = ["1 digit whole number", "2 digit whole number", '1 digit decimal', '2 digit decimal']
  let multDec = ["1 by 1 digit", "2 by 1 digit", "3 by 1 digit","4 by 1 digit", "2 by 2 digit","3 by 2 digit"]
  let asDecNums = ["1-3 digits to the hundredths", "3-4 digits to the hundredths", "4-5 digits to the hundredths", "4-5 digits to the thousandths"]
  let asDecPV = ["Same decimal place values", "Different decimal place values"]
  let probType = ()=>conceptSpecify(ws, "probType", "Problem Type", ["Algorithm","Application" ], createValidationList)
  let algStyle = ()=>conceptProbStyle(ws, "probStyle", "Algorithm Style", ["Set Up","Horizontal"], createValidationList)
  let compareProbType = ()=>conceptSpecify(ws, "probType", "Include", ['Comparing','Ordering'], createValidationList)
  let fractDenom = ()=>conceptSpecify(ws, "denominator", "Denominator Types", ['Common','Uncommon'], createValidationList)
  let fractMaxDenom = ()=>conceptRadio(ws, "maxDenominator", "Max Denominator", ['5', '10', '15'], createValidationList)
  let placeholderZeroCheck = <FormControlLabel
    control={<Checkbox
                className="checkbox"
                name="placeholderZero"
                value={specify.placeholderZero}
                onChange={ws.handleSpecifyInput}
                color="primary"
            />}
  label={<span className="checkbox">Include placeholder zeros</span>}
/>
  // const algorithm = () => algStyle()      

  if (specify && specify.probType){
    if (specify.probType['Algorithm'])
      var algorithm = specify?.probType?.Algorithm && !ws.inputState.concept.includes('Integer') && algStyle()   
    if (specify.probType['Application'])
      var application = conceptAppLevel(ws, "level", "Application Level", ["1: One step", "2: Multi-step"], createValidationList)   
  }
  const dynamicProbType = (style, type) => {
  if (algorithm||application) {
    return (
    <div className='dynamic-container' style={{flexDirection: style==='hz' ? 'row':'column'}}>
      {algorithm} {specify?.probType?.Application && application}
    </div>
    ) 
  }
  return <div style={{minWidth:'270px'}}></div>
}
  switch(ws.inputState.concept) { 

    ////////////////////////// WHOLE NUMBERS ///////////////////////////////

    case "Adding Whole Numbers":  
      return customizeContainer(conceptSpecify(ws, "numbers", "Include", addSubWhole, createValidationList),
              probType(), dynamicProbType()
            );
    case "Subtracting Whole Numbers":
      return customizeContainer(conceptSpecify(ws, "numbers", "Include", addSubWhole, createValidationList),
              conceptSpecify(ws, "regroup", "Include", ['No regrouping', 'Regroup without zero', 'Regroup with zero'], createValidationList),
              probType(), dynamicProbType()
            );
    case "Multiplying Whole Numbers":
      return customizeContainer(conceptSpecify(ws, "numbers", "Include", multWhole, createValidationList),
              probType(), dynamicProbType()
            );
    case "Dividing Whole Numbers":
      return customizeContainer(conceptSpecify(ws, "numbers", "Include", divWhole, createValidationList ),
              probType(), dynamicProbType()   
            );
    case "Comparing Whole Numbers":  
      return customizeContainer(compareProbType(),
            conceptSpecify(ws, "numbers", "Include", addSubWhole, createValidationList ),
          );

    /////////////////////////// DECIMALS ///////////////////////////

    case "Dividing Decimals":
      return customizeContainer(
        <div className="sub-container">
          <div>{conceptSpecify(ws, "divisor", "Divisor", divisor, createValidationList)}</div>
          <div>{conceptSpecify(ws, "dividend", "Dividend", dividend, createValidationList)}</div>
        </div>,
              probType(), dynamicProbType()
            );
    case "Multiplying Decimals":
      return customizeContainer(conceptSpecify(ws, "numbers", "Include", multDec, createValidationList),
              probType(), dynamicProbType()
            );
    case "Subtracting Decimals":{
      let placeholderZero
      if (specify && specify.placeValues && specify.placeValues['Different decimal place values'] &&
      specify && specify.probType && specify.probType['Algorithm'] && specify && specify.probStyle === 'Set Up'){
        // placeholderZero = conceptSpecify(ws, "placeholderZero", 'Display Style', ["Add placeholder zeros"], createValidationList)
        placeholderZero = placeholderZeroCheck
      }
      return customizeContainer(
        <div className="sub-container">
          <div>{conceptSpecify(ws, "numbers", "Include", asDecNums, createValidationList)}</div>
          <div>{conceptSpecify(ws, "placeValues", "Place Values", asDecPV, createValidationList)}</div>
          <div style={{marginLeft:'15px', minHeight:'42px'}}>{placeholderZero}</div>
        </div>,
        <div className="sub-container placeholder">
        <div>{probType()}</div>
        <div>{dynamicProbType('vt')}</div>
      </div>            
      )

    }
    case "Adding Decimals":{
      let placeholderZero;
      if (specify && specify.placeValues && specify.placeValues['Different decimal place values'] &&
        specify && specify.probType && specify.probType['Algorithm'] && specify && specify.probStyle === 'Set Up'
      ){
        placeholderZero = placeholderZeroCheck
      }
      return customizeContainer(
        <div className="sub-container">
          <div>{conceptSpecify(ws, "numbers", "Include", asDecNums, createValidationList)}</div>
          <div>{conceptSpecify(ws, "placeValues", "Place Values", asDecPV, createValidationList)}</div>
          <div style={{marginLeft:'15px', minHeight:'42px'}}>{placeholderZero}</div>
        </div>
        ,
        <div className="sub-container placeholder">
          <div>{probType()}</div>
          <div>{dynamicProbType('vt')}</div>
        </div>
        );
    }
    case "Comparing Decimals":  
    return customizeContainer(compareProbType(),
          conceptRadio(ws, "placeValues", "Place Value", ['Hundredths','Thousandths'], createValidationList ),
        );

    ///////////////////////// OTHER /PRE-ALG /////////////////////////

    case "Order of Operations":
      return customizeContainer(conceptSpecify(ws, "numberType", "Include", ["Whole numbers", "Decimals","Integers",], createValidationList),
              conceptSpecify(ws, "steps", "Steps", ["3 steps", "4 steps", "5 steps"], createValidationList),
              conceptLevel(ws, "Problem Level", ["Small numbers (-5, 8, 0.5)","Medium numbers (-15, 17, 1.2)","Large numbers (-28, 45, 7.2)",], createValidationList)
            );
    case "Input Output Tables":
      return customizeContainer(conceptSpecify(ws, "numberType", "Include", ["Whole numbers","Decimals",], createValidationList),
              conceptSpecify(ws, "numberSize", "Numbers", ["Small","Medium","Large",], createValidationList),
              conceptSpecify(ws, "steps", "Steps", ["One-step","Two-steps",], createValidationList),
             )  

    ///////////////////////////////// FRACTIONS /////////////////////////////////////

    case "Adding Fractions":
      return customizeContainer(
        <div className="sub-container">
          <div>{fractDenom()}</div><div>{fractMaxDenom()}</div>
        </div>,
        <div className="sub-container">
          <div>{conceptSpecify(ws, "numbers", "Include", [
          "Fractions", "Mixed Numbers"], createValidationList)}</div>
          <div>{algStyle()}</div>
        </div> 
      )
    case "Subtracting Fractions":
      return customizeContainer(
        <div className="sub-container">
          <div>{fractDenom()}</div><div>{fractMaxDenom()}</div>
        </div>,
        <div className="sub-container">
          <div>{conceptSpecify(ws, "numbers", "Include", [
          "Fractions", "Mixed Numbers", "Mixed Numbers With Regrouping"], createValidationList)}</div>
          <div>{algStyle()}</div>
        </div> 
                  // <div>{algorithm}</div>
                // conceptLevel(props, "Problem Level", ["One step","Two step","Multi-step",])
             );
    case "Multiplying Fractions":
      return customizeContainer(conceptSpecify(ws, "numbers", "Include", [
                "Unit Fractions with Whole Numbers", "Fractions with Whole Numbers", "Fractions",
                "Mixed Numbers", "Mixed Numbers with Whole Numbers"], createValidationList),
            );
    case "Dividing Fractions":
      return customizeContainer(conceptSpecify(ws, "numbers", "Include", [
                "Unit Fractions with Whole Numbers","Fractions with Whole Numbers", "Fractions",
                "Mixed Numbers", "Mixed Numbers with Whole Numbers"], createValidationList),    
            );

      ////// FRACTION BASICS ////////
    case "Comparing Fractions":
      return customizeContainer(fractDenom(), fractMaxDenom()
              // algStyle()
            );
    case "Simplifying Fractions": //keep calling it steps because Level at the moment is treated differently as a radio button. I want them to be able to select any option.
      return customizeContainer(conceptSpecify(ws, "steps", "Level (Higher level will have larger shared factors)", [
        "Some may not require simplifying","1 easy","2 medium","3 difficult", "4 challenging"], createValidationList),
              // algStyle()
            );
    case "Mixed & Improper Fractions":
      return customizeContainer(conceptSpecify(ws, "type", "Conversion Type", ["Mixed to Improper","Improper to Mixed"], createValidationList),
      conceptSpecify(ws, "numbers", "Include Denominators", ["Up to 10","10 to 20"], createValidationList),
              // algStyle()
            );

    ///////////////////////// AREA PERIMETER /////////////////////////////

    case "Area":
      return customizeContainer(conceptSpecify(ws, "numberType", "Include", ["Whole Numbers","Decimals",], createValidationList),
              conceptSpecify(ws, "numbers", "Include", multWhole, createValidationList),
              // conceptSpecify(props, "specify", "Include", ["Rectangles","Parallelograms", "Triangles", "Trapezoids"]),
              )
    case "Perimeter":
      let whole
      if (specify.numberType){
        if (specify.numberType['Decimals']){
          var decimal = conceptSpecify(ws, "decimals", "Decimals", asDecNums, createValidationList)
          var decimal2 = conceptSpecify(ws, "placeValues", "Decimal Place Values", asDecPV, createValidationList);
        } if (ws.inputState.specify.numberType['Whole Numbers']){
          whole = conceptSpecify(ws, "wholeNumbers", "Whole Numbers", addSubWhole.slice(0,3), createValidationList);
        }
      }
      return customizeContainer(
        <div className="sub-container">
          <div>{conceptSpecify(ws, "numberType", "Include", ["Whole Numbers","Decimals",], createValidationList)}
          </div>
          <div>{whole}</div>
        </div>,
        <div className='dynamic-container'>
          {decimal}{decimal2}
        </div>
          // conceptSpecify(props, "specify", "Include", ["Rectangles","Parallelograms", "Triangles", "Trapezoids"]),
             )


    ////////// EQUATIONS ////////////

    case "Whole Number Equations":{
      let asOptions = addSubWhole.slice(0,4)
      let addSub, multiply, divide
      if (specify.operation){
        if (specify.operation['Addition'] || specify.operation['Subtraction']){
          addSub = conceptSpecify(ws, "asNumbers", "Add & Subtract", asOptions, createValidationList)
        } if (specify.operation['Division']){
          //opposite operation to solve equation
          multiply = conceptSpecify(ws, "multiply", "Division", multWhole, createValidationList)
        } if (specify.operation['Multiplication']){
          divide = conceptSpecify(ws,"divide", "Multiplication", divWhole, createValidationList)
        }
      }
      
      return customizeContainer(
        conceptSpecify(ws, "operation", "Include", [
              "Addition","Subtraction", "Multiplication","Division"], createValidationList, ['(x + 2 = 8)', '(x - 2 = 8)', '(2x = 8)', '(x ÷ 2 = 8)']),
          <div className='dynamic-container'>
            {addSub}{multiply}{divide}
          </div>
        )      
    }
    case "Integer Equations":{
      let asOptions = addSubWhole.slice(0,3)
      let addSub, multiply, divide

      if (specify.operation){
        if (specify.operation['Addition'] || specify.operation['Subtraction']){
          addSub = conceptSpecify(ws, "asNumbers", "Add & Subtract", asOptions, createValidationList)
        } if (specify.operation['Division']){
          multiply = conceptSpecify(ws, "multiply", "Division", multWhole, createValidationList)
        } if (specify.operation['Multiplication']){
          divide = conceptSpecify(ws,"divide", "Multiplication", divWhole, createValidationList)
        }
      }
      
      return customizeContainer(
        conceptSpecify(ws, "operation", "Include", [
              "Addition","Subtraction", "Multiplication","Division"], createValidationList,  ['(x + -2 = 8)', '(x - 2 = -8)', '(-2x = -8)', '(x ÷ -2 = 8)']),
          <div className='dynamic-container'>
            {addSub}{multiply}{divide}
          </div>
        )
    }
   case "Decimal Equations": {
    let addSub, multiply, divide
    if (specify.operation){
      if (specify.operation['Addition'] || specify.operation['Subtraction']){
        addSub = conceptSpecify(ws, "asNumbers", "Add & Subtract", asDecNums, createValidationList)
      } if (specify.operation['Division']){
        multiply = conceptSpecify(ws, "multiply", "Division", multDec, createValidationList)
      } if (specify.operation['Multiplication']){
        divide = conceptSpecify(ws, "dividend", "Mult- Num 1", dividend, createValidationList)
        var divide2 = conceptSpecify(ws, "divisor", "Mult- Num 2", divisor, createValidationList)
      }
    }
    // const allStyle = addSub && multiply && divide ? 'all-style' : ''
    const allStyle = 'all-style'
    return customizeContainer(conceptSpecify(ws, "operation", "Include", [
                  "Addition","Subtraction", "Multiplication","Division"], createValidationList,  ['(x + 2 = 8.4)', '(x - 2 = 8.4)', '(2x = 8.4)', '(x ÷ 2 = 8.4)']),
              <div className={`dynamic-container ${allStyle}`}>
                <div className='dynamic-sub-container'>{addSub}{multiply}</div>
                <div>
                  <div className='dynamic-sub-container'>{divide}{divide2}</div>
                  {divide && <><div className="eq-ex eq">200 = 5x</div><div className="eq-ex explain"><div>Num 1</div><div>Num 2</div></div></>}
                </div>
              </div>,
            )

   }
   
   //////////////////////// INTEGERS ////////////////

    case "Adding Integers":
      return customizeContainer(conceptSpecify(ws, "numbers", "Include", [
                  "1 digit numbers","2 digit numbers", "3 digit numbers"], createValidationList),
                  probType(), dynamicProbType() 
              )
    case "Subtracting Integers":
      return customizeContainer(conceptSpecify(ws, "numbers", "Include", [
                  "1 digit numbers","2 digit numbers", "3 digit numbers"], createValidationList),
                  probType(), dynamicProbType() 
              )
    case "Multiplying Integers":
      return customizeContainer(conceptSpecify(ws, "numbers", "Include", multWhole, createValidationList),
        probType(), dynamicProbType() 
              )
    case "Dividing Integers":
      return customizeContainer(conceptSpecify(ws, "numbers", "Include", divWhole, createValidationList),
      probType(), dynamicProbType() 
              )
    case "Comparing Integers":
      return customizeContainer(compareProbType(),
              conceptSpecify(ws, "numbers", "Include", [
                "1 digit numbers","2 digit numbers", "3 digit numbers"], createValidationList),
                
              )
  default:

    return <p>Return to the homepage to begin creating your assessment!</p>

}
};

export default ConceptCustomization;
