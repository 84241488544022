import { Link, useLocation } from "react-router-dom";
import { React, useContext, useEffect, useState } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import QuestionIcon from '../../app-files/images/icon-question.svg'
import Modal from "../../shared/components/UIElements/Modal";

import { WSContext } from "../../app/context/ws-context";
import Button from "../../shared/components/FormElements/Button";
import WorksheetImg from '../../app-files/images/activity-ws.png'
import TTBImg from '../../app-files/images/activity-ttb.png'
import BingoImg from '../../app-files/images/sample-addsubintegers.png'
import SHImg from '../../app-files/images/activity-sh.png'
import TaskCardsImg from '../../app-files/images/activity-tc.png'
import ActivityDescriptions from "./ActivityDescriptions";
import { Checkbox } from "@material-ui/core";
import SwitchPremade from "../components/SwitchPremade";
import { conceptsCantBeOnSH } from "../create-worksheet/problems/general";

const ActivitySelections = (props) => {
  const ws = useContext(WSContext);
  const location = useLocation();
  const {activity, questionStyle} = ws.generalSelection
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showActivityModal, setShowActivityModal] = useState(false);
  const [showCustomizeBingo, setShowCustomizeBingo] = useState(false);
  const [updateBGQuanity, setUpdateBGQuantity] = useState(false)


  useEffect(()=>{
    window.scrollTo(0, 0)
  }, [])

  const handleGenSelectInput = (name, value) => {
    const tempGS = {...ws.generalSelection}
    const maxQuantity =  value==='Scavenger Hunt' ? 24 : value==='Tic Tac Bingo' ? 18 : 36

    const checkSH = () => {
      let usCheck = ws.userSelection.find(us => us.specify.ac || conceptsCantBeOnSH.includes(us.concept))
      return name === 'activity' && value==='Scavenger Hunt' && tempGS.activity && usCheck
    }

    if (name==='activity'){
      tempGS.maxQuantity = maxQuantity
      tempGS.questionStyle = ''
      if (!value.includes('Bingo') && showCustomizeBingo){
        setShowCustomizeBingo(false)
      }
      if (value.includes('Bingo')){
        tempGS.questionStyle = 'Task Cards'
        if (!tempGS.BGQuantity || !updateBGQuanity){
          tempGS.BGQuantity = value === 'Bingo' ? 30 : 15
        }
      }
    }

    if ((name=== 'activity' || name==='questionStyle') && value!=='Worksheet'){
      tempGS.savePaper = false
      tempGS.columns = false
    }

    if (name==='activity' && (value==='Worksheet' || value==='Task Cards')){ //set both activity & question style to WS/TC since they selected a plain activity.
      tempGS.activity = value
      tempGS.questionStyle = value

    } else if (checkSH()){
      setShowConfirmModal(true)
      return

    } else{
      tempGS[name] = value
    }

    // check if quantity is over maxQuantity after switching activities (36 Q ws to 24 max SH) and there is only one concept (Premade or custom)
    // If so, we should automatically update the quantity rather than setting an error message for the user.
    if (tempGS.quantity > maxQuantity && ws.userSelection.length === 1) {
      let tempUS = [...ws.userSelection]
      tempUS[0].specify.quantity = maxQuantity
      tempGS.quantity = maxQuantity

      ws.setUserSelection(tempUS)
    }
    ws.setGeneralSelection(tempGS)
  };

  const confirmSH = async () => {
    let tempGS = {...ws.generalSelection}
    let tempUS = ws.userSelection.filter(sel =>  !conceptsCantBeOnSH.includes(sel.concept))
    ws.setUserSelection(tempUS)
    const getQuantity = () => {
      let quantity = 0
      for (let i of tempUS){
        quantity+= i.specify.quantity
      }
      return quantity
    }
    tempGS = {...tempGS, activity: 'Scavenger Hunt', questionStyle: '', maxQuantity: 24, quantity: getQuantity(), ac: false}

    ws.setGeneralSelection(tempGS)
    localStorage.setItem('gs', JSON.stringify({...tempGS}))
    setShowConfirmModal(false)
  }

  const cancelSH = () => {
    setShowConfirmModal(false)
  }

  const cancelActivityModal = () => {
    setShowActivityModal(false)
  }

  const toggleCustomizeBingo = () => {
    setShowCustomizeBingo(!showCustomizeBingo)
  }

  const gsOnChange = (e) => {
    const {name, value} = e.target
    const tempGS = {...ws.generalSelection}
    tempGS[name] = value
    ws.setGeneralSelection(tempGS)
    if (name === 'BGQuantity'){
      setUpdateBGQuantity(true)
    }
    localStorage.setItem('gs', JSON.stringify({...tempGS}))
  }
  
  let bingoCardInput = <TextField variant="filled" className="BG-input" id="filled-basic"
    label={<span className="">Bingo Card Quantity</span>}
    name="BGQuantity" type="BGQuantity"
    value={ws.generalSelection.BGQuantity}
    onChange={gsOnChange}
    placeholder="Bingo Card Quantity"
    required
    min="1" max="40"
  />

  const createActivityBtn = (value, questStyle) => {
    let img = value === 'Worksheet' ? WorksheetImg : value === 'Task Cards' ? TaskCardsImg : 
      value === 'Bingo' ? BingoImg : value === 'Tic Tac Bingo' ? TTBImg : SHImg

      return (
      <div className={`activity-btn ${((activity === value) || (questStyle && value === questionStyle)) ? 'active' : ''}`} 
        onClick={()=>handleGenSelectInput(questStyle ? 'questionStyle' : 'activity', value)}>
        <div className="overlay">
          <div className="name">{value}</div>
          {!questStyle && <div className="amt">{value === 'Worksheet' ? '(Free)' : '($1.50 or 1 Token)'}</div>}
        </div>
        <img src={img} alt={value}/>
      </div>
    )
  }
  let questionStyleRadio = 
    <div className="activity-btn-group">
      {createActivityBtn('Worksheet', true)}
      {createActivityBtn('Task Cards', true)}
    </div>
  const customActivity = location.pathname === '/activity-selection'

  return (
    <div className={`main-container activity-selections ${!customActivity ? 'premade-ws' : ''}`}>
      <div className="title-container">
        <h1>Select your {customActivity ? 'Custom' : 'Premade'} Activity
          <SwitchPremade path={customActivity ? "/premade-activity-selection" : "/activity-selection"} title={customActivity ? "Switch to premade" : "Switch to a custom assignment"}/>
        </h1>
      </div>
      <div className="ws-style-container">
        <p className='customize-title'>Activity Style
          <img src={QuestionIcon} alt="Activity information" onClick={()=>setShowActivityModal(true)}/>
        </p>
        <div className="activity-btn-group">
          {createActivityBtn('Worksheet')}
          {createActivityBtn('Task Cards')}
        </div>

        <div className="activity-btn-group">
          <div className="bingo-group">
            {createActivityBtn('Bingo')}
            {createActivityBtn('Tic Tac Bingo')}
          </div>

          <div className="sh-group">
            {createActivityBtn('Scavenger Hunt')}
          </div>
        </div>

        <div className="bingo-customize">
        {(activity === 'Bingo' || activity === 'Tic Tac Bingo') &&
          <FormControlLabel label={<span className="checkbox">Customize Bingo Activity</span>}
            control={<Checkbox color="primary" name="score"
              checked={showCustomizeBingo}
              value={showCustomizeBingo} 
              onChange={toggleCustomizeBingo}/>}
          />}
            {showCustomizeBingo && bingoCardInput }
            {showCustomizeBingo && activity === 'Tic Tac Bingo' && <p>(Partners share a card)</p>}
            {showCustomizeBingo && <><p className='customize-title'>Problem Style</p>
              <div className="details"><div>{questionStyleRadio}</div></div></>}
        </div>
      </div>

      <Link to={customActivity ? `/concept-selection` : '/premade-concept-selection'} className="btn-link">
        <button type="submit" className="btn">
          {ws.userSelection.length > 0 && customActivity ? 'Add More Concepts' : customActivity ? 'Start Adding Concepts' : 'Select Concept'}
        </button>
      </Link>

      <Modal show={showConfirmModal} onCancel={cancelSH}
        header="Are you sure?"
        style={{minHeight:'0'}}
        footerClass="worksheet-item__modal-actions"
        footer={<><Button inverse onClick={cancelSH}>CANCEL</Button>
                  <Button danger onClick={confirmSH}>CONFIRM</Button></>
        }
      >
        <p style={{ margin: 0, padding: '20px'}}>
          Changing to Scavenger Hunt will remove answer choices and comparing concepts.
        </p>
      </Modal>

      <Modal show={showActivityModal} onCancel={cancelActivityModal}
        header="Activity Descriptions"
        footerClass="worksheet-item__modal-actions"
        className="activity-modal"
        contentClass="activity-wrapper"
      >
        <ActivityDescriptions  />
      </Modal>
    </div>
  );
};

export default ActivitySelections;