import React from "react";
import Button from "../../shared/components/FormElements/Button";
import Card from "../../shared/components/UIElements/Card";
import { cap } from "../create-worksheet/problems/general";
import WorksheetItem from "./WorksheetItem";

const WorksheetList = (props) => {
  if (props.items.length === 0) {
    return (
      <div className="ws-list center">
        <Card style={{ display: "flex", flexDirection: "column" }}>
          <h2>No worksheets found. Try creating one!</h2>
          <div style={{ textAlign: "center" }}>
            <Button to="/concept-selection" style={{ display: "inline-block" }}>
              Make a worksheet
            </Button>
          </div>
        </Card>
      </div>
    );
  }

  return (
    <React.Fragment>
      <ul className="ws-list">
        {props.items.map((worksheet) => (
          <WorksheetItem
            key={worksheet.id}
            id={worksheet.id}
            // title={cap(worksheet.title)}
            // columns={worksheet.columns}
            generalSelection={worksheet.generalSelection}
            userSelection={worksheet.userSelection}
            worksheetCopy={worksheet.worksheetCopy}
            created={worksheet.created}
            creatorId={worksheet.creator}
            worksheetDeletedHandler={props.worksheetDeletedHandler}
          />
        ))}
      </ul>
    </React.Fragment>
  );
};

export default WorksheetList;
