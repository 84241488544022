import React, { useState, useContext, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Input from "../shared/components/FormElements/Input";
import Button from "../shared/components/FormElements/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
// import Card from "../shared/components/UIElements/Card";
import LoadingSpinner from "../shared/components/UIElements/LoadingSpinner";
import ErrorModal from "../shared/components/UIElements/ErrorModal";
import {
  VALIDATOR_MINLENGTH,
  VALIDATOR_EMAIL,
} from "../shared/util/validators";
import { useForm } from "../shared/hooks/form-hook";
import { useHttpClient } from "../shared/hooks/http-hook";
import { AuthContext } from "../app/context/auth-context";
import UserCard from "./UserCard";
import wwLogoIcon from "../app-files/images/icon-only.png";
import GoogleLogin from "react-google-login";

const Authenticate = () => {
  const history = useHistory();
  const auth = useContext(AuthContext);
  const [loginInactive, setLoginInactive] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const [inactiveEmail, setInactiveEmail] = useState("");
  const { isLoading, error, sendRequest, clearError, setManualError } = useHttpClient();
  const [formState, inputHandler, setFormData] = useForm(
    //(initialInputs,initialInputValidity) returns [newState, inputHandler]
    {
      email: {
        value: "",
        isValid: false,
      },
      password: {
        value: "",
        isValid: false,
      },
    },
    false
  );

  const authSubmitHandler = async (event) => {
    event.preventDefault();
    try {
      const responseData = await sendRequest(
        `/api/users/login`,
        "POST",
        JSON.stringify({
          email: formState.inputs.email.value,
          password: formState.inputs.password.value,
        }),
        {
          "Content-Type": "application/json",
        }
      );
      if (responseData.status === "inactive") {
        setLoginInactive(true);
        setInactiveEmail(responseData.user.email);
      } else {
        auth.login(responseData);
        history.push("/");
      }
    } catch (err) {}
  };
  const emailHandler = async (event) => {
    event.preventDefault();
    setIsFinished(false);
    setProcessing(true);
    try {
      await sendRequest(
        `/api/users/activate`,
        "POST",
        JSON.stringify({
          email: inactiveEmail,
        }),
        {
          "Content-Type": "application/json",
        }
      );
      setProcessing(false);
      setIsFinished(true);
    } catch (err) {
      setProcessing(false);
      setIsFinished(false);
    }
  };

  const handleGoogleLogin = async googleData => {
    try {
      const responseData = await sendRequest(
        "/api/users/googleauth",
        "POST",
        JSON.stringify({
          token: googleData.tokenId
        }),
        {
          "Content-Type": "application/json",
        }
      );
      if (responseData.user && responseData.user.email) {
        auth.login(responseData);
        history.push("/");
      }
    } catch (err) {}
    }

    const handleGoogleLoginFail = (e) => {
      if(!(e && e.error === 'idpiframe_initialization_failed')) {
        setManualError('There was an issue connecting with your Google account.');
      }
    };

  if (loginInactive) {
    return (
      <React.Fragment>
        <div className="authenticate-container">
          <UserCard className="authentication" title={"Activate Account"}>
            {isFinished ? (
              <div className="account-type" style={{ textAlign: "center" }}>
                <img
                  src={wwLogoIcon}
                  alt="Worksheet Wonderland"
                  style={{ paddingTop: "20px", width: "250px" }}
                />
                <p
                  className="blurb"
                  style={{
                    color: "purple",
                    fontSize: "2.5em",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Email Sent!
                </p>
                <p
                  className="blurb"
                  style={{ padding: "20px 0 40px 0", fontWeight: 600 }}
                >
                  An email has been sent with instructions to confirm your
                  account.
                </p>
              </div>
            ) : (
              <>
                <div className="account-type" style={{ textAlign: "center" }}>
                  <p
                    className="blurb"
                    style={{ fontWeight: 600, fontSize: "1.2em" }}
                  >
                    You can send an email with instructions to confirm your
                    account by clicking the button below.
                  </p>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      disabled={isFinished}
                      size="small"
                      onClick={(e) => {
                        emailHandler(e);
                      }}
                    >
                      Send Account Confirmation Email
                    </Button>
                  </div>
                </div>
                {processing ? <CircularProgress /> : null}
              </>
            )}
          </UserCard>
        </div>
      </React.Fragment>
    );
  }
  const errorStyle = {paddingTop: '0', marginTop: '0', minHeight: '21px'}
  return (
    <React.Fragment>
      <div className="authenticate-container">
        <ErrorModal error={error} onClear={clearError} />
        <UserCard className="authentication" title={"Log in"}>
          {isLoading && <LoadingSpinner asOverlay />}

          <form onSubmit={authSubmitHandler} style={{ maxWidth: '100%' }}>
            <Input
              id="email"
              element="input"
              type="text"
              label="Email"
              validators={[VALIDATOR_EMAIL()]}
              errorText="Please enter a valid email."
              onInput={inputHandler}
              onChange={clearError}
            />
            <Input
              id="password"
              element="input"
              type="text"
              label="Password"
              validators={[VALIDATOR_MINLENGTH(6)]}
              errorText="Please enter a valid password at least 6 characters."
              onInput={inputHandler}
              onChange={clearError}
              hideText={true}
              autocomplete="off"
            />
            {/* {error ? <p style={errorStyle}>{error}</p> : <p style={errorStyle}></p>} */}
            <Link className="forgot-pwd" to="/auth/forgot-password">
              Forgot your password?
            </Link>

            <Button className="btn" type="submit" disabled={!formState.isValid}>
              {"LOG IN"}
            </Button>
          </form>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingBottom: '10px',
            }}
          >
            <div
              style={{ width: 100, height: 1, borderTop: "1px solid lightgray" }}
            />
            <div style={{ color: "lightgray", margin: '0 10px 3px 10px' }}>or</div>
            <div
              style={{ width: 100, height: 1, borderTop: "1px solid lightgray" }}
            />
          </div>
          <GoogleLogin
            clientId="841851598822-0bjt2blds2h457dsgrihsrrtkmtr7he8.apps.googleusercontent.com"
            buttonText="Continue with Google"
            onSuccess={handleGoogleLogin}
            onFailure={handleGoogleLoginFail}
            cookiePolicy={"single_host_origin"}
          />
          <div className="switch" style={{ paddingTop: 10 }}>
            {"Don't have an account?"}
            <Button sec size="small" to="/signup">
              {"SIGN UP"}
            </Button>
          </div>
        </UserCard>
      </div>
    </React.Fragment>
  );
};

export default Authenticate;
