import { specHZ, specify, specAC } from "../helper"

export const MixImproperFractions = (props) => {
  const {createLink, ac} = props
  const c1 = 'Mixed & Improper Fractions'
  const [mixImp, impMix, to10, to20, upTo20] = ['Mixed to Improper', 'Improper to Mixed', 'Up to 10', '10 to 20', 'Up to 20']
  
  const title = (mod1, mod2, mod3) => {
    let newMod1 = mod1 === 'Mixed to Improper' ? 'Mixed Numbers to Improper Fractions' : mod1 === 'Improper to Mixed' ? 'Improper Fractions to Mixed Numbers' : 'Mixed Numbers & Improper Fractions'
    return `Converting ${newMod1}`
  }

  const btnTitle = (mod1) => {
    return `Fractions with Denominators ${mod1.toLowerCase()}`
  }
  const createSpec = (spec1, spec2) => {
    let specAS = [specify('type', spec1), specify('numbers', spec2), specAC(ac)]

    return {[c1]: specAS}
  }

  return (<>
    <div className="buttonGroup">
      <div className="concept-topic">Mixed Numbers to Improper Fractions</div>
      {createLink([c1], btnTitle(to10), title(mixImp), createSpec([mixImp], [to10]), 12)}
      {createLink([c1], btnTitle(to20), title(mixImp), createSpec([mixImp], [to20]), 12)}
      {createLink([c1], btnTitle(upTo20), title(mixImp), createSpec([mixImp], [to10, to20]), 12)}
    </div>
    <div className="buttonGroup">
      <div className="concept-topic">Improper Fractions to Mixed Numbers</div>
      {createLink([c1], btnTitle(to10), title(impMix), createSpec([impMix], [to10]), 12)}
      {createLink([c1], btnTitle(to20), title(impMix), createSpec([impMix], [to20]), 12)}
      {createLink([c1], btnTitle(upTo20), title(impMix), createSpec([impMix], [to10, to20]), 12)}

    </div>

    {/* ISSUE WITH THESE CAUSES NO WORKSHEET TO BE CREATED WITH NO ERROR OUt SO IT ENLESSLY LOADS... */}
    {/* <div className="buttonGroup">
      <div className="concept-topic">Mixed Conversions between Improper Fractions & Mixed Numbers</div>
      {createLink([c1], btnTitle(to10), title('both'), createSpec([mixImp, impMix], [to10]), 12)}
      {createLink([c1], btnTitle(to20), title('both'), createSpec([mixImp, impMix], [to20]), 12)}
      {createLink([c1], btnTitle(upTo20), title('both'), createSpec([mixImp, impMix], [to10, to20]), 12)}

    </div> */}
    </>
  )
}