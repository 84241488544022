import React, { useContext, useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import { AuthContext } from "../../../app/context/auth-context";
import { WSContext } from "../../../app/context/ws-context";
import MainHeader from "./MainHeader";
import NavLinks from "./NavLinks";
import SideDrawer from "./SideDrawer";
import Backdrop from "../UIElements/Backdrop";
import { Menu, MenuItem } from "@material-ui/core";
import IconSettings from "../../../app-files/images/icon-settings.svg";
import IconSignout from "../../../app-files/images/icon-sign-out.svg";
import UserIcon from "../UIElements/UserIcon";
import wwLogo from "../../../app-files/images/logo.svg";
import wwLogoIcon from "../../../app-files/images/icon-only.png";
import Modal from "../UIElements/Modal";
import PurchaseTokens from "../../../user/account/PurchaseTokens";
import WelcomeModal from "../../../general/home/WelcomeModal";

const MainNavigation = (props) => {
  const auth = useContext(AuthContext);
  const ws = useContext(WSContext);
  const [showModal, setShowModal] = useState(false);
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const [closeLock, setCloseLock] = useState(false);

  const [showPurchaseTokensModal, setShowPurchaseTokensModal] = useState(false);

  useEffect(() => {
    if (auth && auth.user && auth.user.showWelcome) {
      setShowModal(true);
      auth.unsetWelcome();
    }
  }, [auth && auth.user && auth.user.showWelcome]);

  const openDrawerHandler = () => {
    setDrawerIsOpen(true);
  };
  const closeDrawerHandler = () => {
    setDrawerIsOpen(false);
  };
  const [dropdownDisplay, setDropdownDisplay] = React.useState(null);
  const handleClick = (e) => {
    e.stopPropagation();
    setDropdownDisplay(e.currentTarget);
  };
  const handleClose = () => {
    setDropdownDisplay(null);
  };
  return (
    <React.Fragment>
      {drawerIsOpen && <Backdrop onClick={closeDrawerHandler} />}
      <SideDrawer show={drawerIsOpen} onClick={closeDrawerHandler}>
        <nav className="drawer-nav">
          <Link to="/" className="logo">
            <img src={wwLogo} alt="Worksheet Wonderland" />
          </Link>
          <NavLinks />
        </nav>
      </SideDrawer>
      <MainHeader className="main-navigation">
        <button
          className={`menu-btn ${!auth.isLoggedIn && "not-logged"}`}
          aria-label="Menu"
          onClick={openDrawerHandler}
        >
          <span />
          <span />
          <span />
        </button>
        <Link to="/" className="logo mobile-hidden">
          <img src={wwLogo} alt="Worksheet Wonderland" />
        </Link>
        <nav className="header-nav">
          <NavLinks />
        </nav>
        {auth.isLoggedIn ? (
          <li className="user-btn">
            <div
              className="token-btn"
              style={{ alignSelf: "center" }}
              onClick={() =>
                setShowPurchaseTokensModal(!showPurchaseTokensModal)
              }
            >
              <span style={{ color: "#803F87", fontWeight: "bold" }}>
                {auth.user && auth.user.tokens ? auth.user.tokens : 0}
              </span>
              {` Token${auth.user && auth.user.tokens !== 1 ? "s" : ""}`}
            </div>
            <button
              onClick={handleClick}
              className="dropdown"
              aria-label="User Settings"
            >
              <UserIcon name={auth.user?.name} />
            </button>
          </li>
        ) : (
          <li className="sign-in">
            <NavLink
              className="signup"
              to="/signup"
              onClick={() => {
                if (window.location.pathname === "/signup") {
                  window.location.reload();
                }
              }}
            >
              Sign up
            </NavLink>
            <NavLink
              to="/login"
              onClick={() => {
                if (window.location.pathname === "/login") {
                  window.location.reload();
                }
              }}
            >
              Log in
            </NavLink>
          </li>
        )}

        <Menu
          style={{ minHeight: "500px", maxHeight: "500px", height: "115px" }}
          key={props.key}
          anchorEl={dropdownDisplay}
          keepMounted
          open={Boolean(dropdownDisplay)}
          onClose={handleClose}
          getContentAnchorEl={null}
          transformOrigin={{ horizontal: 'center', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
          disableScrollLock={true}
        >
          <MenuItem style={{ minHeight: "30px" }}>
            <NavLink className="menu-items" to="/account/settings">
              <button onClick={handleClose}>
                <img src={IconSettings} alt="settings" /> Account Settings
              </button>
            </NavLink>
          </MenuItem>

          <MenuItem style={{ minHeight: "30px" }}>
            <div className="menu-items">
              <button
                onClick={() => {
                  auth.logout();
                  handleClose();
                }}
              >
                <img className="sign-out" src={IconSignout} alt="sign out" />
                Sign out
              </button>
            </div>
          </MenuItem>
        </Menu>
        <Modal
          show={showPurchaseTokensModal}
          onCancel={() => {
            if (!closeLock) {
              setShowPurchaseTokensModal(false);
            }
          }}
          header="Purchase Tokens"
          footerClass="worksheet-item__modal-actions"
          headerClass="purchase-tokens-header"
          contentClass="purchase-tokens-wrapper"
        >
          <PurchaseTokens
            onFinish={() => {
              setShowPurchaseTokensModal(false);
            }}
            setCloseLock={setCloseLock}
          />
        </Modal>
      </MainHeader>
      <WelcomeModal
        show={showModal}
        onCancel={() => setShowModal(false)}
        handleClearSelections={() => ws.handleClearSelections()}
      />
    </React.Fragment>
  );
};

export default MainNavigation;
