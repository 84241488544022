import {answerChoicesKey, randInt, selectRand, shuffleArray} from '../general.js'
import {cell_style_fraction, tstyles, horizontalFractions, verticalFractions, ak_cell_style, akTStyles } from './fract-shared'
import Table from '../Table.js'
import {Text, StyleSheet} from "@react-pdf/renderer";
const { create, all } = require('mathjs')
const config = { number: 'Fraction'}
const math = create(all, config)

export const sTstyles = StyleSheet.create({
  table: {
      display: "table", //gives individual fraction overall style.
       width: "560", //120
      //  textAlign:'center',
  },
  row: {
      flexDirection: "row",
  },
  cell:{
      backgroundColor:'white',
  },
  header: {
    backgroundColor: 'grey'
  }
});

const GCF = (...arr) => {
  const _gcf = (x, y) => (!y ? x : GCF(y, x % y));
  return [...arr].reduce((a, b) => _gcf(a, b));
};
export const simplify = (num, denom) => {
  let gcf = GCF(num, denom)
  return {num:num/gcf, denom:denom/gcf}

}
const getAlreadySimplifiedFraction = (num, denom) => {
  let [newNum, newDenom] = [num, denom]
  let count = 0
  while (num < denom && count < 6) {
    newNum+=1
    count+=1
    let {num: simpNum} = simplify(newNum, denom)

    // if adding 1 to the numerator created a simplified fraction..
    if (simpNum === newNum) {
      return {num: newNum, denom}

      // else let's try adding 1 to the denominator instead
    } else {
      newDenom+=1
      let {num: simpNum} = simplify(num, newDenom)

      if (simpNum === num) {
        return {num, denom: newDenom}
      }
    }

  }

  // not able to create a better option so just simplify the original
  return simplify(num, denom)

}
const openQuestArr = ['Simplify the fraction.', 'Write the fraction in simplest form.']
const acQuestArr = ['Which shows the following fraction in simplest form?', 'Which shows the fraction written in simplest form?']

export const fractionNumbers = (userSelection, generalSelection) => {
  const {steps} = userSelection.specify
  // let largeNums = userSelection.specify.numbers === '3 digit' ? [5, 5, 7, 7, 7, 11] : null;
  // let mediumNums = userSelection.specify.numbers === '3 digit' ? [3, 3, 3, 3, 3, 5] : null;
  let factors = []
  const Zero = selectRand([1, 1]) //possibly no need to simplify
  const One = selectRand([2, 2, 2, 2, 3, 3, 3, 3, 5, 5])
  const Two = selectRand([2, 2, 2, 2, 3, 3, 3, 3, 5])
  const Three = selectRand([2, 2, 2, 3, 3, 3])
  const Four = selectRand([2, 2, 2, 3, 3, 3])
  
  //build up the fraction problem backwards so that user can specify the exact number of steps required to simplify.
  // if they do not select a level
  if (steps['1 easy'] || (!steps['2 medium'] && !steps['3 difficult'] && !steps['4 challenging'])){ //1 common prime factor
    factors.push([One], [One], [One])
  }
  if (steps['2 medium']){ //2 shared prime factors
    factors.push([One, Two], [One, Two], [One, Two])
  }
  if (steps['3 difficult']){ //3 shared prime factors
    factors.push([One, Two, Three], [One, Two, Three], [One, Two, Three])
  }
  if (steps['4 challenging']){ //4 shared prime factors
    factors.push([One, Two, Three, Four], [One, Two, Three, Four], [One, Two, Three, Four])
  }
  let factorArray = selectRand(factors)
  let max = factorArray.length < 2 ? 10 : factorArray.length < 3 ? 8 : 5;
  var tempnum1 = randInt(1,max-1)
  var tempdenom1 = randInt(tempnum1+1,max)

  let {num, denom} = simplify(tempnum1, tempdenom1)

  let [probNum1, probDenom1] = [num, denom];

  for (let factor of factorArray){ // create the fraction problem numbers by multiplying all factors
    probNum1 *= factor;
    probDenom1 *= factor;
  }

  // grab the non-simplifiable fractions after building them so that they are about the same size as the other fractions.
  if (steps['Some may not require simplifying'] && randInt(0, 6) === 0) {
    let newProbNums = getAlreadySimplifiedFraction(probNum1, probDenom1)
    probNum1 = newProbNums.num
    probDenom1 = newProbNums.denom
    num = newProbNums.num
    denom = newProbNums.denom
  }
  
  // let [probNum1, probDenom1] = shuffleArray(array)[0]
  var answer = {num, denom, answer:true} //Table([[num],[denom]], cell_style_fraction, tstyles, '', '', generalSelection.activity)
  var shAnswerKey = {num, denom} //Table([[num],[denom]], ak_cell_style, akTStyles, '', '', generalSelection.activity)

  var prob = {num:probNum1, denom:probDenom1, text:selectRand(openQuestArr)} //Table([[probNum1],[probDenom1]], cell_style_fraction, sTstyles, selectRand(openQuestArr), '', generalSelection.activity)

  return {num, denom, prob, answer, shAnswerKey, factors:factorArray, probNum1, probDenom1}      
}

const createFractionInfo = (userSelection, generalSelection) => {
  var {num, denom, num2, denom2} = fractionNumbers(userSelection)
  var f1 = math.fraction(`${num}/${denom}`)
  var fract1 = Table([[num],[denom]], cell_style_fraction, tstyles, '', '', generalSelection.activity)
  let answer, wrong;

  return {fract1, answer, wrong}
}


export const simplifyFract = (userSelection, generalSelection) => {
  const {activity} = generalSelection
  const {num, denom, prob, answer, shAnswerKey, factors, probNum1, probDenom1} = fractionNumbers(userSelection, generalSelection)
  let questionText = userSelection.specify.ac ? selectRand(acQuestArr) : selectRand(openQuestArr)

  if (factors.length > 2){
    var [mult1, mult2, mult3] = [factors[0], factors[1], randInt(2, 5)]
  } else{
    [mult1, mult2, mult3] = [factors[0]===1? factors[0]+4 : factors[0]-1, factors[0]+1, factors[0]+randInt(2,3)]
  }
  let wrong = [];
  let wrongCheck = ({num, denom}) => {
    if (Number.isNaN(num) || Number.isNaN(denom)){
      return
    }
    if (denom !==0){
      let simpFract = simplify(num, denom)
      num = simpFract.num
      denom = simpFract.denom
    }
    let duplicate = false
    for (let i of wrong){
      if ((i.num === num && i.denom === denom) || (answer.num === num && answer.denom === denom)){
        duplicate = true
      }
    }
    if(!duplicate){
        wrong.push({num, denom, improper: true})
    }
  }
  wrongCheck({num, denom:denom*mult3})
  wrongCheck({num:num+randInt(1,3), denom:denom+1})
  wrongCheck({num:num+randInt(1,3), denom:denom+randInt(2,3)})
  wrongCheck({num, denom:denom+1})
  wrongCheck({num, denom:denom+randInt(2,3)})
  wrongCheck({num:num+randInt(1,3), denom})
  
  !factors.includes(1) && wrongCheck({num:num*mult1, denom:denom*mult1})
  !factors.includes(1) ? wrongCheck({num:denom, denom:num}) : wrongCheck({num:num*mult2*mult1, denom:denom*mult2*mult1})
  var AC = answerChoicesKey(answer, wrong[0], wrong[1], wrong[2])

  var problem = {prob: prob,
      answerChoices: AC,
      correctAnswer: answer, shAnswerKey}
  return problem
}

export const randSimplifyFract = (userSelection, generalSelection, questionAnswerList) => {

  var probArray = [simplifyFract]
  
  var randProb = selectRand(probArray)
  return randProb(userSelection, generalSelection)
}