import React, { useState } from "react";
import { Link } from "react-router-dom";
import Button from "../shared/components/FormElements/Button";
import Input from "../shared/components/FormElements/Input";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useForm } from "../shared/hooks/form-hook";
import { useHttpClient } from "../shared/hooks/http-hook";
import { VALIDATOR_EMAIL } from "../shared/util/validators";
import UserCard from "./UserCard";
import ErrorModal from '../shared/components/UIElements/ErrorModal'

const ForgotPwd = (props) => {
  const [processing, setProcessing] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const [formState, inputHandler, setFormData] = useForm(
    {
      email: {
        value: "",
        isValid: false,
      },
    },
    false
  );
  const authSubmitHandler = async (event) => {
    event.preventDefault();
    setProcessing(true);
    try {
      const responseData = await sendRequest(
        `/api/users/new-password`,
        "POST",
        JSON.stringify({
          email: formState.inputs.email.value
        }),
        {
          'Content-Type': 'application/json'
        }
      );
      setProcessing(false);
      setIsFinished(true);
    } catch (err) {
      setProcessing(false);
      setIsFinished(false);
    }
  };
  return (
    <div className="authenticate-container">
      <ErrorModal error= {error} onClear={clearError}/>
      <UserCard title="Forgot your password">
        <p className="blurb">
          Lost your password? Please enter your email address. You will receive
          a link to create a new password via email.
        </p>
        <Input
          id="email"
          label="Email"
          element="input"
          validators={[VALIDATOR_EMAIL()]}
          onInput={inputHandler}
          disabled={processing || isFinished}
        />
        <Button
          disabled={!formState.isValid || processing || isFinished}
          onClick={(e) => {
            authSubmitHandler(e);
          }}
        >
          Reset Password
        </Button>
        <div
          style={{
            padding: "15px 0 15px 0",
            display: "flex",
            justifyContent: "center",
            height: "70px",
          }}
        >
          {processing ? <CircularProgress /> : null}
          {isFinished ? (<div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ paddingRight: '20px', color: 'darkgreen', fontSize: '18px', fontWeight: 'bold' }}>EMAIL SENT!</div>
            <Link to="/">
              Go Back
            </Link></div>
          ) : null}
        </div>
      </UserCard>
    </div>
  );
};

export default ForgotPwd;
