import {answerChoicesKey, randInt, selectRand, showQText, shuffleArray} from '../general'
import Table from '../Table.js'
import {Text} from "@react-pdf/renderer";
import { randDivWhole } from '../whole-numbers/divide.js';
import {order, order2, orderAC, orderAC2, orderAC3, orderAC4} from '../ordering'
import {compareSymbol} from '../whole-numbers/co-whole'

const { create, all } = require('mathjs')
const config = { number: 'Fraction'}
const math = create(all, config)
const wrongCompare = () => {

}

const integerNumbers = (userSelection, generalSelection, probType) => {
  let [numArray, compNumArray, controlledArray, controlledCompArray] = [[], [], [], []]
  let numSelect = userSelection.specify.numbers

  if (numSelect['1 digit numbers']){
    let ogNum = randInt(-8,-5)
    let posNum = randInt(1, 9)
    let nums = [ogNum, ogNum+1, ogNum-1, ogNum+2, ogNum+3, ogNum+4]
    numArray.push([...nums, posNum]);
    compNumArray.push([...nums, ...nums, posNum, ogNum, ogNum, ogNum, ogNum])

  } if (numSelect['2 digit numbers']){
    let ogNum = randInt(-89,-10);
    let posNum = ogNum+Math.abs(ogNum)+randInt(10,89)
    let nums = [ogNum, ogNum-1, ogNum-2, ogNum-3, ogNum-4, ogNum-5, ogNum-randInt(6,10)]
    numArray.push([...nums, posNum]);
    compNumArray.push([...nums, ...nums, posNum, ogNum, ogNum, ogNum, ogNum])

  } if (numSelect['3 digit numbers']) {
    let ogNum = randInt(-899,-100);
    let posNum = ogNum+Math.abs(ogNum)+randInt(100,899)
    let nums = [ogNum, ogNum-1, ogNum-2, ogNum-3, ogNum-4, ogNum-randInt(5,20), ogNum-randInt(21,49), ogNum-randInt(50,100)]
    numArray.push([...nums, posNum]);
    compNumArray.push([...nums, ...nums, posNum, ogNum, ogNum, ogNum])
  } 
  var numList = shuffleArray(selectRand(numArray))
  let compNumList = shuffleArray(selectRand(compNumArray))
return {compareOnly: [compNumList[0], compNumList[1]], order: numList}
}
const randWrongSymbol = ['+', '-', 'x', '#', '%']

const randTrueQ = ['Which of the following shows a true comparison?', 'Which shows a true comparison between two numbers?', 'Which shows an accurate comparison between numbers?', 'Which of the following shows a true comparison?']
const randFalseQ = ['Which of the following does NOT show a true comparison?', 'Which does NOT show a true comparison?', 'Which of the comparisons below is NOT true?', 'Which symbol does NOT accurately compare the two numbers?', 'Which comparison is false?', 'Which comparison is NOT accurate?']
let questionArr = [randFalseQ, randTrueQ]

export const compareWhole = (userSelection, generalSelection) => {
  const [num1, num2] = integerNumbers(userSelection, generalSelection).compareOnly
  let {answer, wrongAC} = compareSymbol(num1, num2)
  var AC = answerChoicesKey(answer, wrongAC[0], wrongAC[1], selectRand(randWrongSymbol))
  const questionText = userSelection.specify.ac ? 
    `Which of the following would create a true comparison between the two numbers?` :
    `Write a comparison symbol (<, >, or =) between the two numbers that creates a true comparison.`
  let showQuestionText = showQText(generalSelection, userSelection)
  
  let prob = showQuestionText ? `${questionText} \n \n \
    ${num1.toLocaleString()} ____ ${num2.toLocaleString()}` : `${num1.toLocaleString()} ____ ${num2.toLocaleString()}`
  var problem = {prob: prob,
      answerChoices: AC,
      correctAnswer: answer}
  return problem
}

export const compareWholeAC = (userSelection, generalSelection) => {
  const [num1A, num2A, num1B, num2B, num1C, num2C, num1D, num2D] = integerNumbers(userSelection, generalSelection).order

  let prob = [compareSymbol(num1A, num2A), compareSymbol(num1B, num2B), compareSymbol(num1C, num2C),compareSymbol(num1D, num2D)]
  let answerIdx = randInt(0,3)
  let questIdx = randInt(0,1)
  let questionText = selectRand(questionArr[questIdx])
  const symbol = (idx) => {
    if (idx === answerIdx){
      return questIdx === 0 ? prob[idx].wrong[0] : prob[idx].answer
    } else {
      return questIdx === 0 ? prob[idx].answer : prob[idx].wrong[0] //if looking for false answer - rest will be true;
    }
  }
  let answers = [`${num1A.toLocaleString()} ${symbol(0)} ${num2A.toLocaleString()}`, 
                `${num1B.toLocaleString()} ${symbol(1)} ${num2B.toLocaleString()}`, 
                `${num1C.toLocaleString()} ${symbol(2)} ${num2C.toLocaleString()}`, 
                `${num1D.toLocaleString()} ${symbol(3)} ${num2D.toLocaleString()}`]
  let answer = answers[answerIdx]
  answers.splice(answerIdx, 1)
  var AC = answerChoicesKey(answer, answers[0], answers[1], answers[2])

  var problem = {prob: questionText,
      answerChoices: AC,
      correctAnswer: answer}
  return problem
}

export const randCOIntegers = (userSelection, generalSelection) => {
  let {probType, ac} = userSelection.specify
  let probArray = []
  if (probType['Comparing']){
    probArray.push(compareWhole)
    if (ac && generalSelection.activity!=='Scavenger Hunt'){
      probArray.push(compareWholeAC)
    }
  } 
  if (probType['Ordering']){
    probArray.push(order, order2)
    if (ac && generalSelection.activity!=='Scavenger Hunt'){
      probArray.push(orderAC, orderAC2, orderAC3, orderAC4)
    }
  } 

  var randProb = selectRand(probArray)
  return randProb(userSelection, generalSelection, integerNumbers)
}