import {Text,  View,  StyleSheet, Image} from "@react-pdf/renderer";
import { LogoIcon } from "../../app-files/images/LogoIcon";
import { shuffleArray } from "../create-worksheet/problems/general";
import { borderList } from "./tcBorders";
import HorizontalDraft from '../../app-files/images/draft-horizontal.png';

export const createSH = (questionAnswerList, userSelection, generalSelection) => {
  const {colors, styleNum} = generalSelection.styles
  const border = borderList.filter(border=>{return border.name===generalSelection.styles.border.name})[0].create(colors, styleNum)
  const logo = LogoIcon(generalSelection.styles,'large')
  const tstyles = StyleSheet.create({
    shWrapper: {
      position: "relative",
      height:'99.99%',
      width:'100%',
      display:'flex',
      flexDirection:'column',
      alignItems:'center',
      justifyContent: 'center',
    },
    letterContainer:{
      left:0,
      top:0,
      marginTop:10,
      position:"absolute",
      display:'flex',
      flexDirection:'row',
      alignItems:'center',
      justifyContent:'center',
    },
    letter:{
      display:'flex',
      flexDirection:'row',
      alignItems:'center',
      justifyContent:'center',
      fontSize:'70',
      fontWeight:'bold',
      padding:'20 35 20 35',
      borderRadius: '50%',
      minWidth:115,
      backgroundColor: colors.secDark==='#fff' ? colors.mainDark : colors.secDark,
      color:'white',
      textAlign:'center'
    },
    akLetter:{
      marginRight:10,
      marginLeft:10,
      textAlign:'center',
      fontSize:14,
      minWidth:20
    },
    answerWrapper:{
      dislay:'flex',
      alignItems: 'flexEnd',
      justifyContent:'center',
    },
    answer:{
      width:630, //640
      fontSize:'115',
      fontWeight:'bold',
      paddingTop:10,
      textAlign:'center',
      display:'flex',
      flexDirection:'row',
      alignItems: 'center',
      justifyContent:'flexEnd',
      marginBottom: 10,
      borderBottom: '20 solid black'
    },
    smallAnswer: {
      fontSize: '80'
    },
    viewAnswer: {
      width:630, //640
      fontSize:'60',
      fontWeight:'bold',
      paddingTop:10,
      paddingBottom:10,
      display:'flex',
      flexDirection:'row',
      alignItems: 'center',
      justifyContent:'center',
      borderBottom: '20 solid black'
    },
    questionWrapper:{
      display:'flex',
      flexDirection:'row',
      justifyContent:'center',
      alignItems:'center',
      width:'100%',
    },
    question:{
      fontSize:'50',
      height:'250', //250 maybe was for stacked fractions
      width: '560', //233
      display:'flex',
      flexDirection:'row',
      justifyContent:'center',
      alignItems:'center',

    },
    WPquestion: {
      fontSize:'28', //Not fontsize for tables
    },
    OoOquestion:{
      fontSize:40,
      textAlign:'center'
    },
    smallOoOquestion:{
      fontSize:34,
      textAlign:'center'
    },
    answerKey:{
      display:'flex',
      flexDirection:'row',
      alignItems: 'center',
      width:300,
      marginTop:3,
      minHeight: 28,
      maxHeight: 28
    },

    splitAnswer:{
      // width:400,
      // textAlign:'right'
    },
    label:{
      // fontSize:34,
      // width:50,
      // marginBottom:20,
    },
    draft: {
      position: 'absolute',
      display:'block',
      height: '100%',
      width: '100%',
      top:-30,
      marginLeft:-20,
      //horizontal draft
      minWidth: 1000,
      maxWidth: 1000,
      minHeight: 800,
      maxHeight: 800
    },
    draftHZ: {

    },
  })  
  const repeatDraftBG = () => { // so that it is more difficult to attempt to remove if they try to.
    let bg = 1;
    let draftBG = []
    while(bg > 0){
      draftBG.push(<Image src={HorizontalDraft} fixed style={[tstyles.draft]} />)
      bg--;
    }
    return draftBG
  }
  let [letters, orderedLetters, answerKey] = [[], [], {}]
  for (let i=0; i<questionAnswerList.length; i++){ //Need to keep separate now for later shuffle
    letters.push(String.fromCharCode(i+65));
    orderedLetters.push(String.fromCharCode(i+65));
  }

  let shuffledLetters = generalSelection.styles.shuffledLetters || shuffleArray(letters)

  const SHCards = questionAnswerList.map((question, idx)=>{
    let answer = idx===0 ? questionAnswerList[questionAnswerList.length-1].answer : questionAnswerList[idx-1].answer
    let akAnswer = idx===0 ? questionAnswerList[questionAnswerList.length-1].answerKey : questionAnswerList[idx-1].answerKey
    //check problem length for WP bc they can select both WP or Algorithm so even if WP is selected it may not be 
    //should find a better way such as having application questions pass through the problem style, or making all problems work with the max width

    let questionStyle = question.concept==='Input Output Tables' || question.concept==='Order of Operations' || question.probType==='Application' ? 'WPquestion':'' //gives longer problems smaller fonts
    if (question.concept==='Order of Operations'){
      const questionText = question.question.props.children[0].props.children

      questionStyle = 'OoOquestion'
      if (questionText.split('\n')[1].length>31){
        questionStyle = 'smallOoOquestion'
        // tstyles.OoOquestion.fontSize=28
      }
    }
    let answerStyleQ = idx===0 ? questionAnswerList[questionAnswerList.length-1] : questionAnswerList[idx-1] //need to use q before to decide answer's styling
    let answerStyle = answerStyleQ.concept==='Input Output Tables'  ? 'smallAnswer' : ''

    //styles answer into text or view
    let displayAnswer = answer.type === 'TEXT' ? <Text style={[tstyles.answer, tstyles[answerStyle], {borderBottom: `20 solid ${colors.mainLight}`}]}>{answer}</Text> : 
                                                <View style={[tstyles.viewAnswer,  {borderBottom: `20 solid ${colors.mainLight}`}]}>{answer}</View>
    
    let currentQAIdx = idx===0 ? questionAnswerList.length-1 : idx-1
    answerKey[shuffledLetters[currentQAIdx]] = akAnswer ? akAnswer : answer //if a specific answer key answer was sent through that means it requires special styling (Most likely a table answer, The answer key needs to be much smaller vs the answer shown and can't be controlled by this styling since it is a nested component )
    return{
        answerLetter: shuffledLetters[currentQAIdx],
        letter: shuffledLetters[idx],

        card: (<View style={[tstyles.shWrapper, {zIndex: -1}]} wrap={false} >
        {border}
        {/* {generalSelection.styles.border.create(colors, styleNum)} */}
        <View  style={tstyles.letterContainer}><Text style={tstyles.letter}>{shuffledLetters[idx]}</Text></View>
        {displayAnswer}
        <View style={tstyles.questionWrapper}>
          <View style={[tstyles.question, tstyles[questionStyle]]}>{question.question}</View>
        </View>
        {logo}
        {/* {repeatDraftBG()} */}
      </View>)
      }
  })
  let orderedAnswers = {}
  for (let i=0; i<orderedLetters.length;i++){
    orderedAnswers[orderedLetters[i]] = answerKey[orderedLetters[i]]
  }
  
  const answerKeyDisplay = []
  const orderedSHCards = []
  for (let key in orderedAnswers){ 
    orderedSHCards.push(SHCards.find(card=>card.letter===key).card)
    answerKeyDisplay.push(
      orderedAnswers[key].type==='TEXT' ? 
      <View style={tstyles.answerKey}><Text style={tstyles.akLetter}>{key}</Text><Text>{orderedAnswers[key]}</Text></View>: //whole answer needs wrapped in text bc sometimes answer is plain text.
      <View style={tstyles.answerKey}><Text style={tstyles.akLetter}>{key}</Text>{orderedAnswers[key]}</View> // needs wrapped in a view bc this is for nested views (Tables)
      )
  }
  return [orderedSHCards, answerKeyDisplay, shuffledLetters]
    
}