import { specHZ, specify, specAC } from "../helper"

export const SimplifyingFractions = (props) => {
  const {createLink, ac} = props
  const c1 = 'Simplifying Fractions'
  const [noSimp, easy, med, hard, challenge] = ['Some may not require simplifying', '1 easy', '2 medium', '3 difficult', '4 challenging' ]
  
  const title = (mod1, mod2, mod3) => {
    return `Simplifying Level ${mod1}`
  }
  const createSpec = (spec1) => {
    let specAS = [specify('steps', spec1), specAC(ac)]

    return {[c1]: specAS}
  }

  return (<>
    <div className="buttonGroup">
      <div className="concept-topic">Simplifying Fractions (All Require Simplifying)</div>
      {createLink([c1], title(easy), title('1'), createSpec([easy]), 12)}
      {createLink([c1], title(easy+' & '+med), title('1-2'), createSpec([easy, med]), 12)}
      {createLink([c1], title(med), title('2'), createSpec([med]), 12)}
      {createLink([c1], title(med+ ' & '+hard), title('2-3'), createSpec([med, hard]), 12)}
      {createLink([c1], title(hard), title('3'), createSpec([hard]), 12)}
      {createLink([c1], title(hard+ ' & '+challenge), title('3-4'), createSpec([hard, challenge]), 12)}
      {createLink([c1], title(challenge), title('4'), createSpec([challenge]), 12)}


    </div>
    <div className="buttonGroup">
      <div className="concept-topic">Simplifying Fractions(Some may not require simplifying)</div>
      {createLink([c1], title(easy), title('1'), createSpec([easy, noSimp]), 12)}
      {createLink([c1], title(easy+' & '+med), title('1-2'), createSpec([easy, med, noSimp]), 12)}
      {createLink([c1], title(med), title('2'), createSpec([med, noSimp]), 12)}
      {createLink([c1], title(med+ ' & '+hard), title('2-3'), createSpec([med, hard, noSimp]), 12)}
      {createLink([c1], title(hard), title('3'), createSpec([hard, noSimp]), 12)}
      {createLink([c1], title(hard+ ' & '+challenge), title('3-4'), createSpec([hard, challenge, noSimp]), 12)}
      {createLink([c1], title(challenge), title('4'), createSpec([challenge, noSimp]), 12)}

    </div>

    </>
  )
}