import { specAlg, specify, specAC } from "../helper"

export const AddingDecimals = (props) => {
  const {probStyle, createLink, ac} = props
  const hz = probStyle === 'Horizontal'
  const c1 = 'Adding Decimals'
  const [dig1_3H, dig3_4H, dig4_5H, dig4_5Th] = ['1-3 digits to the hundredths', '3-4 digits to the hundredths', '4-5 digits to the hundredths', '4-5 digits to the thousandths']
  const [ph, samePV, diffPV] = ['Add placeholder zeros', 'Same decimal place values', 'Different decimal place values']
  
  const title = (mod1, mod2) => {
    return `Adding ${mod1} digit Decimals up to the ${mod2}`
  }
  const createSpec = (spec1, spec2) => {
    let specAS = [specify('numbers', spec1), specify('placeValues', spec2), specify('probStyle', probStyle), specAlg, specAC(ac)]

    return {[c1]: specAS}
  }

  return (<>
    {/* <div className="buttonGroup">
      <div className="concept-topic">1-3 Digit Algorithms to the Hundredths</div>
      {createLink([c1], title('1-3', 'hundredths (Set up)'), '', createSpec([dig1_3H], [samePV]), 18)}
      {createLink([c1], title('1-3', 'hundredths (Horizontal -Same place value)'), '', createSpec([dig1_3H], [samePV], 'hz'), 18)}
      {createLink([c1], title('1-3', 'hundredths (Horizontal -Different place value)'), '', createSpec([dig1_3H], [diffPV], 'hz'), 18)}
    </div>
    <div className="buttonGroup">
      <div className="concept-topic">3-4 Digit Algorithms to the Hundredths</div>
      {createLink([c1], title('3-4', 'hundredths (Set up)'), '',createSpec([dig3_4H], [samePV]), 18)}
      {createLink([c1], title('3-4', 'hundredths (Horizontal -Same place value)'), '', createSpec([dig3_4H], [samePV], 'hz'), 18)}
      {createLink([c1], title('3-4', 'hundredths (Horizontal -Different place value)'), '', createSpec([dig3_4H], [diffPV], 'hz'), 18)}
    </div>
    <div className="buttonGroup">
      <div className="concept-topic">4-5 Digit Algorithms to the Hundredths</div>
      {createLink([c1], title('4-5', 'hundredths (Set up)'), '', createSpec([dig4_5H], [samePV]), 18)}
      {createLink([c1], title('4-5', 'hundredths (Horizontal -Same place value)'), '', createSpec([dig4_5H], [samePV]), 'hz', 18)}
      {createLink([c1], title('4-5', 'hundredths (Horizontal -Different place value)'), '', createSpec([dig4_5H], [diffPV]), 'hz', 18)}
    </div>
    <div className="buttonGroup">
      <div className="concept-topic">4-5 Digit Algorithms to the Thousandths</div>
      {createLink([c1], title('4-5', 'thousandths (Set up)'), '', createSpec([dig4_5Th], [samePV]), 18)}
      {createLink([c1], title('4-5', 'thousandths (Horizontal -Same place value)'), '', createSpec([dig4_5Th], [samePV]), 'hz', 18)}
      {createLink([c1], title('4-5', 'thousandths (Horizontal -Different place value)'), '', createSpec([dig4_5Th], [diffPV]), 'hz', 18)}
    </div> */}


    <div className="buttonGroup">
      <div className="concept-topic">Adding Decimals (Same Decimal Place Values) </div>
      {createLink([c1], title('1-3', 'hundredths'), '', createSpec([dig1_3H], [samePV]), 18)}
      {createLink([c1], title('3-4', 'hundredths'), '',createSpec([dig3_4H], [samePV]), 18)}
      {createLink([c1], title('4-5', 'hundredths'), '', createSpec([dig4_5H], [samePV]), 18)}
      {createLink([c1], title('4-5', 'thousandths'), '', createSpec([dig4_5Th], [samePV]), 18)}
    </div>
    <div className="buttonGroup">
      <div className="concept-topic">Adding Decimals (Different Decimal Place Values)</div>
      {createLink([c1], title('1-3', 'hundredths'), '', createSpec([dig1_3H], [diffPV]), 18)}
      {createLink([c1], title('3-4', 'hundredths'), '', createSpec([dig3_4H], [diffPV]), 18)}
      {createLink([c1], title('4-5', 'hundredths'), '', createSpec([dig4_5H], [diffPV]), 18)}
      {createLink([c1], title('4-5', 'thousandths'), '', createSpec([dig4_5Th], [diffPV]), 18)}
    </div>
    </>
  )
}
