import * as g from "../general";
import {randInt, shuffleArray, selectRand, answerChoicesKey, wrongOptions, fixNum, cap} from '../general'
import {verticalAlignWhole} from '../vertical-align'
export const multNumbers = (userSelection) =>{
    let numSelect = (userSelection.concept === 'Multiplying Whole Numbers' || userSelection.concept==='Area') ? 
                        userSelection.specify.numbers : 
                    userSelection.concept === 'Tables' ? userSelection.specify.wholeNumbers : userSelection.specify.multiply
    var numArray = []
    if (numSelect['1 by 1 digit']) {
        numArray.push([randInt(2, 9), randInt(2, 20), randInt(2, 9)])

    } if (numSelect['2 by 1 digit']) {
        numArray.push([randInt(2, 9), randInt(12, 67), randInt(10, 99)])

    } if (numSelect['3 by 1 digit']) {
        numArray.push([randInt(2, 9), randInt(12, 67), randInt(100, 999)])

    } if (numSelect['4 by 1 digit']) { 
        numArray.push([randInt(2, 9), randInt(12, 67), randInt(1000, 9999)])

    } if (numSelect["2 by 2 digit"]) { 
        numArray.push([randInt(12, 49), randInt(12, 67), randInt(50, 99)])

    } if (numSelect["3 by 2 digit"]) { 
        numArray.push([randInt(20, 99), randInt(20, 90), randInt(102, 999)])
    }
    var numList = g.selectRand(numArray)
    // numList.sort((a, b) => a-b);
    const [numberS, numberM, numberL] = numList
    
    const answer =numberS*numberL //answer & wrong are only for standard one-step problems.
    const wrong= shuffleArray(wrongOptions(answer, 'multiply', numberL, numberS)) //wont be great wrong userSelection for the level 3 Quetions   
    
    return {answer, numberL, numberM, numberS, wrong}
}
export const multAlg = (userSelection, generalSelection) => {
    var {numberS, numberL, answer, wrong} = multNumbers(userSelection)

    var AC = answerChoicesKey(answer, wrong[0], wrong[1], wrong[2])
    if (userSelection.specify.probStyle==='Set Up') {
        var prob = {numberL:fixNum(numberL), numberS:fixNum(numberS), op:'×'}
    } else {
        prob = `${fixNum(numberL)} × ${fixNum(numberS)} = `
    }
    var problem = {prob: prob,
                answerChoices: AC,
                correctAnswer:answer.toLocaleString(),
                probStyle:userSelection.specify.probStyle
                }
    return problem
}

export const multWholeBasic = (userSelection, generalSelection) => { //basic product/sum/difference
    var {numberS, numberM, numberL, answer, wrong} = multNumbers(userSelection)

    if (userSelection.specify.level === 'One step') {
        var prob1 = (`What is the product of ${numberS} and ${fixNum(numberL)}?`)
        var prob2 = (`What is the product of ${fixNum(numberL)} and ${numberS}?`)
        var prob3 = (`A number is ${fixNum(numberL)} times as large as ${numberS}. What is the number?`)
        var prob4 = (`A number is ${numberS} times as large as ${fixNum(numberL)}. What is the number?`)
        var prob5 = (`How much many total are in ${numberS} groups with ${fixNum(numberL)} in each group?`)
        var prob6 = (`How much many total are in ${fixNum(numberL)} groups with ${numberS} in each group?`)

        var randProb = selectRand([prob1, prob2, prob3, prob4, prob5, prob6])

    } else if (userSelection.specify.level === 'Multi-Step') {
        prob1 = (`What is the sum of ${numberM} and the product of ${fixNum(numberL)} and ${numberS}?`)
        prob2 = (`What is the sum of ${numberM} and the product of ${numberS} and ${fixNum(numberL)}?`)
        prob3 = (`What is the difference between ${numberM} and the product of ${numberS} and ${fixNum(numberL)}?`)
        prob4 = (`What is the difference between ${numberM} and the product of ${fixNum(numberL)} and ${numberS}?`)
        randProb = selectRand([prob1, prob2, prob3, prob4])
        if (randProb === prob1 || randProb === prob2){ 
            answer = numberL*numberS+numberM
        } else {
            answer = numberL*numberS-numberM
        }
        wrong = [numberM*numberS+numberL, numberL*numberS, answer+1, answer+2]
        if (answer > 4){
            wrong.push(answer-1, answer-2)
        }

    } else {
        var numberL2 = randInt(111,99) //not going to change based on specify
        var numberS2 = randInt(12,99)
        prob1 = (`What is the difference between the product of ${fixNum(numberL)} and ${numberS} and the product of ${numberS2} and ${numberL2}?`)
        prob2 = (`What is the sum of the product of ${fixNum(numberL)} and ${numberS} and the product of ${numberS2} and ${numberL2}?`)
        prob3 = (`A number is ${numberS} times as much as the sum of ${numberM} and ${numberS2}and ${numberS}. What is the number?`)
        randProb = [prob1, prob2, prob3][randInt(0,2)]

        if (randProb === prob1){
            answer = numberL*numberS - numberS2*numberL2
        } else if (randProb === prob2){
            answer = numberL*numberS + numberS2*numberL2
        } else {
            answer = numberS*(numberM+numberS2+numberS)
        }

        wrong = [numberL2+numberS2+numberL+numberS, numberL*numberS+numberL2+numberS2, answer+1, answer-1, answer+2, answer-2]

    }
    
    let AC = answerChoicesKey(answer, wrong[0], wrong[1], wrong[2])
    let problem = {prob: randProb,
        answerChoices: AC,
        correctAnswer: answer.toLocaleString(), 
        probType: 'Application'
    }

    return problem     

}



export const randMultWhole = (userSelection, generalSelection) => {
    let probArray = []
    if (userSelection.specify.probType['Application']) {
        probArray.push(multWholeBasic)

    } if (userSelection.specify.probType['Algorithm']) {
        probArray.push(multAlg)
    }
    let randProb = selectRand(probArray)
    return randProb(userSelection, generalSelection)
}
