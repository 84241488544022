import { randInt, shuffleArray, wrongOptions, answerChoicesKey, selectRand, removeDup, fixNum } from "../general";

const wrongDivInt = (answer, numberS, numberL) => {
    let wrong = [answer+randInt(1,2), answer-randInt(1,2)]
    let wrongCheck = (checkAns) => {
    let duplicate = false
    for (let i of wrong){
        if (i === checkAns || checkAns === answer){
        duplicate = true
        }
    }
    if(!duplicate){
        wrong.push(checkAns)
    }
    }
    wrongCheck(answer*-1) //inverse answer
    wrongCheck(answer+randInt(1,2)*-1)
    wrongCheck(numberS*numberL) //opposite op
    return shuffleArray(wrong)
}

export const divIntNumbers = (userSelection) => {
  let numSelect = userSelection.concept === 'Dividing Integers' ? userSelection.specify.numbers : userSelection.specify.divide
  var numArray = []
  // Repeat negative by negative 2 times to ensure a balanced amount of neg/neg vs neg/pos
  if (numSelect['2 by 1 digit']) {
      numArray.push([randInt(-10, -5), randInt(2, 9)], [randInt(5, 10), randInt(-9, -2)], [randInt(-10, -5), randInt(-9, -2)], [randInt(-10, -5), randInt(-9, -2)],
      )
  } if (numSelect['3 by 1 digit']) {
      numArray.push(
          [randInt(50, 99), randInt(-9, -2)], [randInt(-99, -50), randInt(2, 9)], [randInt(-99, -50), randInt(-9, -2)], [randInt(-99, -50), randInt(-9, -2)],
          [randInt(25, 50), randInt(-9, -5)], [randInt(-50, -25), randInt(5, 9)], [randInt(-50, -25), randInt(-9, -5)], [randInt(-50, -25), randInt(-9, -5)],
          [randInt(101, 166), randInt(-6, -2)], [randInt(-166, -101), randInt(2, 6)], [randInt(-166, -101), randInt(-6, -2)],  [randInt(-166, -101), randInt(-6, -2)],
          [randInt(166, 249), randInt(-4, -2)], [randInt(-249, -166), randInt(2, 4)], [randInt(-249, -166), randInt(-4, -2)], [randInt(-249, -166), randInt(-4, -2)],
      )
  } if (numSelect['4 by 1 digit']) {
      numArray.push(
          [randInt(500, 999), randInt(-9, -2)], [randInt(-999,-500), randInt(2, 9)], [randInt(-999,-500), randInt(-9, -2)], [randInt(-999,-500), randInt(-9, -2)],
          [randInt(250, 500), randInt(-9, -4)], [randInt(-500,-250), randInt(4, 9)], [randInt(-500, -250), randInt(-9, -4)], [randInt(-500, -250), randInt(-9, -4)],
          [randInt(1000, 1660), randInt(-6, -2)], [randInt(-1600,-1000), randInt(2, 6)], [randInt(-1660, -1000), randInt(-6, -2)], [randInt(-1660, -1000), randInt(-6, -2)],
          [randInt(1660, 2490), randInt(-4, -2)], [randInt(-2490,-1660), randInt(2, 4)], [randInt(-2490, -1660), randInt(-4, -2)], [randInt(-2490, -1660), randInt(-4, -2)],
      )
  } if (numSelect['3 by 2 digit']) { 
      numArray.push(
          [randInt(7, 41), randInt(-24,-15)], [randInt(-41, -7), randInt(15, 24)], [randInt(-41, -7), randInt(-24, -15)], [randInt(-41, -7), randInt(-24, -15)],
          [randInt(3, 19), randInt(-50,-35)], [randInt(-19, -3), randInt(35, 50)], [randInt(-19, -3), randInt(-50, -35)], [randInt(-19, -3), randInt(-50, -35)],
          [randInt(15, 28), randInt(-35, -21)], [randInt(-28, -15), randInt(21, 35)], [randInt(-28, -15), randInt(-35, -21)], [randInt(-28, -15), randInt(-35, -21)],
          [randInt(3, 12), randInt(-80, -50)], [randInt(-12, -3), randInt(50, 80)], [randInt(-12, -3), randInt(-80, -50)], [randInt(-12, -3), randInt(-80, -50)],
      )
  } if (numSelect['4 by 2 digit']) {
      numArray.push(
          [randInt(67, 416), randInt(-24,-15)], [randInt(-416, -67), randInt(15, 24)], [randInt(-416, -67), randInt(-24, -15)], [randInt(-416, -67), randInt(-24, -15)],
          [randInt(28, 199), randInt(-50,-35)], [randInt(-199,-28), randInt(35, 50)], [randInt(-199, -28), randInt(-50, -35)], [randInt(-199, -28), randInt(-50, -35)],
          [randInt(48, 285), randInt(-35,-21)], [randInt(-285, -48), randInt(21, 35)], [randInt(-285, -48), randInt(-35, -21)], [randInt(-285, -48), randInt(-35, -21)],
          [randInt(20, 124), randInt(-80,-50)], [randInt(-124, -20), randInt(50, 80)], [randInt(-124, -20), randInt(-80, -50)], [randInt(-124, -20), randInt(-80, -50)],
      )
  }
  var [answer, numberS] = selectRand(numArray)
  var numberL = answer*numberS
  const wrong = wrongDivInt(answer, numberS, numberL)
  var AC = answerChoicesKey(answer, wrong[0], wrong[1], wrong[2])
  
  return {answer, numberS, numberL, wrong, AC}
}

const divInt = (userSelection) => {

  var {numberL, numberS, answer, AC} = divIntNumbers(userSelection)

  var prob =`${fixNum(numberL)} ÷ ${numberS} = `
  
  var problem = {prob: prob,
              answerChoices: AC,
              correctAnswer:answer,
              }
  return problem
}

export const divIntBasic = (userSelection) => {
    let {numberL, numberS, answer, wrong} = divIntNumbers(userSelection)
    let prob =`What is the quotient of ${fixNum(numberL)} and ${numberS}?`
    let prob2 =`How many equal groups of ${numberS} can be made with ${fixNum(numberL)}?`
    let prob3 =`How many groups of ${numberS} can be made with ${fixNum(numberL)}?`

    let randNum = numberL > 9 || numberL < -9  ? randInt(10,99) : randInt(2, 9)
    let randProb = numberS < 0 && numberL < 0 ? selectRand([prob, prob2, prob3]) : prob // (Only use "How many groups" question type if both  numbers are negtaive. So how many groups of -2 can be made with -8.)

    if (userSelection.specify.level === 'Multi-Step'){
        let temp1 =  `What is ${randNum} more than the quotient of ${fixNum(numberL)} and ${numberS}?`
        let temp2 =  `What is the sum of ${randNum} and the quotient of ${fixNum(numberL)} and ${numberS}?`
        let temp3 =  `What is ${randNum} less than the quotient of ${fixNum(numberL)} and ${numberS}?`
        let temp4 =  `What is the product of ${randNum} and the quotient of ${fixNum(numberL)} and ${numberS}?`
        randProb = selectRand([temp1, temp2, temp3, temp4])
        if (randProb === temp1 || randProb === temp2){
            answer = answer + randNum
            wrong = [randNum+numberL, numberL/numberS, numberL+randNum+numberS, answer+1, answer-1, answer+2, answer-2, answer*-1]
        } else if (randProb === temp3) {
            answer = answer - randNum
            wrong = [randNum+numberL, numberL/numberS, numberL/numberS+randNum, answer+1, answer-1, answer+2, answer-2, answer*-1]
        } else {
            answer = randNum*answer
            wrong = [randNum*numberL, numberL/numberS, answer+1, answer-1, answer+2, answer-2, answer*-1]
        }
    }

    wrong = shuffleArray(removeDup(wrong))
    let AC = answerChoicesKey(answer, wrong[0], wrong[1], wrong[2])

    let problem = {prob: randProb,
                answerChoices: AC,
                correctAnswer:answer,
                probType: 'Application',
                
                }

    return problem
}

export const randDivInt = (userSelection, generalSelection) => {
  var probArray = []
  if (userSelection.specify.probType['Algorithm']) {
    probArray.push(divInt)
  } 
  if (userSelection.specify.probType['Application']) {
    probArray.push(divIntBasic)
  }
  var randProb = selectRand(probArray)
  return randProb(userSelection, generalSelection)
}