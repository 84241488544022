export const borderTest = {
  concept: "Adding Whole Numbers",
  specify: {numbers:{'5 digit numbers':true}, probType:{'Algorithm':true}, probStyle: 'Set Up', quantity: 10, ac: false},
  isChecked: false,
  key: null
};


//level for Application = 'One step' or 'Multi-Step
const getOptions = (concept, quantity, ac, vt, level='Multi-Step') => {
  quantity=5
  ac = false
let app = false
let alg = true
vt = true
const options = {
  //////////////////// WHOLE NUMBERS ////////////////////////////
  addWN:{ concept: "Adding Whole Numbers", 
    specify: {level, quantity, ac, 
      numbers:{'7 digit numbers':false, '6 digit numbers': false, '5 digit numbers':false, '4 digit numbers':false, '2 digit numbers':true}, 
      probType:{'Algorithm':alg, 'Application':app}, 
      probStyle: vt?'Set Up':'Horizontal'},
  },
  subWN:{ concept: "Subtracting Whole Numbers", 
    specify: {level, quantity, ac,
      numbers:{'6 digit numbers':false, '2 digit numbers':true}, 
      regroup: {'No regrouping':true, 'Regroup with zero':false, 'Regroup without zero':false}, 
      probType:{'Algorithm':alg, 'Application':app}, 
      probStyle: vt?'Set Up':'Horizontal'},
  },
  multWN:{ concept: "Multiplying Whole Numbers", 
    specify: { level, quantity, ac,
      numbers:{'1 by 1 digit':true, '2 by 1 digit':false, '2 by 2 digit': false, '3 by 2 digit': false, '4 by 1 digit':false}, 
      probType:{'Algorithm':alg, 'Application':app}, 
      probStyle: vt?'Set Up':'Horizontal', 
     },
    },
  divWN:{ concept: "Dividing Whole Numbers", 
    specify: {level, quantity, ac,
      numbers:{'4 by 2 digit': false, '4 by 1 digit': false, '3 by 2 digit':false, '3 by 1 digit':false, '2 by 1 digit':true}, 
      probType:{'Algorithm':alg, 'Application':app}, 
      probStyle: vt?'Set Up':'Horizontal'},
    },
  /////////////////// COMPARING AND ORDERING /////////////////////////
  
  compInt:{ concept: "Comparing Integers",
    specify: { quantity, ac,
      numbers:{'1 digit numbers':true, '2 digit numbers':true}, 
      probType:{'Comparing':false, 'Ordering':true},},
  },
  compDec:{ concept: "Comparing Decimals",
    specify: {quantity, ac,
      probType:{'Comparing':false, 'Ordering':true}, 
      placeValues:'Thousandths', },
  },
  compWN:{ concept: "Comparing Whole Numbers",
    specify: {quantity, ac,
      numbers:{'7 digit numbers':true}, 
      probType:{'Comparing':false, 'Ordering':true}, },
  },


  //////////////// DECIMAL OPERATIONS ///////////////////////
  divDec:{ concept: "Dividing Decimals",isChecked: false,key: null,
    specify:{ac, quantity, level,
      dividend:{'3 digits': true,'4 digits': true },
      divisor:{ '1 digit decimal': false, '1 digit whole number': true,
      '2 digit decimal': false, '2 digit whole number': true},
      probStyle: vt?"Set Up":"Horizontal",
      probType:{ Algorithm: alg, Application: app},
      }
  },
  multDec:{ concept: "Multiplying Decimals",isChecked: false,key: null,
    specify:{ac, quantity, level,
      numbers:{'3 by 1 digit': false,'4 by 1 digit': false, '2 by 2 digit': true, '3 by 2 digit':true},
      probStyle: vt?"Set Up":'Horizontal',
      probType:{ Algorithm: alg, Application: app},
    }
  },
  addDec:{ concept: "Adding Decimals",isChecked: false,key: null,
    specify:{ac, quantity, level,
      numbers:{'1-3 digits to the hundredths': true,'3-4 digits to the hundredths': false, '4-5 digits to the hundredths': false, '4-5 digits to the thousandths':true},
      placeValues:{'Same decimal place values': false,'Different decimal place values': true},
      placeholderZero: {'Add placeholder zeros':true},
      probStyle: vt?'Set Up':'Horizontal',
      probType:{ Algorithm: alg, Application: app},
    }
  },
  subDec:{ concept: "Subtracting Decimals",isChecked: false,key: null,
    specify:{ac, quantity, level,
      numbers:{'1-3 digits to the hundredths': true,'3-4 digits to the hundredths': true, '4-5 digits to the hundredths': true, '4-5 digits to the thousandths':false},
      placeValues:{'Same decimal place values': false,'Different decimal place values': true},
      placeholderZero: {'Add placeholder zeros':true},
      probStyle: vt?'Set Up':'Horizontal',
      probType:{ Algorithm: alg, Application: app},
    }
  },


  iot:{ concept: "Input Output Tables",
    specify: {quantity, ac:true, level,
      numberType:{'Whole numbers':false, 'Decimals':true}, 
      numberSize:{'Small':false, 'Medium':false, 'Large':true},
      steps: {'One-step':false, 'Two-steps':true}},

  },
  ooo:{concept: "Order of Operations",
    specify: {quantity, ac, level,
      numberType:{'Whole numbers':false, 'Decimals':false, 'Integers':true},// 1 2 (small, medium, large)
      steps: {'3 steps':false, '4 steps':false, '5 steps':true}, 
    },

  },

  ////////////////////////////// EQUATIONS //////////////////////////////
  wholeEq:{concept: "Whole Number Equations",
  specify: {quantity, ac,
    operation:{'Addition':true, 'Subtraction':true,'Multiplication':true, 'Division':true},
    asNumbers:{'1 digit numbers':false, '2 digit numbers':true, '3 digit numbers':false, '4 digit numbers': false},
    multiply: {'3 by 2 digit':false, '1 by 1 digit':true},
    divide:{'4 by 2 digit':false, '2 by 1 digit':true},
    },

  },
  intEq:{concept: "Integer Equations",
    specify: {quantity, ac,
      operation:{'Addition':true, 'Subtraction':true,'Multiplication':true, 'Division':true},
      asNumbers:{'1 digit numbers':true, '2 digit numbers':false, '3 digit numbers':true},
      multiply: {'3 by 2 digit':true, '1 by 1 digit':true},
      divide:{'4 by 2 digit':true, '2 by 1 digit':true},
      },
  },
  decEq:{concept: "Decimal Equations",
    specify: {quantity, ac,
      operation:{'Addition':true, 'Subtraction':true,'Multiplication':true, 'Division':true},
      asNumbers:{'1-3 digits to the hundredths': false,'3-4 digits to the hundredths': false, '4-5 digits to the hundredths': false, '4-5 digits to the thousandths':true},
      multiply: {'3 by 1 digit': true,'4 by 1 digit': true, '2 by 2 digit': false, '3 by 2 digit':true},
      dividend:{'2 digits': true,'4 digits': true },
      divisor:{ '1 digit decimal': true, '1 digit whole number': false,
      '2 digit decimal': true, '2 digit whole number': false},
      },
  },

  //////////////////////////INTEGER OPERATIONS ///////////////////////////////
  addInt:{ concept: "Adding Integers",
    specify: {quantity, ac, level,
      numbers:{'1 digit numbers':true, '2 digit numbers':false, '3 digit numbers':false},
      probType:{ Algorithm: alg, Application: app},
      },
  },
  subInt:{ concept: "Subtracting Integers",
    specify: {quantity, ac, level,
      numbers:{'1 digit numbers':true, '2 digit numbers':false, '3 digit numbers':false},
      probType:{ Algorithm: alg, Application: app},
      },
  },
  divInt:{ concept: "Dividing Integers",
    specify: {quantity, ac, level,
      numbers:{'4 by 2 digit':false, '2 by 1 digit':true},
      probType:{ Algorithm: alg, Application: app},
      },
  },
  multInt:{ concept: "Multiplying Integers",
    specify: {quantity, ac, level,
      numbers:{'1 by 1 digit':true, '3 by 2 digit':false},
      probType:{ Algorithm: alg, Application: app},
      },
  },

  //////////////////////// FRACTION OPERATIONS /////////////////////////////
  addF:{ concept: "Adding Fractions",
    specify: {quantity, ac,
      numbers:{'Fractions':true, 'Mixed Numbers':true}, 
      probStyle: vt?'Set Up':'Horizontal',
      denominator:{'Common':false, 'Uncommon':true}, 
      maxDenominator: '10', //5 10 15 
      },
  },
  subF:{ concept: "Subtracting Fractions",
    specify: {quantity, ac,
      numbers:{'Fractions':true, 'Mixed Numbers':false, "Mixed Numbers With Regrouping":false},
      probStyle: vt?'Set Up':'Horizontal',
      denominator:{'Common':true, 'Uncommon':false}, 
      maxDenominator: '10', //5 10 15 
      },
  },
  multF:{ concept: "Multiplying Fractions",
    specify: {quantity, ac,
      numbers:{"Unit Fractions with Whole Numbers":true, "Fractions with Whole Numbers":false, "Fractions":false,
      "Mixed Numbers":false, "Mixed Numbers with Whole Numbers":false},  
      },

  },
  divF:{ concept: "Dividing Fractions",
    specify: {quantity, ac,
      numbers:{"Unit Fractions with Whole Numbers":true, "Fractions with Whole Numbers":false, "Fractions":false,
      "Mixed Numbers":false, "Mixed Numbers with Whole Numbers":false},  
     },
  },
  /////////////////////  FRACTION BASICS ///////////////////////
  simpF:{ concept: "Simplifying Fractions",
    specify: {quantity, ac,
      steps:{"Some may not require simplifying":true, "1 easy":true, "2 medium":false, "3 difficult": false, "4 challenging":true},
      },
  },
  miF:{ concept: "Mixed & Improper Fractions",
    specify: {quantity, ac,
      type:{"Mixed to Improper":false, "Improper to Mixed":true},
      numbers:{'Up to 10':true, '10 to 20':false},
      },
  },
  compF:{concept: "Comparing Fractions",
    specify: {quantity, ac,
      denominator:{"Common":false, "Uncommon":true},
      // probType:{'Comparing':true, 'Ordering':true},
      maxDenominator: '10',
      },

  },
  ////////////////// AREA AND PERIMETER //////////////////////
  area: {concept: "Area",//14
    specify: {quantity, ac,
      numberType:{"Whole Numbers":false, "Decimals":true}, // 1 by 1 & 2 by 1 only if Whole Numbers
      numbers:{'1 by 1 digit':false, '2 by 1 digit':true,'3 by 1 digit':true, '4 by 1 digit':false, '2 by 2 digit':true, '3 by 2 digit':false,},
      },

  },
  per: {concept: "Perimeter",//14
    specify: {quantity, ac,
      numberType:{"Whole Numbers":true, "Decimals":true},
      wholeNumbers:{"1 digit numbers":true, "3 digit numbers":true},
      decimals:{"1-3 digits to the hundredths":false, "3-4 digits to the hundredths":false,  "4-5 digits to the hundredths":true, "4-5 digits to the thousandths":true},
      placeValues:{'Same decimal place values':true, 'Different decimal place values':false},
      },

  }
}

  return options[concept]
}
//mixUSCheck 

// export const mixUSCheck =   [
//   //concept, quantity, ac
//   getOptions('per', 10, false),
//   getOptions('per', 10, true)

//   // options.area, 
//   ]




  /////// ORDERING CANNOT WORK WITH SH 
 /// (conceptCode, quantity, answerchoices, Set Up, level)
  export const mixUSCheck =   [
    //ordering must have AC with Bingo/Tic Tac Bingo
    // getOptions('compF', 3, true),
    // getOptions('compWN', 5, false),
    // getOptions('compDec', 2, true),


    // getOptions('addF', 3, true, false),
    // getOptions('addF', 5, true, true),
    // getOptions('subF', 6, true, false),
    // getOptions('multF', 10, true),
    // getOptions('divF', 2, true),
    // getOptions('miF', 3, true),
    // getOptions('simpF', 3, true),
    
    getOptions('addWN', 5, true, true),
    getOptions('subWN', 5, true, true),
    getOptions('multWN', 3, true, true),
    // getOptions('divWN', 3, true, true),
  
    // getOptions('addDec', 3, true, false),
    // getOptions('subDec', 3, true, false),
    // getOptions('multDec', 2, true, true),
    // getOptions('divDec', 2, true, true),
    
    // getOptions('addInt', 10, false),
    // getOptions('subInt', 3, true),
    // getOptions('multInt', 3, true),
    // getOptions('divInt', 2, true),


    // getOptions('iot', 10), //must have ac
    // getOptions('ooo', 1, true, false, '2'),


    // getOptions('intEq', 5, true),
    // getOptions('wholeEq', 2, true),
    // getOptions('decEq', 5, true),


    // getOptions('per', 2, false),
    // getOptions('area', 2, false),

 

    ]
    const getQuantity = () => {
      let quantity = 0
      for (let i of mixUSCheck){
        quantity+= i.specify.quantity
      }
      return quantity
    }
    export const genBorderTest = {
      mixed: true,
      columns: true,
      // savePaper: true,
      title: "Mixed Concepts",

      // activity:"Scavenger Hunt",
      // questionStyle:"Scavenger Hunt",
      // activity:'Bingo',
      // activity: "Tic Tac Bingo",

      // questionStyle: 'Task Cards',
      // activity: 'Task Cards',
      
      activity: 'Worksheet',
      questionStyle: 'Worksheet',
      // styles:{}, //colors:{main, mainLight, mainDark, sec, secLight, secDark}, border, styleNum

      //samples WW Colors
      // styles: {colors:{
      //   sec:'#522b52', secLight: '#b3a0b3', secDark: '#381638',
      //   main:'#609285', mainLight:'#a8cac2',mainDark:'#405852'}
      // }, //WW Colors opposite

 


      key: null,
      // BGQuantity:30,
      ac: true,
      header: {name: true, date: true}, //name, date, period, score, number
      quantity: getQuantity()
    };
    genBorderTest.maxQuantity = genBorderTest.activity === 'Scavenger Hunt' ? 24 : genBorderTest.activity === 'Tic Tac Bingo' ? 18 : 36









//testing light colors
// styles: {colors: {
//   sec:'#609285', secLight:'#a8cac2',secDark:'#405852',
//   main: '#FFFF66', mainLight: '#FFFF99', mainDark: '#FFFF00'
//   }},

//testing white sec color
// styles: {colors: {
//   sec: '#fff', secLight: '#fff', secDark: '#fff', 
//   main: 
// }}

// styles: {colors:{
//   main:'#522b52', mainLight: '#b3a0b3', mainDark: '#381638',
//   sec:'#609285', secLight:'#a8cac2',secDark:'#405852'}
// }, //WW Colors regular


/////MIXED OPERATIONS


// export const genBorderTest = {
//   mixed: true,
//   columns: true, 
//   title: "Mixed Concepts",
//   activity: 'Worksheet',
//   questionStyle: 'Worksheet',
//   styles: {colors:{
//     sec:'#522b52', secLight: '#b3a0b3', secDark: '#381638',
//     main:'#609285', mainLight:'#a8cac2',mainDark:'#405852'}
//   }, //WW Colors opposite
//   key: null,
//   BGQuantity:30,
//   ac: true,
//   header: {name: true, date: true, score: true}, //name, date, period, score, number
//   quantity: getQuantity()
// };
// getOptions('ooo', 2, true),
// getOptions('iot', 2), //must have ac

// getOptions('area', 2, false),
// getOptions('wholeEq', 2, true),
// getOptions('multDec', 2, true, true),
// getOptions('divDec', 2, true, true),

// getOptions('multF', 2, true),
// getOptions('divF', 2, true),
// getOptions('compDec', 2, true),


