import {randInt, randDec, roundDec, shuffleArray, wrongOptions, answerChoicesKey,
        largestDecPV, decPV, removeDec, selectRand, displayZero, fixNum, removeDup,
} from '../general.js'
import { verticalAlignDec} from '../vertical-align'
export const asNumbers = (userSelection, op) =>{
    const {concept, specify} = userSelection
    const {placeValues} = userSelection.specify
    var numArray = []
    let numSelect
    if (concept === 'Adding Decimals' || concept==='Subtracting Decimals'){
        numSelect =  specify.numbers 
    } else if (concept==='Tables' || concept==='Perimeter'){
        numSelect = specify.decimals
    } else { //EQUATIONS
        numSelect = specify.asNumbers
    }

    if (numSelect['1-3 digits to the hundredths']) {
        if (placeValues && placeValues['Same decimal place values']){
            numArray.push(
                [randDec(1,9,2), randDec(1,9,2)],
                [randDec(1,9,2), randDec(0.1,0.99,2)],
                [randDec(1,9,1), randDec(1,9,1)],
                [randDec(1,9,1), randDec(0.1,0.9,1)],        
            )
        }
        if (concept==='Decimal Equations' || placeValues['Different decimal place values']){
            numArray.push(
                [randDec(1,9,2), randDec(1,9,1)],
                [randDec(1,9,2), randDec(0.1,0.9,1)],
                [randDec(1,9,1), randDec(0.11,0.99,2)],
                [randDec(1,9,1), randDec(0.11,0.99,2)],
                [randDec(1,9,2), randDec(1,9,1)],
                [randDec(1,9,2), randDec(0.1,0.9,1)],
                [randInt(10,20), randDec(1,9,1)],
                [randInt(10,20), randDec(0.1,0.9,1)],
                [randInt(1,9), randDec(1,9,2)],
                [randInt(1,9), randDec(0.10,0.99,2)],
                [randInt(1,9), randDec(10,99,1)],
            
            )
        } 
    } if (numSelect['3-4 digits to the hundredths']) {
        if (placeValues && placeValues['Same decimal place values']){
            numArray.push(
                [randDec(1,9,2), randDec(1,9,2)],
                [randDec(1,9,2), randDec(10,99,2)],
                [randDec(10,99,1), randDec(10,99,1)],
                [randDec(10,99,2), randDec(10,99,2)],   
                [randDec(10,99,1), randDec(100,999,1)],
                [randDec(100,999,1), randDec(100,999,1)],       
            )
        }
        if (concept==='Decimal Equations' || placeValues['Different decimal place values']){
            numArray.push(
                [randDec(1,9,2), randDec(10,99,1)],
                [randDec(1,9,2), randDec(100,999,1)],
                [randDec(10,99,1), randDec(10,99,2)],
                [randDec(100,999,1), randInt(2,1000)],
                [randDec(10,99,1), randInt(10,99)],   
                [randDec(10,99,1), randInt(2,1000)],
                [randDec(1,9,2), randInt(10,99)],   
                [randDec(1,9,2), randInt(2,1000)],   
            )
        } 
    } if (numSelect['4-5 digits to the hundredths']) {
        if (placeValues && placeValues['Same decimal place values']){
            numArray.push(
                [randDec(100,999,2), randDec(10,99,2)], 
                [randDec(100,999,2), randDec(100,999,2)],
                [randDec(100,999,1), randDec(100,999,1)],
                [randDec(100,999,1), randDec(1000,9999,1)],   
                [randDec(10,99,2), randDec(10,99,2)],      
            )
        }
        if (concept==='Decimal Equations' || placeValues['Different decimal place values']){
            numArray.push(
                [randDec(10,99,2), randDec(100,999,1)], 
                [randDec(100,999,1), randInt(2,1000)],
                [randDec(1000,9999,1), randInt(2,10000)],
                [randDec(10,99,2), randInt(2,1000)],
                [randDec(10,99,2), randDec(109,999,1)],       
            )
        } 
    } if (numSelect['4-5 digits to the thousandths']) {
        if (placeValues && placeValues['Same decimal place values']){
            numArray.push(
                [randDec(100,999,2), randDec(10,99,2)], 
                [randDec(100,999,2), randDec(100,999,2)],
                [randDec(100,999,1), randDec(100,999,1)],
                [randDec(100,999,1), randDec(1000,9999,1)],   
                [randDec(10,99,2), randDec(10,99,2)],
                [randDec(1,9,3), randDec(10,99,3)],
                [randDec(10,99,3), randDec(10,99,3)],
                [randDec(1,9,3), randDec(1,9,3)],
            )
        }
        if (concept==='Decimal Equations' || placeValues['Different decimal place values']){
            numArray.push( 
                [randDec(100,999,1), randInt(2,1000)], 
                [randDec(10,99,2), randInt(2,1000)],
                [randDec(10,99,2), randDec(100,999,1)], 
                [randDec(10,99,2), randDec(10,99,3)], 
                [randDec(10,99,2), randDec(1,9,3)],
                [randInt(2,10), randDec(10,99,3)],
                [randDec(100,999,1), randDec(10,99,3)], 
                [randDec(100,999,1), randDec(10,99,2)], 
                [randDec(100,999,1), randDec(1,9,3)], 
                [randInt(2,99), randDec(1,9,3)],
                [randDec(1000,9999,1), randInt(2,10000)], 
            )
        } 
    } 
    var numList = selectRand(numArray)
    var [numberS, numberL] = [Math.min(numList[0], numList[1]), Math.max(numList[0], numList[1])]
    var [pvS, pvL] = [decPV(numberS), decPV(numberL)] 
    var pv = largestDecPV(numberS, numberL)
    var answer = op === 'add' ? roundDec(numberL+numberS, pv) : roundDec(numberL-numberS, pv)
    var wrong= wrongOptions(answer, 'decimal', numberL, numberS, pv) 
    if (op === 'sub'){
        wrong.push(roundDec(numberS+numberL, pv))
    } else {
        wrong.push(roundDec(numberL-numberS, pv))
    }
    if (pvS !== pvL && op==='sub'){
        wrong.push(roundDec(Math.abs((removeDec(numberL)-removeDec(numberS))/Math.pow(10, pv)), pv))
    }
    if (placeValues && !placeValues['Same decimal place values']) {
        wrong.push(roundDec((removeDec(numberL)+removeDec(numberS))/Math.pow(10, pv), pv))
    }
    //create a random 3rd number to be used in multi-step problems, that is the same pv is they are the same, or could be a whole/tenth/hundredth otherwise.
    let randNum = pvS === pvL ? randDec(1, 9, pv) : randDec(1, 9, randInt(0,2))
    return {numberS, pvS, numberL, randNum, pvL, wrong: shuffleArray(removeDup(wrong)), pv, answer}

}

export const addDecAlg = (userSelection, generalSelection) => {
    var {numberS, numberL, pvS, pvL, answer, pv, wrong} = asNumbers(userSelection, 'add')

    if (userSelection.specify.probStyle==='Set Up') {
        var prob = {numberL, numberS, op: '+'} //verticalAlignDec(numberL, '+', numberS, generalSelection.activity)
        if (userSelection.specify.placeholderZero){
            let pv = largestDecPV(numberL, numberS)
            prob = {numberL:displayZero(numberL, pv), numberS:displayZero(numberS, pv), op: '+'}
        }
    } else {
        prob = `${fixNum(numberL, pvL)} + ${fixNum(numberS, pvS)} = `
    }
    var AC = answerChoicesKey(answer, wrong[0], wrong[1], wrong[2])
    var problem = {prob: prob,
                answerChoices: AC,
                correctAnswer:answer,
                probStyle: userSelection.specify.probStyle

                }
    return problem
}
export const addDecBasic =  (userSelection, generalSelection) => {
    var {numberS, numberL, pvS, pvL, answer, pv, wrong, randNum} = asNumbers(userSelection, 'add')

    let prob = `What is the sum of ${fixNum(numberL, pvL)} and ${fixNum(numberS, pvS)}?`
    let prob2 = `What is the sum of ${fixNum(numberS, pvS)} and ${fixNum(numberL, pvL)}?`
    let prob3 = `What is the total of ${fixNum(numberS, pvS)} and ${fixNum(numberL, pvL)}?`
    let prob4 = `How much is ${fixNum(numberS, pvS)} and ${fixNum(numberL, pvL)} combined?`

    if (userSelection.specify.level === 'Multi-Step'){
        prob =  `What is ${randNum} more than the sum of ${fixNum(numberL, pvL)} and ${fixNum(numberS, pvS)}?`
        prob2 =  `What is ${fixNum(numberL, pvL)} more than the sum of ${randNum} and ${fixNum(numberS, pvS)}?`
        prob3 =  `The sum of ${fixNum(randNum)} and ${fixNum(numberS, pvS)} is added to ${fixNum(numberL, pvL)}. What is the total sum?`
        prob4 =  `What is the total of ${fixNum(numberL, pvL)} combined with the sum of ${randNum} and ${fixNum(numberS, pvS)}?`
        pv = largestDecPV(numberS, numberL, randNum)
        answer = roundDec(answer + randNum, pv)
        wrong = [randNum+numberL, numberL+numberS, answer+1, answer+2]
        if (answer > 4) {
            wrong.push(answer-0.1, answer-1)
        }
    }
    prob = selectRand([prob, prob2, prob3, prob4])
    wrong = shuffleArray(removeDup(wrong.map(num=>fixNum(num, pv))))
    var AC = answerChoicesKey(answer, wrong[0], wrong[1], wrong[2])
    var problem = {prob: prob,
                answerChoices: AC,
                correctAnswer:answer,
                probType: 'Application'
                }
    return problem
}

export const randAddDec = (userSelection, generalSelection) => {
    var probArray = []
    if (userSelection.specify.probType['Algorithm']){
        probArray.push(addDecAlg)
    }
    if (userSelection.specify.probType['Application']){
        probArray.push(addDecBasic)
    }
    var randProb = selectRand(probArray)
    return randProb(userSelection, generalSelection)
}

export const subDecAlg = (userSelection, generalSelection) => {
    var {numberS, numberL, pvS, pvL, pv, answer, wrong} = asNumbers(userSelection, 'sub')

    if (userSelection.specify.probStyle==='Set Up') {
        var prob = {numberL: fixNum(numberL, pvL), numberS:fixNum(numberS, pvS), op: '-'} //verticalAlignDec(numberL, '-', numberS, generalSelection.activity)
        if (userSelection.specify.placeholderZero){
            let pv = largestDecPV(numberL, numberS)
            prob = {numberL:displayZero(numberL, pv), numberS:displayZero(numberS, pv), op: '-'}
        }
    } else {
        prob = `${fixNum(numberL, pvL)} - ${fixNum(numberS, pvS)} = `
    }
    var AC = answerChoicesKey(answer, wrong[0], wrong[1], wrong[2])
    var problem = {prob: prob,
                answerChoices: AC,
                correctAnswer:answer,
                probStyle: userSelection.specify.probStyle,
                }

    return problem
}

export const subDecBasic =  (userSelection, generalSelection) => {
    var {numberS, numberL, pvS, pvL, answer, pv, wrong, randNum} = asNumbers(userSelection, 'sub')
    // let randNum = answer > 9 && pvS !== pvL ? randInt(10,100) : randInt(2, 9)

    let prob = `What is the difference between ${fixNum(numberL, pvL)} and ${fixNum(numberS, pvS)}?`
    if (userSelection.specify.level === 'Multi-Step'){
        let temp1 =  `What is ${randNum} more than the difference between ${fixNum(numberL, pvL)} and ${fixNum(numberS, pvS)}?`
        let temp2 =  `How much greater is ${randNum} and ${fixNum(numberL, pvL)} combined than ${fixNum(numberS, pvS)}?`
        prob = selectRand([temp1, temp2])
        if (prob === temp1){
            answer = roundDec(randNum+answer, pv)
            wrong = [randNum+numberL, numberL+numberS, answer+1, answer+0.1]

        } else {
            answer = roundDec((numberL+randNum) - numberS)
            wrong = [numberL-numberS, numberL+numberS+randNum, answer+1, answer+0.1, answer+1.1]
        }
        if (answer > 4){
            wrong.push(answer-1, answer-2)
        }
    }

    wrong = shuffleArray(removeDup(wrong)).slice(0,3).map(num=>roundDec(num, pv))
    var AC = answerChoicesKey(answer, wrong[0], wrong[1], wrong[2])
    var problem = {prob: prob,
                answerChoices: AC,
                correctAnswer:answer,
                probType: 'Application'
                }
    return problem
}


export const randSubDec = (userSelection, generalSelection) => {
    var probArray = []
    if (userSelection.specify.probType['Algorithm']){
        probArray.push(subDecAlg)
    }
    if (userSelection.specify.probType['Application']){
        probArray.push(subDecBasic)
    }
    var randProb = selectRand(probArray)
    return randProb(userSelection, generalSelection)
}
