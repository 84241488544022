export const specifyList = (obj, value) => { //searching for true values
  let specifyArray = []
  for (let i in obj) {
    if (!obj[i]){
      continue;
    }
    
    if (typeof obj[i] === 'string' && obj[i].length>0){
      if (obj[i] === '5' || obj[i] === '10' || obj[i]=== '15'){
        specifyArray.push('Max denominator: ' + obj[i])
      } else if (obj[i] < 4) { //value is a number 1 / 2 / 3 which means it is a level
        specifyArray.push('Level: '+ obj[i])
      } else {
        specifyArray.push(obj[i])
      }
    } else if (i === 'quantity') {
      continue;
    } else {
      let filterValues = Object.keys(obj[i]).filter(key => obj[i][key]===value)
      filterValues = filterValues.map(value=>{
        let newValue = value
        
        if (value.includes('ommon')){
          newValue = value+ ' Denominators'
        } else if (value === 'Up to 10' || value === '10 to 20'){
          newValue = 'Denominators ' + value.toLowerCase()
        } else if (value.includes('it who') || value.includes('it dec')){ //digit whole numbers or digit decimals for divide decimals
          newValue = value  + ' divisors'
        } else if (value === '2 digits' || value === '3 digits' || value === '4 digits'){
          newValue = 'Dividends with ' + value
        } 
        return newValue
      })
      specifyArray = [...specifyArray, ...filterValues]
    }
  }
  // let joinedSpecifyArray = specifyArray.join(', ')
  let specifyList = specifyArray.map((item, i) => <li key={i}>{item}</li>)
  return specifyList
}