import React from 'react';
import TeachEx2 from '../../app-files/images/tables.png';
import TeachEx1 from '../../app-files/images/add-fract.png';
import TeachEx3 from '../../app-files/images/sample-mixedconcepts.png'

const WSExamples = () => {

  return (
    <div className="teacher-ex-container">
      <img data-aos="fade-up" data-aos-anchor-placement="top-center" className="teacher-ex schoolex1" src={TeachEx1} alt="small whole numbers 3 steps"/>
      <img data-aos="fade-up" data-aos-anchor-placement="top-center"className="teacher-ex schoolex2" src={TeachEx2} alt="large whole numbers 3 steps"/>
      <img data-aos="fade-up" data-aos-anchor-placement="top-center" className="teacher-ex schoolex3" src={TeachEx3} alt="small whole numbers 4 steps"/>
    </div>
  )
}

export default WSExamples