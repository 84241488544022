
import {StyleSheet} from "@react-pdf/renderer";
import {fixNum, splitDec} from './general'
import Table from './Table'
const tstyles = StyleSheet.create({ // WS algorithm Add/Sub/Mult whole
    table: {
        display: "table",
        // textAlign:'right',
        padding: '0 10 10 10', //bottom 30
        margin:'0 10 10 10', 
        maxWidth: '100%',
        maxHeight: 50 //new
        
        // minHeight:80
    },
    row: {
        flexDirection: "row",
    },
    cell: {
      backgroundColor:'white',
      textAlign:'center'
    },
    header: {
      backgroundColor: 'grey'
    }
  });
  const shTstyles = StyleSheet.create({ // SH algorithm add/sub/mult whole
    table: {display: "table",
      // textAlign:'right',
      padding: '10 10 30 10',
      margin:'-50 10 10 -27', //-top is quick fix for why it is lower in the SH
      maxWidth: '100%',
      minHeight:150 //had to set height bc the text was squished??
  },
  });
  const tcTstyles = StyleSheet.create({
    table: {
      display: "table",
      // textAlign:'right',
      padding: '10 10 10 10', //bottom 30
      margin:'0 10 10 -15', 
      maxWidth: '100%',
      minHeight:80,
      maxHeight: 90
  },
  });
const multDecTStyles = StyleSheet.create({
    table: {
         padding: '0 0 10 10',
         margin: '0 10 10 10', 
         width: 100,
         maxWidth: '100%',
    }
  });
  const divDecTStyles = StyleSheet.create({
    table: {
        padding: '0 0 10 10',
        margin:'0 10 10 10', 
        width: 120,
        maxWidth: '100%',
    }
  });
const cell_style_mult_dec = (row_index, col_index, data, direction, activity, questionStyle) => {

  let  borderBottomWidth = (row_index === 1 && col_index === 1) ? 1 : 0
  let width = (col_index === 0) ? 20 : 9*(data[0][1]).toString().length+5
  if (activity === 'Scavenger Hunt'){ //determine the number of digits in the column & multiply by amount for each number col_index === 1 ? 30*data[0][1].length
    width = col_index === 0 ? 55 :  30*(data[0][1]).toString().length +20 //add 20 for a single digit to have more space
    borderBottomWidth = (row_index === 1 && col_index === 1) ? 8 : 0
  }
  if (questionStyle === 'Task Cards'){
    width = col_index === 0 ? 30 : 12*(data[0][1]).toString().length+8
  }
  return {width, borderWidth:0, borderBottomWidth,
          borderStyle: "solid",textAlign:'right'
  }
}
export const verticalAlignMultDec = (numberL, op, numberS, activity, questionStyle) => {
let newTstyles = activity === 'Scavenger Hunt' ? shTstyles : questionStyle === 'Task Cards' ? tcTstyles : tstyles

  return Table([['', numberL],
                [op, numberS]], cell_style_wholeNumbers, newTstyles, null, null, activity, questionStyle)
}

const cell_style_wholeNumbers = (row_index, col_index, data, direction, activity, questionStyle) => {
  let borderBottomWidth = (row_index === 1 && col_index === 1) ? 1 : 0
  let width = (col_index === 0) ? 20 : 9*(data[0][1]).toString().length+5
  let height
  let padding = (col_index===1) ? '0' : '0 5 2 5'
  if (activity === 'Scavenger Hunt'){ //determine the number of digits in the column & multiply by amount for each number col_index === 1 ? 30*data[0][1].length
    width = col_index === 0 ? 55 :  30*(data[0][1]).toString().length+20
    borderBottomWidth = (row_index === 1 && col_index === 1) ? 8 : 0
    // height = (col_index===1 && row_index===1) ?70 : 60
  }
  if (questionStyle === 'Task Cards'){
    width = col_index === 0 ? 30 : 12*(data[0][1]).toString().length+8
  }
  return {width, borderWidth:0, borderBottomWidth, borderStyle: "solid", textAlign:'right', padding, height
  }
}
export const verticalAlignWhole = (numberL, op, numberS, activity, questionStyle, ac) => {
  let newTstyles = activity === 'Scavenger Hunt' ? shTstyles : questionStyle === 'Task Cards' ? tcTstyles : tstyles
  // if(!ac){
  //   tcTstyles.table.margin='30 10 10 -15'
  // }else{
  //   tcTstyles.table.margin='0 10 10 -15'

  // }
    return Table([['', numberL.toLocaleString()],
                  [op, numberS.toLocaleString()]], cell_style_wholeNumbers, newTstyles, null, null, activity, questionStyle)
}

const cell_style_decAlign = (row_index, col_index, data, direction, activity, questionStyle) => {
  let [col1Num, col2Num] = [data[0][1].length, Math.max(data[0][2].length, data[1][2].length)-1]
  let padding = (col_index !== 0) ? '0' : '0 5 2 5'

  // let width = (col_index === 0) ? 20 : 30
  let marginLeft = col_index === 2 ? -1 : 0
  let width = col_index === 0 ? 10 : 
              col_index === 1 ? 10 * col1Num : 
              10 * col2Num; //Find max number & -1 to length but +20 due to decimal point
  let borderBottomWidth = (row_index === 1 && col_index === 1)||( row_index === 1 && col_index ===2) ? 1 : 0
  if(questionStyle === 'Task Cards'){
    width = col_index === 0 ? 15 : 
            col_index === 1 ? 15 * col1Num : 
            15 * col2Num + 10; //Find max number & -1 to length but +20 due to decimal point
  marginLeft = col_index === 2 ? -1 : 0
          
  }
  if (activity === 'Scavenger Hunt'){
    //determine the number of digits in the column & multiply by amount for each number
    width = col_index === 0 ? 55 : 
            col_index === 1 ? 35 * col1Num :
            35 * col2Num + 20; //Find max number & -1 to length but +20 due to decimal point
    borderBottomWidth = (row_index === 1 && col_index === 1)||( row_index === 1 && col_index ===2) ? 8 : 0
    marginLeft = col_index === 2 ? -2 : 0
  }
  const textAlign = (col_index === 2) ? 'left' : 'right'
  return {width, borderWidth: 0, borderBottomWidth, borderStyle: "solid", textAlign, marginLeft, padding
  }
}

export const verticalAlignDec = (numberL, op, numberS, activity, questionStyle) => {
let newTstyles = activity === 'Scavenger Hunt' ? shTstyles : questionStyle === 'Task Cards' ? tcTstyles : tstyles

  var [wholeNumberL, decNumberL] = splitDec(numberL)
  var [wholeNumberS, decNumberS] = splitDec(numberS)
  return Table([['', wholeNumberL,'.'+decNumberL],
                [op, wholeNumberS, '.'+decNumberS]], cell_style_decAlign, newTstyles, null, null, activity, questionStyle)
}
const cell_style_divide = (row_index, col_index, data, direction, activity, questionStyle ) => {
  let [TC, SH, idx0, idx1, idx2] = [questionStyle === 'Task Cards', activity === 'Scavenger Hunt',col_index=== 0, col_index===1, col_index===2]

  let borderTopWidth =  !idx1 ? (SH ? 7.5: TC ? 2.5 : 1.5) : 0 //was colindex2
  let borderRightWidth = idx1 ? (SH ? 8: TC ? 3 : 2) : 0
  let borderTopRightRadius = idx1 ? (SH ? 120: TC ? 90 : 1) : 0 //switched from right to left
  let borderBottomRightRadius = idx1 ? (SH ? 120: TC ? 90: 60): 0
  let width = idx0 ? 20 : idx1 ? 9: 40
  let textAlign = idx0 ? 'right' : 'left'
  let paddingLeft = idx2 ? (SH ? 15 : TC ? 7 : 5) : 0
  let paddingRight = idx0 ? (SH ? 5 : TC ? 1 : 1) : 0
  let marginRight = idx1 ? (SH ? -8 : TC ? -4 : 0): 0
  let borderTopColor = idx0 ? 'white':'black'
  let minHeight = questionStyle === 'Worksheet' ? 20 : 25 // columns caused left side not to show.
  let height, paddingBottom
  let paddingTop = SH || TC ? 3 : 1
  if (activity === 'Scavenger Hunt'){
    width = idx0 ? 30*data[0][0].toString().length : 
            idx1 ? 20 : 30*data[0][2].toString().length + 20
  }
  if (questionStyle === 'Task Cards'){
    width = idx0 ? 15*data[0][0].toString().length : 
            idx1 ? 15 : 10*data[0][2].toString().length + 15
  }

  return {width, borderWidth:0, borderRightWidth,
          borderTopWidth, borderStyle: "solid",
          textAlign, paddingLeft, marginRight, paddingTop, borderTopColor,
          borderTopRightRadius, borderBottomRightRadius, paddingRight, height, paddingBottom, minHeight 
  }
}


export const verticalDivide = (numberL, numberS, activity, questionStyle, ac) => {
  let newTstyles = activity === 'Scavenger Hunt' ? shTstyles : questionStyle === 'Task Cards' ? tcTstyles : tstyles
  // if (!ac){
  //   tcTstyles.table.margin='70 10 10 -15'
  // } else{
  //   tcTstyles.table.margin='0 10 10 -15'

  // }

    return Table([[numberS, ' ',fixNum(numberL)]], cell_style_divide, newTstyles, null, null, activity, questionStyle)
}

export const verticalProbStyling = (prob, concept, generalSelection, ac) => {
  const {activity, questionStyle} = generalSelection
  let [numberL, numberS, op] = [prob.numberL, prob.numberS, prob.op]
  
  if (concept.includes('Dividing')){
    return verticalDivide(numberL, numberS, activity, questionStyle, ac)
  } else if (concept.includes('Whole Numbers')){
    return verticalAlignWhole(numberL, op, numberS, activity, questionStyle, ac)
  } else if (concept === 'Multiplying Decimals'){
    return verticalAlignMultDec(numberL, op, numberS, activity, questionStyle, ac)
  } else if (concept.includes('Decimals')){
    return verticalAlignDec(numberL, op, numberS, activity, questionStyle, ac)
  }
}



