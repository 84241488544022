import { specAC, specAlg, specHZ, specify } from "../helper"

export const MultiplyingIntegers = (props) => {
  const {createLink, ac} = props
  const c1 = 'Multiplying Integers'
  const [dig1_1, dig2_1, dig3_1, dig4_1, dig2_2, dig3_2] = ['1 by 1 digit', '2 by 1 digit', '3 by 1 digit', '4 by 1 digit', '2 by 2 digit', '3 by 2 digit']
  
  const title = (mod1) => {
    return `Multiplying ${mod1} Integers`
  }
  const createSpec = (spec1, spec2) => {
    let specAS = [specify('numbers', spec1), specAlg, specAC(ac)]

    return {[c1]: specAS}
  }

  return (<>
    <div className="buttonGroup">
      <div className="concept-topic">Multiplying Integer Algorithms</div>
      {createLink([c1], title(dig1_1), '', createSpec([dig1_1]), 18)}
      {createLink([c1], title(dig1_1+' & '+dig2_1), '', createSpec([dig1_1, dig2_1]), 18)}
      {createLink([c1], title(dig2_1), '', createSpec([dig2_1]), 18)}
      {createLink([c1], title(dig2_1+' & '+dig3_1), '', createSpec([dig2_1, dig3_1]), 18)}
      {createLink([c1], title(dig3_1), '', createSpec([dig3_1]), 18)}
      {createLink([c1], title(dig3_1+' & '+dig4_1), '', createSpec([dig3_1, dig4_1]), 18)}
      {createLink([c1], title(dig4_1), '', createSpec([dig4_1]), 18)}
      {createLink([c1], title(dig4_1+' & '+dig2_2), '', createSpec([dig4_1, dig2_2]), 18)}
      {createLink([c1], title(dig2_2), '', createSpec([dig2_2]), 18)}
      {createLink([c1], title(dig2_2+' & '+dig3_2), '', createSpec([dig2_2, dig3_2]), 18)}
      {createLink([c1], title(dig3_2), '', createSpec([dig3_2]), 18)}
    </div>
    </>
  )
}