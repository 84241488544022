import {answerChoicesKey, randInt, selectRand, showQText, shuffleArray} from '../general.js'
import {cell_style_fraction, tstyles, horizontalFractions, verticalFractions } from './fract-shared'
import Table from '../Table.js'
import {Text} from "@react-pdf/renderer";
import { fractionNumbers } from './add-sub-fract.js';
import { randDivWhole } from '../whole-numbers/divide.js';

const { create, all } = require('mathjs')
const config = { number: 'Fraction'}
const math = create(all, config)
const wrongCompare = () => {

}
const createFractionInfo = (userSelection, generalSelection) => {
  var {number1, number2} = fractionNumbers(userSelection)
  var f1 = math.fraction(`${number1.num}/${number1.denom}`)
  var f2 = math.fraction(`${number2.num}/${number2.denom}`)
  // var fract1 = Table([[num],[denom]], cell_style_fraction, tstyles, '', '', generalSelection.activity)
  // var fract2 = Table([[num2],[denom2]], cell_style_fraction, tstyles, '', '', generalSelection.activity)
  let answer, wrong, wrongAC;
  if (math.compare(math.number(f1),math.number(f2)) === 1) {
    answer = '>'
    wrongAC = shuffleArray(['<', '='])
    wrong = shuffleArray(['<', '<', '<', '='])
  } else if (math.compare(math.number(f2),math.number(f1))===1) {
    answer = '<'
    wrongAC = shuffleArray(['>', '='])
    wrong = shuffleArray(['>', '>', '>', '='])

  } else {
    answer = '='
    wrongAC = shuffleArray(['<', '>'])
    wrong = shuffleArray(['<', '>'])

  }

  return {number1, number2, answer, wrong, wrongAC}
}
const randWrongSymbol = ['+', '-', 'x', '#', '%']
export const compareFract = (userSelection, generalSelection) => {

  const {number1, number2, answer, wrongAC} = createFractionInfo(userSelection,generalSelection)
  // var prob1 = horizontalFractions(fract1, fract2, <Text>_____</Text>, generalSelection.activity, userSelection)
  var prob1 = {number1, number2, symbol:'_____', text:'Create a true comparison using <, >, or ='}
  var AC = answerChoicesKey(answer, wrongAC[0], wrongAC[1], selectRand(randWrongSymbol))

  var problem = {prob: prob1,
      answerChoices: AC,
      correctAnswer: answer}
  return problem
}

const randTrueQ = ['Which of the following shows a true comparison?', 'Which shows a true comparison between two numbers?', 'Which shows an accurate comparison between numbers?', 'Which of the following shows a true comparison?']
const randFalseQ = ['Which of the following does NOT show a true comparison?', 'Which does NOT show a true comparison?', 'Which of the comparisons below is NOT true?', 'Which symbol does NOT accurately compare the two fractions?', 'Which comparison is false?', 'Which comparison is NOT accurate?']
let questionArr = [randFalseQ, randTrueQ]
export const compareFractAC = (userSelection, generalSelection) => {
  const prob = [
    createFractionInfo(userSelection, generalSelection), createFractionInfo(userSelection, generalSelection),
    createFractionInfo(userSelection, generalSelection), createFractionInfo(userSelection, generalSelection)
  ]
  let answerIdx = randInt(0,3)
  let questIdx = randInt(0,1)
  let questionText = selectRand(questionArr[questIdx])
  const symbol = (idx) => {
    if (idx === answerIdx){
      if (questIdx === 0){ //question is asking for false answer
        return prob[idx].wrong[0]
      } else { //question is looking for true answer
        return prob[idx].answer
      }
    } else {
      if (questIdx === 0){ //if looking for false answer the rest will be true answers
        return prob[idx].answer
      } else {
        return prob[idx].wrong[0]
      }
    }
  }
  const answers = [
    {number1:prob[0].number1, number2:prob[0].number2, symbol:symbol[0]},
    {number1:prob[1].number1, number2:prob[1].number2, symbol:symbol[1]},
    {number1:prob[2].number1, number2:prob[2].number2, symbol:symbol[2]},
    {number1:prob[3].number1, number2:prob[3].number2, symbol:symbol[3]},


    // horizontalFractions(prob[0].fract1, prob[0].fract2, <Text>{symbol(0)}</Text>, generalSelection.activity, userSelection),
    // horizontalFractions(prob[1].fract1, prob[1].fract2, <Text>{symbol(1)}</Text>, generalSelection.activity, userSelection),
    // horizontalFractions(prob[2].fract1, prob[2].fract2, <Text>{symbol(2)}</Text>, generalSelection.activity, userSelection),
    // horizontalFractions(prob[3].fract1, prob[3].fract2, <Text>{symbol(3)}</Text>, generalSelection.activity, userSelection)
  ]
  let answer = answers[answerIdx]
  answers.splice(answerIdx, 1)
  var AC = answerChoicesKey(answer, answers[0], answers[1], answers[2])


  var problem = {prob: questionText,
      answerChoices: AC,
      correctAnswer: answer}
  return problem
}
  

export const randCOFract = (userSelection, generalSelection) => {
  var probArray = [compareFract]
  if (userSelection.ac && generalSelection.activity !== 'Scavenger Hunt'){
    probArray.push(compareFractAC)
  }
  var randProb = selectRand(probArray)
  return randProb(userSelection, generalSelection)
}