import React from 'react';

import Modal from './Modal';
import Button from '../FormElements/Button';

const DownloadModal = props => {
  return (
    <Modal
      onCancel={props.onClear}
      header="Purchase Activity"
      show={props.show}
      hideFooter={true}
      hideTopPadding={true}
    >
      {props.children}
    </Modal>
  );
};

export default DownloadModal;