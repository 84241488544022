import React, { useContext, useState } from "react";
import Button from "../../shared/components/FormElements/Button";
import UserCard from "../UserCard";
import AccountSidebar from "./AccountSidebar";
import CheckoutForm from "../../shared/components/FormElements/CheckoutForm";
import { AuthContext } from "../../app/context/auth-context";
import { useHttpClient } from "../../shared/hooks/http-hook";
import CircularProgress from "@material-ui/core/CircularProgress";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

let stripePromise = null;

const PurchaseTokens = (props) => {
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [messageData, setMessageData] = useState({});
  const [tokenProcessing, setTokenProcessing] = useState(0);
  const [showCheckout, setShowCheckout] = useState(false);
  const [clientSecret, setClientSecret] = useState("");
  const [paymentDetails, setPaymentDetails] = useState({});

  const processToken = async (val) => {
    setTokenProcessing(val);
    stripePromise = await loadStripe(auth.user ? auth.user.stripeClient : null);
    fetch("/api/users/create-payment-intent", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: auth.token,
      },
      body: JSON.stringify({
        userId: auth.user.userId,
        type:
          val === 1 ? "pack5" : val === 2 ? "pack10" : val === 3 ? "pack25" : 0,
      }),
    })
      .then((res) => {
        if(res && res.status === 401) {
          auth.logout();
        }
        return res.json()
      })
      .then((data) => {
        setClientSecret(data.clientSecret ? data.clientSecret : "");
        if (!data.clientSecret) {
          onPaySuccess();
        } else {
          setPaymentDetails(data.details);
          setShowCheckout(true);
        }
      });
  };

  const onBack = () => {
    setMessageData({});
    setTokenProcessing(0);
    setClientSecret("");
    setShowCheckout(false);
    stripePromise = null;
    if (props.onFinish) {
      props.onFinish();
    }
  };

  const onPaySuccess = async () => {
    //Add tokens
    // const responseData = await sendRequest(
    //   `/api/users/add-tokens`,
    //   "PUT",
    //   JSON.stringify({
    //     token: auth.token,
    //     userId: auth.user.userId,
    //     amount:
    //       tokenProcessing === 1
    //         ? 5
    //         : tokenProcessing === 2
    //         ? 12
    //         : tokenProcessing === 3
    //         ? 25
    //         : 0,
    //   }),
    //   {
    //     "Content-Type": "application/json",
    //   }
    // );
    // if (responseData && responseData.user && responseData.user.userId) {
    //   auth.updateUser(responseData.user);
    //   onBack();
    // }
    const checkStatus = async () => {
      const responseData = await sendRequest(
        `/api/users/check-payment-status`,
        "POST",
        "{}",
        {
          "Content-Type": "application/json",
        }
      );
      if (responseData && responseData.valid) {
        auth.updateUser(responseData.user);
        setMessageData({
          message: responseData.message,
          color: responseData.messageColor,
          valid: true,
        });
        setTokenProcessing(0);
        // setTimeout(() => {
        //   onBack();
        // }, 2000);
      } else if (responseData && responseData.invalid) {
        setMessageData({
          message: responseData.message,
          color: responseData.messageColor,
          valid: false,
        });
        setTokenProcessing(0);
      } else {
        setTimeout(() => {
          checkStatus();
        }, 1000);
      }
    };
    checkStatus();
  };

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <UserCard title="Purchase Tokens">
      {showCheckout ? (
        <div className="Checkout">
          {clientSecret && (
            <Elements options={options} stripe={stripePromise}>
              <CheckoutForm
                messageData={messageData}
                onBack={onBack}
                close={onBack}
                onSuccess={onPaySuccess}
                paymentDetails={paymentDetails}
                setCloseLock={props.setCloseLock}
                value={
                  tokenProcessing === 1
                    ? 5
                    : tokenProcessing === 2
                    ? 10
                    : tokenProcessing === 3
                    ? 20
                    : 0
                }
              />
            </Elements>
          )}
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            position: "relative",
            paddingTop: "30px",
            width: "100%",
            flexWrap: "wrap",
            justifyContent: "space-evenly",
          }}
        >
          <div
            style={{
              width: "100%",
              fontSize: "18px",
              margin: "0 0 20px",
              textAlign: "center",
            }}
          >
            Activities can be purchased individually for $1.50 each. You can
            also save by purchasing tokens in advance below!
          </div>
          <div
            style={{
              alignSelf: "center",
              width: "100%",
              textAlign: "center",
              paddingBottom: "20px",
            }}
          >
            <span>You currently have </span>
            <span
              style={{ color: "#803F87", fontWeight: "bold", fontSize: "22px" }}
            >
              {auth.user && auth.user.tokens ? auth.user.tokens : 0}
            </span>
            {` Token${
              auth.user && auth.user.tokens !== 1 ? "s" : ""
            } Available`}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              position: "relative",
              border: "2px solid #806a80",
              width: "30%",
              margin: "0 5px 10px 10px",
              borderRadius: "8px",
              minWidth: "200px",
            }}
          >
            <div
              style={{
                color: "#405852",
                fontWeight: "bold",
                fontSize: "24px",
                textAlign: "center",
                padding: "10px 10px 0 10px",
                flexGrow: 1,
              }}
            >
              <span style={{ fontSize: "50px", fontFamily: "Acme" }}>5</span>{" "}
              Tokens
            </div>
            <div
              style={{
                fontStyle: "italic",
                color: "#803F87",
                fontSize: "30px",
                fontWeight: "bold",
                background: "#d5ebe6",
                width: "100%",
                textAlign: "center",
              }}
            >
              {"Save 20%"}
            </div>
            <div
              style={{
                color: "#803F87",
                fontWeight: "500",
                paddingTop: "10px",
              }}
            >
              {"$1.20 per activity"}
            </div>
            <div
              style={{
                fontStyle: "italic",
                color: "#803F87",
                fontWeight: "bold",
              }}
            ></div>
            <Button
              disabled={!auth.user}
              onClick={() => {
                processToken(1);
              }}
              style={{
                width: 185,
                height: 53,
                display: "flex",
                justifyContent: "center",
              }}
            >
              {tokenProcessing !== 1 ? (
                "$6.00"
              ) : (
                <CircularProgress
                  style={{ color: "#803F87", width: "30px", height: "30px" }}
                />
              )}
            </Button>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              position: "relative",
              border: "2px solid #806a80",
              width: "30%",
              margin: "0 5px 10px 10px",
              borderRadius: "8px",
              minWidth: "200px",
            }}
          >
            <div
              style={{
                color: "#405852",
                fontWeight: "bold",
                fontSize: "24px",
                textAlign: "center",
                padding: "10px 10px 0 10px",
                flexGrow: 1,
              }}
            >
              <span style={{ fontSize: "50px", fontFamily: "Acme" }}>10</span>{" "}
              Tokens
            </div>
            <div
              style={{
                fontStyle: "italic",
                color: "#803F87",
                fontWeight: "bold",
                fontSize: "30px",
                background: "#d5ebe6",
                width: "100%",
                textAlign: "center",
              }}
            >
              {"Save 33%"}
            </div>
            <div
              style={{
                color: "#803F87",
                fontWeight: "500",
                paddingTop: "10px",
              }}
            >
              {"$1.00 per activity"}
            </div>
            <Button
              disabled={!auth.user}
              onClick={() => {
                processToken(2);
              }}
              style={{
                width: 185,
                height: 53,
                display: "flex",
                justifyContent: "center",
              }}
            >
              {tokenProcessing !== 2 ? (
                "$10.00"
              ) : (
                <CircularProgress
                  style={{ color: "#803F87", width: "30px", height: "30px" }}
                />
              )}
            </Button>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              position: "relative",
              border: "2px solid #806a80",
              width: "30%",
              margin: "0 5px 10px 10px",
              borderRadius: "8px",
              minWidth: "200px",
            }}
          >
            <div
              style={{
                color: "#405852",
                fontWeight: "bold",
                fontSize: "24px",
                textAlign: "center",
                padding: "10px 10px 0 10px",
                flexGrow: 1,
              }}
            >
              <span style={{ fontSize: "50px", fontFamily: "Acme" }}>25</span>{" "}
              Tokens
            </div>
            <div
              style={{
                fontStyle: "italic",
                color: "#803F87",
                fontWeight: "bold",
                fontSize: "30px",
                background: "#d5ebe6",
                width: "100%",
                textAlign: "center",
              }}
            >
              {"Save 46%"}
            </div>
            <div
              style={{
                color: "#803F87",
                fontWeight: "500",
                paddingTop: "10px",
              }}
            >
              {"$0.80 per activity"}
            </div>
            <Button
              disabled={!auth.user}
              onClick={() => {
                processToken(3);
              }}
              style={{
                width: 185,
                height: 53,
                display: "flex",
                justifyContent: "center",
              }}
            >
              {tokenProcessing !== 3 ? (
                "$20.00"
              ) : (
                <CircularProgress
                  style={{ color: "#803F87", width: "30px", height: "30px" }}
                />
              )}
            </Button>
          </div>
        </div>
      )}
    </UserCard>
  );
};

export default PurchaseTokens;
