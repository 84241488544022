import {randInt, decPV, randDec, shuffleArray, selectRand, wrongOptions, answerChoicesKey, orderDigits, fixNum, roundDec} from '../general'
import * as g from '../general'

const multDecWrong = (answer) => {
    const ansPV = decPV(answer)
    const wrongOptions = []
    const [wrong1, wrong2] = [answer*10, answer/10]
    wrongOptions.push([wrong1, wrong2, answer/100, answer*100])
    if (ansPV === 0 && answer > 5){ //whole numbers
        let wrong = [answer+1, answer-1, answer-2, answer+2]
        if (answer > 20){
            wrongOptions.push([answer+10, answer+11, Math.abs(answer-10), answer+1, answer-1])
        } else {
            wrongOptions.push(wrong)
        }
    }
    if (answer < 1){ //0.____
        let wrongAns = [answer+0.1, answer+0.2]
        if (answer > 0.2){
            let wrong = [...wrongAns, answer-0.1, answer+0.2]
            wrongOptions.push(wrong)
        }
        if (ansPV > 1){
            let wrong = [...wrongAns, answer+0.01, answer+0.02, answer-0.01, answer-0.02]
            let secondWrong = [answer+0.11, Math.abs(answer-0.11), answer+0.01, answer+0.02, answer-0.01 ]
            wrongOptions.push(wrong, secondWrong)
        }
    }

    if (ansPV > 0){
        let wrong = [answer+0.1, answer+0.2, wrong1, wrong1+0.1]
        let secondWrong = [wrong1, wrong2, answer*100, answer/100]
        let thirdWrong = [answer+0.01, answer+0.02, answer+0.11, answer+0.12, wrong2, wrong2+0.01, wrong2+0.11]
        if (wrong2 > 0.3){
            wrong.push(wrong1-0.1, answer-0.1)
        } else if (answer > 0.5){
            wrong.push(answer-0.2)
        } else if (answer > 0.3){
            wrong.push(answer-0.1, wrong1-0.1)
        }
        wrongOptions.push(wrong, secondWrong, thirdWrong)
    }
    if (ansPV > 1) {
        let wrong = [answer+0.01, answer+0.02, wrong1, wrong1+0.01, wrong1+0.02]
        let secondWrong = [ wrong2+0.01, answer+0.01, answer+0.2, wrong2+0.2, wrong2]
        if (answer > 0.1){
            wrong.push(answer-0.01, answer-0.02, wrong1-0.01)
            secondWrong.push(wrong2-0.01, answer-0.2)
        }
        wrongOptions.push(wrong, secondWrong)
    }
    const wrongChoices = shuffleArray(selectRand(g.removeDup(wrongOptions))).slice(0,3).map(num=>roundDec(num, 5))
    return wrongChoices
}
export const multNumbers = (userSelection) =>{
    const {concept, specify} = userSelection
    var numArray = []
    let numbers
    let numSelect = concept === 'Multiplying Decimals' || concept==='Area' ? specify.numbers : 
                    concept === 'Tables' ? specify.decimals : specify.multiply
    // if (userSelection.specify['numbers']['Decimal x Whole Number']) {
    //     numArray.push(
    //         [randInt(2, 9), randDec(0.1, 0.9, 1)],
    //         [randInt(2, 9), randDec(0.1, 0.9, 2)],
    //         [randInt(2, 9), randDec(1, 9, 1)],
    //         [randInt(2, 9), randDec(1, 9, 2)],
    //         [randInt(2, 9), randDec(10,99, 1)]
    //     )
    if (numSelect['1 by 1 digit']) {
        let randDecimal = [randDec(0.2, 0.9, 1), randDec(0.02,0.09,2)][randInt(0,1)]
        numArray.push(
            [randInt(2, 9), randDecimal],
            [randDec(0.2, 0.9, 1), randDecimal],            
            [randDec(0.02, 0.09, 2), randInt(2,9) ]
        )
    }
    if (numSelect['2 by 1 digit']) {
        let randDecimal = [randDec(1, 9, 1), randDec(0.2,0.99,2)][randInt(0,1)]
        numArray.push(
            [randInt(2, 9), randDecimal],
            [randDec(0.2, 0.9, 1), randDecimal],
            [randDec(0.2, 0.9, 1), randDecimal],
            [randDec(0.2, 0.9, 1), randInt(10,99) ],
            [randDec(0.02, 0.09, 2), randDecimal],
            [randDec(0.02, 0.09, 2), randInt(10,99) ]
        )
    }

    if (numSelect['3 by 1 digit']) {
        var randDecimal = [randDec(10, 99, 1), randDec(2,9,2), randDec(0.2, 0.999, 3)][randInt(0,2)]
        numArray.push(
            [randInt(2, 9), randDecimal],
            [randDec(0.2, 0.9, 1), randDecimal],
            [randDec(0.2, 0.9, 1), randDecimal],
            [randDec(0.2, 0.9, 1), randInt(100,999) ],
            [randDec(0.02, 0.09, 2), randDecimal],
            [randDec(0.02, 0.09, 2), randInt(100,999) ]
        )
    } if (numSelect['4 by 1 digit']) {
        randDecimal = [randDec(100, 999, 1), randDec(20,99,2), randDec(2, 9, 3), randDec(0.2, 0.9999, 4)][randInt(0,3)]
        numArray.push(
            [randInt(2, 9), randDecimal],
            [randDec(0.2,0.9, 1), randInt(1000,9999)],
            [randDec(0.2,0.9,1), randDecimal],
            [randDec(0.02,0.09,2), randDecimal],
            [randDec(0.02,0.09,2), randInt(1000,9999)]
        )
    } if (numSelect['2 by 2 digit']) {
        randDecimal = [randDec(1, 9, 1), randDec(0.2,0.99,2)][randInt(0,1)]
        var randDecimal2 = [randDec(1, 9, 1), randDec(0.2,0.99,2)][randInt(0,1)]

        numArray.push(
            [randInt(11, 99), randDecimal],
            [randInt(11, 99), randDecimal],
            [randDecimal, randDecimal2],
            [randDecimal, randDecimal2],
            [randDec(1, 9, 1), randDec(0.2,0.99,2)],
            [randDec(1, 9, 1), randDec(0.2,0.99,2)]

        )
    } if (numSelect['3 by 2 digit']) {
        var randDecimal3 = [randDec(10, 99, 1), randDec(2,9,2), randDec(0.2, 0.999, 3)][randInt(0,2)]
        var randDecimal2 = [randDec(1, 9, 1), randDec(0.2,0.99,2)][randInt(0,1)]
        numArray.push(
            [randInt(11, 99), randDecimal3],
            [randDecimal2, randInt(100,999)],
            [randDecimal2, randDecimal3],
            [randDecimal2, randDecimal3],
            [randDecimal2, randDecimal3]

        )
    }
    var num = selectRand(numArray)
    const [numberS, numberL] = [Math.min(...num), Math.max(...num)]
    var multPV = decPV(numberS)+decPV(numberL)
    const answer = Number((numberS*numberL).toFixed(multPV))
    let wrong = multDecWrong(answer)
    // const numberM = answer > 30 ? randInt(20, Math.floor(answer-1)) : randInt(2, Math.floor(answer-1))
    let numberM = answer > 99 || answer < 2 ? randInt(2, 99) :  Math.floor(answer)

    return {answer, multPV, numberS, numberL, numberM, wrong}
}

export const multDecAlg = (userSelection, generalSelection) => {
    var {numberS, numberL, answer, multPV, wrong} = multNumbers(userSelection)

    var [topNum, bottomNum] = orderDigits(numberS, numberL)
    if (userSelection.specify.probStyle==='Horizontal') {
        var prob = `${fixNum(numberL)} × ${numberS} = `
    } else{
        prob = {numberL: fixNum(topNum), numberS: bottomNum, op:'×'} //verticalAlignMultDec(secondNum, '×', firstNum, generalSelection.activity) 
    }
    var AC = answerChoicesKey(answer, wrong[0], wrong[1], wrong[2])
    // var order = (numberS, numberL)
    var problem = {prob: prob,
                answerChoices: AC,
                correctAnswer: fixNum(answer),
                probStyle: userSelection.specify.probStyle

                }
    return problem
}


export const multDecBasic = (userSelection) => { //basic product/sum/difference
    var {numberS, numberM, numberL, wrong, answer, multPV} = multNumbers(userSelection)
    if (userSelection.specify.level === 'One step') {
        var prob1 = (`What is the product of ${numberS} and ${numberL.toLocaleString()}?`)
        var prob2 = (`What is the product of ${numberL.toLocaleString()} and ${numberS}?`)
        var prob3 = (`A number is ${numberL.toLocaleString()} times as large as ${numberS}. What is the number?`)
        var prob4 = (`A number is ${numberS} times as large as ${numberL.toLocaleString()}. What is the number?`)
        var prob5 = (`How much many total are in ${numberS} groups with ${fixNum(numberL)} in each group?`)
        var prob6 = (`How much many total are in ${fixNum(numberL)} groups with ${numberS} in each group?`)
        var randProb = selectRand([prob1, prob2, prob3, prob4, prob5, prob6])

    } else if (userSelection.specify.level === 'Multi-Step') {
        prob1 = (`What is the sum of ${numberM} and the product of ${numberL.toLocaleString()} and ${numberS}?`)
        prob2 = (`What is the sum of ${numberM} and the product of ${numberS} and ${numberL.toLocaleString()}?`)
        prob3 = (`What is the difference between ${numberM} and the product of ${numberS} and ${numberL.toLocaleString()}?`)
        prob4 = (`What is the difference between ${numberM} and the product of ${numberL.toLocaleString()} and ${numberS}?`)
        randProb = selectRand([prob1, prob2, prob3, prob4])
        if (randProb === prob1 || randProb ===prob2){ 
          answer = roundDec(numberL*numberS+numberM, multPV)
        } else {
            answer = numberM > answer ? roundDec(numberM-answer, multPV) : roundDec(answer-numberM, multPV)
        }
        wrong = [numberM*numberS+numberL, numberL*numberS, answer+1, answer+0.1, answer+1.1]
        if (answer > 4) {
            wrong.push(answer-1, answer-0.1, answer-2)
        }
  
    } else {
        var numberL2 = Math.abs(numberL-randInt(2,7))
        var numberS2 = numberS+randInt(2,9)
        prob1 = (`What is the difference between the product of ${numberL.toLocaleString()} and ${numberS} and the product of ${numberS2} and ${numberL2}?`)
        prob2 = (`What is the sum of the product of ${numberL.toLocaleString()} and ${numberS} and the product of ${numberS2} and ${numberL2}?`)
        prob3 = (`A number is ${numberS} times as much as the sum of ${numberM} and ${numberS2}and ${numberS}. What is the number?`)
        randProb = selectRand([prob1, prob2, prob3])
  
        if (randProb === prob1){
            answer = roundDec(numberL*numberS - numberS2*numberL2, multPV)
        } else if (randProb === prob2){
            answer = roundDec(numberL*numberS + numberS2*numberL2, multPV)
        } else {
            answer = roundDec(numberS*(numberM+numberS2+numberS), multPV)
        }
        wrong = [numberL2+numberS2+numberL+numberS, numberL*numberS+numberL2+numberS2, answer+1, answer-1, answer+2, answer-2]

  
    }
    wrong = shuffleArray(g.removeDup(wrong)).slice(0,3).map(num=>roundDec(num, multPV))
    let AC = answerChoicesKey(answer, wrong[0], wrong[1], wrong[2])
    let problem = {prob: randProb,
        answerChoices: AC,
        correctAnswer: roundDec(answer, multPV), 
        probType: 'Application'
    }
  
    return problem     
  
}


export const randMultDec = (userSelection, generalSelection) => {
    var probArray = []
    if (userSelection.specify.probType['Algorithm']){
        probArray.push(multDecAlg)
    } if (userSelection.specify.probType['Application']){
        probArray.push(multDecBasic)
    }

    var randProb = selectRand(probArray)
    return randProb(userSelection, generalSelection)
}
