import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"

const Faqs = () => {
  const [activeQ, setActiveQ] = useState({})
  useEffect(()=>{
    window.scrollTo(0,0)
  }, [])
  const faqsList = [
    {question: "What concepts do you currently have available?", 
      answer: <p>We currently have 29 customizable math concepts and over 800 premade worksheet templates that can be instantly created most ranging from 3rd to 7th grade 
      level math. We are constantly adding more concepts! <Link to="concept-selection"> View full concept list</Link>.</p>},
    {question:"What makes Worksheet Wonderland different from other worksheet sites?", 
      answer:<p>The biggest difference is that our worksheets are fully customizable and randomly generated each time.
      This means that you can truly differentiate your activities to meet your student's needs. You can 
      mix and match different concepts into the same activity to help your students better retain the material, 
      or to create end of unit assessments.<br/><br/>
      We have the ability to have answer choices on all of our concepts, and also offer concepts that can be difficult to find such as 
      ordering decimals with different place values, 
      Input Output Tables with decimals (that also include tables as the answer choice!), and Fraction problems that can be truly customized and are friendly for students.
      These are things that were difficult for me to find as a teacher and part of what inspired me to make this site!
      </p>},
    {question:`How can I try it out?`, 
      answer:<p>Just <Link to="/activity-selection">try a custom activity</Link> or <Link to="premade-concept-selection">try our premade worksheets</Link>.
      This will allow you to try it out but <Link to="/signup">sign up</Link> to download the worksheets & get a free token that allows you to download any activity.
       You can also check out our <Link to="/samples"> sample page</Link> to get a quick idea of what 
      can be made.</p>},
    {question:`Why does my activity have a "DRAFT" overlay?`, 
      answer:<p>Since our worksheets & activities are randomly generated we allow you to view the draft before making a 
      final decision as to whether or not you would like to purchase it. Please make sure you are logged in 
      to be able to download worksheets & purchase activities</p>},
    {question:`Do your activities come with recording sheets and directions?`,
      answer:<p>Yes, they will not be generated with your worksheet, but we have created directions and general recording sheets that can be used which you can access <Link to="/activities">here</Link>.
      You will also see these linked under the Assignment Concepts table after you generate an assignment.</p>},
    {question:`How can I easily create differentiated assignments?`, 
      answer:<p>Select and customize your concepts, and then after you create your assignment (and download if you are happy with it),
      you will still see your concepts listed on the right side. If you hover over a concept you will see an edit and delete button appear,  
      and then either make them more challenging for your high achievers or simplify some concepts for students who are struggling 
      and need more scaffolding to succeed. Once ready just hit Create Assignment, and you will now have a similar assignment with a different level!</p>},
    {question:`How long does it take for the worksheets/activities to generate?`, 
      answer:<p>Depending on the selections it could take anywhere between 5-30 seconds. Most are almost instant, 
      but options that include many pages such as Bingo games, take longer. If you are on a slower connection it may take longer (If you find it often taking too long please reach out to let us know!).</p>},
    {question:`Do you offer refunds?`, 
      answer:<p>Yes, we offer refunds if you are unhappy with your purchase. We do understand that even though 
      you can preview before purchasing, you may later realize that there is a mistake that you are unhappy with. In order to get 
      a refund please find the worksheet in your worksheet collection and click the menu on the right side. Select Report Issue and 
      explain to us what is wrong with the worksheet. We will investigate and fix the issue if possible and refund your token.</p>},
    {question:`How much does it cost?`, 
      answer:<p>All Worksheets are free, and we use a token system for purchasing activities, where you can save some money by purchasing the tokens in advance. 
        If you purchase activities individually they will cost $1.50 each. With tokens each activity can be as low as $0.80.</p>},
    {question:`Do you offer a subscription model?`, 
      answer:<p>At the moment we only offer paying per activity, but if you are interested in us offering a 
      subscription model please contact us to let us know! contact@worksheetwonderland.com</p>},
    {question:`Do you offer site-wide licenses or group discounts?`, 
      answer:<p>Please contact us at contact@worksheetwonderland.com and let us know what your requirements 
      would be, and we can get back to you with a quote for a site-wide license.</p>},
    {question:`Can I post your worksheets on my own website?`, 
      answer:<p>No, you cannot post our worksheets on any platform other than a password protected school 
      platform to share with students such as google classroom. Please visit our <Link to="terms-of-service">Terms of Service</Link> for 
      more information.</p>},
    {question:`Can an entire grade level share an activity/worksheet?`, 
      answer:<p>No, activities & worksheets generated by Worksheet Wonderland can only be used by the person who created it, 
      unless the school has a site-wide license.</p>},
    // {question:`Can I use this website on mobile devices or tablets?`, 
    //   answer:<p>Currently we are unable to generate the worksheets & activities on mobile devices due to the technology 
    //     used to create the pdfs. We are working on an alternative way to view the pdfs on mobile 
    //     devices. This is something we hope to have ready soon!</p>},
  ]
  const onSetActiveQuestion = (i) => {
    const tempActiveQ = JSON.parse(JSON.stringify(activeQ))
    if (tempActiveQ[i]){
      tempActiveQ[i] = !tempActiveQ[i]
    } else {
      tempActiveQ[i] = true;
    }
    setActiveQ(tempActiveQ)
  }
  const faqs = faqsList.map((faq, i)=>{
    return(
    <div className={`faq-qa ${activeQ[i] ? 'active' : ''}`}>
    <div className="faq-question" onClick={()=>onSetActiveQuestion(i)}>
      {faq.question}
      <svg xmlns="http://www.w3.org/2000/svg" width="142" height="91" viewBox="0 0 142 91">
        <g id="Group_115" data-name="Group 115" transform="translate(-188 -327)">
          <path id="Polygon_401" data-name="Polygon 401" d="M71,0l71,74H0Z" transform="translate(330 401) rotate(180)" fill="#9781b5" opacity="0.67"/>
          <path id="Polygon_402" data-name="Polygon 402" d="M71,0l71,74H0Z" transform="translate(330 418) rotate(180)" fill="#9781b5" opacity="0.67"/>
        </g>
      </svg>
    </div>
    <div className={`faq-answer`}>{faq.answer}</div>
  </div>
    )
  })
  return (
    <div className="main-container info faqs">
      <div className="content">
        <h2 className="title">FAQS</h2>
        {faqs}
      </div>
    </div>

  )
}
export default Faqs