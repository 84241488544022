import { specHZ, specify, specAC } from "../helper"

export const WholeNumEquations = (props) => {
  const {createLink, ac} = props
  const c1 = 'Whole Number Equations'
  const [add, sub, mult, div, mix, dig1, dig2, dig3, dig4] = ['Addition', 'Subtraction', 'Multiplication', 'Division', 'Mixed Operation', '1 digit numbers', '2 digit numbers', '3 digit numbers', '4 digit numbers']
  const [dig1_1, dig2_1, dig3_1, dig4_1, dig2_2, dig3_2, dig4_2] = ['1 by 1 digit', '2 by 1 digit', '3 by 1 digit', '4 by 1 digit', '2 by 2 digit', '3 by 2 digit', '4 by 2 digit']
  const title = (mod1, mod2, mod3) => {
    if (mod1 === 'Addition' || mod1 === 'Subtraction' || mod1 === 'Addition & Subtraction'){
      
      return `${mod2} digit Whole Number ${mod1} Equations`
    } else  {
      return `${mod2} Whole Number ${mod1} Equations`
    }
    
  }

  const createSpec = (spec1, spec2, spec3, spec4) => {
    let specAS = [specify('operation', spec1), specAC(ac)]
    if (spec2?.length > 0)
      specAS.push(specify('asNumbers', spec2))
    if (spec3?.length > 0)
      specAS.push(specify('multiply', spec3))
    if (spec4?.length > 0)
      specAS.push(specify('divide', spec4))

    return {[c1]: specAS}
  }

  return (<>
    <div className="buttonGroup">
      <div className="concept-topic">Whole Number Addition Equations (10 = x + 2)</div>
      {createLink([c1], title(add, '1'), '', createSpec([add], [dig1], []), 18)}
      {createLink([c1], title(add, '1-2'), '', createSpec([add], [dig1, dig2], []), 18)}
      {createLink([c1], title(add, '2'), '', createSpec([add], [dig2], []), 18)}
      {createLink([c1], title(add, '2-3'), '', createSpec([add], [dig2, dig3], []), 18)}
      {createLink([c1], title(add, '3'), '', createSpec([add], [dig3], []), 18)}
      {createLink([c1], title(add, '3-4'), '', createSpec([add], [dig3, dig4], []), 18)}
      {createLink([c1], title(add, '4'), '', createSpec([add], [dig4], []), 18)}

    </div>
    <div className="buttonGroup">
      <div className="concept-topic">Whole Number Subtraction Equations (10 = x - 2)</div>
      {createLink([c1], title(sub, '1'), '', createSpec([sub], [dig1], []), 18)}
      {createLink([c1], title(sub, '1-2'), '', createSpec([sub], [dig1, dig2], []), 18)}
      {createLink([c1], title(sub, '2'), '', createSpec([sub], [dig2], []), 18)}
      {createLink([c1], title(sub, '2-3'), '', createSpec([sub], [dig2, dig3], []), 18)}
      {createLink([c1], title(sub, '3'), '', createSpec([sub], [dig3], []), 18)}
      {createLink([c1], title(sub, '3-4'), '', createSpec([sub], [dig3, dig4], []), 18)}
      {createLink([c1], title(sub, '4'), '', createSpec([sub], [dig4], []), 18)}

    </div>
    <div className="buttonGroup">
      <div className="concept-topic">Whole Number Addition & Subtraction Equations</div>
      {createLink([c1], title(add+' & '+sub, '1'), '', createSpec([add,sub], [dig1], []), 18)}
      {createLink([c1], title(add+' & '+sub, '1-2'), '', createSpec([add,sub], [dig1, dig2], []), 18)}
      {createLink([c1], title(add+' & '+sub, '2'), '', createSpec([add,sub], [dig1, dig2], []), 18)}
      {createLink([c1], title(add+' & '+sub, '2-3'), '', createSpec([add,sub], [dig1, dig2], []), 18)}
      {createLink([c1], title(add+' & '+sub, '3'), '', createSpec([add,sub], [dig1, dig2], []), 18)}
      {createLink([c1], title(add+' & '+sub, '3-4'), '', createSpec([add,sub], [dig1, dig2], []), 18)}
      {createLink([c1], title(add+' & '+sub, '4'), '', createSpec([add,sub], [dig1, dig2], []), 18)}

    </div>
    <div className="buttonGroup">
      <div className="concept-topic">Whole Number Multiplication Equations (10 = 2x)</div>
      {createLink([c1], title(mult, dig2_1), '', createSpec([mult], [], [], [dig2_1], ), 18)}
      {createLink([c1], title(mult, dig3_1), '', createSpec([mult], [], [], [dig3_1], ), 18)}
      {createLink([c1], title(mult, dig4_1), '', createSpec([mult], [], [], [dig4_1], ), 18)}
      {createLink([c1], title(mult, dig3_2), '', createSpec([mult], [], [], [dig3_2], ), 18)}
      {createLink([c1], title(mult, dig4_2), '', createSpec([mult], [], [], [dig4_2], ), 18)}

    </div>
    <div className="buttonGroup">
      <div className="concept-topic">Whole Number Division Equations (10 = x ÷ 2)</div>
      {createLink([c1], title(div, dig1_1), '', createSpec([div], [], [dig1_1]), 18)}
      {createLink([c1], title(div, dig2_1), '', createSpec([div], [], [dig2_1]), 18)}
      {createLink([c1], title(div, dig3_1), '', createSpec([div], [], [dig3_1]), 18)}
      {createLink([c1], title(div, dig4_1), '', createSpec([div], [], [dig4_1]), 18)}
      {/* {createLink([c1], title(div, dig2_2), '', createSpec([div], [], [dig2_2]), 18)} */}
      {createLink([c1], title(div, dig3_2), '', createSpec([div], [], [dig3_2]), 18)}

    </div>

    <div className="buttonGroup">
      <div className="concept-topic">Whole Number Multiplication & Division Equations</div>
      {createLink([c1], title(mult+' & '+div, '1-2 digit',), '', createSpec([div, mult], [], [dig1_1, dig2_1,], [dig2_1], ), 18)}
      {createLink([c1], title(mult+' & '+div, dig2_1,), '', createSpec([div, mult], [], [dig2_1], [dig2_1], ), 18)}
      {createLink([c1], title(mult+' & '+div, dig3_1,), '', createSpec([div, mult], [], [dig3_1], [dig3_1], ), 18)}
      {createLink([c1], title(mult+' & '+div, dig4_1,), '', createSpec([div, mult], [], [dig4_1], [dig4_1], ), 18)}
      {createLink([c1], title(mult+' & '+div, dig3_2,), '', createSpec([div, mult], [], [dig3_2], [dig3_2], ), 18)}
    </div>

    <div className="buttonGroup">
      <div className="concept-topic">Whole Number Equations with All 4 Mixed Operations</div>
      {createLink([c1], title(mix, '1-2 digit (Basic)',), '', createSpec([add, sub, div, mult], [dig1], [dig1_1], [dig2_1], ), 18)}
      {createLink([c1], title(mix, '1-2 digit',), '', createSpec([add, sub, div, mult], [dig1, dig2], [dig1_1, dig2_1], [dig2_1], ), 18)}
      {createLink([c1], title(mix, '2-3 digit',), '', createSpec([add, sub, div, mult], [dig2, dig3], [dig3_1, dig3_2], [dig3_1, dig3_2]), 18)}
      {createLink([c1], title(mix, '2-4 digit',), '', createSpec([add, sub, div, mult], [dig3, dig4], [dig4_1, dig4_2], [dig4_2, dig4_1]), 18)}
    </div>
    </>
  )
}