import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import { errorValidation } from "./errorValidation";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { FormControl } from "@material-ui/core";
import { AuthContext } from "../../app/context/auth-context";
import { WSContext } from "../../app/context/ws-context";
import { deviceType } from "../../app/App";
import { conceptsAlwaysRequireAC, conceptsMustHaveACWithBingo } from "../create-worksheet/problems/general";


const GeneralCustomize = (props) => {
  const [errorState, setErrorState] = useState(true);
  const [link, setLink] = useState('concept-selection')
  const auth = useContext(AuthContext);
  const ws = useContext(WSContext);
  const {quantity, maxQuantity, activity} = ws.generalSelection
  const {specify, concept} = ws.inputState
  const history = useHistory()
  let disabledHover = errorState ? 'disabled-hover':null
  const mustHaveAC = conceptsAlwaysRequireAC.includes(concept) || (conceptsMustHaveACWithBingo.includes(concept) && activity.includes('Bingo'))

  const checkErrorValidation = () => {
    let errorCheck = errorValidation(specify, props.validationList, ws.generalSelection, ws.userSelection, ws.editing)
    setErrorState(errorCheck);
  };

  useEffect(() => {
    checkErrorValidation(specify, props.validationList, ws.generalSelection, ws.userSelection, ws.editing);
  }, [ws.inputState, props.validationList]);


  const handleAddConcept = (type) => {
    let tempList = JSON.parse(JSON.stringify(ws.userSelection));
    let tempInput = JSON.parse(JSON.stringify(ws.inputState));
    let tempGS = JSON.parse(JSON.stringify(ws.generalSelection))
    let newQuantity
    
    //no option for AC - AC are required for these situations:
    if (mustHaveAC){
      tempInput.specify.ac = true;
    }

    if (type === 'edit') {
      let oldQuantity = tempList[ws.editing].specify.quantity
      const difference = Math.abs(specify.quantity - oldQuantity)
      const add = specify.quantity > oldQuantity 
      newQuantity = add ? quantity + difference : quantity - difference 
      tempList.splice(ws.editing, 1, tempInput);
    } else {
      newQuantity = ws.generalSelection.quantity += specify.quantity 
      tempList.push(tempInput)

    }
    tempGS.quantity = newQuantity

    ws.setEditing(-1);
    ws.resetMainState('input')
    ws.setMainState(null, tempList)
    ws.setGeneralSelection(tempGS)
    localStorage.setItem("gs", JSON.stringify(tempGS))
    localStorage.setItem("us", JSON.stringify([...tempList]))
    localStorage.removeItem("ws")
    if (newQuantity === ws.generalSelection.maxQuantity){
      history.push('/final-selections')
    } else {
      history.push('/concept-selection')
    }
  };

  const addQButton = errorState === false ? (
    <button className={`btn ${disabledHover}`} onClick={() => handleAddConcept()}>
      Add Questions
    </button>
    ) : (<>
    <button disabled className={`btn disabled ${disabledHover}`}>
      Add Questions
    </button>
    {/* {deviceType() !== 'desktop' && <p style={{margin: '0'}}>Must be on a desktop to create worksheets</p>} */}
    </>)
  const editButton = errorState === false ? (
    <button className={`btn ${disabledHover}`} onClick={() => handleAddConcept('edit')}>
      Update Questions
    </button>
    ) : (
    <button disabled className={`btn disabled ${disabledHover}`}>
      Update Questions
    </button>
  )
  let remainingQuantity = quantity ? maxQuantity - quantity : maxQuantity
  if (ws.editing > -1){
    remainingQuantity = maxQuantity - (quantity - ws.userSelection[ws.editing]?.specify.quantity)
  }

  return (
    <div className="general-customize">
      <div className="row1-container">
        <FormControl>
          <div style={{ display: "flex", flexDirection: "column", width: '100px', marginRight: '20px'}}>
            <TextField variant="filled" id="filled-basic" required className="quantity-input"
              label={<span className="quantity-label">Quantity</span>}
              name="quantity" type="number" 
              maxLength={remainingQuantity}
              value={specify.quantity}
              onChange={ws.handleSpecifyInput}
              placeholder={remainingQuantity === 1 ? '1' : remainingQuantity>=0 ? `1-${remainingQuantity}`: 0}
              InputProps={{
                inputProps: { 
                  type:'number',
                    max: remainingQuantity, min: 1
                }
              }}
            />

          </div>
        </FormControl>
        
        {activity !== 'Scavenger Hunt' && !mustHaveAC &&
          <FormControlLabel label={<span className="checkbox">Include Answer Choices</span>}
            control={<Checkbox className="checkbox" name="ac" color="primary"
              checked={ws.inputState?.specify.ac ? !!ws.inputState.specify.ac : false}
              value={specify.ac} onChange={ws.handleSpecifyInput}
              />}
            />
        }{" "}
        {(activity !== 'Scavenger Hunt' && mustHaveAC) && <div>Answer choices required</div>}
      </div>
      <div className="addQ-container">
        {ws.editing>-1 ? editButton : addQButton}
      </div>
      
    </div>
  );
};

export default GeneralCustomize;
