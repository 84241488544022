import { specAC, specAlg, specHZ, specify } from "../helper"

export const SubtractingIntegers = (props) => {
  const {createLink, ac} = props
  const c1 = 'Subtracting Integers'
  const [dig1, dig2, dig3] = ['1 digit numbers', '2 digit numbers', '3 digit numbers']
  
  const title = (mod1) => {
    return `Subtracting ${mod1} digit Integers`
  }
  const createSpec = (spec1, spec2) => {
    let specAS = [specify('numbers', spec1), specAlg, specAC(ac)]

    return {[c1]: specAS}
  }

  return (<>
    <div className="buttonGroup">
      <div className="concept-topic">Subtracting Integer Algorithms</div>
      {createLink([c1], title('1'), '', createSpec([dig1]), 18)}
      {createLink([c1], title('1-2'), '', createSpec([dig1, dig2]), 18)}
      {createLink([c1], title('2'), '', createSpec([dig2]), 18)}
      {createLink([c1], title('2-3'), '', createSpec([dig2, dig3]), 12)}
      {createLink([c1], title('3'), '', createSpec([dig3]), 12)}
    </div>
    </>
  )
}