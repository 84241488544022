import {randInt, shuffleArray, wrongOptions, answerChoicesKey, selectRand, roundDec, multDecPV, largestDecPV, decPV, fixNum, removeDup} from './general.js'
import Table from './Table';
import {multNumbers} from './whole-numbers/multiply';
import {multNumbers as decMultNumbers } from './decimals/multiply-dec' 
import {asNumbers} from './whole-numbers/add-sub'
import {asNumbers as decAddNumbers} from './decimals/add-sub-dec'
import { StyleSheet } from "@react-pdf/renderer";
export const shtstyles = StyleSheet.create({
  table: {
      display: "table", 
      //  width: "100%", //120, try wider
       width: 620, //100% didn't work/?
       marginTop:-130,
       marginLeft:-30,
       minHeight:350,
       marginBottom:50
      //  textAlign:'center',
  },
  row: {
      flexDirection: "row",
  },
  cell:{
      backgroundColor:'white',
  },
  header: {
    backgroundColor: 'grey'
  }
});

const cell_style_rectangle = (row_index, col_index, data, direction, activity, questionStyle) => {
  let mult = activity === 'Scavenger Hunt'? 1.5 : 1
  let rectHeight = Number(data[0][0].replace(/[^.0-9]+/g, ""))
  let rectWidth =Number(data[1][1].replace(/[^.0-9]+/g, ""))
  let borderLeftWidth = (col_index === 0 ||  (col_index===1 && row_index ===1)) ? 0 : 1
  let borderTopWidth = (col_index === 0 || row_index===1) ? 0 : 1
  let borderRightWidth = (row_index===0 && col_index===1) ? 1 : 0
  let borderBottomWidth = (row_index === 1 || col_index === 0) ? 0 : 1
  let fontSize
  const backgroundColor = 'transparent'
  if (activity === 'Scavenger Hunt'){
    borderLeftWidth = (col_index === 0 ||  (col_index===1 && row_index ===1)) ? 0 : 5
    borderTopWidth = (col_index === 0 || row_index===1) ? 0 : 5
    borderRightWidth = (row_index===0 && col_index===1) ? 5 : 0
    borderBottomWidth = (row_index === 1 || col_index === 0) ? 0 : 5
    fontSize = 20
  }
  if (questionStyle === 'Task Cards'){
    fontSize = 14
  }
  
  const ratio = rectHeight/rectWidth

  let wsRectWidth = rectWidth > 100 && ratio < 0.65 ? 120 : //can be slightly wider if it is a lower ratio/not square
                    rectWidth > 80 && ratio < 0.7 ? 100 : 
                    rectWidth > 70 ?  80 : 
                    rectWidth > 20 ? 70 : 60

  let height = row_index===1 ? 20*mult :
              ratio > 0.95 ? wsRectWidth : //basically a square
              ratio > 0.65 ? wsRectWidth - 10 : //very close
              ratio > 0.40 ? wsRectWidth / 2 : //about 50% less
              ratio > 0.20 ? 30 : 
              30
  height = row_index !== 1 && activity==='Scavenger Hunt' ? height*2 : height;

  const width = col_index===0 ? 60*mult : activity==='Scavenger Hunt' ? wsRectWidth*2 : wsRectWidth

  const display = (col_index===0) ? 'flex':null
  const justifyContent=(col_index===0)?'center':null
  const alignItems=(col_index===0)?'center':null
  const textAlign = (col_index===1 && row_index===1) ? 'center':null
  return {
      width, height, borderLeftWidth, borderRightWidth, fontSize,
      borderTopWidth, borderBottomWidth, borderStyle: "solid",
      backgroundColor, display, justifyContent, alignItems ,textAlign
  }
}
const cell_style_exponent = (row_index, col_index, data, direction) => {
  const backgroundColor = (row_index === 0 && col_index === 1) ? 'white' : 'white'
  const display = (col_index===1) ? 'flex':null
  const justifyContent=(col_index===1)?'flexStart':null
  const alignItems=(col_index===1)?'flexStart':null
  const textAlign=(col_index===1)? 'top':null

  return {
      backgroundColor, display, justifyContent, alignItems, textAlign, width:50, height:50
  }
}


const createExponent = (num, pow) => {
  return (Table([
    [num, pow],
    ], cell_style_exponent, tstyles, null))
}

const tstyles = StyleSheet.create({
  table: {
      display:'flex',
      justifyContent:'flex-start',
      alignItems:'flex-end',
      // minHeight:140, //removed to allow 6 to fit on a WS 
  },
});
const tcTstyles = StyleSheet.create({
  table: {
      display:'flex',
      justifyContent:'flex-start',
      alignItems:'flex-end',
      minHeight:170
  },
});
const noACTStyle = StyleSheet.create({
  table: {
      display:'flex',
      justifyContent:'center',
      alignItems:'center',
      minHeight:170
  },
});
let measurement = ['cm','in','mm','m','ft','yd']
let areaTextArray = ['What is the area of the rectangle?', 'What is the rectangle\'s area?']

export const areaPerStyling = (prob, concept, gs, ac) => {
  let [randNum, randNum2] = prob.direction === 'vertical' ? [prob.numberL, prob.numberS] : [prob.numberS, prob.numberL]
  let tStyle = gs.activity === 'Scavenger Hunt' ? shtstyles : gs.questionStyle === 'Task Cards' && ac ? tcTstyles :
    gs.questionStyle === 'Task Cards' && !ac ? noACTStyle : tstyles
  
  let rectangle = Table([
    [randNum, ''],
    ['', randNum2]
    ], cell_style_rectangle, tStyle, prob.text, 'horizontal', gs.activity, gs.questionStyle)
  return (
    rectangle
  )
}
const getRectDirection = (gs) => {
  let direction = gs.questionStyle === 'Task Cards' ? 'horizontal' : selectRand(['horizontal', 'vertical'])
  return direction
}
export const area = (userSelection, gs) => {
  let numbers = []
  if (userSelection.specify.numberType['Whole Numbers']){
    numbers.push(multNumbers(userSelection))
  }
  if (userSelection.specify.numberType['Decimals']) {
    numbers.push(decMultNumbers(userSelection))
  }
  let unit = selectRand(measurement)
  let {numberS, numberL} = selectRand(numbers)
  let rectangle = {numberS:fixNum(numberS)+unit, numberL: fixNum(numberL)+unit, text: selectRand(areaTextArray)}

  let answer = numberS*numberL
  let pv = multDecPV(numberS, numberL)
  answer = Number.isInteger(answer) ? answer : roundDec(answer, pv)

  let wrong= wrongOptions(answer, 'multiply', numberL, numberS)   
  let perimeterWrong = numberS*2+numberL*2
  perimeterWrong = perimeterWrong === answer ? perimeterWrong+1 : perimeterWrong
  wrong.push(perimeterWrong) 

  wrong = shuffleArray(removeDup(wrong.map(num=>roundDec(Number(num), pv))))
  let AC = answerChoicesKey(answer, wrong[0], wrong[1], wrong[2])
  let problem = {prob: rectangle,
  answerChoices: AC,
  correctAnswer: fixNum(answer), direction: getRectDirection(gs)
  }
    return problem
}
export const area2 = (userSelection, gs) => {
  let numbers = []
  if (userSelection.specify.numberType['Whole Numbers']){
    numbers.push(multNumbers(userSelection))
  }
  if (userSelection.specify.numberType['Decimals']) {
    numbers.push(decMultNumbers(userSelection))
  }
  let {numberS, numberL} = selectRand(numbers)
  var pv = decPV(numberS)+decPV(numberL)

  var answer = Number((numberL*numberS).toFixed(pv))
  
  let text = selectRand(areaTextArray)
  let unit = selectRand(measurement)
  numberL = fixNum(numberL)
  let prob1 = `What is the area of a rectangle with a length of ${numberL}${unit} and a width of ${numberS}${unit}?`
  let prob2 = `What is the area of a rectangle with a width of ${numberS}${unit} and a length of ${numberL}${unit}?`
  let prob3 = `A rectangle has a width of ${numberS}${unit} and a length of ${numberL}${unit}. ${text}`
  let prob4 = `A rectangle has a length of ${numberL}${unit} and a width of ${numberS}${unit}. ${text}`
  let prob5 = `A rectangle has 2 sides that measure ${numberL}${unit} and 2 sides that measure ${numberS}${unit}. ${text}`
  let prob6 = `A rectangle has 2 sides that measure ${numberS}${unit} and 2 sides that measure ${numberL}${unit}. ${text}`
  let prob7 = `There is a rectangle with a length of ${numberL}${unit} and a width of ${numberS}${unit}. ${text}`
  let prob8 = `There is a rectangle with a width of ${numberS}${unit} and a length of ${numberL}${unit}. ${text}`

  let randProb = selectRand([prob1, prob2, prob3, prob4, prob5, prob6, prob7, prob8])

            
  let wrong = wrongOptions(answer, 'multiply', numberL, numberS).map(num=> roundDec(num, pv))
  let AC = answerChoicesKey(answer, wrong[0], wrong[1], wrong[2])
  let problem = {prob: randProb,
  answerChoices: AC,
  correctAnswer: fixNum(answer),probType:'Application'
  }
  return problem
}


let perimeterTextArray = ['What is the perimeter of the rectangle?', 'How long is the entire length around the rectangle?', "What is the rectangle's perimeter?"]

export const perimeter = (userSelection, gs) => {
  let numbers = []
  if (userSelection.specify.numberType['Whole Numbers']){
    numbers.push(asNumbers(userSelection))
  }
  if (userSelection.specify.numberType['Decimals']) {
    numbers.push(decAddNumbers(userSelection))
  }
  let {numberS, numberL} = selectRand(numbers)
  var pv = largestDecPV(numberS, numberL)

  let unit = selectRand(measurement)

  let [numberSF, numberLF] = [fixNum(numberS), fixNum(numberL)]
  let rectangle = {numberS:numberSF+unit,numberL: numberLF+unit, text: selectRand(perimeterTextArray)}

  let answer = roundDec((numberS*2+numberL*2),pv)
  let wrong = [numberS*numberL, answer+randInt(1,5), numberS+numberL, numberS+numberL+numberL, numberL*2, numberS*2]
  wrong= shuffleArray(removeDup(wrong.map(num=> roundDec(num, pv))))
  let AC = answerChoicesKey(answer, wrong[0], wrong[1], wrong[2], unit)
  let problem = {prob: rectangle,
  answerChoices: AC,
  correctAnswer: fixNum(answer),direction: getRectDirection(gs)
  }
    return problem
}


export const perimeter2 = (userSelection, gs) => {
  let numbers = []
  if (userSelection.specify.numberType['Whole Numbers']){
    numbers.push(asNumbers(userSelection))
  }
  if (userSelection.specify.numberType['Decimals']) {
    numbers.push(decAddNumbers(userSelection))
  }
  let {numberS, numberL} = selectRand(numbers)
  var pv = largestDecPV(numberS, numberL)

  let text = selectRand(perimeterTextArray)
  let unit = selectRand(measurement)
  let [numberSF, numberLF] = [fixNum(numberS), fixNum(numberL)]

  let prob1 = `What is the perimeter of a rectangle with a length of ${numberLF}${unit} and a width of ${numberSF}${unit}?`
  let prob2 = `What is the perimeter of a rectangle with a width of ${numberSF}${unit} and a height of ${numberLF}${unit}?`
  let prob3 = `A rectangle has a width of ${numberSF}${unit} and a length of ${numberLF}${unit}. ${text}`
  let prob4 = `A rectangle has a length of ${numberLF}${unit} and a width of ${numberSF}${unit}. ${text}`
  let prob5 = `A rectangle has 2 sides that measure ${numberLF}${unit} and 2 sides that measure ${numberSF}${unit}. ${text}`
  let prob6 = `A rectangle has 2 sides that measure ${numberSF}${unit} and 2 sides that measure ${numberLF}${unit}. ${text}`
  let prob7 = `There is a rectangle with a length of ${numberLF}${unit} and a width of ${numberSF}${unit}. ${text}`
  let prob8 = `There is a rectangle with a width of ${numberSF}${unit} and a length of ${numberLF}${unit}. ${text}`

  let randProb = selectRand([prob1, prob2, prob3, prob4, prob5, prob6, prob7, prob8])
  let answer = roundDec((numberS*2+numberL*2),pv)

  let wrong= [answer+randInt(1,5), numberS+numberL, numberS+numberL+numberL, numberL*2, numberS*2]
  if (numberS <100 && numberL < 100) {
    wrong.push(numberS*numberL)
  }
  wrong = removeDup(shuffleArray(wrong.map(num=>roundDec(num, pv))))
  let AC = answerChoicesKey(answer, wrong[0], wrong[1], wrong[2], unit)
  let problem = {prob: randProb,
  answerChoices: AC,
  correctAnswer: fixNum(answer), probType:'Application', 
  }
  return problem
}

// Still need to make word problems work through the process since they are all set up to be table problems.
export const randArea = (userSelection, generalSelection) => {
  let probArray = [area]
  let randProb = selectRand(probArray)
  return randProb(userSelection, generalSelection)
}
export const randPerimeter = (userSelection, generalSelection) => {
  let probArray = [perimeter]
  let randProb = selectRand(probArray)
  return randProb(userSelection, generalSelection)
}