import addWholeHz from './../../app-files/images/ex/addwhole-hz.png';
import addWholeSu from './../../app-files/images/ex/addwhole-su.png';
import subWholeHz from './../../app-files/images/ex/subwhole-hz.png'
import subWholeSu from './../../app-files/images/ex/subwhole-su.png'
import multWholeHz from './../../app-files/images/ex/multwhole-hz.png'
import multWholeSu from './../../app-files/images/ex/multwhole-su.png'
import divWholeHz from './../../app-files/images/ex/divwhole-hz.png'
import divWholeSu from './../../app-files/images/ex/divwhole-su.png'

import addDecHz from './../../app-files/images/ex/adddec-hz.png';
import addDecSu from './../../app-files/images/ex/adddec-su.png';
import subDecHz from './../../app-files/images/ex/subdec-hz.png'
import subDecSu from './../../app-files/images/ex/subdec-su.png'
import multDecHz from './../../app-files/images/ex/multdec-hz.png'
import multDecSu from './../../app-files/images/ex/multdec-su.png'
import divDecHz from './../../app-files/images/ex/divdec-hz.png'
import divDecSu from './../../app-files/images/ex/divdec-su.png'


import addFractHz from './../../app-files/images/ex/addfract-hz.png';
import addFractSu from './../../app-files/images/ex/addfract-su.png';
import subFractHz from './../../app-files/images/ex/subfract-hz.png'
import subFractSu from './../../app-files/images/ex/subfract-su.png'

export const specifyImages = {
  'Adding Whole Numbers': [addWholeSu, addWholeHz], 'Subtracting Whole Numbers': [subWholeSu, subWholeHz],
  'Multiplying Whole Numbers': [multWholeSu, multWholeHz], 'Dividing Whole Numbers': [divWholeSu, divWholeHz],
  'Adding & Subtracting Whole Numbers': [addWholeSu, addWholeHz], 'Multiplying & Dividing Whole Numbers': [divWholeSu, divWholeHz],
  'Adding Decimals': [addDecSu, addDecHz], 'Subtracting Decimals': [subDecSu, subDecHz],
  'Multiplying Decimals': [multDecSu, multDecHz], 'Dividing Decimals': [divDecSu, divDecHz],
  'Adding & Subtracting Decimals': [addDecSu, addDecHz], 'Multiplying & Dividing Decimals': [divDecSu, divDecHz],
  'Adding Fractions': [addFractSu, addFractHz], 'Subtracting Fractions': [subFractSu, subFractHz],
  'Adding & Subtracting Fractions': [addFractSu, addFractHz]

}