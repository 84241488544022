import {answerChoicesKey, randInt, selectRand, showQText, shuffleArray} from '../general'
import {order, order2, orderAC, orderAC2, orderAC3, orderAC4} from '../ordering'
const { create, all } = require('mathjs')
const config = { number: 'Fraction'}

const wholeNumbers = (userSelection, generalSelection, controlDigits) => {
  //controlDigits &controlledArray for when ordering multiple lists of different digits to keep them the save place value. Important for when styling 7 digit vs 5 digit.
  let [numArray, compNumArray, controlledArray, controlledCompArray] = [[], [], [], []]
  let numSelect = userSelection.specify.numbers

  if (numSelect['1 digit numbers']){
    let nums = [1, 2, 3, 4, 5, 6, 7, 8, 9]
    numArray.push(nums);
    compNumArray.push([...nums, randInt(1, 9), randInt(1, 9), randInt(1, 9), randInt(1, 9)])

    if(controlDigits === 1) {
      controlledArray.push(nums)
      controlledCompArray.push([...nums, randInt(1, 9),  randInt(1, 9), randInt(1, 9), randInt(1, 9)])
    }

  } if (numSelect['2 digit numbers']){
    let ogNum = randInt(16,85);
    let nums = [ogNum, ogNum+1, ogNum-1, ogNum+2, ogNum-2, ogNum+10, ogNum+11, ogNum+3, ogNum-3, ogNum+4, ogNum-4, ogNum+5, ogNum-5]
    compNumArray.push([...nums, ogNum, ogNum, ogNum, ogNum, ogNum, ogNum])
    numArray.push(nums);

    if(controlDigits === 2){
      controlledArray.push(nums)
      controlledCompArray.push([...nums, ogNum, ogNum, ogNum, ogNum, ogNum, ogNum])
    } 

  } if (numSelect['3 digit numbers']) {
    let ogNum = randInt(110,899);
    let nums = [ogNum, ogNum+1, Number(ogNum.toString().substr(0,2))+randInt(0,9), ogNum-1, ogNum+2, ogNum-2, ogNum+10, ogNum-10,ogNum+11, ogNum+3, ogNum-3, ogNum+4, ogNum-4, ogNum+100, ogNum+111]
    numArray.push(nums);
    compNumArray.push([...nums, ogNum, ogNum, ogNum, ogNum, ogNum, ogNum, ogNum, ogNum])
    
    if(controlDigits === 3){
      controlledArray.push(nums)
      controlledCompArray.push([...nums, ogNum, ogNum, ogNum, ogNum, ogNum, ogNum, ogNum, ogNum])
    } 

  } if (numSelect['4 digit numbers']){
    let ogNum = randInt(1100,8999);
    let nums = [ogNum, ogNum+1, Number(ogNum.toString().substr(0,3))+randInt(10,30),ogNum-1, ogNum+2, ogNum-2, ogNum+10, ogNum-10,ogNum+11, ogNum+3, ogNum-3, ogNum+200, ogNum+1000, ogNum+100, ogNum+111]
    numArray.push(nums);
    compNumArray.push([...nums, ogNum, ogNum, ogNum, ogNum, ogNum, ogNum, ogNum, ogNum])

    if(controlDigits === 4){
      controlledArray.push(nums)
      controlledCompArray.push([...nums, ogNum, ogNum, ogNum, ogNum, ogNum, ogNum, ogNum, ogNum])
    } 

  } if (numSelect['5 digit numbers']){
    let ogNum = randInt(11000,89999);
    let nums = [ogNum, ogNum+1, Number(ogNum.toString().substr(0,4))+randInt(100,300),ogNum-1, ogNum+2, ogNum-2, ogNum+10, ogNum-10,ogNum+11, ogNum+1000, ogNum-300, ogNum+4, ogNum-4, ogNum+100, ogNum+111, ogNum+101, ogNum+1111, ogNum+1011]
    numArray.push(nums);
    compNumArray.push([...nums, ogNum, ogNum, ogNum, ogNum, ogNum, ogNum, ogNum, ogNum])

    if(controlDigits === 5){
      controlledArray.push(nums)
      controlledCompArray.push([...nums, ogNum, ogNum, ogNum, ogNum, ogNum, ogNum, ogNum, ogNum])
    }

  } if (numSelect['6 digit numbers']){
    let ogNum = randInt(110000,899999);
    let nums = [ogNum, ogNum+1, Number(ogNum.toString().substr(0,5))+randInt(1000,3000),ogNum-1, ogNum+2, ogNum-2, ogNum+10, ogNum-10,ogNum+11, ogNum+10000, ogNum-2000, ogNum+4, ogNum-4, ogNum+100, ogNum+111, ogNum-100, ogNum-111, ogNum+100000, ogNum+11011]
    numArray.push(nums);
    compNumArray.push([...nums, ogNum, ogNum, ogNum, ogNum, ogNum, ogNum, ogNum, ogNum])

    if(controlDigits === 6){
      controlledArray.push(nums)
      controlledCompArray.push([...nums, ogNum, ogNum, ogNum, ogNum, ogNum, ogNum, ogNum, ogNum])
    } 

  } if (numSelect['7 digit numbers']) {
    let ogNum = randInt(1100000,8999999);
    let nums = [ogNum, ogNum+1, Number(ogNum.toString().substr(0,6))+randInt(10000,30000),ogNum-1, ogNum+2, ogNum-2, ogNum+10, ogNum-10,ogNum+11, ogNum+1000, ogNum-300, ogNum+4, ogNum-4, ogNum+100000, ogNum+111000, ogNum+11111, ogNum+1010101]
    numArray.push(nums);
    compNumArray.push([...nums, ogNum, ogNum, ogNum, ogNum, ogNum, ogNum, ogNum, ogNum])

    if(controlDigits === 7){
      controlledArray.push(nums)
      controlledCompArray.push([...nums, ogNum, ogNum, ogNum, ogNum, ogNum, ogNum, ogNum, ogNum])
    }
  }

  var numList = shuffleArray(controlDigits ? controlledArray[0] : selectRand(numArray))
  var compNumList = shuffleArray(controlDigits ? controlledCompArray[0] : selectRand(compNumArray))
  return {compareOnly: [compNumList[0], compNumList[1]], order:numList }
}
const randWrongSymbol = ['+', '-', 'x', '#', '%']

export const compareSymbol = (num1, num2)=> {
  if (Number(num1)>Number(num2)){
    return {answer:'>', wrongAC: ['<', '='], wrong: shuffleArray(['<', '<', '<', '='])}
  } else if (num1<num2) {
    return {answer:'<', wrongAC: ['>', '='], wrong: shuffleArray(['>', '>', '>', '='])}
  } else {
    return {answer: '=', wrongAC: ['>', '<'], wrong: shuffleArray(['>', '<'])}
  }
}
const randTrueQ = ['Which of the following shows a true comparison?', 'Which shows a true comparison between two numbers?', 'Which shows an accurate comparison between numbers?', 'Which of the following shows a true comparison?']
const randFalseQ = ['Which of the following does NOT show a true comparison?', 'Which does NOT show a true comparison?', 'Which of the comparisons below is NOT true?', 'Which symbol does NOT accurately compare the two numbers?', 'Which comparison is false?', 'Which comparison is NOT accurate?']
let questionArr = [randFalseQ, randTrueQ]

export const compareWhole = (userSelection, generalSelection) => {
  const [num1, num2] = wholeNumbers(userSelection, generalSelection).compareOnly
  let {answer, wrongAC} = compareSymbol(num1, num2)
  var AC = answerChoicesKey(answer, wrongAC[0], wrongAC[1], selectRand(randWrongSymbol))
  const questionText = userSelection.specify.ac ? 
    `Which of the following would create a true comparison between the two numbers?` :
    `Write a comparison symbol (<, >, or =) between the two numbers that creates a true comparison.`

  let showQuestionText = showQText(generalSelection, userSelection)
  let prob = showQuestionText ? `${questionText} \n \n \
  \u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0${num1.toLocaleString()} ____ ${num2.toLocaleString()}` : `${num1.toLocaleString()} ____ ${num2.toLocaleString()}`
  var problem = {prob: prob,
      answerChoices: AC,
      correctAnswer: answer}
  return problem
}

export const compareWholeAC = (userSelection, generalSelection) => {
  const [num1A, num2A, num1B, num2B, num1C, num2C, num1D, num2D] = wholeNumbers(userSelection, generalSelection).order

  let prob = [compareSymbol(num1A, num2A), compareSymbol(num1B, num2B), compareSymbol(num1C, num2C),compareSymbol(num1D, num2D)]
  let answerIdx = randInt(0,3)
  let questIdx = randInt(0,1)
  let questionText = selectRand(questionArr[questIdx])
  const symbol = (idx) => {
    if (idx === answerIdx){
      return questIdx === 0 ? prob[idx].wrong[0] : prob[idx].answer
    } else {
      return questIdx === 0 ? prob[idx].answer : prob[idx].wrong[0] //if looking for false answer - rest will be true;
    }
  }
  let answers = [`${num1A.toLocaleString()} ${symbol(0)} ${num2A.toLocaleString()}`, 
                `${num1B.toLocaleString()} ${symbol(1)} ${num2B.toLocaleString()}`, 
                `${num1C.toLocaleString()} ${symbol(2)} ${num2C.toLocaleString()}`, 
                `${num1D.toLocaleString()} ${symbol(3)} ${num2D.toLocaleString()}`]
  let answer = answers[answerIdx]
  answers.splice(answerIdx, 1)
  var AC = answerChoicesKey(answer, answers[0], answers[1], answers[2])

  var problem = {prob: questionText,
      answerChoices: AC,
      correctAnswer: answer}
  return problem
}

export const randCOWhole = (userSelection, generalSelection) => {
  let {probType, ac} = userSelection.specify
  let probArray = []
  if (probType['Comparing']){
    probArray.push(compareWhole)
    if (ac && generalSelection.activity!=='Scavenger Hunt'){
      probArray.push(compareWholeAC)
    }
  } 
  if (probType['Ordering']){
    probArray.push(order, order2)
    if (ac && generalSelection.activity!=='Scavenger Hunt'){
      probArray.push(orderAC, orderAC2, orderAC3, orderAC4)
    }
  } 

  var randProb = selectRand(probArray)
  return randProb(userSelection, generalSelection, wholeNumbers)
}