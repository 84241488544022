import { React, useEffect } from "react";
import Child from "../../../app-files/images/child.svg";
import AOS from 'aos';
import "aos/dist/aos.css"
import Differentiate from "../Differentiate";
import Infinite from "../Infinite";
import Cover from "../Cover";
import Engage from "../Engage";

const Parents = () => {
  useEffect(() => {
    AOS.init({
      duration:2000,
      once: true,

    });
    window.scrollTo(0,0)


  }, []);

  //Sec
  const diffPG = <p>Create <span className="bold SB">custom</span> assignments at exactly your child's <span className="bold MD">level</span> and build up the difficulty over time as they <span className="bold SB">master</span> the content</p>
  //Main
  const engagePG = <p>We create more than just worksheets, but also <span className="bold SB">engaging</span> activities you can use at home such as scavenger hunts, and bingo games.</p>
  //White
  const infinitePG = <p>We <span className="bold SB">randomly</span> generate all assignments so that you can make <span className="bold MD">endless</span> versions. With Worksheet Wonderland you will <span className="bold SB">never</span> run out of problems</p>
  const coverPG = 'Worksheet Wonderland can help your child succeed whether they need more support, or more of a challenge.'
  const coverIMG = <img src={Child} alt="child"></img>
  const coverTitle = 'Create custom assignments at home'

  return (
    <div className="main-general-container">
      <Cover paragraph={coverPG} image={coverIMG} title={coverTitle} />
      <Differentiate paragraph = {diffPG} />
      <Infinite paragraph = {infinitePG} />
      <Engage paragraph={engagePG} />
      
    </div>
  );
};

export default Parents;
