import React, {useEffect} from 'react'
import { Link } from 'react-router-dom'

const TermsofService = () => {
  useEffect(()=>{
    window.scrollTo(0,0)
  }, [])
  return(
    <div className="main-container info terms-of-service">
      <div className="content">
        <h2>Terms of Service</h2>
        <li>The worksheets and activities generated by Worksheet Wonderland can be used for educational 
          purposes by the user that generated the activity. </li>
        <li>Content generated by Worksheet Wonderland may not be uploaded to other websites, or distributed to students on open platforms. 
          Content may be shared with students on password protected platforms such as Google Classroom.</li>
        <li>Content generated by Worksheet Wonderland may not be reproduced for commercial use. </li>
        <li>Please contact us to inqure about a site-wide license.</li>

        <div className="more-info">For more information please visit out <Link to="/faqs">FAQS page</Link>, or for other questions please reach out at contact@worksheetwonderland.com</div>
      </div>
    </div>

  )
}


export default TermsofService

