import React, {useEffect} from 'react'
import exGraph from '../../../app-files/images/example-graph.png'
import exHw from '../../../app-files/images/example-hw.png'
import ActivityDescriptions from '../../../worksheet/activity-select/ActivityDescriptions'

const Activities = () => {
  useEffect(()=>{
    window.scrollTo(0,0)
  }, [])
  return(
    <div className="main-container info activities">
    <div className="content">
      <div className="content-title">
        <h2>Wonderland Activity Descriptions</h2>
      </div>
      <ActivityDescriptions/>
    </div>
  </div>
  )
}

export default Activities