import { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { WSContext } from "../../app/context/ws-context";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AddingWholeNumbers } from "./worksheet-links/whole-numbers/AddingWhole";
import { generalSel, userSel } from "./PremadeSelections";
import { SubtractingWholeNumbers } from "./worksheet-links/whole-numbers/SubtractingWhole";
import { AddSubWholeNumbers } from "./worksheet-links/whole-numbers/AddSubWhole";
import { MultiplyWholeNumbers } from "./worksheet-links/whole-numbers/MultiplyWhole";
import { DividingWholeNumbers } from "./worksheet-links/whole-numbers/DividingWhole";
import { AddingDecimals } from "./worksheet-links/decimals/AddingDecimals";
import { SubtractingDecimals } from "./worksheet-links/decimals/SubtractingDecimals";
import { AddSubDecimals } from "./worksheet-links/decimals/AddSubDecimals";
import { MultDivDecimals } from "./worksheet-links/decimals/MultDivDecimals";
import { DividingDecimals } from "./worksheet-links/decimals/DividingDecimals";
import { MultiplyDecimals } from "./worksheet-links/decimals/MultiplyDecimals";
import { AddingFractions } from "./worksheet-links/fractions/AddingFractions";
import { SubtractingFractions } from "./worksheet-links/fractions/SubtractingFractions";
import { AddSubFractions } from "./worksheet-links/fractions/AddSubFractions";
import { MultiplyingFractions } from "./worksheet-links/fractions/MultiplyingFractions";
import { DividingFractions } from "./worksheet-links/fractions/DividingFractions";
import { MultDivFractions } from "./worksheet-links/fractions/MultDivFractions";
import { MultDivWholeNumbers } from "./worksheet-links/whole-numbers/MultDivWhole";
import { MixImproperFractions } from "./worksheet-links/fractions/MixImproper";
import { AddingIntegers } from "./worksheet-links/integers/AddingIntegers";
import { SubtractingIntegers } from "./worksheet-links/integers/SubtractingIntegers";
import { AddSubIntegers } from "./worksheet-links/integers/AddSubIntegers";
import { MultiplyingIntegers } from "./worksheet-links/integers/MultiplyIntegers";
import { DividingIntegers } from "./worksheet-links/integers/DividingIntegers";
import { SimplifyingFractions } from "./worksheet-links/fractions/Simplifying";
import { OrderofOperations } from "./worksheet-links/OrderofOps";
import { MultDivIntegers } from "./worksheet-links/integers/MultDivIntegers";
import { Tables } from "./worksheet-links/Tables";
import { DecimalEquations } from "./worksheet-links/equations/DecimalEquations";
import { WholeNumEquations } from "./worksheet-links/equations/WholeNumEquations";
import { IntegerEquations } from "./worksheet-links/equations/IntegerEquations";
import { ComparingWholeNumbers } from "./worksheet-links/whole-numbers/ComparingWholeNumbers";
import { ComparingDecimals } from "./worksheet-links/decimals/ComparingDecimals";
import { ComparingIntegers } from "./worksheet-links/integers/ComparingIntegers";
import { ComparingFractions } from "./worksheet-links/fractions/ComparingFractions";
import { AreaPerimeter } from "./worksheet-links/AreaPerimeter";
import { AuthContext } from "../../app/context/auth-context";
import { cap, conceptsAlwaysRequireAC, conceptsMustHaveACWithBingo } from "../create-worksheet/problems/general";

export const WorksheetLinks = (props) => {
  const {probStyle, concept} = props
  const history = useHistory()
  const location = useLocation()
  const ws = useContext(WSContext);
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const {activity} = ws.generalSelection

  const mustHaveAC = conceptsAlwaysRequireAC.includes(concept) || (conceptsMustHaveACWithBingo.includes(concept) && activity.includes('Bingo'))
  const cannotHaveAC = activity === 'Scavenger Hunt' 
  const ac = mustHaveAC ? true : cannotHaveAC ? false : 'allowed'
  const sampleCount = async(ws) => {
    try {
      await sendRequest(
        `/api/worksheets/sample-count/${ws}-${concept}`, 'POST',
        '',
        {
          "Content-Type": "application/json",
        }
      );
    } catch (err) {

    }
  }

  const createWS = async(concepts, title, quantity, specify) => {

    const userSelection = userSel(concepts, quantity, specify)
    const generalSelection = generalSel(concepts, userSelection, title, ws.generalSelection, quantity)
    ws.setUserSelection(userSelection)
    ws.setGeneralSelection(generalSelection)
    localStorage.setItem('gs', JSON.stringify({...generalSelection}))
    localStorage.setItem('us', JSON.stringify([...userSelection]))

    if (location.path !== '/display-assignment')
      history.push('/display-assignment')
    sampleCount(title)
  
    ws.handleDuplicate("new", userSelection, generalSelection); 
    }

  const createLink = (concepts, displayTitle, title, specify, wsQty) => { 
    let wsTitle = title ? title : displayTitle
    let {activity, questionStyle} = ws.generalSelection

    let quantity = activity === 'Scavenger Hunt' ? 24 : activity === 'Tic Tac Bingo' ? 18 : activity === 'Bingo' ? 24 : activity === 'Task Cards' ? 36 : wsQty
    return (<div className="concept-button" onClick={()=>createWS(concepts, wsTitle, quantity, specify)}>{displayTitle}</div>)
  }

  switch(concept) { 
    // WHOLE NUMBERS
    case "Adding Whole Numbers": return  <AddingWholeNumbers createLink={createLink} probStyle={probStyle} ac={ac}/>    
    case "Subtracting Whole Numbers": return <SubtractingWholeNumbers createLink={createLink} probStyle={probStyle} ac={ac}/>
    case "Adding & Subtracting Whole Numbers": return <AddSubWholeNumbers createLink={createLink} probStyle={probStyle} ac={ac}/>
    case "Multiplying Whole Numbers": return <MultiplyWholeNumbers createLink={createLink} probStyle={probStyle} ac={ac}/>
    case "Dividing Whole Numbers": return <DividingWholeNumbers createLink={createLink} probStyle={probStyle} ac={ac}/>
    case "Multiplying & Dividing Whole Numbers": return <MultDivWholeNumbers createLink={createLink} probStyle={probStyle} ac={ac}/>
    case "Comparing Whole Numbers": return <ComparingWholeNumbers createLink={createLink} generalSelection={ws.generalSelection} probStyle={probStyle} ac={ac}/>

    // DECIMALS
    case "Adding Decimals": return  <AddingDecimals createLink={createLink} probStyle={probStyle} ac={ac}/>    
    case "Subtracting Decimals": return <SubtractingDecimals createLink={createLink} probStyle={probStyle} ac={ac}/>
    case "Adding & Subtracting Decimals": return <AddSubDecimals createLink={createLink} probStyle={probStyle} ac={ac}/>
    case "Multiplying Decimals": return <MultiplyDecimals createLink={createLink} probStyle={probStyle} ac={ac}/>
    case "Dividing Decimals": return <DividingDecimals createLink={createLink} probStyle={probStyle} ac={ac}/>
    case "Multiplying & Dividing Decimals": return <MultDivDecimals createLink={createLink} probStyle={probStyle} ac={ac}/>
    case "Comparing Decimals": return <ComparingDecimals createLink={createLink} probStyle={probStyle} ac={ac}/>

    // FRACTIONS
    case "Adding Fractions": return  <AddingFractions createLink={createLink} probStyle={probStyle} ac={ac}/>    
    case "Subtracting Fractions": return <SubtractingFractions createLink={createLink} probStyle={probStyle} ac={ac}/>
    // case "Adding & Subtracting Fractions": return <AddSubFractions createLink={createLink} probStyle={probStyle} />
    case "Multiplying Fractions": return <MultiplyingFractions createLink={createLink} probStyle={probStyle} ac={ac}/>
    case "Dividing Fractions": return <DividingFractions createLink={createLink} probStyle={probStyle} ac={ac}/>
    // case "Multiplying & Dividing Fractions": return <MultDivFractions createLink={createLink} probStyle={probStyle} />
    case "Simplifying Fractions": return <SimplifyingFractions createLink={createLink} probStyle={probStyle} ac={ac}/>
    case "Mixed & Improper Fractions": return <MixImproperFractions createLink={createLink} probStyle={probStyle} ac={ac}/>
    case "Comparing Fractions": return <ComparingFractions createLink={createLink} probStyle={probStyle} ac={ac}/>
    
    // INTEGERS
    case "Adding Integers": return <AddingIntegers createLink={createLink} probStyle={probStyle} ac={ac}/>
    case "Subtracting Integers": return <SubtractingIntegers createLink={createLink} probStyle={probStyle} ac={ac}/>
    case "Adding & Subtracting Integers": return <AddSubIntegers createLink={createLink} probStyle={probStyle} ac={ac}/>
    case "Multiplying Integers": return <MultiplyingIntegers createLink={createLink} probStyle={probStyle} ac={ac}/>
    case "Multiplying & Dividing Integers": return <MultDivIntegers createLink={createLink} probStyle={probStyle} ac={ac}/>
    case "Dividing Integers": return <DividingIntegers createLink={createLink} probStyle={probStyle} ac={ac}/>
    case "Comparing Integers": return <ComparingIntegers createLink={createLink} probStyle={probStyle} ac={ac}/>


    // OTHER CONCEPTS
    case "Order of Operations": return <OrderofOperations createLink={createLink} probStyle={probStyle} ac={ac}/>
    case "Input Output Tables": return <Tables createLink={createLink} probStyle={probStyle} ac={ac}/>
    // case "Area & Perimeter": return <AreaPerimeter createLink={createLink} probStyle={probStyle} />

    // EQUATIONS
    case "Whole Number Equations": return <WholeNumEquations createLink={createLink} probStyle={probStyle} ac={ac}/>
    case "Decimal Equations": return <DecimalEquations createLink={createLink} probStyle={probStyle} ac={ac}/>
    case "Integer Equations": return <IntegerEquations createLink={createLink} probStyle={probStyle} ac={ac} />

    default: // redirect back to concept selection since that means they somehow typed in the wrong thing.
      props.noConceptFound()
      return <div>We could not find that concept, please try again</div>
  }

}

