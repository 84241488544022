import { specAC, specify } from "../helper"

export const SubtractingFractions = (props) => {
  const {probStyle, createLink, ac} = props
  const hz = probStyle === 'Horizontal'
  const c1 = 'Subtracting Fractions'
  const [fract, mix, mixRG, c, uc] = ['Fractions', 'Mixed Numbers', 'Mixed Numbers With Regrouping', 'Common', 'Uncommon']
  
  const title = (mod1, mod2, mod3) => {
    return `Subtracting ${mod1} with ${mod2} Denominators up to ${mod3}`
  }
  const createSpec = (spec1, spec2, spec3) => {
    let specAS = [specify('numbers', spec1), specify('denominator', spec2), specify('maxDenominator', spec3), specify('probStyle', probStyle), specAC(ac)]

    return {[c1]: specAS}
  }

  return (<>
    <div className="buttonGroup">
      <div className="concept-topic">Subtracting Fractions with Common Denominators</div>
      {createLink([c1], title(fract, '', '5'), '', createSpec([fract], [c], '5'), 12)}
      {createLink([c1], title(fract, '', '10'), '', createSpec([fract], [c], '10'), 12)}
      {createLink([c1], title(fract, '', '15'), '', createSpec([fract], [c], '15'), 12)}
      <div className="sec-title">Fractions with Mixed Numbers</div>
      <div className="sec"></div>
      {createLink([c1], title(fract+' & '+mix, '', '5'), '', createSpec([fract, mix], [c], '5'), 12)}
      {createLink([c1], title(fract+' & '+mix, '', '10'), '', createSpec([fract, mix], [c], '10'), 12)}
      {createLink([c1], title(fract+' & '+mix, '', '15'), '', createSpec([fract, mix], [c], '15'), 12)}
    </div>
    <div className="buttonGroup">
      <div className="concept-topic">Subtracting Fractions with Uncommon Denominators</div>
      {createLink([c1], title(fract, '', '5'), '', createSpec([fract], [uc], '5'), 18)}
      {createLink([c1], title(fract, '', '10'), '', createSpec([fract], [uc], '10'), 18)}
      {createLink([c1], title(fract, '', '15'), '', createSpec([fract], [uc], '15'), 18)}
      <div className="sec-title">Fractions with Mixed Numbers</div>
      <div className="sec"></div>
      {createLink([c1], title(fract+' & '+mix, '', '5'), '', createSpec([fract, mix], [uc], '5'), 12)}
      {createLink([c1], title(fract+' & '+mix, '', '10'), '', createSpec([fract, mix], [uc], '10'), 12)}
      {createLink([c1], title(fract+' & '+mix, '', '15'), '', createSpec([fract, mix], [uc], '15'), 12)}
    </div>
    {/* <div className="buttonGroup">
      <div className="concept-topic">Subtracting Fractions with Any Denominators</div>
      {createLink([c1], title(fract, '', '5'), '', createSpec([fract], [uc, c], '5'), 18)}
      {createLink([c1], title(fract, '', '10'), '', createSpec([fract], [uc, c], '10'), 18)}
      {createLink([c1], title(fract, '', '15'), '', createSpec([fract], [uc, c], '15'), 18)}
      <div className="sec-title">Fractions with Mixed Numbers</div>
      <div className="sec"></div>
      {createLink([c1], title(fract+' & '+mix, '', '5'), '', createSpec([fract, mix], [uc, c], '5'), 18)}
      {createLink([c1], title(fract+' & '+mix, '', '10'), '', createSpec([fract, mix], [uc, c], '10'), 18)}
      {createLink([c1], title(fract+' & '+mix, '', '15'), '', createSpec([fract, mix], [uc, c], '15'), 18)}
    </div> */}

    <div className="buttonGroup">
      <div className="concept-topic">Subtracting Mixed Numbers with Common Denominators</div>
      <div className="sec-title">No regrouping</div>
      <div className="sec"></div>
      {createLink([c1], title(mix, '', '5'), '', createSpec([mix], [c], '5'), 12)}
      {createLink([c1], title(mix, '', '10'), '', createSpec([mix], [c], '10'), 12)}
      {createLink([c1], title(mix, '', '15'), '', createSpec([mix], [c], '15'), 12)}
      <div className="sec-title">Can include regrouping</div>
      <div className="sec"></div>
      {createLink([c1], title(mix, '', '5'), '', createSpec([mix, mixRG], [c], '5'), 12)}
      {createLink([c1], title(mix, '', '10'), '', createSpec([mix, mixRG], [c], '10'), 12)}
      {createLink([c1], title(mix, '', '15'), '', createSpec([mix, mixRG], [c], '15'), 12)}
      <div className="sec-title">All include regrouping</div>
      <div className="sec"></div>
      {createLink([c1], title(mix, '', '5'), '', createSpec([mixRG], [c], '5'), 12)}
      {createLink([c1], title(mix, '', '10'), '', createSpec([mixRG], [c], '10'), 12)}
      {createLink([c1], title(mix, '', '15'), '', createSpec([mixRG], [c], '15'), 12)}

    </div>
    <div className="buttonGroup">
      <div className="concept-topic">Subtracting Mixed Numbers with Uncommon Denominators</div>
      <div className="sec-title">No regrouping</div>
      <div className="sec"></div>
      {createLink([c1], title(mix, '', '5'), '', createSpec([mix], [uc], '5'), 12)}
      {createLink([c1], title(mix, '', '10'), '', createSpec([mix], [uc], '10'), 12)}
      {createLink([c1], title(mix, '', '15'), '', createSpec([mix], [uc], '15'), 12)}
      <div className="sec-title">Can include regrouping</div>
      <div className="sec"></div>
      {createLink([c1], title(mix, '', '5'), '', createSpec([mix], [uc], '5'), 12)}
      {createLink([c1], title(mix, '', '10'), '', createSpec([mix], [uc], '10'), 12)}
      {createLink([c1], title(mix, '', '15'), '', createSpec([mix], [uc], '15'), 12)}
      <div className="sec-title">All include regrouping</div>
      <div className="sec"></div>
      {createLink([c1], title(mix, '', '5'), '', createSpec([mix], [uc], '5'), 12)}
      {createLink([c1], title(mix, '', '10'), '', createSpec([mix], [uc], '10'), 12)}
      {createLink([c1], title(mix, '', '15'), '', createSpec([mix], [uc], '15'), 12)}

    </div>
    {/* <div className="buttonGroup">
      <div className="concept-topic">Subtracting Mixed Numbers with Any Denominators</div>
      {createLink([c1], title(mix, 'Any', '5'), '', createSpec([mix], [uc, c], '5'), 18)}
      {createLink([c1], title(mix, 'Any', '10'), '', createSpec([mix], [uc, c], '10'), 18)}
      {createLink([c1], title(mix, 'Any', '15'), '', createSpec([mix], [uc, c], '15'), 18)}
      <div className="sec-title">Can include regrouping</div>
      <div className="sec"></div>
      {createLink([c1], title(mix, 'Any', '5'), '', createSpec([mix, mixRG], [uc, c], '5'), 18)}
      {createLink([c1], title(mix, 'Any', '10'), '', createSpec([mix, mixRG], [uc, c], '10'), 18)}
      {createLink([c1], title(mix, 'Any', '15'), '', createSpec([mix, mixRG], [uc, c], '15'), 18)}
      <div className="sec-title">All include regrouping</div>
      <div className="sec"></div>
      {createLink([c1], title(mix, 'Any', '5'), '', createSpec([mixRG], [uc, c], '5'), 18)}
      {createLink([c1], title(mix, 'Any', '10'), '', createSpec([mixRG], [uc, c], '10'), 18)}
      {createLink([c1], title(mix, 'Any', '15'), '', createSpec([mixRG], [uc, c], '15'), 18)}

    </div> */}
    </>
  )
}