import { pdfjs, Document, Page, Outline } from 'react-pdf';
import { pdf } from '@react-pdf/renderer';
import React, { useCallback, useState, useEffect, useContext } from 'react';
import downloadIcon from '../app-files/images/icon-download.svg'
import hzBG from '../app-files/images/draft-horizontal-bg.png'
import vtBG from '../app-files/images/draft-vertical-bg.png'
import LoadingSpinner from "../shared/components/UIElements/LoadingSpinner";
import uuid from 'react-uuid'
import { WSContext } from './context/ws-context';
import { AuthContext } from './context/auth-context';
import { useHttpClient } from "../shared/hooks/http-hook";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PDFViewer = ({ pdfDoc, status, gs, key }) => {
  const [pdfUrl, setPdfUrl] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(null);
  const [loading, setLoading] = useState(true)
  const [loadingReport, setLoadingReport] = useState(true)
  const [errorMessage, setErrorMessage] = useState(null)
  const ws = useContext(WSContext);
  const auth = useContext(AuthContext);
  const { isLoading, sendRequest, clearError } = useHttpClient();

  const BG = gs.activity === 'Bingo' || gs.activity === 'Tic Tac Bingo'
  const onDocumentLoadSuccess = useCallback((document) => {
    const { numPages: nextNumPages } = document;
    setNumPages(nextNumPages - 1); // -1 to remove the final terms of use page
    let initialPage = gs.activity === 'Worksheet' ? 1 : 2 
    setPageNumber(initialPage);
    setLoading(false)
  }, []);

  const onItemClick = useCallback(
    ({ pageNumber: nextPageNumber }) => setPageNumber(nextPageNumber),
    [],
  );

  const changePage = useCallback(
    (offset) => setPageNumber((prevPageNumber) => (prevPageNumber || 1) + offset),
    [],
  );

  const previousPage = useCallback(() => changePage(-1), [changePage]);

  const nextPage = useCallback(() => changePage(1), [changePage]);
  
  const submitErrorReport = async(error) => {
    try {
      const wsData = {generalSelection: ws.generalSelection, userSelection: ws.userSelection, worksheetCopy: ws.createdWorksheetState}
      await sendRequest(
        `/api/worksheets/support-ticket`,
        "POST",
        JSON.stringify({
          issue: 'error',
          message: error.name+ ': '+ error.message + 'Stack: ' + error.stack,
          user: auth.user?.userId, 
          ...wsData
        }),
        {
          'Content-Type': 'application/json'
        }
      );
      setErrorMessage("An error report has been submitted and we will work on fixing the issue.")
      setLoadingReport(false)

    } catch {
      setErrorMessage("We were unable to create an error report, if you would like to submit one, please click 'Report Worksheet Issue' below.")
      setLoadingReport(false)
    }
  }
  const handleError = (error) => {
    setLoading(false)
    setErrorMessage("We are working on creating a report to fix this issue in the future.")
    setLoadingReport(true)
    submitErrorReport(error)

  }
  useEffect(() => {
    pdf(pdfDoc).toBlob().then(blob => {
      setPdfUrl(URL.createObjectURL(blob));
    }).catch(err=> {
      handleError(err)    
    })
  }, [pdf]);
  const displayActivity = BG ? gs.activity + ' ' + gs.questionStyle : gs.activity
  const loadingMessages = <div className="iframe-loading">
    {<LoadingSpinner className="loading-container" />}
    <div className="statements">
      <div className="sm sm-1">Creating your {displayActivity}...</div>
      <div className="sm sm-2">Generating your custom problems...</div>
      <div className="sm sm-3">Creating an answer key...</div>
      
      {(BG) ? 
        <div className="sm sm-4">Creating your Bingo cards...</div>
        :<div className="sm sm-4">Just a bit more styling...</div>}
        <div className="sm sm-5">Finishing touches...</div>
        <div className="sm sm-6">Almost done...</div>
    </div>
  </div>
  const errorMessages = <div className="ws-error">
    <div className="title">Error</div>
    <div className="generic">We apologize but this worksheet had an issue being generated. If you would like to you can try again.</div>
    <div className="specific" key={errorMessage}>{errorMessage}</div>
    {loadingReport && <LoadingSpinner className="loading-container" />}
  </div>
  const prevBtn = <button disabled={pageNumber <= 1} onClick={previousPage} className="prev-btn"></button>
  const nextBtn = <button disabled={pageNumber >= numPages} onClick={nextPage} className="next-btn"></button>
  return (

      !errorMessage ? <div className={`pdf-viewer-wrap ${status === 1 ? 'pdf-draft' : ''}`}>
        {status === 0 && !loading && <a href={pdfUrl} target="_blank" download rel="noreferrer"><img src={downloadIcon} alt=""/> Download Assignment</a>}
        <div className="page-turn-wrap">
          {!loading && prevBtn}
          <div className="document-load-wrap" onContextMenu={(e) => {e.preventDefault()}}>
            {status === 1 && !loading && <img className="horizontal-overlay" alt="" src={hzBG}/>}
            {status === 1 && !loading && <img className="vertical-overlay" alt="" src={vtBG}/>}
            <Document
              file={pdfUrl}
              onLoadSuccess={onDocumentLoadSuccess}
              onLoadError={(err)=>{handleError(err)}}
              onItemClick={onItemClick}
              loading=""
              noData=""
            >
              <Outline
                className="custom-classname-outline"
                onItemClick={onItemClick}
              />

              <Page renderMode='svg' pageNumber={pageNumber} key={pageNumber} loading=""/>

            </Document>
            {loading && loadingMessages}
          </div>
          {!loading && nextBtn}
        </div>

        {!loading && <div className="page-number-display">
          {prevBtn}
          <span>
            {`Page ${pageNumber || (numPages ? 1 : '--')} of ${numPages || '--'} ${status === 1 && BG && pageNumber >= numPages ? '(Preview includes first Bingo card page)' : ''}`}
          </span>
          {nextBtn}
        </div>}

      </div> : 
      <div className={`pdf-viewer-wrap ${status === 1 ? 'pdf-draft' : ''}`}>
        {errorMessage && errorMessages}

      </div>

    
  );
}

export default PDFViewer;

