import { answerChoicesKey, randInt, selectRand, shuffleArray, girlList } from "../general";

const GCF = (...arr) => {
  const _gcf = (x, y) => (!y ? x : GCF(y, x % y));
  return [...arr].reduce((a, b) => _gcf(a, b));
};

export const LCM = (a,b) => {
  return a/GCF(a,b)*b;
}

const LCMNums = () => {
  let randNum = randInt(2,9)
  let numArray = selectRand([[4,6], [8,12], [4,10], [12,18], [16,24], [12,16],[6,9],[9,12], [8,10],[6,10], 
              [2,2*randNum],[3, 3*randNum], [4,4*randNum], [5, 5*randNum], [6, 6*randNum], [7, 7*randNum], [8, 8*randNum], [9, 9*randNum],
              [randInt(2,10), randInt(2,20)], [randInt(2,10), randInt(2,20)], [randInt(2,10), randInt(2,20)], [randInt(2,10), randInt(2,20)], 
              [randInt(2,10), randInt(2,20)], [randInt(2,10), randInt(2,20)], [randInt(2,10), randInt(2,20)], [randInt(2,10), randInt(2,20)], 
            ])
  return shuffleArray(numArray)
}
const GCFNums = () => {
  let randNum = randInt(2,9)
  let numArray = selectRand([[4,6], [8,12], [4,10], [12,18], [16,24], [12,16],[6,9],[9,12], [8,10],[6,10], 
              [2,2*randNum],[3, 3*randNum], [4,4*randNum], [5, 5*randNum], [6, 6*randNum], [7, 7*randNum], [8, 8*randNum], [9, 9*randNum],
              [randInt(2,10), randInt(2,20)], [randInt(2,10), randInt(2,20)], [randInt(2,10), randInt(2,20)], [randInt(2,10), randInt(2,20)], 
              [randInt(2,10), randInt(2,20)], [randInt(2,10), randInt(2,20)], [randInt(2,10), randInt(2,20)], [randInt(2,10), randInt(2,20)], 
            ])
  return shuffleArray(numArray)
}


export const findLCM = (userSelection, generalSelection) => {
  const [num1, num2] = LCMNums()

  let answer = LCM(num1, num2)
  const wrong = [GCF(num1, num2), answer+randInt(1,3), answer-randInt(1,3)]
  var AC = answerChoicesKey(answer, wrong[0], wrong[1], wrong[2])
  let prob = `What is the least common multiple of ${num1} and ${num2}?`
  var problem = {prob: prob,
      answerChoices: AC,
      correctAnswer: answer}
  return problem
}
export const findLCM2 = (userSelection, generalSelection) => { //not finished
  const [num1, num2] = LCMNums()
  let randGirl = selectRand(girlList)
  let answer = LCM(num1, num2)
  const wrong = [GCF(num1, num2), answer+randInt(1,3), answer-randInt(1,3)]
  var AC = answerChoicesKey(answer, wrong[0], wrong[1], wrong[2])
  let prob = `${randGirl} is buying packages of  ${num1}  and ${num2}?`
  var problem = {prob: prob,
      answerChoices: AC,
      correctAnswer: answer}
  return problem
}

export const findGCF = (userSelection, generalSelection) => {
  const [num1, num2] = GCFNums()

  let answer = LCM(num1, num2)
  const wrong = [GCF(num1, num2), answer+randInt(1,3), answer-randInt(1,3)]
  var AC = answerChoicesKey(answer, wrong[0], wrong[1], wrong[2])
  let prob = `What is the least common multiple of ${num1} and ${num2}?`
  var problem = {prob: prob,
      answerChoices: AC,
      correctAnswer: answer}
  return problem
}