import React from 'react';
import bingo from '../../app-files/images/sample-addsubintegers.png'
import sh from '../../app-files/images/activity-sh.png'

const Engage = (props) => {
  return(
    <div className="engage section-container" style={{backgroundColor:'var(--main-color)'}}>
    <div className="description-container">
      {props.paragraph}
      {/* <img className="engage-conceptCustomize" src={ConceptCustomizeEx} alt="differentiate"></img> */}
    </div>
    <div className="ex-container">
      <img className="ex ex1" src={bingo} alt="small whole numbers 3 steps"/>
      <img className="ex ex2" src={sh} alt="large whole numbers 3 steps"/>
    </div>
  </div>
  )
}

export default Engage