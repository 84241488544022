
import {Text,View, StyleSheet,} from "@react-pdf/renderer";
import {styles} from '../createWorksheetcss'


export var sportList = ['football', 'soccer', 'baseball']
export var boyList = ["Aaron","Aiden","Alex","Andrew","Angel","Anthony","Antonio","Ben","Blake","Brandon","Brett","Bryson","Caleb","Carlos","Carter","Charles","Chris","Conner","Cooper","Cristian","Daniel","David","Diego","Donte","Dylan","Eduardo","Elijah","Emiliano","Erick","Ethan","Francisco","Frank","Gabriel","Gavin","Greg","Hayden","Henry","Hugo","Hunter","Isaac","Isaiah","Ivan","Jack","Jackson","Jacob","James","Jared","Jason","Javier","Javier","Jayden","Jeremy","Jorge","José","Joseph","Joshua","Josiah","Josue","Juan","Julian","Kaiden","Karter","Kenneth","Kevin","Kyle","Landon","Leandro","Liam","Logan","Lucas","Luis","Luke","Manuel","Mason","Matthew","Max","Michael","Miguel","Misael","Nathan","Nicholas","Nico","Noah","Nolan","Oliver","Orlando","Owen","Pablo","Ralph","Raul","Rodrigo","Ryan","Samuel","Santiago","Thomas","Timothy","Travis","Trevor","Troy","Tyler","Vance","Victor","Wade","Walter","Wesley","William","Wyatt","Xavier","Zachary","Zack","Zahir","Zander"]

export var girlList = ["Abigail","Addison","Alexis","Allison","Amelia","Andrea","Angela","Aria","Ashley","Audrey","Ava","Avery","Baylie","Bethany","Brianna","Brittany","Brooklyn","Camila","Carrie","Charlotte","Chloe","Christine","Claire","Daisy","Danielle","Denah","Diana","Emily","Emma","Emmy","Eva","Evelyn","Faith","Fatima","Fiona","Francesca","Gabriella","Giselle","Grace","Hailey","Hannah","Harper","Hazel","Irene","Isabella","Jaleska","Jasmine","Jenna","Julia","Kaitlyn","Katherine","Kayla","Kaylee","Khloe","Kiara","Kylie","Lanay","Laura","Lauren","Layla","Lily","Lizbeth","Lorena","Lucy","Luna","Lyla","Madison","Maria","Marilyn","Mary","Melissa","Mia","Michelle","Miranda","Monica","Natalia","Nicole","Nora","Olivia","Paige","Pamela","Rachel","Rebecca","Riley","Sadie","Samantha","Sarah","Savannah","Scarlett","Sophia","Stehanie","Stella","Taylor","Teagan","Tessa","Tiana","Valentina","Valerie","Vanessa","Veronica","Victoria","Viviana","Willa","Wren","Xavia","Yasmin","Zariah","Zoey"]
export var nameList = boyList.concat(girlList)
export var womanList = ["Ms. Vallery", "Mrs. Estes", 'Ms. Smith', 'Ms. Gonzalez', 'Ms. Hernandez', 
'Mrs. Mendez', 'Mrs. Magee', 'Mrs. Henderson', 'Mrs. Robertson', 'Mrs. Martin', 'Ms. Davidson', 
'Ms. Brown', 'Mrs. Perez', 'Mrs. Peterson', "Mrs. Keefe", "Mrs. Mendoza", "Mrs. Orr", 
"Mrs. Guerra", "Mrs. Hatter", "Mrs. Rios", "Mrs. Quinones", "Mrs. Serrano", "Mrs. DePavia",
'Mrs. Duncan', 'Mrs. Howard', 'Mrs. Sanchez', 'Mrs. Swain', 
]

export var candyList = ["sour patch kids","skittles","m&ms", "Mike & Ikes", "Starbursts"]
export var forestAnimal = ["rabbit", "frog", "moth", "snake", "spider", "monkey", "bird"]
export var lakeAnimal = ["frog", "duck", "otter"]
export var desertAnimal = ["lion", "zebra", "giraffe","antelope", "meerkat", "warthog", "hyena"]

export var bigPetList = ["cat", "dog", "rabbit"]
export var smallPetList = ["rabbit","guinea pig", 'lizard','turtle','hamster','ferret','rat']
export var xsmallPetList = ['hamster', 'fish', 'frog','tarantula']
export var disasterList = ["zombie apocalypse", "category 5 hurricane", 'snow storm', 'tsunami','ice storm', 'flood','natural disaster']

export var storeList = ["Kroger", "Mega Market", "Walmart", "Target", "Fiesta", "Whole Foods Market"]

export var itemLong = ['string', 'wood', 'rope', 'paper', 'yarn', 'metal', 'wire', 'pipe']
export var measureList = ['inches','feet','yards','meters','centimeters']

export var groupList = ["package", "container" ]
export var itemList = ["Pokemon cards", "baseball cards", "pencils", "erasers", "books", "stickers", "pens", "jelly beans", "candies", "M&Ms", "cookies", "small toys",]
export var itemPkgList = ["Cheetos", "crackers", "Skittles", "takis", "Starbursts", "M&Ms", "tortilla chips", "fruit snacks", "gummy bears", "Sour Patch Kids", "Doritos", "Pasta"]
export var itemList400_1500 = ['phone','tv','computer','laptop', 'game system']
export var itemList20_60 = ["game", "blanket", "game controller"]
export var itemList10_20 = ["toy", 'book', 'movie', 'candle', 'lego set', 'board game', 'card game']
export var itemList2_9 = ['doll', 'lego set', 'movie', 'toy', 'bag of chip', 'teddy bear', 'puzzle', "rubik's cube", ]
export var foodList = ["soup", "pizza", "pretzel", "cookie", "potato", "cracker", "banana"]
export var disasterFoodList = ['cookie', 'cracker','oatmeal','soup', 'candy', 'frozen dinner','mac & cheese']
export var themeList = ["flower", "space","superhero","neon","jungle","glitter"]
export var tourList = ['glacier', 'whale watching', 'ice cave', 'puffin viewing', 'castle', 'northern lights viewing', 'volcano viewing', 'mountain climbing', 'scuba diving', 'zipline', 'snorkeling']
export var eventHire = ['caterer', 'photographer', 'band', 'DJ']
export var each = ['for each', 'for one', 'per']
export var canItemList = ['black beans', 'kidney beans', 'chick peas', 'spinach', 'pinto beans', 'sweet potatoes']
export var materialList = ['maple wood', 'pipe', 'drywall', 'roof shingles', 'bricks']
export var buildList = ['house', 'apartment building', 'shed', 'tree house', 'library', 'store']


// require answer choices with bingo activities bc the non-AC questions would require very long answers or too many repeat answers (Such as all < < > > )
export const conceptsMustHaveACWithBingo = ['Comparing Whole Numbers', 'Comparing Integers', 'Comparing Fractions', 'Comparing Decimals', 'Input Output Tables']

// concept cannot be currently used on SH since we dont want AC on it, and most of the non-AC questions would be too long/ just not make sense.
export const conceptsCantBeOnSH = ['Comparing Whole Numbers', 'Comparing Integers', 'Comparing Fractions', 'Comparing Decimals']

// Do not have questions that align with NO AC, but does work on SH since I set up to where the SH serves as the answer choice to select the equation.
export const conceptsAlwaysRequireAC = ['Input Output Tables']

export const roundDec = (num, pv=2) => {
  num = typeof num === 'string' ? Number(num) : num
  if (Number.isInteger(num)) {
    return num
  }  else {
    var roundNum = Math.round((num + Number.EPSILON) * Math.pow(10, pv)) / Math.pow(10, pv)

    //if answer to rounding is 0 ex: 0.002839 round to tenth, need to increase pv until at least 4
    if (roundNum === 0){
      if (pv > 4) {
        return Number(roundNum)
      }
      return roundDec(num, pv+1)
    }
    return Number(roundNum)

  }

}
export function randInt(x, y, noZeroOne){
  var num =  Math.floor(Math.random() * (y - x + 1) + x)
  if (noZeroOne === true){ // Usually when they enter -9, 9 and it's possible to go through 0, 1 but wouldn't want it in the problem. 7 x 1 + 0 
    if (num !== 0 && num!== 1){
      return num
    }else {
        return randInt(x, y, true)
    }
  } else {
    return num
  }

}
export const randDec = (x, y, place) => { //place can be 0 so it can randomly create either whole numbers or decimals as well.
  var num = (Math.random()*(y-x) + x).toFixed(place)
  if (Number.isInteger(Number(num)) && place !== 0) {
    return randDec(x, y, place)

     // num generated ended with 0 and needs to be re-done to ensure we get the exact right number of 
     // digits in the problems.
  } else if (place !== 0 && Number(num).toString().split('.')[1].length < place){
    return randDec(x, y, place)

  } else {
    return Number(num)
  }
}
export const decPV = (x) => { //determine  digits after dec
  if (!x || Number.isInteger(Number(x)) || !x.toString().split('.')[1]){
    return 0
  }else{
    return x.toString().split('.')[1].length
  }
}
// returns [largeNum, smallNum]
export const orderDigits = (x, y) => { //determine  digits after dec
  let [lengthX, lengthY]  = [removeDec(x, 'string').length, removeDec(y, 'string').length]
  if (lengthX > lengthY){
    return [x, y]
  } else if (lengthX < lengthY){
    return [y, x]
    
    //digit length is equal so check instead for original numbers value to determine order
  } else if (x > y) {
    return [x, y]
  } else {
    return [y, x]
  }
  
}
export const largestDecPV = (x, y, z) => {
  return Math.max(decPV(x), decPV(y), decPV(z))
}
export const decOp = (x, y, pv, op) => {
  return (Number(eval(x+op+y).toFixed(pv)))
}
export const multDecPV = (x, y) => { //determine # digits behind dec in mult answer

  if(Number.isInteger(Number(x)) && Number.isInteger(Number(y))) {
    return 0
  } else {
    return(decPV(x)+decPV(y))
  }
}

export const removeDec = (x, type) => { //type=== number or string
  const num = x.toString().replace('.', '')
  return type === 'string' ? num : Number(num)
}
export const splitDec = (x) => {
  let splitDec = x.toString().split('.')
  let whole = splitDec[0]
  if (splitDec[1] === undefined){
    var decimal = ''
  } else {
    decimal = splitDec[1]
  }
  return [whole, decimal]

}
export const removeDup = (array) => {
  return new Array(...new Set(array))
}
export const displayZero = (x, pv) => {
  let newX = x.toString().split('')
  let xPV = x.toString().split('.')[1]?.length || 0

  while (xPV < pv){
    xPV === 0 ? newX.push('.0') : newX.push('0')
    xPV++
  }
  return newX.join('').toLocaleString(undefined, {minimumFractionDigits: pv})
}

export const cap = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
export var cityList = [{city:'San Francisco', pop:randInt(850000,900000)},
                    {city:'Houston', pop:randInt(2000000,2500000)},
                    {city:'Austin', pop:randInt(900000,1000000)},
                    {city:'San Antonio', pop:randInt(1400000,1500000)},
                    {city:'Los Angeles', pop:randInt(3700000,4300000)}]


export const shuffleArray = (array, concept) => {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return ([...array])
  }

export const selectRand = (array) => {
  return shuffleArray(array)[0]
}
// export var packageCombo = [{i:'hot dog buns', i2: 'hot dogs'}, 
// {i:selectRand(['burger buns', 'brioche buns', 'pretzel buns', 'sesame seed buns']), i2:selectRand(['burger patties', 'veggie patties'])}, 
// {i:'juice boxes', i2: ['donuts', 'kolaches']}]

export const wrongOptions = (answer, op, numL, numS, pv) => {
    var wrong = [answer+1, answer+2, answer+3]
    
    if (answer>20){
      wrong.push(answer+10, answer+11, answer-10, answer-11)
    }
    if (answer>200) {
      wrong.push(answer+100, answer-100, answer+111, answer+101,)
    }
    if (answer > 1){
      wrong.push(answer-1)
    }
    if (op === 'multiply') {
    var stringNumS = (numS).toString().split('.').join('')

      wrong.push(answer*10)
      if (numS > 9 || stringNumS.length>1) { //no placeholder when smaller number is 2 digits
        var noPlaceHolder = Number(stringNumS[0])*numL+ Number(stringNumS[1])*numL
        wrong.push(noPlaceHolder)
      }
      wrong.push(answer+100)
    }else if (op === 'add') {
      wrong.push(numL+numS+numL, numL-numS, answer+3, answer-2)
    }else if (op === 'sub') {
      wrong.push(numL+numS)
    }else if (op === 'divide') {
      wrong.push(numL+numS, numL*numS)
      if (answer > 20){
        wrong.push(answer*10)
      }
    } else if (op === 'decimal') {
      if (pv === 2){
        wrong.push(answer+0.01, Math.abs(answer-0.01))
      }
      if (answer>99) {
        wrong.push(answer-1.1, answer+101.1)
      }
      wrong.push(answer/10, answer*10, answer+11.1, answer+0.1, answer+0.2, Math.abs(answer-0.1), Math.abs(answer-0.2)) //(numL+numS).toFixed(2), (answer +100).toFixed(1), (answer+0.1).toFixed(2), (answer +0.03).toFixed(2),
    
    }
    var wrongChoices = shuffleArray(removeDup(wrong)).slice(0,3)
    if (op === 'decimal'){
      wrongChoices = wrongChoices.map(choice=> roundDec(choice, pv))
    }
    return (wrongChoices)
  }
  export const fixNum = (num, pv) => {
    num = pv ? roundDec(num, pv) : num
    return num.toLocaleString(undefined, {minimumFractionDigits: decPV(num)})
  }
  export const answerChoicesKey = (answer, wrong, wrong2, wrong3, f) => {
    const lc = selectRand([['A', 'B', 'C', 'D'], ['F','G','H','J ']])
    let [correctAnswer, wrongAnswer, answerLetter, wrongLetter] = ['', '', '', ''] //wrongAnswer is for BINGO in case we need to fill in some "wrong" on the grid.
    var answers = shuffleArray([answer, wrong, wrong2, wrong3]).filter(choice => choice !== undefined)
    if (f === '$') {
      answers = answers.map(num=> roundDec(num,2))
      var [$, unit] = ['$', '']
    } else if (f === undefined) {
      [$, unit] = ['', '']
    } else {
      [$, unit] = ['', ' '+f]
    } 
    const optD = answers.length === 4
    if (typeof answer === 'string') {
      var A = (` ${lc[0]} ` + answers[0])
      var B = (` ${lc[1]} ` + answers[1])
      var C = (` ${lc[2]} ` + answers[2])
      var D = optD && (` ${lc[3]} ` + answers[3])

    } else if (typeof answer === 'number'){
      A = (` ${lc[0]} ` + $ + fixNum(answers[0])+ unit)
      B = (` ${lc[1]} ` + $ + fixNum(answers[1])+ unit)
      C = (` ${lc[2]} ` + $ + fixNum(answers[2])+ unit)
      D = optD && (` ${lc[3]} ` + $ + fixNum(answers[3])+ unit)

    } else { //view, fraction/table
      A = {...answers[0], letter:lc[0]}
      B = {...answers[1], letter:lc[1]}
      C = {...answers[2], letter:lc[2]}
      D = optD && {...answers[3], letter:lc[3]}
    }
    if (answers[0].answer || answers[0] === answer){
      correctAnswer = A
      answerLetter = lc[0]
      wrongLetter = selectRand([lc[1], lc[2], lc[3]])
      wrongAnswer = selectRand([answers[1], answers[2], answers[3]])
    } else if (answers[1].answer || answers[1] === answer){
      correctAnswer = B
      answerLetter = lc[1]
      wrongLetter = selectRand([lc[0], lc[2], lc[3]])
      wrongAnswer = selectRand([answers[0], answers[2], answers[3]])

    } else if (answers[2].answer || answers[2] === answer){
      correctAnswer = C
      answerLetter = lc[2]
      wrongLetter = selectRand([lc[1], lc[0], lc[3]])
      wrongAnswer = selectRand([answers[1], answers[0], answers[3]])

    } else if (optD) {
      correctAnswer = D
      answerLetter = lc[3]
      wrongLetter = selectRand([lc[1], lc[2], lc[0]])
      wrongAnswer = selectRand([answers[1], answers[2], answers[0]])
    }
    

    return {A, B, C, D, correctAnswer, answerLetter, wrongAnswer, wrongLetter}
  }
//   export const numberWithCommas = (x) => {
//     return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
// }

export const showQText = (gs, us) => {
  return !gs.directions?.length || us.length > 1 || gs.questionStyle !== 'Worksheet'
}