import { specAlg, specHZ, specify } from "../helper"

export const ComparingIntegers = (props) => {
  const {createLink, ac} = props
  const [c1] = ['Comparing Integers']
  const [dig1, dig2, dig3] = ['1 digit numbers', '2 digit numbers', '3 digit numbers']
  const [com, order] = ['Comparing', 'Ordering']
  const title = (mod1, mod2) => {
    return `${mod1} ${mod2} Digit Integers`
  }

  const showNoAC = ac === false || ac === 'allowed'
  const showAC = ac === true || ac === 'allowed'

  const createSpec = (spec1, spec2, spec3=false) => {
    let specC1 = [specify('numbers', spec1), specify('probType', spec2), specify('ac', spec3), specAlg, specify('ac', ac === true ? true : ac === false ? false : spec3)]
    return {[c1]: specC1}
  }
  return (<>
    {showNoAC && <div className="buttonGroup">
      <div className="concept-topic">Comparing Integers {showAC && '(No answer choices)'}</div>
      {createLink([c1], title(com, '1'), '', createSpec([dig1], [com]), 12)}
      {createLink([c1], title(com, '1-2'), '', createSpec([dig1, dig2], [com]), 12)}
      {createLink([c1], title(com, '2'), '', createSpec([dig2, [com]], [com]), 12)}
      {createLink([c1], title(com, '2-3'), '', createSpec([dig2, dig3], [com]), 12)}
      {createLink([c1], title(com, '3'), '', createSpec([dig3], [com]), 12)}
    </div>}
    {showAC && <div className="buttonGroup">
      <div className="concept-topic">Comparing Integers {showNoAC && '(Answer choices)'}</div>
      {createLink([c1], title(com, '1'), '', createSpec([dig1], [com], true), 16)}
      {createLink([c1], title(com, '1-2'), '', createSpec([dig1, dig2], [com], true), 16)}
      {createLink([c1], title(com, '2'), '', createSpec([dig2, [com]], [com], true), 16)}
      {createLink([c1], title(com, '2-3'), '', createSpec([dig2, dig3], [com], true), 16)}
      {createLink([c1], title(com, '3'), '', createSpec([dig3], [com], true), 16)}
    </div>}
    {showNoAC && <div className="buttonGroup">
      <div className="concept-topic">Ordering Integers {showAC && '(No answer choices)'}</div>
      {createLink([c1], title(order, '1'), '', createSpec([dig1], [order]), 12)}
      {createLink([c1], title(order, '1-2'), '', createSpec([dig1, dig2], [order]), 12)}
      {createLink([c1], title(order, '2'), '', createSpec([dig2, [com]], [order]), 12)}
      {createLink([c1], title(order, '2-3'), '', createSpec([dig2, dig3], [order]), 12)}
      {createLink([c1], title(order, '3'), '', createSpec([dig3], [order]), 12)}
    </div>}
    {showAC && <div className="buttonGroup">
      <div className="concept-topic">Ordering Integers {showNoAC && '(Answer choices)'}</div>
      {createLink([c1], title(order, '1'), '', createSpec([dig1], [order], true), 10)}
      {createLink([c1], title(order, '1-2'), '', createSpec([dig1, dig2], [order], true), 10)}
      {createLink([c1], title(order, '2'), '', createSpec([dig2, [order]], [order], true), 10)}
      {createLink([c1], title(order, '2-3'), '', createSpec([dig2, dig3], [order], true), 10)}
      {createLink([c1], title(order, '3'), '', createSpec([dig3], [order], true), 10)}
    </div>}
    {showNoAC && <div className="buttonGroup">
      <div className="concept-topic">Comparing & Ordering Integers {showAC && '(No answer choices)'}</div>
      {createLink([c1], title(com+' & '+order, '1'), '', createSpec([dig1], [order, com]), 12)}
      {createLink([c1], title(com+' & '+order, '1-2'), '', createSpec([dig1, dig2], [order, com]), 12)}
      {createLink([c1], title(com+' & '+order, '2'), '', createSpec([dig2, [com]], [order, com]), 12)}
      {createLink([c1], title(com+' & '+order, '2-3'), '', createSpec([dig2, dig3], [order, com]), 12)}
      {createLink([c1], title(com+' & '+order, '3'), '', createSpec([dig3], [order, com]), 12)}
    </div>}
    {showAC && <div className="buttonGroup">
      <div className="concept-topic">Comparing & Ordering Integers {showNoAC && '(Answer choices)'}</div>
      {createLink([c1], title(com+' & '+order, '1'), '', createSpec([dig1], [order, com], true), 10)}
      {createLink([c1], title(com+' & '+order, '1-2'), '', createSpec([dig1, dig2], [order, com], true), 10)}
      {createLink([c1], title(com+' & '+order, '2'), '', createSpec([dig2], [order, com], true), 10)}
      {createLink([c1], title(com+' & '+order, '2-3'), '', createSpec([dig2, dig3], [order, com], true), 10)}
      {createLink([c1], title(com+' & '+order, '3'), '', createSpec([dig3], [order, com], true), 10)}
    </div>}
    </>
  )
}

