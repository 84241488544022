import React, { useContext, useState } from "react";
import AccountSidebar from "./AccountSidebar";
import PurchaseTokens from './PurchaseTokens'

const PurchaseTokensPage = (props) => {

  return (
    <div className="account purchase-tokens">
      <AccountSidebar page="purchase-tokens" />
      <PurchaseTokens />
    </div>
  );
};

export default PurchaseTokensPage;
