import {randInt, selectRand, shuffleArray} from '../general.js'
import {Text,View,StyleSheet,} from "@react-pdf/renderer";
import Table from '../Table.js'
import { fixImproper } from './mixed-improper.js';
import { fractionNumbers, simplify } from './simplify.js';

const { create, all } = require('mathjs')
const config = { number: 'Fraction'}
const math = create(all, config)

export const tstyles = StyleSheet.create({
  table: {
      display: "table", //gives individual fraction overall style.
       width: "30", //120, try wider
  },
});
export const tstylesTC = StyleSheet.create({
  table: {
      display: "table", //gives individual fraction overall style.
       width: "100%", //120, try wider
  },
});
export const tstylesSH = StyleSheet.create({
  table: {
      display: "table", //gives individual fraction overall style.
       width: "70", //120, try wider
  },
});

//separate answer key styling due to being unable to style the nested views on the answer key page.
export const akTStyles = StyleSheet.create({
  table: {
      display: "table", //gives individual fraction overall style.
       width: "30",
  },
  row: {
      flexDirection: "row",
  },
  cell:{
      backgroundColor:'white',
  },
  header: {
    backgroundColor: 'grey'
  }
});
export const acStyles = StyleSheet.create({
  table: {
    display:'table',
    width:'30'
  }

})
export const styles = StyleSheet.create({

  fractProbContainer: {
    marginLeft: '20',
    width: '100%',
    display:'flex',
    justifyContent: 'center',
    alignContent:'center',
    flexDirection:'row',
  },
  fractProbContainerSH: {
    marginLeft: -50,
    display:'flex',
    justifyContent: 'center',
    alignItems:'center',
    flexDirection:'row',
  },
  fractProbContainerTC: {
    // marginLeft: '20',
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    flexDirection:'row',
    width:'100%'
  },
  problemLine: {
    borderBottom:0.5,
    width: '60', 
    display:'flex',
    flexDirection: 'row',
    alignItems:'flex-end',
    justifyContent:'space-between',
    paddingRight:5

  },
  problemLineTC: {
    width: '80'
  },
  problemLineSH: {
    borderBottom:7,
    width:'200', //was 180 but issue with + after making flex-end
    display:'flex',
    flexDirection: 'row',
    alignItems:'flex-end',
    justifyContent:'center', //space out the + & within the line
    paddingRight:15
  },
  horizontalLine: {
      display:'flex',
      marginLeft:10,
      marginTop:5
  },
  verticalFractionContainer: {
      display:'flex',
      flexDirection:'column',
      alignItems:'flex-end',
      justifyContent:'flex-end',
      width:50
  },
  verticalFractionContainerSH: {
    display:'flex',
    flexDirection:'column',
    alignItems:'flex-end',
    justifyContent:'center', 
    width:150, 
    fontSize:40,
  },
  vertAC:{
    marginTop: -30
  },
  hzFractCont: {
    display:'flex',
    flexDirection: 'row',
    justifyContent: 'left',
    alignItems: 'center',
    width:'100%',
    minWidth:200

  },
  hzFractContSH: {
    display:'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  hzFractContTC: {
    display:'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width:'100%'
  },
  fraction: { //horizontal fractions
      paddingRight: '0',
      width:'auto',
      minWidth:'30',
      maxWidth:'30'
  },
  fractionTC: {
    display:'flex',
    flexDirection:'row',
    justifyContent:'center',
    width:'auto',
    minWidth:'40',
    maxWidth:'40'
  },
  fractionSH: {
      display:'flex',
      flexDirection:'row',
      justifyContent:'flex-start',
      minWidth:'80',
      maxWidth:'80',
      width:'auto'
  },

  integer: {
    display:'flex',
    flexDirection:'row',
    justifyContent:'center',
    minWidth:'15',
    maxWidth:'15',
    width:'auto'
  },
  integerTC: {
    display:'flex',
    flexDirection:'row',
    justifyContent:'center',
    minWidth:'30',
    maxWidth:'30',
    width:'auto'
  },
  integerSH: {
    display:'flex',
    flexDirection:'row',
    justifyContent:'center',
    minWidth:'80',
    maxWidth:'80',
    width:'auto'

  },

  mixedFract: {
    display:'flex',
    flexDirection:'row',
    justifyContent:'flex-start',
    minWidth:'35',
    maxWidth:'35',
    width:'auto'
  },
  mixedFractTC: {
    display:'flex',
    flexDirection:'row',
    justifyContent:'flex-start',
    minWidth:'55',
    maxWidth:'55',
    width:'auto'
  },
  mixedFractSH: {
    display:'flex',
    flexDirection:'row',
    justifyContent:'flex-start',
    minWidth:'120',
    maxWidth:'120',
    width:'auto'
  },

  vertOp:{
      display:'flex',
      flexDirection:'row',
      justifyContent:'flex-start',
      paddingBottom:5,
      paddingRight:10
  },
  horizonOp:{ //horizontal fraction op
    textAlign:'center',
    margin:'5 5 0 5',
  },
  opSH:{
    display:'flex',
    flexDirection:'row',
    justifyContent:'flex-start',
    width:90,
    paddingBottom:30,
    paddingRight:10
  },
  horizonOpSH: {
    textAlign:'center',
    marginTop:10
  },
  mixedContainerAK: {
    display:'flex',
    flexDirection:'row',
    alignItems:'center',
    justifyContent:'center',
  },
  mixedContainer: { //controls the whole number / fraction inside mixed numbers
    display:'flex',
    flexDirection:'row',
    alignItems:'center',
    justifyContent:'center',
    minWidth: '35',
  },
  mixedContainerTC: {
    display:'flex',
    flexDirection:'row',
    alignItems:'center',
    justifyContent:'flex-end',
    minWidth: '45',
  },
  mixedContainerSH: {
    display:'flex',
    flexDirection:'row',
    alignItems:'center',
    justifyContent:'flex-end',
    minWidth: '120',

  },

  shMixFraction: {
    minWidth: 70,
  },
  shMixFractionA: {
    minWidth:110,
    marginLeft:35,

  },
  whole:{ //whole that is inside mixed number

  },
  wholeSHA: {
    fontSize:100,
    width:'auto'
  }
}
);

export const verticalFractions = (question, gs, ac) => {
  const {op, number1, number2} = question.prob

    let [problemLine, fractProbContainer, opStyle, vfContainer, vertAC, tcProbLine] = 
      ['problemLine', 'fractProbContainer', 'vertOp', 'verticalFractionContainer', '', '']

    if (gs.activity === 'Scavenger Hunt'){
        problemLine = 'problemLineSH'
        fractProbContainer = 'fractProbContainerSH'
        vfContainer = 'verticalFractionContainerSH' // new
        opStyle = 'opSH'
    } else if (gs.questionStyle === 'Task Cards'){
      fractProbContainer = 'fractProbContainerTC'
      tcProbLine = 'problemLineTC'
      if(!ac){ //bring problem up to make it more centered
        vertAC = 'vertAC'
      }
    }
  return (
      <View style={[styles[fractProbContainer], styles[vertAC]]}>
          <View  style={[styles[problemLine], styles[tcProbLine]]}>
              <Text style={styles[opStyle]}>
                  {op}
              </Text>
              <View style= {styles[vfContainer]}>
              {createProbFraction(number1, gs)[0] }
              {createProbFraction(number2, gs)[0] }
              </View>
          </View>
      </View>
  )
}
export const horizontalFractions = (question, gs, userSelection, dontFix) => {
  const tc = gs.questionStyle === 'Task Cards'
  const sh = gs.activity === 'Scavenger Hunt'
  const {op, symbol, number1, number2} = question.prob
    let [fractionStyle, fractionStyle2, hzFractCont, opStyle, integerStyle, integerStyle2] = ['fraction', 'fraction','hzFractCont','horizonOp', '', '']
    if (sh){
      fractionStyle= 'fractionSH';
      fractionStyle2= 'fractionSH';
      hzFractCont = 'hzFractContSH'
      opStyle = 'horizonOpSH';
    } else if (tc){
      fractionStyle= 'fractionTC';
      fractionStyle2= 'fractionTC';
      hzFractCont = 'hzFractContTC'
    }
    if (Number.isInteger(number1) || (!number1.num && !number1.denom)){
      integerStyle = sh ? 'integerSH' : tc ? 'integerTC' : 'integer'
    } else if (number1.whole){
      fractionStyle= sh ? 'mixedFractSH' : tc ? 'mixedFractTC' : 'mixedFract'
    }
    if (Number.isInteger(number2)|| (!number2.num && !number2.denom)){
      integerStyle2 = sh ? 'integerSH' : tc ? 'integerTC' : 'integer'
    } else if (number2.whole){
      fractionStyle2 = sh ? 'mixedFractSH' : tc ? 'mixedFractTC' :'mixedFract'
    }
    
  let answerSpace = question.concept!== 'Comparing Fractions' && !sh ? <Text style={styles.horizontalLine}>= ____ </Text>:null
  return (
          <View style= {styles[hzFractCont]}>
            <View style= {[styles[fractionStyle], styles[integerStyle]]}>
            {/* <View style= {styles[fractionStyle]}> */}
              {createProbFraction(number1, gs, false, dontFix)[0]}
            </View>
            <Text style={styles[opStyle]}> {op || symbol} </Text>
            <View style= {[styles[fractionStyle2], styles[integerStyle2]]}>
              {createProbFraction(number2, gs, false, dontFix)[0] }
            </View>
              {answerSpace}
          </View>
  )
}
export const MixedNumber = (wholeNum, fract1, activity, questionStyle, ak) => {
  let SH = activity === 'Scavenger Hunt'
  let TC = questionStyle === 'Task Cards'
  let fractionStyle = SH && ak==='shA' ? 'shMixFractionA': SH? 'shMixFraction':'fract1'
  let mixedContainer = ak ? 'mixedContainerAK' : SH  ? 'mixedContainerSH' : TC ? 'mixedContainerTC': 'mixedContainer' // ak ? 'mixedContainerSHAK'

  let wholeStyle = SH && !ak ? 'whole' : null
  return(
    <View style={styles[mixedContainer]}>
      <Text style = {styles[wholeStyle]}> 
        {wholeNum}
      </Text>
      <View style= {styles[fractionStyle]}>
        {fract1}
      </View>
    </View>
  )
}

  //style for the actual fraction example: 1/2
export const cell_style_fraction = (row_index, col_index, data, direction, activity, questionStyle) => {
  let borderTopWidth = (row_index === 0) ? 0 : 1
    //data = [[num], [denom]] //check if denom is 3 digit number, make bigger
  let width = String(data[1][0]).length < 3 ? '25' : '35'

  let marginBottom
  if (questionStyle === 'Task Cards'){
    width = String(data[1][0]).length < 3 ? '25' : '45'
  }
  if (activity === 'Scavenger Hunt') { //sh problem fraction
    borderTopWidth = (row_index === 0 ) ? 0 : 4
    width = String(data[1][0]).length < 3 ? '55' : '100'
    marginBottom = row_index === 0 ? 5 : 0
  }

  return {
      width, borderWidth:0, 
      borderTopWidth, 
      marginLeft:5,
      textAlign:'center',
      borderStyle: "solid",
      marginBottom
  }
}

//sh answer key fraction (Very small)
export const ak_cell_style = (row_index, col_index, data, direction, activity) => {
  let borderTopWidth = (row_index === 0) ? 0 : 1
  let width = '25'
  return {
      width,
      borderWidth:0, 
      borderTopWidth, 
      marginLeft:5,
      textAlign:'center',
      borderStyle: "solid",
  }
}

//sh answers at the top (larger)
export const shA_cell_style = (row_index, col_index, data, direction, activity) => {
  let borderTopWidth = (row_index === 0) ? 0 : 6
  let width = '95'
  let fontSize = 50
  return {
      width, fontSize,
      borderWidth:0, 
      borderTopWidth, 
      marginLeft:5,
      textAlign:'center',
      borderStyle: "solid",
  }
}


export const divMultFractNumbers = (userSelection) => {
  const {numbers} = userSelection.specify
  let numArray= []
  let num = selectRand([1,1,1,1,1,1,1,1,2,2,2,2,2,2,2,3,3,3,3,3,3,3,4,4,4,4,4,5,5,5,5,6,6,6,7,7,7,8]) // all numerators shouldn't have an equal chance
  let num2 = selectRand([1,1,1,1,1,1,1,1,2,2,2,2,2,2,2,3,3,3,3,3,3,3,4,4,4,4,4,5,5,5,5,6,6,6,7,7,7,8])
  let denom = randInt(num+1,9)
  let denom2 =  randInt(num2+1,9)
  let number1 = simplify(num, denom)
  let number2 = simplify(num2, denom2)

  if (numbers['Fractions']) {
    numArray.push({number1, number2})
  } if (numbers['Fractions with Whole Numbers']){
    let newNumber1 = randInt(2,9)
    numArray.push({number1: newNumber1, number2})
  } if (numbers['Unit Fractions with Whole Numbers']) {
    let newNumber1 = randInt(2,9)
    let newDenom2 = randInt(2,9) //since it will be a numerator of 1 - make denom2 random & does not need to be simplified
    number2 = {num:1, denom:newDenom2}
    numArray.push({number1: newNumber1, number2})
  } if (numbers['Mixed Numbers']){
    let newNumber1 = {whole: randInt(1,9), ...number1}
    let newNumber2 = {whole: randInt(1,9), ...number2}
    numArray.push({number1: newNumber1, number2: newNumber2})
  } if (numbers['Mixed Numbers'] && numbers['Fractions']){
    let newNumber2 = {whole:randInt(1,9),...number2}
    numArray.push({number1, number2: newNumber2})
  } if (numbers['Mixed Numbers with Whole Numbers']){
    let newNumber1 = randInt(2,10)
    let newNumber2 = {whole:randInt(1,9),...number2}
    numArray.push({number1: newNumber1, number2: newNumber2})
  }
  let fractNumbers = selectRand(numArray)
  // let fractNumbers = {number1:7, number2: {num:1, denom: 9}}
  return fractNumbers
}
export const createProbFraction = (number, generalSelection) => {
  const {num, denom, whole} = number
  const {activity, questionStyle} = generalSelection
  const SH = activity === 'Scavenger Hunt'
  var styledNumber
  let wholeStyle = 'wholeStyle'
  const tstyle = SH ? tstylesSH : tstyles
  const styleWhole = (whole) => {
    // return <View style = {[tstyles.table, styles[wholeStyle]]}><Text>{whole}</Text></View>
    return <Text>{whole}</Text>

  }
  if (Number.isInteger(number)){
    styledNumber = styleWhole(number)
  } else if (!number.num && !number.denom) {
    styledNumber = styleWhole(number.whole)
    number=number.whole
  } else if (whole && whole!==0){
    
    let styledFract = Table([[num],[denom]], cell_style_fraction, tstyle, '', '', activity, questionStyle)
    styledNumber = MixedNumber(whole, styledFract, activity, questionStyle)
    number = math.fraction(`${denom*whole+num}/${denom}`)
  } else {
    styledNumber = Table([[num],[denom]], cell_style_fraction, tstyle, '', '', activity, questionStyle)
    number = math.fraction(`${num}/${denom}`)

  }
  return [styledNumber, number]
}

export const createFraction = (number, generalSelection, ak, dontFix) => {
  const {activity, questionStyle} = generalSelection
  var styledNumber
  let cellStyle = ak==='shA' ? shA_cell_style : ak ? ak_cell_style : cell_style_fraction
  let tStyle = ak ? akTStyles : number.letter ?  acStyles : activity === 'Scavenger Hunt' ? tstylesSH : tstyles
  let wholeStyle = ak==='shA' ? 'wholeSHA' : 'whole'
  const styleWhole = (whole) => {
    // return <View style = {[tStyle.table, styles[wholeStyle]]}><Text>{whole}</Text></View>
    return <View style = {[styles[wholeStyle]]}><Text>{whole}</Text></View>

  }
  if (dontFix){
    styledNumber = Table([[number.num],[number.denom]], cellStyle, tStyle, '', '', activity, questionStyle)

  } else if (!number.num && !number.denom){
    if (Number.isInteger(number)){
      styledNumber = styleWhole(number)
    } else {
      styledNumber = styleWhole(number.whole)
      number=number.whole
    }

  }else {
    let {num, denom, whole} = fixImproper(number.num, number.denom, number.whole)
    if (num === 0 ){ // whole numbers -> 4 0/1
      styledNumber = styleWhole(whole)
      number = whole
    } 
    else if (denom === 0){
      styledNumber = Table([[num],[denom]], cellStyle, tStyle, '', '', activity, questionStyle)
      number = whole
      if (whole !== 0){
      styledNumber = MixedNumber(whole, styledNumber, activity, questionStyle, ak)
      }
    }
     else if ((whole === 0 || !whole) && num < denom){ //Regular Fractions (or denominator is 0 should be a false answer)
      styledNumber = Table([[num],[denom]], cellStyle, tStyle, '', '', activity)
      number = math.fraction(`${num}/${denom}`)

    } else if (whole === 0 && denom === 1) { //whole numbers => 6/1 
      styledNumber = styleWhole(num)
      number = num
    } else { // Mixed Numbers
      let styledFract = Table([[num],[denom]], cellStyle, tStyle, '', '', activity, questionStyle)
      styledNumber = MixedNumber(whole, styledFract, activity, questionStyle, ak)
      number = math.fraction(`${denom * whole + num}/${denom}`)
    }
  }
  return [styledNumber, number]
}