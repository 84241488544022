import { answerChoicesKey, randInt, selectRand, shuffleArray } from "../general.js";
import { horizontalFractions, verticalFractions, createFraction} from "./fract-shared";
import { Text } from "@react-pdf/renderer";
import { simplify } from "./simplify.js";
import { LCM } from "./gcf-lcm.js";
const { create, all } = require("mathjs");
const config = { number: "Fraction" };
const math = create(all, config);

//returns 2 fraction numbers in the form of {number1:{num: __, denom:___, whole: ___}, number2: {num: __, denom:___, whole:___}}
export const fractionNumbers = (userSelection) => {
  const { concept } = userSelection;
  const { denominator, maxDenominator, numbers } = userSelection.specify;
  let num, denom, denom2, whole, whole2;
  let numArray = [];

  //ensures the right fraction comes first during subtraction problems.
  const fixSubtraction = (fractions) => {
    let { number1, number2 } = fractions;
    let { num, denom, whole } = number1;
    let { num: num2, denom: denom2, whole: whole2 } = number2;
    let f1 = math.fraction(`${num}/${denom}`);
    let f2 = math.fraction(`${num2}/${denom2}`);

    // switch numbers if f1 < f2 &  we have basic fractions or no regrouping
    if ((numbers && numbers["Mixed Numbers"] && !numbers["Mixed Numbers With Regrouping"]) ||
      (numbers["Fractions"] && !whole & !whole2)) {

      if (math.compare(math.number(f1), math.number(f2)) === -1) {
        [num, num2, denom, denom2] = [num2, num, denom2, denom];
      }
      // switch numbers if f1 > f2 & we MUST be regrouping  (Else it will be random which one is larger)
    } else if (numbers && !numbers["Mixed Numbers"] && numbers["Mixed Numbers With Regrouping"]) {
      if (math.compare(math.number(f1), math.number(f2)) === 1) {
        [num, num2, denom, denom2] = [num2, num, denom2, denom];
      }
    }
    // ensure that the whole numbers are not equal when regrouping or else they will be negative. Can change in the future to include ability for negative answers.
    if (numbers && numbers["Mixed Numbers With Regrouping"] && whole === whole2) {
      whole += randInt(1, 3);
    }
    number1 = { num, denom, whole };
    number2 = { num: num2, denom: denom2, whole: whole2 };
    return { number1, number2 };
  };
  // to determine the whole numbers &  denominator/numerator for number2
  const createNumbers = (num, denom, denom2) => {
    let [equalWhenSimplified, originalNumber2, CD, comparing] = [false, {}, "", concept === 'Comparing Fractions']

    // STEP 1 - Create basic numbers for number2
    if (denominator["Common"] && !denominator["Uncommon"]) {
      denom2 = denom;
    } else if (denominator["Common"]) {
      CD = randInt(0, 1);
      denom2 = CD === 1 ? denom : denom2;
    }
    if (!comparing && !numbers['Fractions'] &&
      (numbers["Mixed Numbers"] || numbers["Mixed Numbers With Regrouping"])) {
      whole = randInt(1, 9);
      whole2 = whole > 1 ? whole - randInt(0, whole - 1) : whole; //ensure whole is larger
    } else if (!comparing &&
      (numbers["Mixed Numbers"] || numbers["Mixed Numbers With Regrouping"])) {
      whole = randInt(0, 1) === 0 ? randInt(1, 9) : null;
      whole2 = randInt(0, 1) === 0 ? randInt(1, 9) : null;
      let greater = whole >= whole2 ? whole : whole2; //ensure whole is larger
      let less = whole < whole2 ? whole : whole2;
      whole = greater;
      whole2 = less;
    }
    let num2 = randInt(1, denom2 - 1);

    // STEP 2 - Check if numerators and denominators are both equal 2/5 + 2/5,
    //// if concept is comparing give it a smaller chance to be equal
    if (num2 === num && denom === denom2) {
      let newNumerator = comparing && randInt(1, 3) === 3 ? false : true;
      if (newNumerator) {
        num2 = num2 > 2 ? num2 - 1 : num2 + 1;
      }
    }

    // STEP 3 - Set the numbers up with the values created above
    let number1 = { num, denom, whole };
    let number2 = { num: num2, denom: denom2, whole: whole2 };

    // STEP 4 - if concept is comparing fractions with uncommon denom allow two numbers to have the chance to be the same, 
    // then only simplify one number in next step (Some may be EXACTLY the same - which we will attempt to fix in STEP)
    if (comparing && denominator['Uncommon'] && randInt(0, 3) === 0 && maxDenominator !== 5) {
      originalNumber2 = number2 // save number2 in case it's needed in STEP 6
      number2 = number1
      equalWhenSimplified = true
    }

    // STEP 5 - simplify fractions as long as they allowed uncommon denominators since that could make them unequal
    // Only simplify first number if supposed to be equalWhenSimplified
    if (denominator["Uncommon"] && (denom !== denom2 || equalWhenSimplified)) {
      number1 = { ...simplify(num, denom), whole };
      number2 = !equalWhenSimplified ? { ...simplify(num2, denom2), whole: whole2 } : number2;
    }

    // STEP 6 - After simplifying ensure that denominators are still different if supposed to be uncommon
    if (((denominator["Uncommon"] && !denominator["Common"]) || CD === 0)) {
      if (number1.denom === number2.denom && !equalWhenSimplified) {
        number2.denom = number2.denom + 1;
      }

      // if entire fraction is equal (2/5 compared with 2/5) even after simplifying one of them in STEP 5 & we should have uncommon denominators
      // attempt to make number2 an equal fraction (2/5 comp with 4/10) within the maxDenominator specified.
      if (equalWhenSimplified && number1.denom === number2.denom) {
        if (number2.denom*3 <= maxDenominator) {
          number2.num = number2.num*3
          number2.denom = number2.denom*3
        } else if (number2.denom*2 <= maxDenominator) {
          number2.num = number2.num*2
          number2.denom = number2.denom*2
        } else {
          number2 = originalNumber2 // if we are unable to make equal fractions with unequal denominators -> go back to the original unequal numbers
        }
      }
    }

    numArray.push({ number1, number2 });
  };
  // Create the numerator and denominator for number1, using createNumbers to make the rest.
  if (Number(maxDenominator) > 4) {
    num = randInt(1, 4);
    denom = randInt(num + 1, 5);
    denom2 = denom > 3 ? denom - randInt(1, 2) : denom + randInt(1, 2);
    createNumbers(num, denom, denom2);
  }
  if (Number(maxDenominator) > 9) {
    num = randInt(1, 9);
    denom = num > 5 ? randInt(num + 1, 10) : randInt(num + 5, 10);
    denom2 = denom > 5 ? denom - randInt(1, 4) : denom + randInt(1, 4);
    createNumbers(num, denom, denom2);
  }
  if (Number(maxDenominator) > 14) {
    num = randInt(1, 14);
    denom = num > 10 ? randInt(num + 1, 15) : num > 5 ? randInt(num + 6, 15) : randInt(num + 10, 15);
    denom2 = denom > 10 ? denom - randInt(1, 9)
        : denom > 5 ? denom + randInt(1, 5)
        : denom + randInt(5, 9);
    createNumbers(num, denom, denom2);
  }

  let fractNumbers = selectRand(numArray);
  fractNumbers = concept === "Subtracting Fractions" ? fixSubtraction(fractNumbers) : fractNumbers;
  // fractNumbers = {number1:{num: 1, denom:2}, number2: {num: 2, denom:2}} /// TESTING
  return fractNumbers;
};


const styleFractProb = (fract1, fract2, op, us, gs) => {
  if (us.specify.probStyle === "Set Up") {
    var prob1 = verticalFractions(fract1, fract2, <Text>{op}</Text>, gs.activity, us);
  } else {
    prob1 = horizontalFractions(fract1, fract2, <Text>{op}</Text>, gs.activity, us);
  }
  return prob1;
};
const getFractAnswers = (fract1, fract2, op, gs) => {
  var answerFract = op === "+" ? math.format(math.add(fract1, fract2)) : 
    math.format(math.subtract(fract1, fract2));
  var extraWrong = op === "+" ? math.format(math.abs(math.subtract(fract1, fract2))) :
    math.format(math.add(fract1, fract2));
  var [splitAnswer, splitWrong] = [answerFract.split("/"), extraWrong.split("/")]
  var answer = { num: Number(splitAnswer[0]), denom: Number(splitAnswer[1]), answer: true };
  var shAnswerKey = { num: splitAnswer[0], denom: splitAnswer[1] };
  const LCD = LCM(fract1.d, fract2.d)

  let wrong = [];
  let wrongCheck = ({num, denom}) => {
    if (Number.isNaN(num) || Number.isNaN(denom)){
      return
    }
    if (denom !==0){
      let simpFract = simplify(num, denom)
      num = simpFract.num
      denom = simpFract.denom
    }
    let duplicate = false
    for (let i of wrong){
      if ((i.num === num && i.denom === denom) || (answer.num === num && answer.denom === denom)){
        duplicate = true
      }
    }
    if(!duplicate){
        wrong.push({num, denom})
    }
  }

  wrongCheck({num: answer.num + 1, denom: answer.denom})
  wrongCheck({num: splitWrong[0], denom: splitWrong[1] })
  if (op === "+") {
    if (answer.num < answer.denom){
      wrongCheck({num: fract1.n*fract2.n+1, denom:fract1.d + fract2.d})
      wrongCheck({num: fract1.n+fract2.n+1, denom:fract1.d+randInt(1,3)})
    }

    if(answer.denom !== LCD){
      wrongCheck({num: answer.num, denom: LCD})
    }
    
    if(fract1.d !== fract2.d){ //add straight across (unequal denom)
      wrongCheck({ num: fract1.n + fract2.n, denom: fract1.d + fract2.d }) 
    }
    if(answer.denom !== 1){
      wrongCheck({num: answer.num, denom: answer.denom + answer.denom})
      wrongCheck({ num: answer.num, denom: answer.denom + 1 })
    }
  } else {
    wrongCheck({ num: Math.abs(fract1.n - fract2.n), denom: Math.abs(fract1.d - fract2.d) }) //subtract straight across
    wrongCheck({ num: answer.num, denom: Math.abs(answer.denom - answer.denom) }) // got common denominator but still sub denom
  }
  if (wrong.length < 3){ //these are not great wrong choices but just to ensure that if they select more simple fractions there's definitely enough wrong options.
    wrongCheck({ num: answer.num+2, denom: answer.denom+1})
    wrongCheck({ num: answer.num+1, denom: answer.denom+2})
    wrongCheck({ num: answer.num>3 ? answer.num-1 : answer.num+3, denom: answer.denom+1})
    wrongCheck({ num: answer.num+2, denom: answer.denom+2})
    wrongCheck({ num: answer.num+1, denom: answer.denom+5})
    wrongCheck({ num: answer.num+4, denom: answer.denom+1})
    wrongCheck({ num: answer.num+5, denom: answer.denom+5})

  }
  return { answer, shAnswerKey, wrong: shuffleArray(wrong) };
};


export const addFract = (userSelection, gs) => {
  var { number1, number2 } = fractionNumbers(userSelection);
  const [styledFract1, fract1] = createFraction(number1, gs);
  const [styledFract2, fract2] = createFraction(number2, gs);
  let prob1 = { number1, number2, op: "+" };
  let { answer, shAnswerKey, wrong } = getFractAnswers(fract1, fract2, "+", gs);

  var AC = answerChoicesKey(answer, wrong[0], wrong[1], wrong[2]);
  var problem = {
    prob: prob1,
    answerChoices: AC,
    correctAnswer: answer,
    shAnswerKey,
    probStyle: userSelection.specify.probStyle,
  };
  return problem;
};
export const subFract = (userSelection, gs) => {
  var { number1, number2 } = fractionNumbers(userSelection);
  const [styledFract1, fract1] = createFraction(number1, gs);
  const [styledFract2, fract2] = createFraction(number2, gs);
  let prob1 = { number1, number2, op: "-" };

  let { answer, shAnswerKey, wrong } = getFractAnswers(fract1, fract2, "-", gs);
  var AC = answerChoicesKey(answer, wrong[0], wrong[1], wrong[2]);

  var problem = {
    prob: prob1,
    answerChoices: AC,
    correctAnswer: answer,
    shAnswerKey,
    probStyle: userSelection.specify.probStyle,
  };
  return problem;
};

export const randAddFract = (userSelection, gs) => {
  var probArray = [addFract];

  var randProb = selectRand(probArray);
  return randProb(userSelection, gs);
};

export const randSubFract = (userSelection, gs) => {
  var probArray = [subFract];
  var randProb = selectRand(probArray);
  return randProb(userSelection, gs);
};
