import { Link, useHistory } from "react-router-dom";
import { React, useContext, useEffect, useState } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import BackArrow from '../../app-files/images/back-arrow.svg'
import uuid from 'react-uuid'
import { WSContext } from "../../app/context/ws-context";
import { createDirections } from "../premade-worksheets/PremadeSelections";

const FinalSelections = (props) => {
  const ws = useContext(WSContext);
  const {activity, questionStyle} = ws.generalSelection
  const [verifySelect, setVerifySelect] = useState(false)
  const history = useHistory();
  const handleStartPDF = props.handleStartPDF
  
  useEffect(()=>{
    window.scrollTo(0, 0)

    // could be better to re-set what is stored in localstorage instead
    if (!ws.userSelection?.length) {
      history.push(`/concept-selection`);
    }
  }, [])

  const handleGenSelectInput = (e) => {
    const { name, value, checked, type } = e.target;
    let tempGS = {...ws.generalSelection}

    if (name === "columns" || name === "mixed" || name==="savePaper") {
      tempGS[name] = checked
    } else{
      tempGS[name] = value
    }
    ws.setGeneralSelection(tempGS)
    localStorage.setItem('gs', JSON.stringify({...tempGS}))
  };
  const headerInput = (e) => {
    const { name, value, checked } = e.target;
    let tempGS = {...ws.generalSelection}
    tempGS.header[name] = checked

    ws.setGeneralSelection(tempGS)
    localStorage.setItem('gs', JSON.stringify({...tempGS}))
  }
  const getTitle = () => {
    let title
    const us = ws.userSelection
    const usLen = us.length
    if (usLen === 1 || (usLen === 2 && us[0].concept === us[1].concept)){
      title = us[0].concept
    } else if (usLen === 2){
      title = us[0].concept + ' & ' + us[1].concept 
    } else {
      title = "Mixed Concepts"
    }
    return title
  }
  const getDirections = () => {
    const us = ws.userSelection

    // only auto create Directions if there is only one concept on worksheet
    let directions = us[0] && us.length === 1 ? createDirections(us[0].concept, us[0].specify) : ''

    return directions
  }
  const verifySelections = () => {
    const us = [...ws.userSelection]
    const tempGS = {...ws.generalSelection}

    const {quantity, maxQuantity} = ws.generalSelection
    let tempUS = [...us]
    if (quantity > maxQuantity){
      tempGS.quantity = maxQuantity
      let difference = quantity - maxQuantity
      let newDiff = difference
        for(let u = us.length-1; newDiff > 0; u--){
          if(us[u].specify.quantity >= newDiff){
            tempUS[u].specify.quantity -= newDiff
            newDiff = 0
            break;
          } else {
            tempUS.splice(tempUS.length-1, 1)
            newDiff -= us[u].specify.quantity
          }
        }
        ws.setUserSelection(tempUS)
        ws.setGeneralSelection(tempGS)
      localStorage.setItem('gs', JSON.stringify({...tempGS}))
      localStorage.setItem('us', JSON.stringify([...tempUS]))

    }
    if (!tempGS.title){
      tempGS.title = getTitle()
      ws.setGeneralSelection(tempGS)
      localStorage.setItem('gs', JSON.stringify({...tempGS}))
    }

    if (us.length === 1 && (activity === 'Worksheet' || questionStyle === 'Worksheet')){
      tempGS.directions = getDirections()
      ws.setGeneralSelection(tempGS)
      localStorage.setItem('gs', JSON.stringify({...tempGS}))
    }

    setVerifySelect(true)
  }
  useEffect(()=>{
    if (verifySelect){
      handleStartPDF('new')
      history.push(`/display-assignment`);
    }
  }, [verifySelect, history, handleStartPDF])
  const onBlur = () => {
    if (!ws.generalSelection.title){
      let tempGS = {...ws.generalSelection}
      tempGS.title = getTitle()
      ws.setGeneralSelection(tempGS)
      localStorage.setItem('gs', JSON.stringify({...tempGS}))
    }
  }
  let hasTables = ws.userSelection.findIndex(selection => selection.concept === 'Input Output Tables') > -1
  let columnCheckbox = questionStyle === 'Worksheet' &&
      <FormControlLabel control={<Checkbox color="primary" name="columns"
            value={ws.generalSelection.columns}
            checked={ws.generalSelection.columns}
            onChange={handleGenSelectInput}
            />}
        label={<span className="checkbox">Organize problems into columns</span>}/>
  let savePaperCheckbox = questionStyle === 'Worksheet' &&
      <FormControlLabel control={<Checkbox color="primary" name="savePaper"
            value={ws.generalSelection.savePaper}
            checked={ws.generalSelection.savePaper}
            onChange={handleGenSelectInput}
            />}
        label={<span className="checkbox">Save paper (Less room to show work)</span>}/>
  let headerCheckboxes = questionStyle === 'Worksheet' && <>
    <div className="header-title">Headers</div>
      <div className="header-checkboxes">
        <FormControlLabel label={<span className="checkbox">Name:___________</span>}
          control={<Checkbox color="primary" name="name"
            value={ws.generalSelection.header.name}
            checked={ws.generalSelection?.header.name}
            onChange={headerInput}
            />}
        />
        <FormControlLabel label={<span className="checkbox">Date:_______</span>}
          control={<Checkbox color="primary" name="date"
            checked={ws.generalSelection?.header.date}
            value={ws.generalSelection.header.date} 
            onChange={headerInput}
            />}
        />
        <FormControlLabel label={<span className="checkbox">Period:_______</span>}
          control={<Checkbox color="primary" name="period" 
            checked={ws.generalSelection?.header.period}
            value={ws.generalSelection.header.period} 
            onChange={headerInput}
            />}
        />
        <FormControlLabel label={<span className="checkbox">Number:_______</span>}
          control={<Checkbox color="primary" name="number" 
            checked={ws.generalSelection?.header.number}
            value={ws.generalSelection.header.number} 
            onChange={headerInput}
            />}
        />
        <FormControlLabel label={<span className="checkbox">Score:_______</span>}
          control={<Checkbox color="primary" name="score"
            checked={ws.generalSelection?.header.score}
            value={ws.generalSelection.header.score} 
            onChange={headerInput}
          />}
        />
      </div></>
  return (
    <div className="main-container final-selections">
      <div className="title-container">
        <h1>
          <Link to="/concept-selection">
            <button className="back-btn" onClick={ws.handleClearInput} aria-label="Go Back">
              <img className="back-arrow" src={BackArrow} alt="back"></img>
            </button>
          </Link>
          General Customization
        </h1>
      </div>
      <div className="ws-title-container">
        <TextField variant="filled" id="filled-basic" placeholder={getTitle()} required className="doc-title-input"
          label={<span className="">Assignment Title</span>}
          name="title" type="text"
          value={ws.generalSelection.title}
          onChange={handleGenSelectInput}
          onBlur={onBlur}
          maxLength={75} autoFocus
          InputProps={{
            inputProps: { 
              type:'text',
              maxLength:75
            }
          }}
        />
      </div>
      {(activity === 'Worksheet' || questionStyle === 'Worksheet') && <div className="ws-title-container">
        <TextField variant="filled" id="filled-basic" className="doc-title-input"
          label={<span className="">Directions</span>}
          name="directions" type="text"
          value={ws.generalSelection.directions || getDirections()}
          onChange={handleGenSelectInput}
          onBlur={onBlur}
          maxLength={1000}
          InputProps={{
            inputProps: { 
              type:'text',
              maxLength:1000
            }
          }}
        />
      </div>}
      <div className="ws-style-container">
        <div className="details">
          <div className="general-checkbox">
            {ws.userSelection.length>1 && activity !== 'Scavenger Hunt' && 
            //only show option to mix concepts if more than one concept was selected & not for SH bc they are already mixed.
            <FormControlLabel label={<span className="checkbox"> Mix Up Concepts</span>} className="checkbox-space" 
              control={
                <Checkbox name="mixed" color="primary"
                  value={ws.generalSelection.mixed}
                  checked={ws.generalSelection.mixed}
                  onChange={handleGenSelectInput}
                />}
            />}
            {columnCheckbox}
            {savePaperCheckbox}
            {questionStyle === 'Worksheet' && ws.generalSelection.columns && hasTables && 
              <div className="tables-columns-warning">Due to spacing issues with columns, Input Output Tables will only have one problem style. For more variety either remove columns, or change activities.</div>}
            {headerCheckboxes}
          </div>
        </div>
      </div>
        <button type="submit" className="btn" onClick={verifySelections}>
          Create my assignment
        </button>
    </div>
  );
};

export default FinalSelections;