import React from 'react';
import mixConceptsEx from '../../app-files/images/sample-mixedconcepts.png'

const Spiral = (props) => {
  return(
    <div className="spiral section-container" style={{backgroundColor:'var(--main-color)'}}>

    <div className="ex-container">
      <img data-aos="fade-up-left" className="ex ex1" src={mixConceptsEx} alt="small whole numbers 3 steps"/>
    </div>
    <div className="description-container">
      {props.paragraph}
    </div>
  </div>
  )
}

export default Spiral