import { randInt, shuffleArray, wrongOptions, answerChoicesKey, selectRand, removeDup, fixNum } from "../general";

const wrongMultInt = (answer, numberS, numberL) => {
  let wrong = [answer+randInt(1,2), answer-randInt(1,2)]
  let wrongCheck = (checkAns) => {
    let duplicate = false
    for (let i of wrong){
      if (i === checkAns || checkAns === answer){
        duplicate = true
      }
    }
    if(!duplicate){
        wrong.push(checkAns)
    }
  }
  wrongCheck(answer*-1) //inverse answer
  wrongCheck(numberS*(numberL-1)) // -5 * -7 = 40
  wrongCheck((numberS+1)*numberL) // -5 * -7 = 28
  return shuffleArray(wrong)
  
}

export const multIntNumbers = (userSelection) =>{
  let numSelect = userSelection.concept === 'Multiplying Integers' ? userSelection.specify.numbers : 
                  userSelection.concept === 'Tables' ? userSelection.specify.integers : userSelection.specify.multiply
  var numArray = []
  if (numSelect['1 by 1 digit']) {
      numArray.push([randInt(-9,-1), randInt(-9,-1)])
      numArray.push([randInt(-9,-1), randInt(2, 9)])

  } if (numSelect['2 by 1 digit']) {
      numArray.push([randInt(-9, -1), randInt(10, 99)])
      numArray.push([randInt(-9, -1), randInt(-99, -10)])
      numArray.push([randInt(-9, -1), randInt(-99, -10)])
      numArray.push([randInt(2, 9), randInt(-99, -10)])

  } if (numSelect['3 by 1 digit']) {
      numArray.push([randInt(-9, -1), randInt(100, 999)])
      numArray.push([randInt(-9, -1), randInt(-999, -100)])
      numArray.push([randInt(-9, -1), randInt(-999, -100)])
      numArray.push([randInt(2, 9), randInt(-999, -100)])

  } if (numSelect['4 by 1 digit']) { 
      numArray.push([randInt(-9, -1), randInt(1000, 9999)])
      numArray.push([randInt(-9, -1), randInt(-9999, -1000)])
      numArray.push([randInt(-9, -1), randInt(-9999, -1000)])
      numArray.push([randInt(2, 9), randInt(-9999, -1000)])

  } if (numSelect["2 by 2 digit"]) { 
      numArray.push([randInt(-99,-10), randInt(-99,-10)])
      numArray.push([randInt(-99,-10), randInt(10,99)])

  } if (numSelect["3 by 2 digit"]) { 
      numArray.push([randInt(20, 99), randInt(-999, -100)])
      numArray.push([randInt(-99, -12), randInt(-999, -100)])
      numArray.push([randInt(-99, -12), randInt(-999, -100)])
      numArray.push([randInt(-99, -12), randInt(100, 999)])

  }
  var numList = selectRand(numArray)
  numList.sort((a, b) => a-b);

  const [numberS, numberL] = numList
  const answer = numberS*numberL
  const wrong = wrongMultInt(answer, numberS, numberL)
  const AC = answerChoicesKey(answer, wrong[0], wrong[1], wrong[2])
  let randNum = numberL > 9 || numberL < -9  ? randInt(10,99) : randInt(2, 9)

  return {answer, numberS, numberL, randNum, wrong, AC}
}


const multInt = (userSelection) => {
  var {answer, numberS, numberL, AC} = multIntNumbers(userSelection)

  var prob = selectRand([`${numberS} × ${numberL.toLocaleString()} = `, `${numberL.toLocaleString()} × ${numberS} = `])
  
  var problem = {prob: prob,
              answerChoices: AC,
              correctAnswer:answer,
              }
  return problem
}
export const multIntBasic = (userSelection) => { //basic product/sum/difference
  var {numberS, randNum, numberL, wrong, answer} = multIntNumbers(userSelection)
  if (userSelection.specify.level === 'One Step') {
      var prob1 = (`What is the product of ${numberS} and ${fixNum(numberL)}?`)
      var prob2 = (`What is the product of ${fixNum(numberL)} and ${numberS}?`)
      // remove these not sure they sound right with negative values??
      // var prob3 = (`A number is ${fixNum(numberL)} times as large as ${numberS}. What is the number?`)
      // var prob4 = (`A number is ${numberS} times as large as ${fixNum(numberL)}. What is the number?`)
      var prob5 = numberS > 0 ? (`How much many total are in ${numberS} groups with ${fixNum(numberL)} in each group?`) 
                : numberL > 0 ? (`How much many total are in ${fixNum(numberL)} groups with ${numberS} in each group?`) : null
      var randProb = prob5 ? selectRand([prob1, prob2, prob5]) : selectRand([prob1, prob2])

  } else if (userSelection.specify.level === 'Multi-Step') {
      prob1 = (`What is the sum of ${randNum} and the product of ${fixNum(numberL)} and ${numberS}?`)
      prob2 = (`What is the sum of ${randNum} and the product of ${numberS} and ${fixNum(numberL)}?`)
      let prob3 = (`What is the ${randNum} less than the product of ${numberS} and ${fixNum(numberL)}?`)
      let prob4 = (`What is the ${randNum} less than the product of ${fixNum(numberL)} and ${numberS}?`)
      randProb = selectRand([prob1, prob2, prob3, prob4])
      if (randProb === prob1 || randProb ===prob2){ 
        answer = numberL*numberS+randNum
      } else {
          answer = numberL*numberS-randNum
      }
      wrong = [randNum*numberS+numberL, numberL*numberS, answer-1, answer+1, answer+2, answer-2]

      //right now this doesn't do anything either we should add it to multi-step or create a 3rd "Level"
  } else {
      var numberL2 = Math.abs(numberL-randInt(2,7))
      var numberS2 = numberS+randInt(2,9)
      prob1 = (`What is the difference between the product of ${fixNum(numberL)} and ${numberS} and the product of ${numberS2} and ${numberL2}?`)
      prob2 = (`What is the sum of the product of ${fixNum(numberL)} and ${numberS} and the product of ${numberS2} and ${numberL2}?`)
      let prob3 = (`A number is ${numberS} times as much as the sum of ${randNum} and ${numberS2}and ${numberS}. What is the number?`)
      randProb = selectRand([prob1, prob2, prob3])

      if (randProb === prob1){
          answer = numberL*numberS - numberS2*numberL2
      } else if (randProb === prob2){
          answer = numberL*numberS + numberS2*numberL2
      } else {
          answer = numberS*(randNum+numberS2+numberS)
      }
      wrong = [numberL2+numberS2+numberL+numberS, numberL*numberS+numberL2+numberS2, answer+1, answer-1, answer+2, answer-2]

  }
  wrong = shuffleArray(removeDup(wrong))
  let AC = answerChoicesKey(answer, wrong[0], wrong[1], wrong[2])
  let problem = {prob: randProb,
      answerChoices: AC,
      correctAnswer: answer.toLocaleString(), probType: 'Application'
  }

  return problem     

}
export const randMultInt = (userSelection, generalSelection) => {
  var probArray = []
  if (userSelection.specify.probType['Algorithm']) {
    probArray.push(multInt)
  } 
  if (userSelection.specify.probType['Application']) {
      probArray.push(multIntBasic)
  }
  var randProb = selectRand(probArray)
  return randProb(userSelection, generalSelection)
}