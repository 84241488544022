import { Link } from "react-router-dom";
import { React, useContext, useEffect, useRef, useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { WSContext } from "../../app/context/ws-context";
import { useHttpClient } from "../../shared/hooks/http-hook";
import DownloadModal from "../../shared/components/UIElements/DownloadModal";
import CheckoutForm from "../../shared/components/FormElements/CheckoutForm";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import Button from "../../shared/components/FormElements/Button";
import { AuthContext } from "../../app/context/auth-context";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import PurchaseTokens from "./PurchaseTokensInline";

let stripePromise = null;

const DownloadContent = (props) => {
  const auth = useContext(AuthContext);
  const ws = useContext(WSContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [tokenProcessing, setTokenProcessing] = useState(0);
  const [spendTokenLoading, setSpendTokenLoading] = useState(false);
  const [showCheckout, setShowCheckout] = useState(false);
  const [clientSecret, setClientSecret] = useState("");
  const [paymentDetails, setPaymentDetails] = useState({});
  const [messageData, setMessageData] = useState({});
  const [buyTokensMode, setBuyTokensMode] = useState(false);
  const spendToken = async () => {
    setSpendTokenLoading(true);
    const wsData = {
      generalSelection: ws.generalSelection,
      userSelection: ws.userSelection,
      creator: auth.user.userId,
      worksheetCopy: ws.createdWorksheetState,
      created: new Date(),
    };
    try {
      const responseData = await sendRequest(
        "/api/users/spend-token",
        "POST",
        JSON.stringify({
          wsData: wsData,
        }),
        {
          "Content-Type": "application/json",
        }
      );
      if (responseData && responseData.user && responseData.user.userId) {
        auth.updateUser(responseData.user);
        unlockWorksheet();
      }
      setSpendTokenLoading(false);
    } catch (err) {
      setSpendTokenLoading(false);
    }
  };

  const processPurchase = async (val) => {
    setTokenProcessing(4);
    const wsData = {
      generalSelection: ws.generalSelection,
      userSelection: ws.userSelection,
      creator: auth.user.userId,
      worksheetCopy: ws.createdWorksheetState,
      created: new Date(),
    };
    stripePromise = await loadStripe(
      auth.user ? auth.user.stripeClient : null,
    );
    fetch("/api/users/create-payment-intent", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: auth.token,
      },
      body: JSON.stringify({
        userId: auth.user.userId,
        amount: 1.5,
        wsData: wsData,
        type: "worksheet",
      }),
    })
      .then((res) => {
        if(res && res.status === 401) {
          auth.logout();
        }
        return res.json()
      })
      .then((data) => {
        setClientSecret(data.clientSecret ? data.clientSecret : "");
        if (!data.clientSecret) {
          onPaySuccess();
        } else {
          setPaymentDetails(data.details);
          setShowCheckout(true);
        }
      });
  };

  const onBack = () => {
    setMessageData({});
    setTokenProcessing(0);
    setClientSecret("");
    setShowCheckout(false);
    stripePromise = null;
  };

  const unlockWorksheet = () => {
    ws.setPurchase(true);

    // wipe from localstorage, since we do not want the bought worksheet to still show up in In Progress
    localStorage.removeItem('ws')
    localStorage.removeItem('gs')
    localStorage.removeItem('us')
    props.closeModal();
  };

  const onPaySuccess = async () => {
    const checkStatus = async () => {
      const responseData = await sendRequest(
        `/api/users/check-payment-status`,
        "POST",
        "{}",
        {
          "Content-Type": "application/json",
        }
      );
      if (responseData && responseData.valid) {
        setMessageData({
          message: responseData.message,
          color: responseData.messageColor,
          valid: true,
        });
        setTokenProcessing(0);
        auth.updateUser(responseData.user);
        ws.setPurchase(true)

        // wipe from localstorage, since we do not want the bought worksheet to still show up in In Progress
        localStorage.removeItem('ws')
        localStorage.removeItem('gs')
        localStorage.removeItem('us')
        // setTimeout(() => {
        //   props.closeModal();
        // }, 2000);
      } else if (responseData && responseData.invalid) {
        setMessageData({
          message: responseData.message,
          color: responseData.messageColor,
          valid: false,
        });
        setTokenProcessing(0);
      } else {
        setTimeout(() => {
          checkStatus();
        }, 1000);
      }
    };
    checkStatus();
  };

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div
      style={{
        display: "flex",
        position: "relative",
        paddingTop: showCheckout ? 0 : "30px",
        justifyContent: "center",
      }}
    >
      {buyTokensMode ? (
        <PurchaseTokens
          backToMain={() => {
            setBuyTokensMode(false);
          }}
        />
      ) : (
        <>
          {showCheckout ? (
            <div className="Checkout">
              {clientSecret && (
                <Elements options={options} stripe={stripePromise}>
                  <CheckoutForm
                    messageData={messageData}
                    onBack={onBack}
                    onSuccess={onPaySuccess}
                    close={props.closeModal}
                    paymentDetails={paymentDetails}
                    value={
                      tokenProcessing === 1
                        ? 5
                        : tokenProcessing === 2
                        ? 10
                        : tokenProcessing === 3
                        ? 20
                        : tokenProcessing === 4
                        ? 1.5
                        : 0
                    }
                    setCloseLock={props.setCloseLock}
                  />
                </Elements>
              )}
            </div>
          ) : (
            <div
              style={{ display: "flex", position: "relative", width: "100%" }}
            >
              {auth.user && auth.user.tokens > 0 ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    position: "relative",
                    border: "2px solid #806a80",
                    width: "100%",
                    margin: "0 5px 0 10px",
                    borderRadius: "8px",
                  }}
                >
                  <div
                    style={{
                      color: "#405852",
                      fontWeight: 500,
                      fontSize: "18px",
                      textAlign: "center",
                      padding: "10px",
                      flexGrow: 1,
                    }}
                  >
                    Unlock this worksheet using{" "}
                    <span style={{ fontSize: "21px", fontWeight: "bold" }}>
                      1
                    </span>{" "}
                    token.
                  </div>
                  <div
                    style={{
                      fontStyle: "italic",
                      color: "#803F87",
                      fontWeight: "bold",
                    }}
                  >
                    {auth.user && auth.user.tokens === 1
                      ? `${auth.user.tokens} Token Available`
                      : auth.user && auth.user.tokens
                      ? `${auth.user.tokens} Tokens Available`
                      : "0 Tokens"}
                  </div>
                  <Button
                    disabled={!auth.user || !auth.user.tokens}
                    onClick={() => {
                      spendToken();
                    }}
                    style={{
                      height: 53,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {!spendTokenLoading ? (
                      "Spend Token"
                    ) : (
                      <CircularProgress
                        style={{
                          color: "#803F87",
                          width: "30px",
                          height: "30px",
                        }}
                      />
                    )}
                  </Button>
                </div>
              ) : (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      position: "relative",
                      border: "2px solid #806a80",
                      width: "100%",
                      margin: "0 5px 0 10px",
                      borderRadius: "8px",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        top: "45%",
                        right: "-30px",
                        zIndex: 100,
                      }}
                    >
                      <div
                        style={{
                          padding: "10px 10px 12px 10px",
                          marginTop: "-10px",
                          fontSize: "20px",
                          background: "#803F87",
                          borderRadius: "100%",
                          color: "#fff",
                          fontWeight: "bold",
                        }}
                      >
                        OR
                      </div>
                    </div>
                    <div
                      style={{
                        color: "#405852",
                        fontWeight: 500,
                        fontSize: "18px",
                        textAlign: "center",
                        padding: "10px",
                        flexGrow: 1,
                      }}
                    >
                      Purchase tokens to unlock activities at a discount.
                    </div>
                    <div
                      style={{
                        fontStyle: "italic",
                        color: "#803F87",
                        fontWeight: "bold",
                        position: "absolute",
                        marginTop: "-35px",
                        fontSize: "22px",
                      }}
                    >
                      Best Value
                    </div>
                    <div
                      style={{
                        fontStyle: "italic",
                        color: "#803F87",
                        fontWeight: "bold",
                      }}
                    >
                      {auth.user && auth.user.tokens === 1
                        ? `${auth.user.tokens} Token Available`
                        : auth.user && auth.user.tokens
                        ? `${auth.user.tokens} Tokens Available`
                        : "0 Tokens Available"}
                    </div>
                    <Button
                      disabled={!auth.user}
                      onClick={() => {
                        setBuyTokensMode(true);
                      }}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {"Buy Tokens"}
                    </Button>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      position: "relative",
                      border: "2px solid #806a80",
                      width: "100%",
                      margin: "0 10px 0 5px",
                      borderRadius: "8px",
                    }}
                  >
                    <div
                      style={{
                        color: "#405852",
                        fontWeight: 500,
                        fontSize: "18px",
                        textAlign: "center",
                        padding: "10px",
                        flexGrow: 1,
                      }}
                    >
                      Purchase this activity with a one-time transaction.
                    </div>
                    <Button
                      onClick={() => {
                        processPurchase();
                      }}
                    >
                      {tokenProcessing === 0 ? (
                        "$1.50"
                      ) : (
                        <CircularProgress
                          style={{
                            color: "#803F87",
                            width: "30px",
                            height: "30px",
                          }}
                        />
                      )}
                    </Button>
                  </div>
                </>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default DownloadContent;
