import React from 'react';
import { Link } from "react-router-dom";

const Cover = props => {
  return (
    <div className="header">
      <div className="header-center">
        <div className="header-wrapper">
          <h1>{props.title}</h1>
            {props.image}
          <p>{props.paragraph}</p>
          <div className="signup">
            <Link className="btn" to={props.buttonText?'/admin-plan':"/signup"}>
              {props.buttonText || 'Sign up now!'}
            </Link>
             <p className="one-free">*Download worksheets for free & get 1 free activity after signing up!</p>

          </div>
        </div>
      </div>
    </div>
  )
}

export default Cover;