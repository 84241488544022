import React from 'react'
import { Link } from 'react-router-dom'
import Modal from '../../shared/components/UIElements/Modal'
import wwLogo from "../../app-files/images/newlogo.png";
import star from "../../app-files/images/icon-star.svg";

const WelcomeModal = (props) => {
  let usIP = JSON.parse(localStorage.getItem('us'))
  let wsIP = JSON.parse(localStorage.getItem('ws'))
  return(
    <Modal 
      onCancel={props.onCancel}
      show={props.show}
      hideTopPadding={true}
      // header="Are you sure?"
      footerClass="welcome-modal-footer"
      className="welcome-modal"
      footer={
        <>{usIP && <>
        <div>
          <p className="continue">Continue your previously started activity</p>
          <Link className="btn sec" to={wsIP ? "/display-assignment" : "/concept-selection"} onClick={props.onCancel}>IN PROGRESS</Link>
        </div>
        <div class="btn-or"><p>OR</p></div>
        </>
        }
        <Link className="btn" onClick={() => {props.onCancel(); props.handleClearSelections()}} to="/activity-selection" style={{color: 'white'}} >
          CREATE {usIP || wsIP ? 'NEW' : 'FREE'} ACTIVITY</Link>
      </>
      }
      header={
        <div className="modal-header-container">
          <img src={star} alt="star" />
          Thanks for signing up!
          <img src={star} alt="star" className="right"/>
        </div>
        }
      >
      <div className="welcome-modal-content">
        <img src={wwLogo} alt="Worksheet Wonderland"/>
        <div className="msg">We added one <span>token</span> to your account, so you can create your first customized activity for <span className="free">free!</span></div>
      </div>
    </Modal>
  )
}

export default WelcomeModal
