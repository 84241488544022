import React, { useContext, useState, useEffect } from "react";
import { NavLink, Link, useLocation } from "react-router-dom";
import { AuthContext } from "../../../app/context/auth-context";
import { WSContext } from "../../../app/context/ws-context";
import IconWS from '../../../app-files/images/icon-copy.svg';
import IconFolder from '../../../app-files/images/icon-folder-open.svg';
import IconNewFile from '../../../app-files/images/icon-file-add.svg';
import { Menu, MenuItem } from "@material-ui/core";
import { initialGenValues } from "../../../app/context/ws-store";

const NavLinks = (props) => {
  const auth = useContext(AuthContext);
  const ws = useContext(WSContext);
  const [inProgress, setInProgress] = useState(false) 
  const [createdWS, setCreatedWS] = useState(false)
  const [dropdownDisplayWSMenu, setDropdownDisplayWSMenu] = useState(true); // for hover
  const [clickWSMenu, setClickWSMenu] = useState(false); // for click
  const loc = useLocation();

  useEffect(()=> {
    let usIP = JSON.parse(localStorage.getItem('us'))
    let wsIP = JSON.parse(localStorage.getItem('ws'))
    let gsIP = JSON.parse(localStorage.getItem('gs'))
    
    if (ws.purchase) {
      setInProgress(false)
    } else if (ws.userSelection.length > 0 || ws.createdWorksheetState?.questionAnswerList?.length > 0 || usIP || wsIP){
      setInProgress(true)
      if (wsIP){
        setCreatedWS(true)
      }
    } else if (ws.userSelection.length === 0){
      setInProgress(false)
      setCreatedWS(false)
    }
  }, [ws.userSelection, ws.generalSelection, ws.createdWorksheetState, ws.purchase])
  const handleOpenWSMenu = (e) => {
    e.stopPropagation();
    setDropdownDisplayWSMenu(e.currentTarget)
  }
  const handleCloseWSMenu = () => {
    setDropdownDisplayWSMenu(null)
    setClickWSMenu(false)
  }
  useEffect(()=> {
    if (!dropdownDisplayWSMenu) {
      setTimeout(()=> {
        setDropdownDisplayWSMenu(true)
      }, 100)
    }
  }, [dropdownDisplayWSMenu])
  const handleStartCreateNewWS = (type) => {
    ws.handleClearSelections()
    if (type === 'activity') {
      let tempGS = JSON.parse(JSON.stringify(initialGenValues))
      tempGS.activity = 'Task Cards'
      tempGS.questionStyle = 'Task Cards'
      ws.setGeneralSelection(tempGS)
      localStorage.setItem('gs', JSON.stringify({...tempGS}))
    }
    handleCloseWSMenu()
  }
  const toggleClickWSMenu = (e) => {
    e.stopPropagation();
    setClickWSMenu(!clickWSMenu)
    setDropdownDisplayWSMenu(!clickWSMenu)
  }
  const continueInProgressWS = () => {
    let wsIP = JSON.parse(localStorage.getItem('ws'))
    let usIP = JSON.parse(localStorage.getItem('us'))
    let gsIP = JSON.parse(localStorage.getItem('gs'))


    ws.setMainState(null, usIP, gsIP)
    ws.setCreatedWorksheetState(wsIP)
  }
  const inProgressPath = loc.pathname === '/concept-selection' || loc.pathname === '/concept-customization' || loc.pathname === '/activity-selection' || loc.pathname === '/final-selections'
  const WSInProgress = <div className={`nav-link in-progress mobile-3 ${(inProgress && inProgressPath) ? 'active' : ''}`}>
    <NavLink to={createdWS ? "/display-assignment" : "/concept-selection"} className="main-link">
      <button className="main-button in-progress-btn" onClick={continueInProgressWS}><img src={IconWS} alt="" />In Progress</button>
    </NavLink>
  </div>
  const active = (path, check = true) => loc.pathname === path && check ? 'active' : ''
  const activeNewWS = loc.pathname === '/activity-selection' && !inProgress 
  const menuOptions =  <div className={`menu-options ${dropdownDisplayWSMenu ? 'active': ''} ${clickWSMenu ? 'clicked' : ''}`}>
    <div className="menu-section-title">Premade</div>
      <NavLink className="menu-items" to="/premade-concept-selection">
        <button onClick={handleStartCreateNewWS}>
          Worksheets
        </button>
      </NavLink>
      <NavLink className="menu-items" to="/premade-activity-selection">
        <button onClick={()=>handleStartCreateNewWS('activity')}>
          Activities
        </button>
      </NavLink>
      <div className="menu-section-title">Custom</div>
      <NavLink className="menu-items" to="/concept-selection">
        <button onClick={handleStartCreateNewWS}>
          Worksheets
        </button>
      </NavLink>
      <NavLink className="menu-items" to="/activity-selection">
        <button onClick={()=>handleStartCreateNewWS('activity')}>
          Activities
        </button>
      </NavLink>
    </div>

  const newWSButton = <div className={`nav-link new-worksheet mobile-2 ${active('/activity-selection', !inProgress)}`}>
    <div className="new-ws-wrap">
      <button className="main-button new-assign-btn" onClick={toggleClickWSMenu}>
        <img src={IconNewFile} alt=""/>
        New Assignment
      </button>
      {menuOptions}
    </div>
  </div>
  return (
    <>
    {clickWSMenu && <div className="scrim" onClick={toggleClickWSMenu}></div>}
    <div className="nav-links">
      {auth.isLoggedIn && (<>
        <div className={`nav-link mobile-1 ${active('/myworksheets')}`}>
          <NavLink to="/myworksheets" className="main-link">
            <img src={IconFolder} alt=""/>My Files
          </NavLink>
        </div>
        {newWSButton}
        
        {inProgress && WSInProgress}
      </>)}


      {!auth.isLoggedIn && (<>
        <div className={`nav-link mobile-0 ${active('/parents')}`}>
          <NavLink to="/parents" className="main-link">Parents</NavLink>
        </div>
        <div className={`nav-link mobile-0 ${active('/teachers')}`}>
          <NavLink to="/teachers" className="main-link">Teachers</NavLink>
        </div>
        <div className={`nav-link mobile-1 ${active('/samples')}`}>
          <NavLink to="/samples" className="main-link">
            <button className="main-button free-samples" >Free Samples</button>
          </NavLink>
        </div>
        {newWSButton}

        {inProgress && WSInProgress}
      </>)}

    </div>
    </>

  );
};

export default NavLinks;
