import React from "react";
import UserCard from "../user/UserCard";
import wwLogoIcon from "../app-files/images/icon-only.png";
import Button from "../shared/components/FormElements/Button";
import { Link } from "react-router-dom";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
    this.activeHttpRequests = React.createRef()
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  async componentDidCatch(error, errorInfo) {
    let us = JSON.parse(localStorage.getItem('us'))
    let ws = JSON.parse(localStorage.getItem('ws'))
    let gs = JSON.parse(localStorage.getItem('gs'))

    const httpAbortCtrl = new AbortController();
    this.activeHttpRequests.current?.push(httpAbortCtrl);

    try {
      const headers = {
        'Content-Type': 'application/json'
      }
      let session
      if (localStorage && localStorage.getItem("userData")) {
        session = JSON.parse(localStorage.getItem("userData"));
        headers.Authorization = session.token;
      }
      const body = {
        generalSelection: gs, userSelection: us, worksheetCopy: ws,
        issue: 'errorBoundary',
        message: error.name+ ': '+ error.message + 'Stack: ' + error.stack,
        user: session.user?.userId, 
      }

      const response = await fetch(`/api/worksheets/support-ticket`, {
        method: "POST",
        body: JSON.stringify(body),
        headers,
        signal: httpAbortCtrl.signal,
      });

      const responseData = await response.json();
      this.activeHttpRequests.current = this.activeHttpRequests.current?.filter(
        (reqCtrl) => reqCtrl !== httpAbortCtrl
      );
    } catch (err){
    }
    
  }
  onGoHome() {
    setTimeout(()=> {
      window.location.reload();

    }, 300)
  }
  render() {
    if (this.state.hasError) {
      return (
        <UserCard title="Error">
          <div className="account-type" style={{ textAlign: "center" }}>
            <img
              src={wwLogoIcon}
              alt="Worksheet Wonderland"
              style={{ paddingTop: "20px", width: "250px" }}
            />
            <p
              className="blurb"
              style={{
                color: "purple",
                fontSize: "2.5em",
                fontWeight: "bold",
              }}
            >
              Sorry! Something went wrong.
            </p>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Link to="/">
                <Button className="btn" onClick={this.onGoHome}>
                  {"GO TO HOMEPAGE"}
                </Button>
              </Link>
            </div>
          </div>
          <div
            style={{
              padding: "15px 0 15px 0",
              display: "flex",
              justifyContent: "center",
              height: "70px",
              boxSizing: "border-box",
            }}
          ></div>
        </UserCard>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
