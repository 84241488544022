import React from 'react'
import { Link } from 'react-router-dom'
import downloadIcon from '../../app-files/images/icon-download.svg'

const RecordingSheets = () => {
  return(
    <Link className="directions-link" to="/activity-directions/WorksheetWonderland-RecordingSheets.pdf" target="_blank" download>
    <img src={downloadIcon} alt="download"/>Recording Sheets</Link>
  )
}

export default RecordingSheets