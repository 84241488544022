import React from 'react';
import Ex1 from '../../app-files/images/orderops.png';
import Ex2 from '../../app-files/images/orderops2.png';
import Ex3 from '../../app-files/images/orderops3.png';
// import Ex4 from '../../app-files/images/orderops2B.png';
import ConceptCustomizeEx from '../../app-files/images/customize.png';

const Differentiate = (props) => {

  return (
    <div className="differentiate section-container" style={{backgroundColor:'var(--main-color)'}}>
      <div className="description-container">
        {props.paragraph}
        <img className="concept-custom" src={ConceptCustomizeEx} alt="differentiate"></img>
      </div>
      <div className="ex-container">
        <img data-aos="fade-up-left" className="ex ex1" src={Ex1} alt="small whole numbers 3 steps"/>
        <img data-aos="fade-up-left" className="ex ex2" src={Ex2} alt="large whole numbers 3 steps"/>
        <img data-aos="fade-up-left" className="ex ex3" src={Ex3} alt="small whole numbers 4 steps"/>
      </div>
    </div>
  )
}

export default Differentiate