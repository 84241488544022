import { specHZ, specify } from "../helper"

export const ComparingDecimals = (props) => {
  const {createLink, ac} = props
  const [c1] = ['Comparing Decimals']
  const [com, order, hund, thou] = ['Comparing', 'Ordering', 'Hundredths', 'Thousandths']
  const title = (mod1, mod2, mod3) => {
    return `${mod1} Decimals up to the ${mod2}`
  }

  const showNoAC = ac === false || ac === 'allowed'
  const showAC = ac === true || ac === 'allowed'

  const createSpec = (spec1, spec2, spec3=false) => {
    let specC1 = [specify('placeValues', spec1), specify('probType', spec2), specify('ac', ac === true ? true : ac === false ? false : spec3)]
    return {[c1]: specC1}
  }
  return (<>
    <div className="buttonGroup">
      <div className="concept-topic">Comparing Decimals</div>
      {showNoAC && createLink([c1], title(com, hund), '', createSpec(hund, [com]), 12)}
      {showNoAC && createLink([c1], title(com, thou), '', createSpec(thou, [com]), 12)}

      {showNoAC && showAC && <><div className="sec-title">Include Answer Choices</div>
        <div className="sec"></div></>}
      {showAC && createLink([c1], title(com, hund), '', createSpec(hund, [com], true), 16)}
      {showAC && createLink([c1], title(com, thou), '', createSpec(thou, [com], true), 16)}
    </div>

    <div className="buttonGroup">
      <div className="concept-topic">Ordering Decimals</div>
      {showNoAC && createLink([c1], title(order, hund), '', createSpec(hund, [order]), 12)}
      {showNoAC && createLink([c1], title(order, thou), '', createSpec(thou, [order]), 12)}

      {showNoAC && showAC && <><div className="sec-title">Include Answer Choices</div>
        <div className="sec"></div></>}
      {showAC && createLink([c1], title(order, hund), '', createSpec(hund, [order], true), 16)}
      {showAC && createLink([c1], title(order, thou), '', createSpec(thou, [order], true), 16)}
    </div>

    <div className="buttonGroup">
      <div className="concept-topic">Comparing & Ordering Decimals</div>
      {showNoAC && createLink([c1], title(com+' & '+order, hund), '', createSpec(hund, [order, com]), 12)}
      {showNoAC && createLink([c1], title(com+' & '+order, thou), '', createSpec(thou, [order, com]), 12)}
      
      {showNoAC && showAC && <><div className="sec-title">Include Answer Choices</div>
        <div className="sec"></div></>}
      {showAC && createLink([c1], title(com+' & '+order, hund), '', createSpec(hund, [order, com], true), 16)}
      {showAC && createLink([c1], title(com+' & '+order, thou), '', createSpec(thou, [order, com], true), 16)}
    </div>

    </>
  )
}

