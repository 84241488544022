import React, { useEffect, useState, useContext } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import Button from "./Button";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import { AuthContext } from "../../../app/context/auth-context";
import BackArrow from "../../../app-files/images/back-arrow.svg";

const CheckoutForm = ({
  value,
  onBack,
  onSuccess,
  messageData,
  paymentDetails,
  close,
  setCloseLock,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const auth = useContext(AuthContext);
  const { error, sendRequest, clearError } = useHttpClient();
  const [message, setMessage] = useState(null);
  const [messageColor, setMessageColor] = useState("green");
  const [finished, setFinished] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [paymentElementLoaded, setPaymentElementLoaded] = useState(false);

  useEffect(() => {
    if (messageData && messageData.message) {
      setMessage(messageData.message);
      setMessageColor(messageData.color);
      if (messageData.valid) {
        setFinished(true);
        if (setCloseLock) {
          setCloseLock(false);
        }
      } else {
        setIsLoading(false);
        if (setCloseLock) {
          setCloseLock(false);
        }
      }
    } else {
      setMessage(null);
      setMessageColor("green");
    }
  }, [messageData]);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const finishMessage = () => {
    if (value === 5) {
      return (
        <div style={{ fontSize: "24px" }}>
          <span style={{ color: "purple", fontWeight: "bold", textAlign: "center" }}>5</span> tokens
          have been added to your account.
        </div>
      );
    }
    if (value === 10) {
      return (
        <div style={{ fontSize: "24px" }}>
          <span style={{ color: "purple", fontWeight: "bold", textAlign: "center"  }}>10</span> tokens
          have been added to your account.
        </div>
      );
    }
    if (value === 20) {
      return (
        <div style={{ fontSize: "24px" }}>
          <span style={{ color: "purple", fontWeight: "bold", textAlign: "center"  }}>25</span> tokens
          have been added to your account.
        </div>
      );
    }
    if (value === 1.5) {
      return (
        <div style={{ fontSize: "24px" }}>
          This activity has been unlocked and added to your account.
        </div>
      );
    }
    return "";
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    setMessage("");
    setMessageColor("green");
    setIsLoading(true);
    if (setCloseLock) {
      setCloseLock(true);
    }
    // try {
    //   const responseData = await sendRequest(
    //     "/api/users/add-tokens",
    //     "PUT",
    //     "{}",
    //     {
    //       "Content-Type": "application/json",
    //     }
    //   );
    //   if (responseData && responseData.user && responseData.user.userId) {
    //     auth.updateUser(responseData.user);
    //     onBack();
    //   }
    //   setIsLoading(false);
    // } catch (err) {
    //   setIsLoading(false);
    // }
    const intent = await stripe.confirmPayment({
      elements,
      redirect: "if_required",
      confirmParams: {
        return_url: "http://localhost:3000",
      },
    });
    if (
      intent &&
      intent.paymentIntent &&
      intent.paymentIntent.status === "succeeded"
    ) {
      onSuccess();
    } else if (intent && intent.paymentIntent && intent.paymentIntent.error) {
      if (
        intent.error.type === "card_error" ||
        intent.error.type === "validation_error"
      ) {
        setMessage(intent.error.message);
        setMessageColor("red");
        setIsLoading(false);
        if (setCloseLock) {
          setCloseLock(false);
        }
      } else {
        setMessage("An unexpected error occured.");
        setMessageColor("red");
        setIsLoading(false);
        if (setCloseLock) {
          setCloseLock(false);
        }
      }
    } else {
      setMessage("An unexpected error occured.");
      setMessageColor("red");
      setIsLoading(false);
      if (setCloseLock) {
        setCloseLock(false);
      }
    }

    // setIsLoading(false);
  };

  return (
    <div>
      {finished ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
        >
          <div
            style={{
              fontSize: "50px",
              color: "purple",
              fontWeight: "bold",
              paddingBottom: "20px",
              paddingTop: "20px",
              textAlign: "center"
            }}
          >
            PURCHASE COMPLETE
          </div>
          {finishMessage()}
          <div
            style={{ marginTop: "30px", color: "gray", textAlign: "center" }}
          >
            You should receive an emailed receipt shortly. <br/>
            For questions about your order, please contact us at
            <span style={{ color: "#609285", fontWeight: "bold" }}>
              {" "}
              contact@worksheetwonderland.com
            </span>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              paddingTop: "20px",
            }}
          >
            <Button
              style={{
                minWidth: "205px",
                minHeight: "53px",
                display: "flex",
                justifyContent: "center",
              }}
              onClick={() => {
                close();
              }}
            >
              <span id="button-text">OKAY</span>
            </Button>
          </div>
        </div>
      ) : (
        <>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <div>
              <button
                onClick={() => {
                  onBack();
                }}
                aria-label="Go Back"
                style={{
                  marginRight: "20px",
                  background: "#fff",
                  border: "none",
                  padding: 0,
                  cursor: "pointer",
                }}
              >
                <img
                  className="back-arrow"
                  src={BackArrow}
                  alt="back"
                  style={{ width: "40px" }}
                ></img>
              </button>
            </div>
            <form
              id="payment-form"
              onSubmit={handleSubmit}
              style={{ minHeight: "150px", minWidth: "293px" }}
            >
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    position: "relative",
                    width: "100%",
                    height: "100%",
                    minWidth: "291px",
                  }}
                >
                  <div
                    style={{
                      color: "rgb(128, 106, 128)",
                      fontWeight: 500,
                      marginBottom: "10px",
                      marginTop: "10px",
                    }}
                  >
                    Card Details
                  </div>
                  <PaymentElement
                    id="payment-element"
                    options={{
                      iconStyle: "solid",
                      hidePostalCode: true,
                      style: {
                        base: {
                          iconColor: "rgb(240, 57, 122)",
                          color: "rgb(240, 57, 122)",
                          fontSize: "16px",
                          fontFamily: '"Open Sans", sans-serif',
                          fontSmoothing: "antialiased",
                          "::placeholder": {
                            color: "#CFD7DF",
                          },
                        },
                        invalid: {
                          color: "#e5424d",
                          ":focus": {
                            color: "#303238",
                          },
                        },
                      },
                    }}
                    onReady={(e) => {
                      setPaymentElementLoaded(true);
                    }}
                  />
                  {!paymentElementLoaded ? (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        paddingTop: "50px",
                      }}
                    >
                      <CircularProgress />
                    </div>
                  ) : null}
                </div>
                <div>
                  <div
                    style={{
                      minWidth: "200px",
                      background: "#F6F5F5",
                      padding: "10px",
                      marginLeft: "20px",
                      display: "flex",
                      flexDirection: "column",
                      minHeight: "225px",
                    }}
                  >
                    <div style={{ color: "#806a80", fontWeight: 500 }}>
                      Order Summary
                    </div>
                    <div
                      style={{
                        borderTop: "1px solid lightgray",
                        width: "100%",
                        margin: "10px 0 10px 0",
                      }}
                    ></div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexGrow: 1,
                      }}
                    >
                      <div>{`${
                        value === 5
                          ? "5 Tokens"
                          : value === 10
                          ? "10 Tokens"
                          : value === 20
                          ? "25 Tokens"
                          : value === 1.5
                          ? "Activity"
                          : "0 Tokens"
                      }`}</div>
                      {/* <div>{`$${value.toFixed(2)}`}</div> */}
                      <div>{`$${(paymentDetails.subtotal / 100).toFixed(
                        2
                      )}`}</div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>{"Tax"}</div>
                      <div>{`$${(paymentDetails.tax / 100).toFixed(2)}`}</div>
                    </div>
                    <div
                      style={{
                        borderTop: "1px solid lightgray",
                        margin: "5px 0 5px 0",
                      }}
                    ></div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <div
                          style={{ alignSelf: "flex-end", fontWeight: "bold" }}
                        >
                          Order Total:
                        </div>
                        <div
                          style={{ alignSelf: "flex-end", fontWeight: "bold" }}
                        >
                          <div>{`$${(paymentDetails.total / 100).toFixed(
                            2
                          )}`}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      flexGrow: 1,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        disabled={isLoading || !stripe || !elements || finished}
                        id="submit"
                        style={{
                          minWidth: "205px",
                          height: "35px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <span id="button-text">
                          {isLoading && !finished ? (
                            <CircularProgress
                              style={{
                                marginTop: 7,
                                color: "#803F87",
                                width: "25px",
                                height: "25px",
                              }}
                            />
                          ) : (
                            'Pay Now'
                          )}
                        </span>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          {message ? (
            <div
              id="payment-message"
              style={{
                color: messageColor,
                fontWeight: "bold",
                textAlign: "center",
                paddingTop: "15px",
              }}
            >
              {message}
            </div>
          ) : null}
          {!message ? <div style={{ height: "36px" }} /> : null}
        </>
      )}
    </div>
  );
};

export default CheckoutForm;
