import {conceptsMustHaveACWithBingo, randInt, shuffleArray} from '../create-worksheet/problems/general';
import {conceptCheck} from './conceptCheck';


//Makes a list of only the numbers or object/array of numbers for each question/answer.
export const handleCreateWorksheet = (userSelection, generalSelection) => { 
  // console.log('handleCreateWorksheet - creating Question Answer List', userSelection, generalSelection)
  const {columns, activity, questionStyle, premade} = generalSelection;
//creates the worksheet using react-pdf based on userSelection (array of objects: {level:, specify:, quantity:,})  
  var [questionAnswerList, question] = [[],'']
  let repeatAnswerCount = 0
  let repeatQuestionCount = 0

  const createQuestionAnswerList = (us, randQuest) => { 
    const {specify, concept} = us
    let noRepeatCheck = userSelection.length < 2 && (
      (concept === 'Adding Whole Numbers' && (specify.numbers && specify.numbers['1 digit numbers'] && 
        (!specify.numbers['2 digit numbers'] || !specify.numbers['3 digit numbers'] || !specify.numbers['4 digit numbers']))) ||
      (concept === 'Dividing Whole Numbers' && (specify.numbers && specify.numbers['2 by 1 digit'] && 
        (!specify.numbers['3 by 1 digit'] || !specify.numbers['4 by 1 digit'] || !specify.numbers['3 by 2 digit'] || !specify.numbers['4 by 2 digit'])))
      )
    const noRepeatQuestions = (question, us) => {
      // if too many noRepeats, concept selections may not allow for enough options to prevent repeats, this will allow them
      if (repeatQuestionCount > 9){
        return question
      }
      const repeatQuest = () => {
        repeatQuestionCount++

        let newQ = randQuest(us, generalSelection)
        return noRepeatQuestions(newQ, us)
      }
      if(questionAnswerList.length>0){
        for (let quest of questionAnswerList){
          let {prob} = quest
          let newQProb = question.prob
          if (concept === quest.concept){
            ///// SIMPLIFY / MIX IMP FRACTIONS
            if(concept==='Simplifying Fractions'|| concept==='Mixed & Improper Fractions'){
              if(prob.num === newQProb.num && prob.denom === newQProb.denom && prob.whole === newQProb.whole){
                return repeatQuest()
              } 
              continue

            ///// FRACTION OPERATIONS
            } else if(concept.includes('Fractions')){
              let compare1, compare2, compare1opp, compare2opp ///opp => checks opposite number since they could still be equal  1/3 + 2/3 && 2/3 + 1/3
              if (Number.isInteger(prob.number1)){ //need to ensure that when number is whole number, they are not seen as equal due to both .num /.denom being null
                compare1 = prob.number1 === newQProb.number1
                compare1opp = prob.number1 === newQProb.number2
              } else {
                compare1 = prob.number1.num === newQProb.number1.num &&
                  prob.number1.denom === newQProb.number1.denom && prob.number1.whole === newQProb.number1.whole 
                compare1opp = prob.number1.num === newQProb.number2.num &&
                  prob.number1.denom === newQProb.number2.denom && prob.number1.whole === newQProb.number2.whole 
              }
              if (Number.isInteger(prob.number2)){
                compare2 = prob.number2 === newQProb.number2
                compare2opp = prob.number2 === newQProb.number1
              } else {
                compare2 = prob.number2.num === newQProb.number2.num &&
                  prob.number2.denom === newQProb.number2.denom && prob.number2.whole === newQProb.number2.whole
                compare2opp = prob.number2.num === newQProb.number1.num &&
                prob.number2.denom === newQProb.number1.denom && prob.number2.whole === newQProb.number1.whole
              }
              if((compare1 && compare2) || (compare2opp && compare1opp)){
                return repeatQuest()
              }
              continue

            ///// VERTICAL PROBLEMS 
            } else if (quest.probStyle === 'Set Up' && question.probStyle === 'Set Up'){
                if (typeof prob !== 'string' && typeof newQProb !== 'string') {
                  if((prob.numberS === newQProb.numberS && prob.numberL === newQProb.numberL) || 
                    (prob.numberS === newQProb.numberL && prob.numberL === newQProb.numberS)){
                      return repeatQuest()
                  }
                }
              continue

            ///////////AREA PERIMETER
            } else if (concept==='Area' || concept==='Perimeter') {
              const re = /[a-zA-Z]/ //split on measurement letters first will be the number
              let numS = prob.numberS.split(re)[0]
              let numL = prob.numberL.split(re)[0]
              let newNumS = newQProb.numberS.split(re)[0]
              let newNumL = newQProb.numberL.split(re)[0]
              if ((numS === newNumS && numL === newNumL) || (numS === newNumL && numL === newNumS)){
                return repeatQuest()
              }

            ////////////// TABLES
            } else if (concept==='Input Output Tables') {

              //could be either a string y=mx+b or a table
              if (typeof prob === typeof newQProb){
                if (typeof prob === 'string'){ //actually find the equation to determine if those are equal.
                  let probEq = prob.split('y =')[1]
                  let newQProbEq = newQProb.split('y =')[1]
                  if (probEq.length > 10 || newQProbEq.length > 10){ // a couple of questions start with equation and have longer endings.
                    probEq = probEq.length > 10 ? probEq.split('represents')[0] : probEq
                    newQProbEq = newQProbEq.length > 10 ? newQProbEq.split('represents')[0] : newQProbEq
                  }
                  if (probEq === newQProbEq){
                    return repeatQuest()
                  }
                  //tables check if each value is equal in both x and y side of table
                } else {
                  if (prob.x[0]===newQProb.x[0] && prob.x[1]===newQProb.x[1] && prob.x[2]===newQProb.x[2] && prob.x[3]===newQProb.x[3] &&
                      prob.y[0]===newQProb.y[0] && prob.y[1]===newQProb.y[1] && prob.y[2]===newQProb.y[2] && prob.y[3]===newQProb.y[3]){
                      return repeatQuest()
                }
                }
              }



            ////// PLAIN TEXT - verify they are both strings since some options allow application problems (Strings) with SET UP Algorithms (Objects)

            } else if (typeof prob === 'string' && typeof newQProb === 'string') {
              let splitProb = prob.split(' ')
              let splitNewProb = newQProb.split(' ')

              //BASIC OPERATION SPLIT TO CHECK IF OPPOSITE NUMBERS ARE EQUAL
              if (splitProb[1]==='+' || splitProb[1]==='-' || splitProb[1]==='÷' || splitProb[1]==='×'){
                let [num1, op, num2] = splitProb
                let [newNum1, newOp, newNum2] = splitNewProb
                if ((num1 === newNum1 && num2 === newNum2) || (num1 === newNum2 && num2 === newNum1)){
                  return repeatQuest()
                }
              // PLAIN TEXT NOT BASIC, only check text
              } else if(prob === newQProb){
                return repeatQuest()
              }
              continue
            } 
          } 

          //concepts aren't equal
          continue
        } 

        //If it makes it through entire loop with no repeat
        return question

        //return the first question
      } else {
        return question
      }
    }
    const noRepeatAnswers = (question) => {
      if (repeatAnswerCount > 9){
        return question
      }
      let answerInList = questionAnswerList.filter(quest=>{
        if (us.concept.includes('Fract') && quest.correctAnswer.num){
          return quest.correctAnswer.num === question.correctAnswer.num &&
                 quest.correctAnswer.denom === question.correctAnswer.denom &&
                 quest.correctAnswer.whole === question.correctAnswer.whole
        }
        return quest.correctAnswer === question.correctAnswer
      })
      if (answerInList.length>0){
        repeatAnswerCount++
        let newQ = randQuest(us, generalSelection)
        return noRepeatAnswers(newQ)
      } else {
        return question
      }
    }
    //populates the questionAnswerList with react-pdf styled combinations of question+answer choices, and a separate answer key.
    //checks for type of question to determine if styling needs be a <Text> or <View> also styles table answer choices based on columns
    for (var x = 0; x < us.specify.quantity; x++) {
      if (!us.specify.ac && conceptsMustHaveACWithBingo.includes(us.concept) && activity.includes('Bingo')){
        //make sure that if they switched activities after selecing NO AC & concepts that require AC for Bingo -> PROBABLY SHOULD TRY MOVING THIS TO WHEN THE ACTIVITY CHANGES LOOP THROUGH THE USER SELECTIONS AND CHANGE ALL TO TRUE
        us.specify.ac = true
      }
      let tempQuest = noRepeatQuestions(randQuest(us, generalSelection), us)
      question = activity === 'Scavenger Hunt' && !noRepeatCheck ? noRepeatAnswers(tempQuest) : tempQuest
      questionAnswerList.push({...question, ac:us.specify.ac, concept:us.concept})
      
    }
  }
  // adds a random question using createQuestionAnswerList function based on concept selected by the user.
  for (var i = 0; i < userSelection.length; i++) {
    createQuestionAnswerList(userSelection[i], conceptCheck(userSelection[i], generalSelection))
  }
  if (userSelection.length>0 && generalSelection.mixed){
    questionAnswerList = shuffleArray(questionAnswerList)
  }

  //ensure if they switch activities after already adding more than the maximum for that activity it will still max out.
  // questionAnswerList = questionAnswerList.slice(0, generalSelection.maxQuantity) 
  // console.log('questionAnswerList is..', questionAnswerList)
  return {questionAnswerList, warning: {repeatAnswers: !premade && repeatAnswerCount > 9, repeatQuestions: !premade && repeatQuestionCount > 9}};
};

function hslToHex(h, s, l) { //using hsl for ease of being able to customize random colors, but React-pdf does not support
  l /= 100;
  const a = s * Math.min(l, 1 - l) / 100;
  const f = n => {
    const k = (n + h / 30) % 12;
    let color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color).toString(16).padStart(2, '0');   // convert to Hex and prefix "0" if needed
  };
  return `#${f(0)}${f(8)}${f(4)}`;
}

export const getRandomColors = () => {
  let [h, s, l] = [randInt(20, 350), randInt(15, 100), randInt(30, 75)]

  if (h < 75){ //try to prevent brown 
    s = randInt(50, 100)
    l = randInt(50, 60)
  }
  let main = hslToHex(h, s, l)
  let mainLight = hslToHex(h, s, l + 15)
  let mainDark = hslToHex(h, s, l - 10)
  let secH = h > 200 ? h - randInt(100, h) : h + randInt(100, h) //ensure second color is far enough away (was 150)
  let sec = hslToHex(secH, s, l)
  let secLight = hslToHex(secH, s, l + 15) 
  let secDark = hslToHex(secH, s, l - 10)
  return {
    main, mainLight, mainDark, sec, secLight, secDark
  }

}
const getColors = () => {
  //take color input from user & convert rgb to hsl to darken and lighten??
}

