import {randInt, randDec, shuffleArray, selectRand, wrongOptions, answerChoicesKey, roundDec, decPV, fixNum} from '../general.js'
import * as g from'../general'
import {verticalDivide} from '../vertical-align'

let randEach = ['for one', 'for each', 'per',]
const divDecWrong = (answer) => {
    const ansPV = decPV(answer)
    const wrongOptions = []
    const [wrong1, wrong2] = [answer*10, answer/10]
    if (ansPV === 0 && answer > 0){ //whole numbers
        let wrong = [answer+1, answer-1, answer-2, answer+2]
        if (answer > 20){
            wrong.push(answer+10, answer-10)
        }
        wrongOptions.push(wrong)
    }
    if (answer < 1){ //0.____
        let wrongAns = [wrong1, wrong2, answer+0.1, answer+0.2]
        if (answer > 0.2){
            let wrong = [...wrongAns, answer-0.1, answer+0.2]
            wrongOptions.push(wrong)
        }
        if (ansPV > 1){
            let wrong = [...wrongAns, answer+0.01, answer+0.02]
            if (answer > 0.1){
                wrong.push( answer-0.01, answer-0.02)
            }
            wrongOptions.push(wrong)
        }
    }

    if (ansPV > 0){
        let wrong = [answer+0.1, answer+0.2, wrong2, wrong1, wrong1+0.1, wrong2+0.1]

        if (wrong2 > 0.3){
            wrong.push(wrong2-0.1)
        }
        if (answer > 0.5){
            wrong.push(answer-0.2)
        }
        if (answer > 0.3){
            wrong.push(answer-0.1, wrong1-0.1)
        }
        wrongOptions.push(wrong)
    }
    if (ansPV > 1) {
        let wrong = [answer+0.01, answer+0.02, wrong1, wrong1+0.01, wrong1+0.02]
        let secondWrong = [ wrong2+0.01, answer+0.1, answer+0.2, wrong2]
        if (answer > 0.3){
            wrong.push(answer-0.01, answer-0.02, answer-0.1)
            secondWrong.push(wrong2-0.01, answer-0.2)
        }
        wrongOptions.push(wrong, secondWrong)
    }

    const wrongChoices = shuffleArray(g.removeDup(selectRand(wrongOptions))).slice(0,3).map(num=>roundDec(num, ansPV))
    return wrongChoices
}

const makeAnswerFriendly = (numberS, numL) => {
    let pv = decPV(numL)
    let numDigits = String(g.removeDec(numL)).length
    let maxNumLArr, minNumLArr

    if (numDigits === 2) {
        maxNumLArr = [9.9, .99]
        minNumLArr = numberS < 10 ? [1.2, .12] : [3.5, .35]

    } else if (numDigits === 3) {
        maxNumLArr = [99.9, 9.99, .999]
        minNumLArr = numberS < 10 ? [10.1, 1.01, .101] : [35.1, 3.51, .351]

    } else if (numDigits === 4) {
        maxNumLArr = [999.9, 99.99, 9.999, .9999]
        minNumLArr = numberS < 10 ? [100.1, 10.01, 1.001, .1001] : [350.1, 35.01, 3.501, .3501]
    }
    // grab the min & max number the numberL can be, to then find the min and max ANSWER, get a random answer between the min & max, and then set the new numberL
    let randNum = pv-1
    let maxNumL = maxNumLArr[randNum]
    let minNumL = minNumLArr[randNum]
    let maxNum = roundDec(maxNumL/numberS, pv)
    let minNum = roundDec(minNumL/numberS, pv)
    let newAns = randDec(minNum, maxNum, pv)
    let newL = roundDec(newAns*numberS, pv)

    // for dividing by 1 digit decimals, remove the decimals to determine the number of digits that should appear after the answer, and get a new min/max
    if (numberS < 1) {
        let numSPV = decPV(numberS)
        let multiplier = numSPV === 1 ? 10 : 100
        let tempPV = numSPV <= pv ? pv-numSPV : 0
        let tempNumberS = g.removeDec(numberS)

        minNum = roundDec(minNumL*multiplier/tempNumberS, tempPV)
        maxNum = roundDec(maxNumL*multiplier/tempNumberS, tempPV)
        newAns = randDec(minNum, maxNum, tempPV)
        newL = roundDec(newAns*numberS, pv)
    }

    // check for numbers divisible by 10 since they will need an extra digit in order to maintain the right number of digits in the dividend(numberL). 
    if (numberS > 9 && numberS % 10 === 0) {
        let newAns2 = Number(String(newAns)+randInt(1, 9))
        let newL2 = roundDec(newAns2*numberS, pv)

        // if it's still not able to produce the right number of digits, go ahead and make numberS bigger and re-try.
        if (String(g.removeDec(newL2)).length !== numDigits) {
            let randAdd = randInt(1, 9)
            return makeAnswerFriendly(numberS+randAdd, numL)
        
        } else {
            newAns = newAns2
            newL = newL2
        }
    }

    // digits in dividend do not match requirements specified by user, re-try
    if (String(g.removeDec(newL)).length !== numDigits) {
        return makeAnswerFriendly(numberS, numL)
    }
    

    // for div by 1 digit whole numbers (Would need to add it in for 1 digit decimals), if possible randomly have students need to bring down 1 zero to complete division.
    // In future this might be a checkbox for users to sometimes/always/never require this.
    // for now just make it rarely happen and teachers can always just have their kids stop dividing.
    let randBringDownZero = randInt(0, 2)

    if (randBringDownZero === 0 && numberS < 10) {
        let ansPlusDigit, numLPlusDigit
        if (numberS === 4 || numberS === 8 || numberS === 2 || numberS === 6) {
            ansPlusDigit = Number(String(newAns)+'5')
            numLPlusDigit = roundDec(ansPlusDigit*numberS, pv)
        }
        if (numberS === 5) {
            let digitOps = ['2', '4', '6', '8']
            ansPlusDigit = Number(String(newAns)+selectRand(digitOps))
            numLPlusDigit = roundDec(ansPlusDigit*numberS, pv)
        }

        // only set these if it didn't change the number of digits in numberL (Such as .0425 x 8 = 0.34 vs .042 x 8 = 0.336)
        if (ansPlusDigit && decPV(numLPlusDigit) !== 0 && (String(g.removeDec(numLPlusDigit)).length === numDigits)) {
            newAns = ansPlusDigit
            newL = numLPlusDigit
        }
    
    }
    return {answer:newAns, numberS, numberL: newL, pv}
    
}


export const divNumbers = (userSelection) =>{
    const {dividend, divisor} = userSelection.specify
    let numArrayDivisor = []
    let numArrayDividend = []
    if (divisor['1 digit whole number']) {
        numArrayDivisor.push(randInt(2,9))
    } if (divisor['2 digit whole number']) {
        numArrayDivisor.push(randInt(11,59))
    } if (divisor['1 digit decimal']) {
        numArrayDivisor.push(randDec(0.2,0.9, 1), randDec(0.02, 0.09, 2))
    } if (divisor['2 digit decimal']) {
        numArrayDivisor.push(randDec(1,9,1), randDec(0.1,0.59, 2))
    }
    let numberS = Number(selectRand(numArrayDivisor))

    if (dividend['2 digits']) {
        numArrayDividend.push([randDec(1, 9, 1), 1], [randDec(0.1,0.99,2),2])
    } if (dividend['3 digits']) {
        numArrayDividend.push(
            [randDec(10, 99, 1),1], [randDec(1,9,2),2], [randDec(0.1, 0.999, 3),3])
    } if (dividend['4 digits']) {
        numArrayDividend.push(
            [randDec(100, 999, 1),1], [randDec(20,99,2),2], [randDec(2, 9, 3),3], [randDec(0.2, 0.9999, 4),4])
    } 

    let dividendShuffle = selectRand(numArrayDividend)
    let tempNumberL = Number(dividendShuffle[0])
    let numLpv = dividendShuffle[1]
    
    // For dividing by a larger pv Ex. 17.2 / 0.08, or dividing by any 2 digit decimals, the makefriendly does not work well, so allow any unfriendly numbers to be used.
    if ((decPV(numberS) === 2 && numLpv === 1) || (decPV(numberS) > 0 && String(g.removeDec(numberS)).length === 2)) {

        let numberL = tempNumberL
        let answer = roundDec(numberL/numberS, numLpv+1)
        let wrong = divDecWrong(answer)
        return {numberL, numberS, pv: numLpv, answer, wrong}
 
    // attempt to make numbers friendly for kids to work with.
    } else {    
        let {numberL, answer, pv, numberS:newNumS} = makeAnswerFriendly(numberS, tempNumberL)
        if (!numberL) { // wasn't able to make it friendly while keeping all configurations in place.
            return divNumbers(userSelection)
        }
        let wrong = divDecWrong(answer)
        return {numberL, numberS: newNumS, pv, answer, wrong}

    }
}

export const divideDecAlg = (userSelection, generalSelection) => {
    let {numberL, numberS, answer, pv, wrong} = divNumbers(userSelection)
    let AC = answerChoicesKey(answer, wrong[0], wrong[1], wrong[2])
    if (userSelection.specify.probStyle==='Set Up'){
        var prob = {numberL, numberS, op:'÷'} //verticalDivide(dividend, divisor, generalSelection.activity)
    } else {
        prob = `${numberL} ÷ ${numberS} = `
    }
    let problem = {prob: prob,
                answerChoices: AC,
                correctAnswer:fixNum(answer),
                probStyle: userSelection.specify.probStyle
                }

    return problem
}
export const divideDecBasic = (userSelection) => {
    let {numberL, numberS, answer, pv, wrong} = divNumbers(userSelection)
    let prob = `What is the quotient of ${fixNum(numberL)} and ${fixNum(numberS)}?`
    let randProb = [prob]
    if (Number.isInteger(answer)){
        randProb.push(`How many equal groups of ${fixNum(numberS)} can be made with ${fixNum(numberL)}?`)
        randProb.push(`How many groups of ${fixNum(numberS)} can be made with ${fixNum(numberL)}?`)
    }
    let max = answer > 99 ? 99 : answer
    // let randNum = answer > 30 ? randInt(20, max-1) : answer > 0 ? randInt(1, max-1) : randDec(0.01, max)
    let randNum = answer > 99 || answer < 2 ? randInt(2, 99) :  Math.floor(answer)

    randProb = selectRand(randProb)

    if (userSelection.specify.level === 'Multi-Step'){
        let temp1 =  `What is ${randNum} more than the quotient of ${fixNum(numberL)} and ${numberS}?`
        let temp2 =  `What is the sum of ${randNum} and the quotient of ${fixNum(numberL)} and ${numberS}?`
        let temp3 =  `What is the difference between ${randNum} and the quotient of ${fixNum(numberL)} and ${numberS}?`
        let temp4 =  `What is the product of ${randNum} and the quotient of ${fixNum(numberL)} and ${numberS}?`
        randProb = selectRand([temp1, temp2, temp3, temp4])
        if (randProb === temp1 || randProb === temp2){
            answer = roundDec(answer + randNum, pv)
            wrong = [randNum+numberL, numberL+numberS, answer+1, answer+1.1, answer+0.1]
        } else if (randProb === temp3) {
            answer = answer > randNum ? roundDec(answer - randNum, pv) : roundDec(randNum - answer, pv)
            wrong = [randNum+numberL, numberL/numberS, numberL/numberS+randNum, answer+1,  answer+1.1, answer+0.1]
        } else {
            answer = roundDec(randNum*answer, pv)
            wrong = [randNum*numberL, numberL/numberS, answer+1, answer+1.1, answer+0.1]
        }
        if (answer > 4){
            wrong.push(answer-1, answer-0.1)
        }
    }

    wrong = shuffleArray(g.removeDup(wrong)).slice(0,3).map(num=>roundDec(num, pv))
    let AC = answerChoicesKey(answer, fixNum(wrong[0]), wrong[1], wrong[2])

    let problem = {prob: randProb,
                answerChoices: AC,
                correctAnswer:fixNum(answer),
                probType: 'Application'
                }

    return problem
}


export const randDivDec = (userSelection, generalSelection) => {
    let probArray = []
    if (userSelection.specify.probType['Algorithm']){
        probArray.push(divideDecAlg)
    } if (userSelection.specify.probType['Application']){
        probArray.push(divideDecBasic)
    }
    let randProb = selectRand(probArray)
    return randProb(userSelection, generalSelection)
}


















