import { StyleSheet } from "@react-pdf/renderer";
  import {randInt, shuffleArray, answerChoicesKey, randDec, roundDec, multDecPV, largestDecPV, selectRand, fixNum, removeDup
  } from './general.js'
  import Table from './Table';

export const shtstyles = StyleSheet.create({
  table: {
      display: "table", //gives individual fraction overall style.
       width: "100%", //120, try wider
       marginTop:-90,
       minHeight:300,
       marginBottom:50
  },
  row: {
      flexDirection: "row",
  },
  cell:{
      backgroundColor:'white',
  },
  header: {
    backgroundColor: 'grey'
  }
});
const tstyles = StyleSheet.create({
  table: {
      display: "table",
       width: "100%",
  },

  cell: {
    },
  header: {
    backgroundColor: 'grey'
  }
});
export const shAtstyles = StyleSheet.create({
  table: {
    display: "table", //gives individual fraction overall style.
},
row: {
    flexDirection: "row",
},
cell:{
    backgroundColor:'white',
},
header: {
  backgroundColor: 'grey'
}
});

export const akTStyles = StyleSheet.create({
  table: {
      display: "table", //gives individual fraction overall style.
  },
  row: {
      flexDirection: "row",
  },
  cell:{
      backgroundColor:'white',
  },
  header: {
    backgroundColor: 'grey'
  }
});
export const acStyles = StyleSheet.create({
  table: {
    display:'table',
  }

})

const getTableDirection = (gs) => {
  let direction = selectRand(['vertical', 'horizontal'])
  if (gs.columns && gs.questionStyle === 'Worksheet'){
    direction = 'vertical'
  } else if (gs.activity === 'Scavenger Hunt'){
    direction = 'horizontal'
  }

  return direction
}

const cell_style = (row_index, col_index, data, direction, activity) => {
  let borderLeftWidth = (col_index === 0) ? 1 : 0
  let borderTopWidth = (row_index === 0) ? 1 : 0
  let [borderRightWidth, borderBottomWidth, fontSize] = [1,1, null]
  var width
  let height
  const getLen = (data) => data.toString().split('.').join('').length
  if (activity === 'Scavenger Hunt'){
    borderLeftWidth = (col_index === 0) ? 4 : 0
    borderTopWidth = (row_index === 0) ? 4 : 0
    borderRightWidth = 4
    borderBottomWidth = 4
    fontSize = 24
  }
  if (direction === 'vertical') { //vt data [[x, y], [1, 5], [2, 6], [3, 7], [4, 8]]
    var backgroundColor = (row_index === 0) ? '#b6b6b6' : 'white'
    width = '50';
    if (activity === 'Scavenger Hunt'){
      let col1Max = Math.max(getLen(data[4][0]), getLen(data[3][0]), getLen(data[2][0]), getLen(data[1][0]))
      let col2Max = Math.max(getLen(data[4][1]), getLen(data[3][1]), getLen(data[2][1]), getLen(data[1][1]))
      width =  col_index === 0 ? 30*(col1Max) + 20 : 30*(col2Max) + 20

    }
    
  } else { //hz data: //data = [[x, 1, 2, 3,4], [y, 5,6,7,8]]
    backgroundColor = (col_index === 0) ? '#b6b6b6' : 'white'
    width = col_index===0 ? '35' : '50'
    if (activity === 'Scavenger Hunt')
      width = col_index===0 ? '60' : '100'
  }
  return {
      width, fontSize, borderLeftWidth, borderRightWidth, borderTopWidth, borderBottomWidth,
      borderStyle: "solid", backgroundColor, textAlign:'center', height
  }
}

const shAK_cell_style = (row_index, col_index, data, direction, activity) => {
  let borderLeftWidth = (col_index === 0) ? 1 : 0
  let borderTopWidth = (row_index === 0) ? 1 : 0
  let [borderRightWidth, borderBottomWidth, fontSize] = [1, 1, 10]

  let backgroundColor = (col_index === 0) ? '#b6b6b6' : 'white'
  let width = 50

  return {
      width, fontSize, borderLeftWidth, borderRightWidth, borderTopWidth, borderBottomWidth,
      borderStyle: "solid", backgroundColor, textAlign:'center'
  }
}

export const tableStyling = (prob, gs, ak, ac, direction) => {
  const {x, y} = prob
  let cellStyle = ak===true ? shAK_cell_style : cell_style
  let tStyle = ak ? akTStyles : prob.letter ?  acStyles : tstyles

  // let tStyle = tstyles
  if (gs.activity === 'Scavenger Hunt'){
    tStyle = ak===true ? akTStyles : ak==='shA' ? shAtstyles : shtstyles
  }

  var verticalTable = Table([
    ['x', 'y'],
    [x[0], y[0]],
    [x[1], y[1]],
    [x[2], y[2]],
    [x[3], y[3]],

  ], cellStyle, tStyle, prob.text, 'vertical', gs.activity, gs.questionStyle)

  var horizontalTable = Table([
    ['x', ...x],
    ['y', ...y],
    ], cellStyle, tStyle, prob.text, 'horizontal', gs.activity, gs.questionStyle)
  const table = direction === 'vertical' ? verticalTable : horizontalTable
  return table
}


const randQEq= (gs, ac) => {
  const {activity, questionStyle} = gs
  var questionArray = [
    'Which equation represents the pattern in the table below?',
    'The table below shows a pattern, which equation best represents the same pattern?',
    'Which equation best represents the pattern shown in the table below?',
    'Which equation shows the same pattern based on the x and y values in the table?',
    'Which equation best represents the pattern shown in the table below?',
  ]
  activity !== 'Scavenger Hunt' && questionArray.push( //quest assumes answer choices below
    'Which of the following equations best represents the pattern shown in the table below?',
  )
  activity !== 'Task Cards' && questionStyle !== 'Task Cards' && activity !== 'Scavenger Hunt' && questionArray.push( //quest is too long to fit along with tables in a TC/SH
    'The table below shows a pattern based on the x and y values. Which equation shows the same pattern?'

  )
    return selectRand(questionArray)
}
const randQTable= (x, lineBreak, gs) => {
  const {activity, questionStyle} = gs
  const TC = activity === 'Task Cards' || questionStyle === 'Task Cards'
  let lb = lineBreak ? '\n': ''
  var questionArray = [ //line breaks on SH after match
    `Which table represents the pattern ${lb} ${x}?`,
    `Which table best represents values that match the equation ${x}?`,
    `Which table best represents the equation ${lb} ${x}?`,
    `Which table shows the same pattern as the equation ${TC  && lb} ${x}?`,

]
if (!lineBreak){
  questionArray.push(
    `Which of the following tables best represents the equation ${lb} ${x}?`,
  )
}
if (!TC) {
  questionArray.push(
    `The equation ${x} represents a pattern. Which table matches the equation?`,
    `The equation ${x} represents a pattern based on the x and y values. Which table shows the same pattern?`
  )
}
  return selectRand(questionArray)
}

//makes a random pattern for x values;

const randPattern = (userSelection, rule) => { 
  let addendArray = []
  if (userSelection.specify.numberType['Whole numbers']) {
    addendArray.push(randInt(1,4))
  } if (userSelection.specify.numberType['Decimals']) {
    addendArray.push(randDec(1,4,1))
  }
  let addend = selectRand(addendArray)
  let [i, numArray, newNum] = [0, [], rule]
  while(i<4) {
    newNum = roundDec(Number(newNum)+Number(addend),2)
    numArray.push(newNum)
    i++;
  }
  return {tableNumArray:numArray, addend:addend}
}
const tableNumbers = (userSelection) =>{ 
  const {numberType, numberSize} = userSelection.specify
  var smallDec = [randInt(2,9), randDec(0.1,0.8,1),randDec(0.1,0.8,1), randDec(0.01,0.09,2) ]
  var mediumDec = [randDec(0.01,0.99,2),randDec(0.1,0.8,1), randInt(10, 20),randDec(1,9,1)]
  var largeDec = [randDec(1,9,2), randDec(0.1,0.99,2), randInt(10,50), randDec(1,9,2), randDec(1,9,1)]
  var numArray = []

  if (numberType["Whole numbers"] && numberSize['Small']){
    numArray.push(randInt(2,9), randInt(2,9))

  } if (numberType["Whole numbers"] && numberSize['Medium']){
    numArray.push(randInt(12,30), randInt(12,30))

  } if (numberType["Whole numbers"] && numberSize['Large']){
    numArray.push(randInt(30,99), randInt(30,99))

  } if (numberType["Decimals"] && numberSize['Small']){
    let shuffled = shuffleArray(smallDec)
    numArray.push(shuffled[0], shuffled[1])

  } if (numberType["Decimals"] && numberSize['Medium']){
    let shuffled = shuffleArray(mediumDec)
    numArray.push(shuffled[0], shuffled[1])

  } if (numberType["Decimals"] && numberSize['Large']){
    let shuffled = shuffleArray(largeDec)
    numArray.push(shuffled[0], shuffled[1])
  } 

  // TO DO: ADD INTEGERS
  // if (numberType["Integers"] && numberSize['Small']){
  //   numArray.push(randInt(-9, 9, true), randInt(-9, -2, true))

  // } if (numberType["Integers"] && numberSize['Medium']){
  //   numArray.push(shuffleArray([randInt(10,30), randInt(-30, -10), randInt(-30, -10)]))

  // } if (numberType["Integers"] && numberSize['Large']){
  //   numArray.push(shuffleArray([randInt(30, 90), randInt(-99, -30, true), randInt(-99, -30, true)]))

  // }
  var [rule, rule2] = shuffleArray(numArray)
  rule= Number(rule)
  let num2 = rule<1 && rule>0.1 && numberType['Decimals'] ? roundDec(rule+randDec(0.1,0.9,1),1) : 
            rule<0.1 && numberType['Decimals'] ? roundDec(rule+randDec(0.01, 0.09, 2),2) : 
            roundDec(rule+randInt(3,10),2)

  let patternNum = []
  if (numberType['Whole numbers']) {
    patternNum.push(randInt(1,9),randInt(1,9),randInt(1,9),randInt(1,9),randInt(1,9))
  } 
  if (numberType['Decimals']) {
    patternNum = [...patternNum, randInt(1,9), randInt(1,9), randInt(1,9), randInt(1,9), ...smallDec]
  }
  patternNum = shuffleArray(removeDup(patternNum))
  patternNum = patternNum[0] === num2 ? patternNum[1] : patternNum[0]
  const mainPattern = randPattern(userSelection, Number(patternNum))
  const tableNumList = mainPattern.tableNumArray
  const fakeXPattern = mainPattern.addend 
  var tableNumList2 = randPattern(userSelection, Number(patternNum)).tableNumArray

  return {rule:Number(rule), rule2:Number(rule2), num2:Number(num2), fakeXPattern,
          tableNum:tableNumList, tableNum2:tableNumList2, patternNum:Number(patternNum)}
}

export const tableMultiply1 = (userSelection, gs) => {
  var text = randQEq(gs,)
  var {rule, num2, tableNum, patternNum, fakeXPattern} = tableNumbers(userSelection) 
  let rulePV = multDecPV(tableNum[0], rule) || 2
  var table = {x: tableNum, y: tableNum.map(num => fixNum(num*rule, rulePV)), text}

  var wrongNum = roundDec(rule+1,2)
  var wrongNum3 = roundDec(rule>2 ? rule-1 : rule*2,2)
  var patternWrong = fakeXPattern !== rule && fakeXPattern !== wrongNum && fakeXPattern !== wrongNum3 && fakeXPattern !== wrongNum3 ? fakeXPattern : num2
  var answer = `y = ${rule}x`
  var wrong1 = [`y = ${wrongNum3}x`, 
                `y = x + ${wrongNum}`, 
                `y = ${wrongNum}x`
              ];
  var wrong2 = [`y = x + ${rule}`, 
                `y = x + ${patternWrong}`,
                `y = ${wrongNum}x`];
  var wrong3 = [`y = ${wrongNum3}x`, 
                `y = ${patternWrong}x`,
                `y = ${wrongNum}x`]; 
  let wrong = shuffleArray(selectRand([wrong1, wrong2, wrong3]))

  var AC = answerChoicesKey(answer, wrong[0], wrong[1], wrong[2])
  var problem = {prob: table,
  answerChoices: AC,
  correctAnswer: answer,
  direction: getTableDirection(gs)
  }
  return problem

}


export const tableMultiply2 = (userSelection, gs) => {
  var {rule, num2, patternNum, tableNum, tableNum2, fakeXPattern} = tableNumbers(userSelection) 
  var [wrongNum, wrongNum2] = [roundDec(rule+num2,2),roundDec(Math.abs(rule-num2),2)]
  let [rulePV, rulePVadd] = [multDecPV(tableNum[0], rule) || 2, largestDecPV(tableNum[0], rule) || 2]
  let wrongNum2PV = multDecPV(tableNum2[0], wrongNum2) || 2
  let num2PV = multDecPV(tableNum[0], num2) || 2
  let wrongNumPV = multDecPV(tableNum[0], wrongNum) || 2
  var answer = {x: tableNum, y: tableNum.map(num => fixNum(num*rule, rulePV)), answer:true}
  let shAnswerKey

  var wrong1 = {x:tableNum, y: [
    fixNum(tableNum[0]*rule,rulePV), 
    fixNum(tableNum[1]*wrongNum,wrongNumPV),
    fixNum(tableNum[2]*wrongNum,wrongNumPV),
    fixNum(tableNum[3]*wrongNum,wrongNumPV)
  ]}

  var wrong2 = {x: tableNum2.map(num=>fixNum(num*2, rulePV)), y: [
    fixNum(tableNum2[0]*2*rule,rulePV),
    fixNum(tableNum2[1]*2*num2,num2PV),
    fixNum(tableNum2[2]*2*num2,num2PV),
    fixNum(tableNum2[3]*2*num2,num2PV)
  ]}

  var wrong3 = {x: tableNum, y: tableNum.map(num=>fixNum(num+rule,rulePVadd))}

  if (userSelection.specify.numberType['Decimals']) {
    var wrong4 = {x: tableNum2, y:tableNum2.map(num=>fixNum(num*wrongNum2, wrongNum2PV))}
  } else {
    wrong4 = {x: tableNum2, y: [
      fixNum(tableNum2[0]*rule,2),
      fixNum(tableNum2[1]*rule,2),
      fixNum(tableNum2[2]*wrongNum,2),
      fixNum(tableNum2[3]*num2,2)
    ]}
  }
  //opposite x & y wrong
  var wrong5 = {x: tableNum.map(num => fixNum(num*rule, rulePV)), y: tableNum}

  // fake pattern
  const randNum = randInt(2,7)
  var wrong6 = {x: [
    tableNum[0], 
    fixNum(tableNum[0]*rule, rulePV), 
    fixNum(tableNum[1]*rule, rulePV), 
    fixNum(tableNum[2]*rule, rulePV)], y: tableNum.map(num=>fixNum(num*randNum, rulePV))}
  const lineBreak = gs.activity === 'Scavenger Hunt' || gs.questionStyle === 'Task Cards' ? true: false
  var text = randQTable(`y = ${rule}x `, lineBreak, gs)

  var wrong = shuffleArray([wrong1, wrong2, wrong3, wrong4, wrong5, wrong6])
  var AC = answerChoicesKey(answer, wrong[0], wrong[1], wrong[2])

  var problem = {prob: text,
  answerChoices: AC, //hard coded answer, wrong1, wrong2, wrong3 works
  correctAnswer: answer,
  shAnswerKey: shAnswerKey,
  direction: getTableDirection(gs)

  }
  return problem

}



export const tableAdd1 = (userSelection, gs) => {
    var {rule, num2, patternNum, tableNum, tablNum2, fakeXPattern} = tableNumbers(userSelection) 
    var text= randQEq(gs)
    var wrongNum = roundDec(rule+randInt(1,2),2)
    var wrongNum2 = rule > 3 ? roundDec(Math.abs(rule-randInt(1, 2)),2) : roundDec(rule*2,2)
    var patternWrong = fakeXPattern !== rule && fakeXPattern !== wrongNum && fakeXPattern !== wrongNum2 ? fakeXPattern : num2

    let rulePV = largestDecPV(tableNum[0], rule) || 2

    var table = {x: tableNum, y: tableNum.map(num=>roundDec(num+rule, rulePV)), text}
    var answer = `y = x + ${rule}`
    var wrong1 = [`y = x + ${patternWrong}`, 
                  `y = x + ${wrongNum}`, 
                  `y = ${rule}x`
                ]
    var wrong2 = [`y = x + ${patternWrong}`, 
                  `y = ${patternWrong}x`,
                  `y = ${rule}x`]

    var wrong3 = [`y = x + ${patternWrong}`, 
                  `y = x + ${wrongNum2}`,
                  `y = x + ${wrongNum}`]    

    var wrong = [wrong1, wrong2, wrong3][randInt(0,2)]
    var AC = answerChoicesKey(answer, wrong[0], wrong[1], wrong[2])

    var problem = {prob: table,
    answerChoices: AC,
    correctAnswer: answer,
    direction: getTableDirection(gs)

    }
    return problem

}



export const tableAdd2 = (userSelection, gs) => {
  var {rule, num2, patternNum, tableNum, tableNum2} = tableNumbers(userSelection) 
  var wrongNum = roundDec(rule+num2,2)
  var wrongNum2 = roundDec(Math.abs(rule-num2),2)
  var wrongNum3 = roundDec(rule*2,2)
  num2= Number(num2)

  let rulePV = largestDecPV(tableNum[0], rule) || 2
  let num2PV = largestDecPV(tableNum[0], num2) || 2
  let wrongNum3PV = largestDecPV(tableNum2[0], wrongNum3) || 2

  var answer = {x: tableNum, y: tableNum.map(num=>roundDec(num+rule, rulePV)), answer:true}

  let shAnswerKey

  var wrong1 = {x: tableNum, y: [
    roundDec(tableNum[0]+rule,rulePV),
    roundDec(tableNum[1]+num2,num2PV),
    roundDec(tableNum[2]+num2,num2PV),
    roundDec(tableNum[3]+num2,num2PV)
  ]}

  var randAddend = randInt(2,10)
  var wrong2 = {x: tableNum2, y: [
    roundDec(tableNum[0]+wrongNum3,wrongNum3PV),
    roundDec(tableNum[1]+wrongNum3,wrongNum3PV),
    roundDec(tableNum[2]+wrongNum3,wrongNum3PV),
    roundDec(tableNum[3]+wrongNum3,wrongNum3PV),
  ]} 
  //opposite operation
  var wrong3 = {x: tableNum, y: tableNum.map(num=>fixNum(num*rule, rulePV))}

  ///opposite table
  var wrong4 = {x: tableNum.map(num=>roundDec(num+rule, rulePV)), y:tableNum}

  //fake pattern
  var randNum = randInt(2, 9)
  var wrong5 = {x: [
    tableNum[0], 
    fixNum(tableNum[0]+rule, rulePV), 
    fixNum(tableNum[1]+rule, rulePV), 
    fixNum(tableNum[2]+rule, rulePV)], y: tableNum.map(num=>fixNum(num*randNum, rulePV))}
  const lineBreak = gs.activity === 'Scavenger Hunt' || gs.questionStyle === 'Task Cards' ? true: false
  var text = randQTable(`y = x + ${rule}`, lineBreak, gs)
  var wrong = shuffleArray([wrong1, wrong2, wrong3, wrong4, wrong5])
  var AC = answerChoicesKey(answer, wrong[0], wrong[1], wrong[2])
  
  var problem = {prob: text,
    answerChoices: AC, //hard coded answer, wrong1, wrong2, wrong3 works
    correctAnswer: answer,
    shAnswerKey: shAnswerKey,
    direction: getTableDirection(gs)

  }
  return problem

}

export const table2step = (userSelection, gs) => {
  var text = randQEq(gs)

  var {rule, rule2, num2, patternNum, tableNum, tablNum2} = tableNumbers(userSelection) 
  var wrongNum = roundDec(rule*num2,2)
  var wrongNum2 = roundDec(Math.abs(rule*num2-num2),2)
  var wrongNum3 = roundDec(rule*2,2)
  var table = {x: tableNum, y: tableNum.map(num=> fixNum(num*rule+rule2,2)), text}

  var answer = `y = ${rule}x + ${rule2}`
  if (rule2 === rule) {
    rule2= fixNum(rule2+1, 2)
  }
  var wrong1 = [`y = ${rule}x`, 
                `y = ${rule}x + ${num2}`, 
                `y = ${patternNum}x + ${rule2}`
              ]
  var wrong2 = [`y = ${rule2}x + ${rule}`, 
                `y = x + ${wrongNum2}`,
                `y = ${rule}x + ${patternNum}`]
  var wrong3 = [`y = ${num2}x + ${rule2}`, 
                `y = ${wrongNum3}x + ${rule}`,
                `y = ${rule}x`]               
  var wrong = [wrong1, wrong2, wrong3][randInt(0,2)]
  var AC = answerChoicesKey(answer, wrong[0], wrong[1], wrong[2])

  var problem = {prob: table,
  answerChoices: AC,
  correctAnswer: answer,
  direction: getTableDirection(gs)

  }
  return problem

}
export const table2step2 = (userSelection, gs) => {
  var {rule, rule2, num2, patternNum, tableNum, tableNum2} = tableNumbers(userSelection) 

  var wrongNum = roundDec(rule+num2,2)
  var wrongNum2 = roundDec(Math.abs(rule-num2),2)
  var wrongNum3 = roundDec(rule*2,2)
  let shAnswerKey
  var answer = {x: tableNum, y: tableNum.map(num => fixNum(num*rule+rule2, 2)), answer:true}

  var wrong1 = {x: tableNum, y: [
    fixNum(tableNum[0]*rule+rule2,2),
    fixNum(tableNum[1]*wrongNum+rule2,2),
    fixNum(tableNum[2]*wrongNum+rule2,2),
    fixNum(tableNum[3]*wrongNum+rule2,2)
  ]}
  var wrong2 = {x: tableNum.map(num=>fixNum(num*2, 2)), y: [
    fixNum(tableNum2[0]*2*rule+rule2,2),
    fixNum(tableNum2[1]*2*rule+rule2,2),
    fixNum(tableNum2[2]*2*num2+rule2,2),
    fixNum(tableNum2[3]*2*num2+rule2,2),
  ]}
  var wrong3 = {x: tableNum, y: tableNum.map(num => fixNum(num+rule*rule2, 2))}

  if (userSelection.specify.numberType['Decimals']) {
    var wrong4 = {x: tableNum2, y: tableNum2.map(num=> fixNum((num+rule2)*rule, 2))}

  } else {
    wrong4 = {x: tableNum2, y: [
      fixNum(tableNum2[0]*rule+rule2,2),
      fixNum(tableNum2[1]*rule+rule2,2),
      fixNum(tableNum2[2]*wrongNum+rule2,2),
      fixNum(tableNum2[3]*num2+rule2,2)
    ] }

  }
  const lineBreak = gs.activity === 'Scavenger Hunt' || gs.questionStyle === 'Task Cards' ? true: false
  var text = randQTable(` y = ${rule}x + ${rule2}`, lineBreak, gs)
  var wrong = shuffleArray([wrong1, wrong2, wrong3, wrong4])
  var AC = answerChoicesKey(answer, wrong[0], wrong[1], wrong[2])
  
  var problem = {prob: text,
  answerChoices: AC, //hard coded answer, wrong1, wrong2, wrong3 works
  correctAnswer: answer,
  shAnswerKey: shAnswerKey,
  direction: getTableDirection(gs)

  }
  return problem

}










export const randTable = (userSelection, gs) => {
  let probArray = []
  if(userSelection.specify.steps['One-step']) {

    probArray.push(tableMultiply1, tableAdd1)
    if ((!gs.columns || gs.questionStyle !== 'Worksheet') && gs.activity !== 'Scavenger Hunt'){ //no table answer choices && gs.activity!=='Scavenger Hunt'
      probArray.push(tableMultiply2, tableAdd2)
    }
  }
  if(userSelection.specify.steps['Two-steps']) {
    probArray.push(table2step, table2step)
    if ((!gs.columns || gs.questionStyle !== 'Worksheet') && gs.activity!== 'Scavenger Hunt'){ //no table answer choices && gs.activity!=='Scavenger Hunt'
      probArray.push(table2step2, table2step2)
    }
  }

  var randProb = selectRand(probArray)
  return randProb(userSelection, gs)
}

