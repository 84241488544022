import React, { useEffect, useState } from "react";

import { Switch } from "react-router-dom";
import "../scss/main.css";

import "aos/dist/aos.css";

import LoadingWrapper from "../shared/components/Loading/LoadingWrapper";
import MainNavigation from "../shared/components/Navigation/MainNavigation";

import AuthStore from "./context/auth-store";
import ErrorBoundary from './ErrorBoundary';
import WSStore from "./context/ws-store";
import Routes from "./Routes";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core";
import purple from "@material-ui/core/colors/purple";
import Footer from "../shared/components/footer";

const theme = {
  palette: {
    secondary: { main: purple[500] }, // Purple and green play nicely together.
    primary: { main: "#609285" }, // This is just green.A700 as hex.
  },
};

const muiTheme = createMuiTheme(theme);
const App = () => {
  // const [debugMode, setDebugMode] = useState(window.location.pathname === "/");
  const [debugMode, setDebugMode] = useState(false);

  return (
    <div className="main">
      <AuthStore>
        <LoadingWrapper>
          <ErrorBoundary>
            <WSStore>
              {!debugMode ? <MainNavigation /> : null}
              <ThemeProvider theme={muiTheme}>
                <Switch>
                  <Routes debugMode={debugMode} setDebugMode={setDebugMode} />
                </Switch>
              </ThemeProvider>
              {!debugMode ? <Footer /> : null}
            </WSStore>
          </ErrorBoundary>
        </LoadingWrapper>
      </AuthStore>
    </div>
  );
};

export default App;
