import {randInt, shuffleArray, answerChoicesKey, randDec, roundDec, selectRand, fixNum, showQText, decPV, orderDigits
} from './general.js'
// import { orderOps10Exp, orderOps11Exp, orderOps2Exp, orderOps3Exp, orderOps4Exp, orderOps5Exp, orderOps6Exp, orderOps7Exp, orderOps8Exp, orderOps9Exp, orderOpsExp } from './order-of-ops-exp.js'
const {floor, abs} = Math
export const simpleQArray = ['What is the value of the expression? \n', 'Solve the expression below. \n', 'Simplify the expression.\n',
              'An expression is shown below, what value is equivalent to the expression?\n',
              'Which value is equivalent to the expression below? \n']
export const fixWrong = (answer, wrong, numType) => {
wrong = wrong.map(w => {
if (numType === 'Decimals') {
  return abs(roundDec(w, 3)) // maybe should be different?

} else if (numType === 'Integers') {
  return floor(w) // dont want decimal wrongs

} else {
  return abs(floor(w)) // round & ensure not negative
}
}).filter(num => num !== answer)


return shuffleArray([...new Set(wrong)])
}
export let roundIfDec = (s, expression) => {
  if(String(s).split('.').length > 1){
    return roundDec(expression, 2)
  } else { //dont want decimal answers if whole number
    return Math.floor(expression)
}
}
// since integers do not matter as much which number goes first/second (Whereas with whole/dec larger number HAS to go first), we want a way to create more
// letiety for which numbers are negative while also ensuring at least one is negative. So all values are now randomly either positive/negative so this function will 
// ensure we get at least one, this is dependent on the exact question/number of steps since different values are used in each question, so we have to do it 
// for each problem vs during the initial creation of the numbers.

export const createNeg = (nums) => {
  // if at least one number is not negative or  positive, flip one sign
  if (!nums.find(n=>n < 0) || !nums.find(n=>n > 0)){
    let randNum = randInt(0, nums.length-1)
    nums[randNum] = nums[randNum]*-1
  } 
  return nums
}
export const getBasicWrongAnswers = (answer, numType) => {
  let randSub, randSub2
  let randWrong = []
  if (numType === 'Integers') {
    randSub = answer - randInt(1, 2)
    randSub2 = answer - randInt(3, 4)
    randWrong.push(randSub, randSub2)
  } else if (numType === 'Decimals') {
    randSub = answer > 1 ? answer - randDec(0.1,0.5, 1) : answer - 0.01
    randSub2 = answer - randDec(0.02, 0.03, 2)
    randWrong.push(randSub, randSub2, answer*10, answer/10)
  } else {
    randSub = answer > 3 ? answer-randInt(1, 3) : answer > 2 ? answer-randInt(1, 2) : answer > 1 ? answer - 1 : answer + randInt(5, 6)
    randSub2 = randSub > 1 ? randSub - 1 : randSub + randInt(3, 6)
    randWrong.push(randSub, randSub2, answer*-1, randSub*-1)
  }

  return [answer+randInt(1,2), answer+randInt(3, 4), ...randWrong]
}
export const orderOpsNumbers = (userSelection) => {
  const {numberType, level, exponents, steps:allowedSteps} = userSelection.specify
  let numTypeArray = []
  let numArray = []
  let exponent = {baseS: randInt(2, 5), baseS2: randInt(2, 5), baseM: randInt(2, 9), baseM2: randInt(2, 9), baseL: randInt(5, 9), baseL2: randInt(5, 9)}

  let stepArray = []
  if (allowedSteps['4 steps']) {
    stepArray.push('4 steps')
  } 
  if (allowedSteps['3 steps']) {
    stepArray.push('3 steps')
  }
  if (allowedSteps['5 steps']) {
    stepArray.push('5 steps')
  }
let steps = stepArray.length === 1 ? stepArray[0] : selectRand(stepArray)
  if (numberType['Whole numbers']){
    numTypeArray.push('Whole numbers')

    if (level === '2') {
      numArray.push([randInt(2,9), randInt(2,9),randInt(5,15),randInt(5, 15), randInt(5, 15),
          randInt(5,15),randInt(11,20),randInt(11,20)])

    } else if (level === '3') {
      exponent = {baseS: randInt(2, 9), baseS2: randInt(2, 9), baseM: randInt(5, 13), baseM2: randInt(5, 13), baseL: randInt(8, 15), baseL2: randInt(8, 15)}

      numArray.push([randInt(10,20), randInt(10,20), randInt(15,30), randInt(15,30), randInt(15,30), 
          randInt(15,30), randInt(21,50), randInt(21,50)])
    } else {
      numArray.push([randInt(2,5), randInt(2,5), randInt(2,9), randInt(2,9), randInt(2,9), 
          randInt(2,9),randInt(6,9),randInt(6,9)])
    }

  } 
  let dec = {}
  if (numberType['Decimals']) {
    numTypeArray.push('Decimals')
    let smallDec = shuffleArray([randDec(0.1, 0.9,1), randDec(0.01, 0.09, 2)])
    let medDec = shuffleArray([randDec(1, 4, 1), randDec(1, 4, 2)])
    let largeDec = shuffleArray([randDec(11, 20, 1), randDec(6, 9, 2)])
    dec = {smallDec, medDec, largeDec}

    if (level === '2') {
      dec.main = shuffleArray([randDec(1, 4, 1), randDec(1, 5, 2)])
      numArray.push([randInt(2, 9), randInt(2, 9), randInt(5,15), randInt(5,15),
      randInt(5,15), randInt(5,15), randInt(11, 20), randInt(11,20)])

    } else if (level === '3') {
      exponent = {baseS: randInt(2, 9), baseS2: randInt(2, 9), baseM: randInt(5, 13), baseM2: randInt(5, 13), baseL: randInt(8, 15), baseL2: randInt(8, 15)}
      dec.main = shuffleArray([randDec(11, 20, 1), randDec(6, 9, 2)])
      numArray.push([randInt(10, 20), randInt(10, 20), randInt(15,30), randInt(15,30),
      randInt(15,30), randInt(15,30), randInt(21,50), randInt(21,50)])
    } else {
      dec.main = shuffleArray([randDec(1, 4, 1), randDec(1, 5, 2)]) // I think these are the most normal numbers, use small only when really necessary
      numArray.push([randInt(2, 5), randInt(2, 5), randInt(2, 9), randInt(2, 9),
      randInt(2, 9), randInt(2, 9), randInt(6, 9), randInt(6,9)])
    }

  }
  if (numberType['Integers']){
  numTypeArray.push('Integers')
  exponent = {baseS: randInt(-5, 5, true), baseS2: randInt(-5, 5, true), baseM: randInt(-9, 9, true), baseM2: randInt(-9, 9, true), baseL: randInt(-9, -9, true), baseL2: randInt(-9, -9, true)}

    if (level === '2') {
      let tempNumArray = [randInt(-15, -2, true), randInt(-15, -2, true), 
        randInt(-15, -5, true), randInt(-15, -5, true), 
        randInt(-15, -8, true), randInt(-15, -8, true), 
        randInt(-15, -8, true), randInt(-15, -8, true)].map(num=>randInt(0,1)===0 ? num : num*-1)
      numArray.push(tempNumArray)

    } else if (level === '3') {
      exponent = {baseS: randInt(-5, 5), baseS2: randInt(-5, 5),baseM: randInt(-13, 13, true), baseM2: randInt(-13, 13, true), baseL: randInt(-15, 15, true), baseL2: randInt(-15, 15, true)}
      let tempNumArray = [ randInt(-15, -7, true), randInt(-15, -7, true), 
        randInt(-30, -7, true), randInt(-30, -7, true), 
        randInt(-30, -10, true), randInt(-30, -10, true), 
        randInt(-30, -10, true), randInt(-30, -10, true)].map(num=>randInt(0,1)===0 ? num : num*-1)
      
      numArray.push(tempNumArray)

    } else {
      let tempNumArray = [randInt(-9, -2, true), randInt(-9, -2, true), 
        randInt(-9, -2, true), randInt(-9, -2, true), 
        randInt(-9, -2, true), randInt(-9, -2, true), 
        randInt(-9, -2, true), randInt(-9, -2, true)].map(num=>randInt(0,1)===0 ? num : num*-1)
      numArray.push(tempNumArray)
    }
  } 

  // track the type of problem we are using for this one since they may select more than one option and we need to know in other areas how to process the numbers
  let randNum = numArray.length === 1 ? 0 : randInt(0, numArray.length-1)
  let numList = numArray[randNum]
  if (exponents){
  exponent.exp = shuffleArray([2, 2, 2, 3])
  }
  return { nS2: numList[0], nS: numList[1], nM: numList[2], nM2: numList[3], nM3: numList[4], nM4: numList[5], nL:numList[6], nL2: numList[7], numType: numTypeArray[randNum], steps, dec, exponent}
}
export const orderOps = (userSelection, generalSelection) => { //oooA
  let randQuest = showQText(generalSelection, userSelection) ?  selectRand(simpleQArray) : ''

  let {nL, nL2, nS, nS2, nM, nM2, nM3, nM4, numType, dec, steps} = orderOpsNumbers(userSelection)
  let nD = numType === 'Decimals' ? dec.main[0] : nS2 // sub, 
  let nD2 = numType === 'Decimals' ? dec.main[1] : nL // add, 
  let probData = {}

  if (steps === '3 steps') { // +/- /-+ +/-
    if (numType === 'Integers') {[nD2, nL2, nS, nS2] = createNeg([nD2, nL2, nS, nS2])}
    let dividend = roundDec(nL2 * nS, 2)

    let OoOA = `${nD2} + (${fixNum(dividend)} ÷ ${nS} - ${nS2})` //10 + (40÷8 - 2)
    let OoOB = `(${fixNum(dividend)} ÷ ${nS} - ${nS2}) + ${nD2} ` //(40÷8 - 9) +10
    let OoOC = `${nD2} + ${fixNum(dividend)} ÷ ${nS} - ${nS2}` //10 + 40÷8 - 2

    let answer = nD2 + (nL2 - nS2)
    let wrong = [((nD2 + dividend) / nS) - nS2, dividend/nS - nD,  ...getBasicWrongAnswers(answer, numType)]
    probData = {prob:selectRand([OoOA, OoOB, OoOC]), answer: roundDec(answer, 2), wrong: fixWrong(answer, wrong, numType)}

  } else if (steps === '4 steps') { // +/-x +x/- x/-+
    if (numType === 'Integers') {[nD2, nL2, nS, nD, nM2] = createNeg([nD2, nL2, nS, nD, nM2])}
    let dividend = roundDec(nL2 * nS, 2)

    let OoOA = `${nD2} + (${fixNum(dividend)} ÷ ${nS} - ${nD}) × ${nM2}` //10 + (40÷8 - 9) × 10
    let OoOB = `${nD2} + ${nM2}(${fixNum(dividend)} ÷ ${nS} - ${nD}) `   //10 + 10(40÷8 - 9)
    let OoOC = `${nM2}(${fixNum(dividend)} ÷ ${nS} - ${nD}) + ${nD2} `  //10(40÷8 - 9)+10

    let answer = nD2 + (dividend / nS - nD) * nM2
    let wrong = [nD2 + dividend / nS - nD * nM2, ...getBasicWrongAnswers(answer, numType),    
            (((nD2 + dividend) / nS) - nD) * nM2,
                ]
    probData = {prob:selectRand([OoOA, OoOB, OoOC]), answer: roundDec(answer, 2), wrong: fixWrong(answer, wrong, numType)}

  } else if (steps === '5 steps')  { //no selection/5 +/-x- +x/-- x/--+
    if (numType === 'Integers') {[nD2, nL2, nS, nD, nM3, nM] = createNeg([nD2, nL2, nS, nD, nM3, nM])}
    let dividend = roundDec(nL2 * nS, 2)

    let OoOA = `${nD2} + (${fixNum(dividend)} ÷ ${nS} - ${nD}) × ${nM3} - ${nM}` //10 + (40÷8-9) x 10 - 2 
    let OoOB = `${nD2} + ${nM3}(${fixNum(dividend)} ÷ ${nS} - ${nD}) - ${nM}` //10 + 10(40÷8-9) - 2 
    let OoOC = `${nM3}(${fixNum(dividend)} ÷ ${nS} - ${nD}) - ${nM} + ${nD2}` //10(40÷8-9) - 2 + 10

    let answer = nD2 + ((nL2 - nD) * nM3) - nM
    let wrong = [((nM3 + dividend) / nS) - nD * nM3 - nM, ...getBasicWrongAnswers(answer, numType),       
                (((nD2 + nM3) * dividend) / nS) - nD - nM,
                (((nD2 + dividend) / nS) - nD) * nM3 - nM,
                (nD2 + nL2 - nD) * nM3 - nM + 1]
    probData = {prob:selectRand([OoOA, OoOB, OoOC]), answer: roundDec(answer, 2), wrong: fixWrong(answer, wrong, numType)}

} 
  let {wrong, answer, prob} = probData
  wrong = wrong.slice(0,3).map(choice => roundDec(choice, 2))
  let AC = answerChoicesKey(answer, ...wrong)

  let problem = {prob, text: randQuest,
    answerChoices: AC,
    correctAnswer: fixNum(answer)
  }
  return problem
}

export const orderOps2 = (userSelection, generalSelection) => { //oooB
  let randQuest = showQText(generalSelection, userSelection) ?  selectRand(simpleQArray) : ''
  const {level} = userSelection.specify
  let {nL, nL2, nS, nS2, nM, nM2, nM3, numType, steps, dec} = orderOpsNumbers(userSelection)

  let [nD, nD2, probData] = [nS2, nL2, {}]
  if (numType === 'Decimals') {
    nD = level === '1' ? dec.smallDec[0] : dec.main[0] // dividend
    nD2 = dec.main[1] //add
  }

  if (steps === "3 steps") { // x-/ -/x 
    if (numType === 'Integers') {[nM, nL, nD, nM2] = createNeg([nM, nL, nD, nM2])}
    let dividend = roundDec(nM2*nD, 2)

    let OoOA = `${nM} × (${nL} - ${fixNum(dividend)} ÷ ${nM2})` // 8 x (9 - 40 ÷ 5)
    let OoOB = `(${nL} - ${fixNum(dividend)} ÷ ${nM2}) × ${nM}` // (9 - 40 ÷ 5)x8
    let OoOC = `${nM}(${nL} - ${fixNum(dividend)} ÷ ${nM2})` // 8(9 - 40 ÷ 5)

    let answer = nM * (nL - (dividend / nM2))
    let wrong = [...getBasicWrongAnswers(answer, numType),
      nM * nL - dividend / nM2, //ignore group
      nM * ((nL - dividend) / nM2), //sub first
      (nM * nL - dividend) / nM2, // L to R
      (nM * nL + 1 - dividend) / nM2
    ];
    probData = {prob:selectRand([OoOA, OoOB, OoOC]), answer: roundDec(answer, 3), wrong: fixWrong(answer, wrong, numType)}

  } else if (steps === "4 steps") { // x-/+ -/x+ +x-/
    if (numType === 'Integers') {[nM, nL, nD, nM2, nD2] = createNeg([nM, nL, nD, nM2, nD2])}
    let dividend = roundDec(nM2*nD, 2)

    let OoOA = `${nM}(${nL} - ${fixNum(dividend)} ÷ ${nM2}) + ${nD2}` // 8(9 - 40 ÷ 5) + 9
    let OoOB = `(${nL} - ${fixNum(dividend)} ÷ ${nM2}) × ${nM} + ${nD2}` // (9 - 40 ÷ 5) x 8 + 9
    let OoOC = `${nD2} +  ${nM}(${nL} - ${fixNum(dividend)} ÷ ${nM2})` // 9 + 8(9 - 40 ÷ 5)

    let answer = nM * (nL - dividend / nM2) + nD2;
    let wrong = [...getBasicWrongAnswers(answer, numType),
      nM * nL - dividend / nM2 + nD2, //ignore ()
      (nD2 + nM) * nL - dividend / nM2, // add first
      (nM * nL - dividend) / nM2 + nD2, //L to R
    ];
    probData = {prob:selectRand([OoOA, OoOB, OoOC]), answer: roundDec(answer, 3), wrong: fixWrong(answer, wrong, numType)}

  } else if (steps === "5 steps") { //xx-/+ xx-/+ +xx-/
    if (numType === 'Integers') {[nM, nS, nL, nD, nM2, nD2] = createNeg([nM, nS, nL, nD, nM2, nD2])}
    let dividend = roundDec(nM2*nD, 2)

    let OoOA = `${nM} (${nS} × ${nL} - ${fixNum(dividend)} ÷ ${nM2}) + ${nD2}` //8 (5 x 9 - 40 ÷ 5) + 9
    let OoOB = `${nD2} + (${nL} × ${nS} - ${fixNum(dividend)} ÷ ${nM2}) × ${nM}` //8 (9 x 5 - 40 ÷ 5) + 9
    let OoOC = `${nD2} + ${nM} (${nS} × ${nL} - ${fixNum(dividend)} ÷ ${nM2})` //9 + 8( 5 x 9 - 40 ÷  5)

    let answer = nM * (nS * nL - dividend / nM2) + nD2;
    let wrong = [...getBasicWrongAnswers(answer, numType),
      nM * nS * nL - dividend / nM2 + nD2,
      nM * nS * (nL - dividend / nM2 + nD2),
      (nM * nS * nL - nS2) * nM2 + nD2,
    ];
    probData = {prob:selectRand([OoOA, OoOB, OoOC]), answer: roundDec(answer, 3), wrong: fixWrong(answer, wrong, numType)}

  }
  let {wrong, answer, prob} = probData
  wrong = wrong.slice(0,3)
  let AC = answerChoicesKey(answer, ...wrong)

  let problem = {prob, text: randQuest,
    answerChoices: AC,
    correctAnswer: fixNum(answer)
  }
  return problem
}

export const orderOps3 = (userSelection, generalSelection) => { //oooC
  let randQuest = showQText(generalSelection, userSelection) ?  selectRand(simpleQArray) : ''
  let {nL, nL2, nS, nS2, nM, nM2, nM3, steps, numType, dec} = orderOpsNumbers(userSelection)
  let nD = numType === 'Decimals' ? randDec(0.1, 0.9, 1) : nS2 // subtracted & exponent
  let nD2 = numType === 'Decimals' ? dec.main[0] : nS //mult
  let probData = {}
  if (steps === "3 steps") { // -++ ++-
    if (numType === 'Integers') {[nL, nD, nM, nM2] = createNeg([nL, nD, nM, nM2])}

    let OoOA = `(${nL} - ${nD}) + (${nM} + ${nM2})` //(9 - 2) + (8 + 5)
    let OoOB = `(${nM} + ${nM2}) + (${nL} - ${nD})` //(8 + 5) + (9 - 2)
    let OoOC = `${nM2} + ${nM} + (${nL} - ${nD})` //5 + 8 + (9 - 2)

    let answer = nL - nD + (nM + nM2);
    let wrong = [...getBasicWrongAnswers(answer, numType), nL - nD + nM - nM2, nL + nD + nM + nM2, nL - nD + nM];
    probData = {prob:selectRand([OoOA, OoOB, OoOC]), answer: roundDec(answer, 3), wrong: fixWrong(answer, wrong)}


  } else if (steps === "4 steps") {  // -+x+ x++- +x+-
    if (numType === 'Integers') {[nL, nD, nD2, nM3, nM2] = createNeg([nL, nD, nD2, nM3, nM2])}

    let OoOA = `(${nL} - ${nD}) + ${nD2}(${nM3} + ${nM2})` // (9 - 4) + 5(8 + 2)
    let OoOB = `${nD2}(${nM3} + ${nM2}) + (${nL} - ${nD})` //5(8 + 2) + (9 - 4) 
    let OoOC = `(${nM2} + ${nM3}) × ${nD2} + (${nL} - ${nD})` //(8 + 2) x5 + (9 - 4)

    let answer = (nL-nD)+nD2*(nM3+nM2)
    let wrong = [...getBasicWrongAnswers(answer, numType),
      nL - nD + nD2 * nM3 + nM2,          
      (nL - nD + nD2) * (nM3 + nM2),
      (nL - nD + nD2) * nM3 + nM2,
    ];
    probData = {prob:selectRand([OoOA, OoOB, OoOC]), answer: roundDec(answer, 3), wrong: fixWrong(answer, wrong, numType)}

  } else if (steps === "5 steps") { // x-+x+ x++x- x++-x
    if (numType === 'Integers') {[nD2, nL, nD, nM, nM3, nM2] = createNeg([nD2, nL, nD, nM, nM3, nM2])}

    let OoOA = `${nD2}(${nL} - ${nD}) + ${nM}(${nM3} + ${nM2})` //2(9 - 4) + 5(8 + 2)
    let OoOB = `${nM}(${nM3} + ${nM2}) + ${nD2}(${nL} - ${nD}) ` //5(8 + 2) + 2(9 - 4)
    let OoOC = `${nM}(${nM3} + ${nM2}) + (${nL} - ${nD}) × ${nD2} ` //5(8 + 2) + (9 - 4) x 2

    let answer = nD2*(nL-nD)+nM*(nM3+nM2)
    let wrong = [...getBasicWrongAnswers(answer, numType),
      nD2 * nL - nD + nM * nM3 + nM2,
      (((nL + nS) * nM3) / nD2 - nD) * nM2,
      (nD2 * nL - nD + nM) * nM3 + nM2,
    ];
    if (numType === 'Integers'){
        wrong.push(nD2 * (nL - nD * -1) + nM * (nM3 + nM2),
          nD2 * -1 * (nL - nD * -1) + nM * (nM3 + nM2)
        );
    } else if (numType === 'Decimals') {
        wrong.push(nD2 * (nL - nD / 10) + nM * (nM3 + nM2));
    }
    probData = {prob:selectRand([OoOA, OoOB, OoOC]), answer: roundDec(answer, 3), wrong: fixWrong(answer, wrong, numType)}

  } 

  let {wrong, answer, prob} = probData
  wrong = wrong.slice(0,3)
  let AC = answerChoicesKey(answer, ...wrong)

  let problem = {prob, text: randQuest,
    answerChoices: AC,
    correctAnswer: fixNum(answer)
  }
  return problem
}

export const orderOps4 = (userSelection, generalSelection) => { //oooD
  let randQuest = showQText(generalSelection, userSelection) ?  selectRand(simpleQArray) : ''
  let {nL, nL2, nS, nS2, nM, nM2, nM3, numType, steps, dec} = orderOpsNumbers(userSelection)
  let nD = numType === 'Decimals' ? dec.main[0] : nS //sub, add,
  let nD2 = numType === 'Decimals' ? dec.main[1] : nM2 //mult, add
  let probData = {}
  if (steps === "3 steps") { // +-x x+- x-+
    if (numType === 'Integers') {[nM3, nL, nD, nM2] = createNeg([nM3, nL, nD, nM2])}

    let OoOA = `[${nM3} + (${nL} - ${nD})] × ${nM2}` //[40 + (30 - 10)] x 20
    let OoOB = `${nM2}[${nM3} + (${nL} - ${nD})]` // 20[40 + (30 - 10)]
    let OoOC = `${nM2}(${nL} - ${nD} + ${nM3})` // 20(30 - 10 + 40)

    let answer = (nM3 + (nL - nD)) * nM2;
    let wrong = [ ...getBasicWrongAnswers(answer, numType),
      (nM3 + (nL - nD)) * (nM2 + 1),
      nM3 * nL - nD * nM2,
      nM3 * nM2 + (nL - nD),
      (nM3 + nL - nD + 1) * nM2,         
    ];
    probData = {prob:selectRand([OoOA, OoOB, OoOC]), answer: roundDec(answer, 3), wrong: fixWrong(answer, wrong, numType)}

  } else if (steps === "4 steps") { // +x-x x+x- xx-+
    if (numType === 'Integers') {[nM3, nS2, nL, nD, nM2] = createNeg([nM3, nS2, nL, nD, nM2])}

    let OoOA = `[${nM3} + (${nS2} × ${nL} - ${nD})] × ${nD2}` // [100 + (8 x 40 - 10)] x40
    let OoOB = `${nD2}[${nM3} + (${nS2} × ${nL} - ${nD})]` // 40[100 + (8 x 40 - 10)]
    let OoOC = `${nD2}[(${nS2} × ${nL} - ${nD}) + ${nM3}]` //40[(8 x 40 - 10) + 100]

    let answer = (nM3 + (nS2 * nL - nD)) * nD2;
    let wrong = [ ...getBasicWrongAnswers(answer, numType),
      (nM3 + (nS2 * nL - nD)) * (Number(nD2) + 1),
      (nM3 + nS2) * nL - nD,
      nM3 + (nS2 * nL - nD * nD2),
      ((nM3 + nS2) * nL - nD) * nD2,          
    ];
    probData = {prob:selectRand([OoOA, OoOB, OoOC]), answer: roundDec(answer, 3), wrong: fixWrong(answer, wrong, numType)}

  } else if (steps === "5 steps") { // x++x- x+x-+ ++x-x
    if (numType === 'Integers') {[nD2, nL2, nM, nS2, nL, nD] = createNeg([nD2, nL2, nM, nS2, nL, nD])}
    let OoOA = `${nD2}[${nL2} + (${nM} + ${nS2} × ${nL} - ${nD})]` // 40[100 + (30 + 8 x 40 - 10)]
    let OoOB = `${nD2}[${nL2} + (${nS2} × ${nL} - ${nD} + ${nM})]` // 40[100 + (8 x 40 - 10 + 30)]
    let OoOC = `[${nL2} + (${nM} + ${nS2} × ${nL} - ${nD})] × ${nD2} ` // [100 + (30 + 8 x 40 - 10)] x 40

    let answer = (nL2 + (nM + nS2 * nL - nD)) * nD2;
    let wrong = [...getBasicWrongAnswers(answer, numType),
      (nL2 + (nM + nS2 * nL - nD)) * nD2 + 1,
      (nL2 + nM + nS2) * nL - nD,
      nL2 + nM + (nS2 * nL - nD * nD2),
      ((nL2 + nS2 + nM) * nL - nD) * nD2,
    ]; 
    probData = {prob:selectRand([OoOA, OoOB, OoOC]), answer: roundDec(answer, 3), wrong: fixWrong(answer, wrong, numType)}

  }

  let {wrong, answer, prob} = probData
  wrong = wrong.slice(0,3)
  let AC = answerChoicesKey(answer, ...wrong)

  let problem = {prob, text: randQuest,
    answerChoices: AC,
    correctAnswer: fixNum(answer)
  }
  return problem
}

export const orderOps5 = (userSelection, generalSelection) => { //oooE
  let randQuest = showQText(generalSelection, userSelection) ?  selectRand(simpleQArray) : ''
  let {nL, nL2, nS, nS2, nM, nM2, nM3, numType, steps, dec} = orderOpsNumbers(userSelection)
  let nD = numType === 'Decimals' ? dec.main[0] : nS2 //add & mult, sub
  let nD2 = numType === 'Decimals' ? dec.main[1] : nL //exp, add

  let x = selectRand([' × ', ''])
  let probData = {}
  if (steps === "3 steps") { // x+- x-+ +-x
    if (numType === 'Integers') {[nM3, nD2, nL2, nD] = createNeg([nM3, nD2, nL2, nD])}

    let OoOA = `${nM3}[${nD2} + (${nL2} - ${nD})]` //5[9 + (7 - 2)]
    let OoOB = `${nM3}[(${nL2} - ${nD}) + ${nD2}]` //5[(7 - 2) + 9]
    let OoOC = `[${nD2} + (${nL2} - ${nD})] × ${nM3}` //[9 + (7 - 2)]x5

    let answer = nM3 * (nD2 + (nL2 - nD));
    let wrong = [...getBasicWrongAnswers(answer, numType),
      nM3 + (nD2 + (nL2 + nL2 - nD)),
      nM3 * nD2 + nL2,
      nM3 * nD2 * nL2 - nD,
    ];
    probData = {prob:selectRand([OoOA, OoOB, OoOC]), answer: roundDec(answer, 3), wrong: fixWrong(answer, wrong, numType)}

  } else if (steps === '4 steps') { // x+x- xx-+ x+x-
    if (numType === 'Integers') {[nM3, nD2, nM, nL2, nD] = createNeg([nM3, nD2, nM, nL2, nD])}

    let OoOA = `${nM2}[${nD2} + ${nM}(${nL2} - ${nD})]` // 5[9 + 6(7 - 2)]
    let OoOB = `${nM2}[${nM}(${nL2} - ${nD}) + ${nD2}]` // 5[6(7 - 2) + 9]
    let OoOC = `${nM2} × [${nD2} + ${nM}(${nL2} - ${nD})]` //5x[9 + 6(7 - 2)]

    let answer = nM2 * (nD2 + nM * (nL2 - nD));
    let wrong = [...getBasicWrongAnswers(answer, numType),
      nM2 + (nD2 + nM + (nL2 - nD)),
      nM2 * (nD2 + nM) * (nL2 - nD),
      nM2 * nD2 + (nM * nL2 - nD),
      (nM2 * nD2 + nM) * nL2 - nD,
    ];
    probData = {prob:selectRand([OoOA, OoOB, OoOC]), answer: roundDec(answer, 3), wrong: fixWrong(answer, wrong, numType)}

  } else if (steps === '5 steps'){ // x+x- xxx-+ +xx-x
    if (numType === 'Integers') {[nM3, nD2, nM, nM2, nS, nD] = createNeg([nM3, nD2, nM, nM2, nS, nD])}

    let OoOA = `${nM3} + [${nD2} + ${nM}(${nM2} × ${nS} - ${nD})]` // 5 + [9 + 6(7 x 3 - 2)]
    let OoOB = `${nM3} + [${nM}${x}(${nM2} × ${nS} - ${nD}) + ${nD2}]` // 5 + [6(7 x 3 - 2) + 9]
    let OoOC = `[${nD2} + ${nM}(${nS} × ${nM2} - ${nD})] + ${nM3}` //[9 + 6(3 x 7 - 2)] + 5

    let answer = nM3 + (nD2 + nM * (nM2 * nS - nD));
    let wrong = [...getBasicWrongAnswers(answer, numType),
      nM3 + (nD2 + nM + (nM2 * nS - nD)),
      nM3 + (nD2 + nM) * (nM2 * nS - nD),
      nM3 + nD2 + (nM * nM2 * nS - nD),
      (nM3 + nD2 + nM) * nM2 * nS - nD,
    ];
    probData = {prob:selectRand([OoOA, OoOB, OoOC]), answer: roundDec(answer, 3), wrong: fixWrong(answer, wrong, numType)}

  }      

  let {wrong, answer, prob} = probData
  wrong = wrong.slice(0,3).map(choice => roundDec(choice, 2))
  let AC = answerChoicesKey(answer, ...wrong)

  let problem = {prob, text: randQuest,
    answerChoices: AC,
    correctAnswer: fixNum(answer)
  }
  return problem
}

export const orderOps6 = (userSelection, generalSelection) => { //oooE
  let randQuest = showQText(generalSelection, userSelection) ?  selectRand(simpleQArray) : ''
  let {nL, nL2, nS, nS2, nM, nM2, nM3, steps, numType, dec} = orderOpsNumbers(userSelection)
  const {level} = userSelection.specify

  let [probData, nD, nD2] = [{}, nS, nS2]

  if (numType === 'Decimals') {
    nD = level === '1' ? dec.smallDec[0] : dec.main[0] //mult, dividend
    nD2 = dec.main[1] //mult
  }

  if (steps === "3 steps") {  // x+x
    if (numType === 'Integers') {[nM2, nD, nM, nD2] = createNeg([nM2, nD, nM, nD2])}

    let OoOA = `${nM2} × ${nD} + ${nM} × ${nD2}` // 10 x 2 + 7 x 3
    let OoOB = `${nD2} × ${nL} + ${nM2} × ${nD}` // 7 x 3 + 10 x 2
    let OoOC = `${nD2}(${nM}) + ${nM2}(${nD})` // 7(3) + 10(2)

    let answer = nM2 * nD + nM * nD2
    let wrong = [...getBasicWrongAnswers(answer, numType),
      nD2 * nD, // only one step
      (nM2 * nD + nM) * nD2, // L to R
      nM2 * (nD + nM) * nD2 // Add 1st

    ];
    probData = {prob:selectRand([OoOA, OoOB, OoOC]), answer: roundDec(answer, 3), wrong: fixWrong(answer, wrong, numType)}

  } else if (steps === '4 steps') { // x+x+ +x+x x++x
    if (numType === 'Integers') {[nM2, nD, nL, nD2, nM] = createNeg([nM2, nD, nL, nD2, nM])}

    let OoOA = `${nM2} × ${nD} + ${nL} × ${nD2} + ${nM}` // 10 x 2 + 7 x 3 + 8
    let OoOB = `${nM} + ${nM2} × ${nD} + ${nL} × ${nD2}` // 8 + 10 x 2 + 7 x 3
    let OoOC = `${nM2} × ${nD} + ${nM} + ${nL} × ${nD2}` //10 x 2 + 8 + 7 x 3

    let answer = nM2 * nD + nL * nD2 + nM
    let wrong = [...getBasicWrongAnswers(answer, numType),
        (nM2 * nD + nL + nM) * nD2, // L to R
        nM2 * (nD + nL) * nD2 + nM, // Add 1st
        (nM + nM2 * nD + nL) * nD2 
    ];
    probData = {prob:selectRand([OoOA, OoOB, OoOC]), answer: roundDec(answer, 3), wrong: fixWrong(answer, wrong, numType)}

  } else if (steps === '5 steps'){ // x+x+/ /+x+x x+/+x
    if (numType === 'Integers') {[nM3, nD, nL, nD2, nM, nM2] = createNeg([nM3, nD, nL, nD2, nM, nM2])}
    let dividend = roundDec(nM2 * nM, 2)

    let OoOA = `${nM3} × ${nD} + ${nL} × ${nD2} + ${fixNum(dividend)} ÷ ${nM2}` // 10 x 2 + 7 x 3 + 8 ÷ 4
    let OoOB = `${fixNum(dividend)} ÷ ${nM2} + ${nM3} × ${nD} + ${nL} × ${nD2}` // 8 ÷ 4 + 10 x 2 + 7 x 3
    let OoOC = `${nM3} × ${nD} + ${fixNum(dividend)} ÷ ${nM2} + ${nL} × ${nD2}` //10 x 3 + 8 ÷ 4 + 7 x 3

    let answer = nM3 * nD + nL*nD2 + dividend/nM2;
    let wrong = [...getBasicWrongAnswers(answer, numType),
        ((nM3 * nD + nL) * nD2 + dividend)/ nM2, //L to R
        nM3 * (nD + nL) * nD2 + dividend / nM2, // add first
        ((dividend / nM2 + nM3) * nD + nL) * nD2 // L to R B
    ];
    probData = {prob:selectRand([OoOA, OoOB, OoOC]), answer: roundDec(answer, 3), wrong: fixWrong(answer, wrong, numType)}

  }

  let {wrong, answer, prob} = probData
  wrong = wrong.slice(0,3).map(choice => roundDec(choice, 2))
  let AC = answerChoicesKey(answer, ...wrong)

  let problem = {prob, text: randQuest,
    answerChoices: AC,
    correctAnswer: fixNum(answer)
  }
  return problem
}

export const orderOps7 = (userSelection, generalSelection) => { 
  let randQuest = showQText(generalSelection, userSelection) ?  selectRand(simpleQArray) : ''
  let {nL, nL2, nS, nS2, nM, nM2, nM3, numType, steps, dec} = orderOpsNumbers(userSelection)
  const {level} = userSelection.specify
  let [probData, nD, nD2] = [{}, nS, nS2]

  if (numType === 'Decimals') {
    nD = level === '1' ? dec.smallDec[0] : dec.main[0] //dividend2, 
    nD2 = level === '1' ? dec.smallDec[1] : dec.main[1] //dividend, sub
  }

  if (steps === "3 steps") {  // /+/
    if (numType === 'Integers') {[nL, nM, nM2, nD] = createNeg([nL, nM, nM2, nD])}
    let [dividend, dividend2] = [roundDec(nL * nM, 2), roundDec(nM2 * nD, 2)]

    let OoOA = `${fixNum(dividend)} ÷ ${nM} + ${fixNum(dividend2)} ÷ ${nM2}` // 10 / 2 + 7 / 3

    let answer = nL + nD
    let extraWrong = (dividend / nM + dividend2) / nM2
    let wrong = [...getBasicWrongAnswers(answer, numType), nL + dividend2,
      extraWrong, extraWrong+1, // L to R
    ];
    probData = {prob: OoOA, answer: roundDec(answer, 3), wrong: fixWrong(answer, wrong, numType)}

  } else if (steps === '4 steps') { // /+/x /x+/
    if (numType === 'Integers') {[nL, nM, nM2, nD, nM3] = createNeg([nL, nM, nM2, nD, nM3])}
    let [dividend, dividend2] = [roundDec(nL * nM, 2), roundDec(nM2 * nD, 2)]

    let OoOA = `${fixNum(dividend)} ÷ ${nM} + ${fixNum(dividend2)} ÷ ${nM2} × ${nM3}` // 10 ÷ 2 + 7 ÷ 3 x 8
    let OoOB = `${fixNum(dividend2)} ÷ ${nM2} × ${nM3} + ${fixNum(dividend)} ÷ ${nM}` // 8 ÷ 10 x 2 + 7 ÷ 3

    let answer = nL + nD * nM3
    let wrong = [...getBasicWrongAnswers(answer, numType), nD * nM3, //one of the steps
        (dividend / nM + dividend2 / nM2) * nM3, // L to R
        (dividend / nM + dividend2) / nM2 * nM3, // Add 1st
        (dividend2 / nM2 * (nM3 + dividend) / nM)
    ];
    probData = {prob:selectRand([OoOA, OoOB]), answer: roundDec(answer, 3), wrong: fixWrong(answer, wrong, numType)}

  } else if (steps === '5 steps'){ // x+x+/ /+x+x x+/+x
    if (numType === 'Integers') {[nL, nM, nL2, nD2, nM2, nD] = createNeg([nL, nM, nL2, nD2, nM2, nD])}
    let [dividend, dividend2] = [roundDec(nL * nM, 2), roundDec(nM2 * nD, 2)]

    let OoOA = `${fixNum(dividend)} ÷ ${nM} × ${nL2} - ${nD2} + ${fixNum(dividend2)} ÷ ${nM2}` // 8 ÷ 10 x 2 - 3 + 7 ÷ 3
    let OoOB = `${fixNum(dividend2)} ÷ ${nM2} + ${fixNum(dividend)} ÷ ${nM} × ${nL2} - ${nD2}` // 10 ÷ 2 + 7 ÷ 3 x 8 - 3

    let answer = nL * nL2 - nD2 + nD
    let wrong = [...getBasicWrongAnswers(answer, numType), nM2 + nS * nL2, // miss last step
      (dividend/nM + dividend2 / nM2) * nL2 - nD2, // L to R
      (dividend2 / nM2 + dividend) / nM * nL2 - nD2, // Add 1st
      dividend2 / nM2 * (nL2 - nD2 + dividend) / nM
    ];
    probData = {prob:selectRand([OoOA, OoOB]), answer: roundDec(answer, 3), wrong: fixWrong(answer, wrong, numType)}

  }      

  let {wrong, answer, prob} = probData
  wrong = wrong.slice(0,3).map(choice => roundDec(choice, 2))
  let AC = answerChoicesKey(answer, ...wrong)

  let problem = {prob, text: randQuest,
    answerChoices: AC,
    correctAnswer: fixNum(answer)
  }
  return problem
}
export const orderOps8 = (userSelection, generalSelection) => { 
  let randQuest = showQText(generalSelection, userSelection) ?  selectRand(simpleQArray) : ''
  let {nL, nL2, nS, nS2, nM, nM2, nM3, numType, steps, dec} = orderOpsNumbers(userSelection)
  const {level} = userSelection.specify
  let [probData, nD, nD2] = [{}, nS, nS2]

  if (numType === 'Decimals') {
    nD = level === '1' ? dec.smallDec[0] : dec.medDec[0] // exp, mult
    nD2 = dec.main[1] //sub
  }

  if (steps === "3 steps") {  // +/x /x+
    if (numType === 'Integers') {[nD2, nL2, nM, nD] = createNeg([nD2, nL2, nM, nD])}
    let dividend = roundDec(nL2 * nM, 2)

    let OoOA = `${nD2} + ${fixNum(dividend)} ÷ ${nM} × ${nD}` // 10 + 8 / 4 x 3
    let OoOB = `${fixNum(dividend)} ÷ ${nM} × ${nD} + ${nD2}` // 8 / 4 x 3 + 10
    let OoOC = `${nD2} + ${nD}(${fixNum(dividend)} ÷ ${nM})` // 10 + 3(8 / 4)

    let answer = nD2 + nL2 * nD
    let extraWrong = ((dividend + nD2) / nM) * nD  // L to R
    let wrong = [...getBasicWrongAnswers(answer, numType), nL2 * nD, //miss last step
      extraWrong, extraWrong+1, // L to R
    ];
    probData = {prob:selectRand([OoOA, OoOB, OoOC]), answer: roundDec(answer, 3), wrong: fixWrong(answer, wrong, numType)}

  } else if (steps === '4 steps') { // -+/x /x+-
    if (numType === 'Integers') {[nL, nD2, nL2, nM, nD] = createNeg([nL, nD2, nL2, nM, nD])}
    let dividend = roundDec(nL2 * nM, 2)

    let OoOA = `${nL} - ${nD2} + ${fixNum(dividend)} ÷ ${nM} × ${nD}` // 10 - 2 + 8 / 4 x 3
    let OoOB = `${fixNum(dividend)} ÷ ${nM} × ${nD} + ${nL} - ${nD2}` // 8 / 4 x 3 + 10 - 2
    let OoOC = `${nL} + ${fixNum(dividend)} ÷ ${nM} × ${nD} - ${nD2}` // 10 + 8 / 4 x 3 - 2

    let answer = nL - nD2 + nL2 * nD
    let extraWrong = ((nL - nD2 + dividend) / nM) * nD // L to R
    let wrong = [...getBasicWrongAnswers(answer, numType), nL2 * nD + nL, //one of the steps
        extraWrong, extraWrong+1, // L to R
        (dividend / nM * nD + nD2) - nL, // Add 1st
    ];
    probData = {prob:selectRand([OoOA, OoOB, OoOC]), answer: roundDec(answer, 3), wrong: fixWrong(answer, wrong, numType)}

  } else if (steps === '5 steps'){ // x-+/x  /x+x-
    if (numType === 'Integers') {[nM3, nL, nD2, nL2, nM, nD] = createNeg([nM3, nL, nD2, nL2, nM, nD])}
    let dividend = roundDec(nL2 * nM, 2)

    let OoOA = `${nM3} × ${nL} - ${nD2} + ${fixNum(dividend)} ÷ ${nM} × ${nD}` // 10 x 5 - 2 + 8 / 4 x 3
    let OoOB = `${fixNum(dividend)} ÷ ${nM} × ${nD} + ${nL} × ${nM3} - ${nD2}` // 8 / 4 x 3 + 10 x 5 - 2
    let OoOC = `${nM3} × ${nL} + (${fixNum(dividend)} ÷ ${nM} × ${nD} - ${nD2})` // 10 x 5 + (8 / 4 x 3 - 2)

    let answer = nL*nM3 - nD2 + nL2 * nD
    let extraWrong = ((nL*nM3 - nD2 + dividend) / nM) * nD // L to R
    let wrong = [...getBasicWrongAnswers(answer, numType), 
      nL2 * nD + nL * nM3, //one of the steps
      extraWrong, extraWrong+1, // L to R
      (dividend / nM * nD + nS2) * nM3 - nL, // Add 1st
    ];
    probData = {prob:selectRand([OoOA, OoOB, OoOC]), answer: roundDec(answer, 3), wrong: fixWrong(answer, wrong, numType)}

  } 

  let {wrong, answer, prob} = probData
  wrong = wrong.slice(0,3).map(choice => roundDec(choice, 2))
  let AC = answerChoicesKey(answer, ...wrong)

  let problem = {prob, text: randQuest,
    answerChoices: AC,
    correctAnswer: fixNum(answer)
  }
  return problem
}
export const orderOps9 = (userSelection, generalSelection) => { 
  let randQuest = showQText(generalSelection, userSelection) ?  selectRand(simpleQArray) : ''
  let {nL, nL2, nS, nS2, nM, nM2, nM3, numType, steps, dec} = orderOpsNumbers(userSelection)
  nL = numType === 'Integers' ? nL : roundDec(nL + nS + nS2 + nM2, 2) //make sure newL is big enough to subtract nS & nS2 & nM2

  const {level} = userSelection.specify
  let [probData, nD, nD2] = [{}, nS, nS2]

  if (numType === 'Decimals') {
    nD = dec.medDec[0] // sub
    nD2 = level === '1' ? dec.smallDec[0] :dec.main[1] //sub, add exp
  }

  if (steps === "3 steps") {  // -+- --+
    if (numType === 'Integers') {[nL, nD2, nM, nD] = createNeg([nL, nD2, nM, nD])}

    let OoOA = `${nL} - ${nD2} + ${nM} - ${nD}` // 10 - 2 + 7 - 3
    let OoOB = `${nL} - ${nD2} - ${nD} + ${nM}` // 10 - 2 - 2 + 7

    let answer = nL - nD2 + nM - nD
    let extraWrong = nL - (nD2 + nM) - nD // add first
    let wrong = [...getBasicWrongAnswers(answer, numType), 
      nL - nD2 + nM, nL - nD2 - nD, //miss last step
      extraWrong, extraWrong+1,
    ];
    probData = {prob:selectRand([OoOA, OoOB]), answer: roundDec(answer, 3), wrong: fixWrong(answer, wrong, numType)}

  } else if (steps === '4 steps') { // -+-+ -++-
    if (numType === 'Integers') {[nL, nD2, nM, nD, nL2] = createNeg([nL, nD2, nM, nD, nL2])}

    let OoOA = `${nL} - ${nD2} + ${nM} - ${nD} + ${nL2}` // 10 - 2 + 7 - 3 + 5
    let OoOB = `${nL} - ${nD2} + ${nM} + ${nL2} - ${nD} ` // 10 - 2 + 7 + 5 - 3

    let answer = nL - nD2 + nM - nD + nL2
    let extraWrong = nL - (nD2 + nM + nL2) - nD // add first
    let wrong = [...getBasicWrongAnswers(answer, numType), 
      nL - nD2 + nM - nD, nL - nD2 + nM + nL2, //miss last step
      extraWrong, extraWrong+1,
    ];
    probData = {prob:selectRand([OoOA, OoOB]), answer: roundDec(answer, 3), wrong: fixWrong(answer, wrong, numType)}

  } else if (steps === '5 steps'){ // x-+/x  /x+x-
    if (numType === 'Integers') {[nL, nD2, nM, nD, nL2, nM2] = createNeg([nL, nD2, nM, nD, nL2, nM2])}

    let OoOA = `${nL} - ${nD2} + ${nM} - ${nD} + ${nL2} - ${nM2}` // 10 - 2 + 7 - 3 + 5 - 3
    let OoOB = `${nL} - ${nD2} - ${nD} + ${nM} - ${nM2} + ${nL2}` // 10 - 2 - 3 + 7 - 3 + 5

    let answer = nL - nD2 + nM - nD + nL2 - nM2
    let extraWrong = nL - (nD2 + nM) - (nD + nL2) - nM2 // add first
    let wrong = [...getBasicWrongAnswers(answer, numType), 
      nL - nD2 + nM - nD + nL2, nL - nD2 - nD + nM - nM2, //miss last step
      extraWrong, extraWrong+1,
    ];
    probData = {prob:selectRand([OoOA, OoOB]), answer: roundDec(answer, 3), wrong: fixWrong(answer, wrong, numType)}

  }      

  let {wrong, answer, prob} = probData
  wrong = wrong.slice(0,3).map(choice => roundDec(choice, 2))
  let AC = answerChoicesKey(answer, ...wrong)

  let problem = {prob, text: randQuest,
    answerChoices: AC,
    correctAnswer: fixNum(answer)
  }
  return problem
}

export const orderOps10 = (userSelection, generalSelection) => { 
  let randQuest = showQText(generalSelection, userSelection) ?  selectRand(simpleQArray) : ''
  let {nL, nL2, nS, nS2, nM, nM2, numType, steps, dec} = orderOpsNumbers(userSelection)
  let nD = numType === 'Decimals' ? dec.main[0] : nS //sub, add
  let nD2 = numType === 'Decimals' ? dec.main[1] : nS2 //sub
  let probData = {}
  if (steps === "3 steps") {  // x-(-)
    if (numType === 'Integers') {[nM2, nM, nL2, nD] = createNeg([nM2, nM, nL2, nD])}

    let OoOA = `${nM2} × ${nM} - (${nL2} - ${nD})` // 10 x 2 - (7 - 3)
    let OoOB = `${nM}(${nM2}) - (${nL2} - ${nD})` // 2(10) - (7 - 3)

    let answer = nM2 * nM - (nL2 - nD)
    let extraWrong = nM2 * (nL2 - nM - nD) // sub first
    let wrong = [...getBasicWrongAnswers(answer, numType),
      nM2 * nM, nM * nM2 + (nL2 - nD), //miss last steps // add vs sub
      extraWrong, extraWrong+1,
    ];
    probData = {prob:selectRand([OoOA, OoOB]), answer: roundDec(answer, 3), wrong: fixWrong(answer, wrong, numType)}

  } else if (steps === '4 steps') { // x-+(-) x+(-)-
    if (numType === 'Integers') {[nM2, nM, nD2, nL2, nD] = createNeg([nM2, nM, nD2, nL2, nD])}

    let OoOA = `${nM2} × ${nM} - ${nD2} + (${nL2} - ${nD})` // 10 x 2 - 3 + (7 - 3)
    let OoOB = `${nM2}(${nM}) - ${nD2} + (${nL2} - ${nD})` // 10(2) - 3 + (7 - 3)
    let OoOC = `${nM} × ${nM2} + (${nL2} - ${nD}) - ${nD2}` // 2 × 10 + (7 - 3) - 3
    let OoOD = `${nM}(${nM2}) + (${nL2} - ${nD}) - ${nD2}` // 2(10) + (7 - 3) - 3

    let answer = nM2 * nM - nD2 + (nL2 - nD)
    let extraWrong = nM2 * nM - (nD2 + nL2 - nD) // add first
    let wrong = [...getBasicWrongAnswers(answer, numType), 
      nM2 * nM - nD2, //miss last steps //
      extraWrong, extraWrong+1,
    ];
    probData = {prob:selectRand([OoOA, OoOB, OoOC, OoOD]), answer: roundDec(answer, 3), wrong: fixWrong(answer, wrong, numType)}

  } else if (steps === '5 steps'){ // x+(-)+(-) (-)+x+(-)
    if (numType === 'Integers') {[nM2, nM, nL, nD2, nL2, nD] = createNeg([nM2, nM, nL, nD2, nL2, nD])}

    let OoOA = `${nM2} × ${nM} + (${nL} - ${nD2}) + (${nL2} - ${nD})` // 10 x 2 + (8 - 3) + (7 - 3)
    let OoOB = `${nM2}(${nM}) + (${nL} - ${nD2}) + (${nL2} - ${nD})` // 10(2) + (8 - 3) + (7 - 3)
    let OoOC = `(${nL2} - ${nD}) + ${nM}(${nM2}) + (${nL} - ${nD2})` //  + (7 - 3) + 2(10) + (8 - 3)
    let OoOD = `(${nL2} - ${nD}) + ${nM} × ${nM2} + (${nL} - ${nD2})` //  (7 - 3) + 2 x 10 + (8 - 3)

    let answer = nM2 * nM + (nL2 - nD) + (nL - nD2)
    let extraWrong = nM2 * (nM + nL2 - nD + nL - nD2) // add sub first
    let wrong = [...getBasicWrongAnswers(answer, numType), nM2 + (nL - nD2),
      nM2*nM + (nL - nD2), nM2*nM + (nL2 - nD), //miss last steps //
      extraWrong, extraWrong+1,
    ];
    probData = {prob:selectRand([OoOA, OoOB, OoOC, OoOD]), answer: roundDec(answer, 3), wrong: fixWrong(answer, wrong, numType)}

  }

  let {wrong, answer, prob} = probData
  wrong = wrong.slice(0,3).map(choice => roundDec(choice, 2))
  let AC = answerChoicesKey(answer, ...wrong)

  let problem = {prob, text: randQuest,
    answerChoices: AC,
    correctAnswer: fixNum(answer)
  }
  return problem
}
export const orderOps11 = (userSelection, generalSelection) => { 
  let randQuest = showQText(generalSelection, userSelection) ?  selectRand(simpleQArray) : ''
  let {nL, nL2, nS, nS2, nM, nM2, nM3, numType, steps, dec} = orderOpsNumbers(userSelection)
  const {level} = userSelection.specify
  let [probData, nD, nD2] = [{}, nM, nL]

  if (numType === 'Decimals') {
    nD = level === '1' ? dec.smallDec[0] : dec.main[0] // add, mult, dividend
    nD2 = level === '1' ? dec.smallDec[1] : dec.main[1] // mult, dividend2
  }

  let x = selectRand([' × ', ''])

  if (steps === "3 steps") {  // x(-)+ (-)x+ +x(-)
    if (numType === 'Integers') {[nM2, nL2, nS2, nD] = createNeg([nM2, nL2, nS2, nD])}

    let OoOA = `${nM2}${x}(${nL2} - ${nS2}) + ${nD}` // 10 x (5 - 2) + 3
    let OoOB = `(${nL2} - ${nS2}) × ${nM2} + ${nD}`  // (5 - 2) x 10 + 3)
    let OoOC = `${nD} + ${nM2}${x}(${nL2} - ${nS2})`  // 3 + 10 (5 - 2) 

    let answer = nM2 * (nL2 - nS2) + nD
    let extraWrong = nM2 * (nL2 - nS2 + nD) // add sub first
    let wrong = [...getBasicWrongAnswers(answer, numType),
      nM2 *(nL2 - nS2), //miss last steps // add vs sub
      extraWrong, extraWrong+1,
    ];
    probData = {prob:selectRand([OoOA, OoOB, OoOC]), answer: roundDec(answer, 3), wrong: fixWrong(answer, wrong, numType)}

  } else if (steps === '4 steps') { // /x(-)+ (-)x/+ +/x(-)
    if (numType === 'Integers') {[nM2, nD, nL2, nS2, nD2] = createNeg([nM2, nD, nL2, nS2, nD2])}
    let dividend = roundDec(nD * nM2, 2)

    let OoOA = `${fixNum(dividend)} ÷ ${nM2}${x}(${nL2} - ${nS2}) + ${nD2}` // 10 / 2 x (5 - 2) + 3
    let OoOB = `(${nL2} - ${nS2}) × ${fixNum(dividend)} ÷ ${nM2} + ${nD2}`  // (5 - 2) x 10 / 2 + 3
    let OoOC = `${nD2} + ${fixNum(dividend)} ÷ ${nM2}${x}(${nL2} - ${nS2})`  // 3 + 10 / 2 x (5 - 2) 

    let answer = nM * (nL2 - nS2) + nD2
    let extraWrong = nM * (nL2 - nS2 + nD2) // add sub first
    let wrong = [...getBasicWrongAnswers(answer, numType), 
      answer - nD2, //miss last//
      nM2 * nL2 - nS2 + nD2,
      extraWrong, extraWrong+1,
    ];
    probData = {prob:selectRand([OoOA, OoOB, OoOC]), answer: roundDec(answer, 3), wrong: fixWrong(answer, wrong, numType)}

  } else if (steps === '5 steps'){ // x+(-)+(-) (-)+x+(-)
    if (numType === 'Integers') {[nM2, nD, nL2, nS2, nD2, nS] = createNeg([nM2, nD, nL2, nS2, nD2, nS])}
    let [dividend, dividend2] = [roundDec(nD * nM2, 2), roundDec(nD2 * nS, 2)]

    let OoOA = `${fixNum(dividend)} ÷ ${nM2}${x}(${nL2} - ${nS2}) + ${fixNum(dividend2)} ÷ ${nS}` // 10 / 2 x (5 - 2) + 9 / 3
    let OoOB = `${fixNum(dividend)}${x}(${nL2} - ${nS2}) ÷ ${nM2} + ${fixNum(dividend2)} ÷ ${nS}` // 10 x (5 - 2) / 2 + 9 / 3
    let OoOC = `${fixNum(dividend2)} ÷ ${nS} + ${fixNum(dividend)} ÷ ${nM2}${x}(${nL2} - ${nS2})` // 9 / 3 + 10 / 2 x (5 - 2)
    let OoOD = `${fixNum(dividend2)} ÷ ${nS} + ${fixNum(dividend)}${x}(${nL2} - ${nS2}) ÷ ${nM2}` // 9 / 3 + 10 x (5 - 2) / 2 
    let OoOE = `${fixNum(dividend2)} ÷ ${nS} + (${nL2} - ${nS2}) × ${fixNum(dividend)} ÷ ${nM2}` // 9 / 3 + (5 - 2) x 10 / 2 

    let answer = nD * (nL2 - nS2) + nD2
    let extraWrong = nM * (nL2 - nS2 + nS) // add sub first
    let wrong = [...getBasicWrongAnswers(answer, numType), 
      nM * (nL2 - nS2), //miss last steps //
      extraWrong, extraWrong+1,
    ];
    probData = {prob:selectRand([OoOA, OoOB, OoOC, OoOD, OoOE]), answer: roundDec(answer, 3), wrong: fixWrong(answer, wrong, numType)}
  }   

  let {wrong, answer, prob} = probData
  wrong = wrong.slice(0,3).map(choice => roundDec(choice, 2))
  let AC = answerChoicesKey(answer, ...wrong)

  let problem = {prob, text: randQuest,
    answerChoices: AC,
    correctAnswer: fixNum(answer)
  }
  return problem
}

export const randOrderOps = (userSelection, generalSelection) => {
  let probArray = []
  // let probArray = [ orderOps ]
  if (userSelection.specify.exponents) {
    // probArray.push(orderOpsExp, orderOps2Exp, orderOps3Exp, 
    //   orderOps4Exp, orderOps5Exp, orderOps6Exp, orderOps7Exp, 
    //   orderOps8Exp, orderOps9Exp, orderOps10Exp, orderOps11Exp)
  } else {
    probArray.push(orderOps, orderOps2, orderOps3, orderOps4, 
        orderOps5, orderOps6, orderOps7, orderOps8, orderOps9, orderOps10, orderOps11)
  }
  let randProb = selectRand(probArray)
  return randProb(userSelection, generalSelection)
}
//ideas: 
//make order of op problems the answer choices and have them select the one that is or is not the same as a given answer. 

// give two problems asking if the given problems have the same answer. (make it 50/50)

//make it show student solving and they identify what step was messed up.

