import React, { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

import { WSContext } from "../../app/context/ws-context";

const SwitchPremade = (props) => {
  const ws = useContext(WSContext)
  const [inProgress, setInProgress] = useState(true)
  const switchToPremade = props.path.includes('premade')

  const handleClick = () => {
    // if made a premade ws and now want to switch to custom just clear premade selections that were added.
    if (!switchToPremade) {
      ws.handleClearSelections()
    }
  }

  useEffect(()=> {
    // if trying to switch to a premade from custom, check if there is a ws in progress and just prevent the button from appearing in this case
    // for now we dont want to deal with them switching with active selections and accidentally erase their work in progress.
    // in the future we could keep the button appearing but add in a verification that this will erase their previous selections and use the function above.
    if (switchToPremade) {
      let usIP = JSON.parse(localStorage.getItem('us'))
      let wsIP = JSON.parse(localStorage.getItem('ws'))
      
      if (ws.userSelection.length > 0 || ws.createdWorksheetState?.questionAnswerList?.length > 0 || usIP || wsIP){
        setInProgress(true)
      } else {
        setInProgress(false)
      }
    }
  }, [ws.userSelection, ws.generalSelection, ws.createdWorksheetState, switchToPremade])

  return(
    !inProgress || !switchToPremade ? <NavLink className="switch-premade" to={props.path}>
      <button onClick={handleClick}>{props.title}</button>

    </NavLink> : null
  )
}

export default SwitchPremade