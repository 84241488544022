import React from 'react';

const Infinite = (props) => {

return(
  <div className="infinite section-container">
    <div className="description-container">
      {props.paragraph}
    </div>
  </div>
)
}

export default Infinite