import React, { useEffect, useState, useContext } from "react";
import WorksheetList from "./WorksheetList";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import { AuthContext } from "../../app/context/auth-context";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { WSContext } from "../../app/context/ws-context";
import { initialValues } from "../../app/context/ws-store";

const UserWorksheets = (props) => {
  const auth = useContext(AuthContext);
  const ws = useContext(WSContext)
  const [loadedWorksheets, setLoadedWorksheets] = useState();
  const [sortStatus, setSortStatus] = useState('Newest to Oldest')
  const [sortMenu, setSortMenu] = useState(false)
  const [activePage, setActivePage] = useState(1)
  const [startingPage, setStartingPage] = useState(1)
  const [lastPage, setLastPage] = useState(0)
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  useEffect(()=> {
    window.scrollTo(0, 0)
  }, [])
  const getWorksheets = (page, status) => {
    if(auth.user && auth.user.userId) {
      const fetchWorksheets = async () => {
        try {
          const responseData = await sendRequest(
            `/api/worksheets/get-worksheets/${auth.user.userId}`, 'POST',
            JSON.stringify({
              status: status || sortStatus,
              page: page || activePage
            }),
            {
              "Content-Type": "application/json",
            }
          );
          
          setLoadedWorksheets(responseData.worksheets);
        } catch (err) {}
      };
      fetchWorksheets();
    }
  }
  useEffect(() => {
    ws.setInputState(initialValues)
    ws.setEditing(-1)
    if(auth.user && auth.user.userId) {
      const fetchWorksheets = async () => {
        try {
          const responseData = await sendRequest(
            `/api/worksheets/get-worksheets/${auth.user.userId}`, 'POST',
            JSON.stringify({
              status: sortStatus, page: activePage
            }),
            {
              "Content-Type": "application/json",
            }
          );
          const totalWorksheets = await sendRequest(
            `/api/worksheets/get-worksheets/${auth.user.userId}`, 'GET',
          );
          setLoadedWorksheets(responseData.worksheets);
          setLastPage(Math.ceil(totalWorksheets.total/10)) // number mathches value used to slice worksheets in getWorksheets function
        } catch (err) {}
      };
      fetchWorksheets();
    }
  }, [sendRequest, auth.user]);

  const worksheetDeletedHandler = (deletedWorksheetId) => {
    setLoadedWorksheets((prevWorksheets) =>
      prevWorksheets.filter((worksheet) => worksheet.id !== deletedWorksheetId)
    );
  };

  const changeSortStatus = (status) => {
    setSortStatus(status);
    setSortMenu(false);
    getWorksheets(activePage, status)
  }

  const pageForward = (lastPage) => {
    window.scrollTo(0, 0)

    if (activePage >= 4 && activePage < lastPage){
      setStartingPage(startingPage+1)
      setActivePage(activePage+1)
      getWorksheets(activePage+1)

    } else if (activePage < lastPage){
      setActivePage(activePage+1)
      getWorksheets(activePage+1)
    }
  }
  const pageBack = () => {
    if (activePage === 1) {
      return
    }
    window.scrollTo(0, 0)
    if (activePage >= 4 || activePage === startingPage){
      if (startingPage !== 1){
        setStartingPage(startingPage-1)
      }
      setActivePage(activePage-1)
      getWorksheets(activePage-1)

    } else {
      setActivePage(activePage-1)
      getWorksheets(activePage-1)
    }
  }
  const setPageView = (activePage) => {
    setActivePage(activePage)
    getWorksheets(activePage)
    if (activePage === 1){
      setStartingPage(1)
    } else if (activePage === lastPage  && lastPage > 4){
      setStartingPage(lastPage-4)
    }
    window.scrollTo(0, 0)
  }
  const onePage = lastPage === 1
  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      <div className="main-container user-ws">
        <div className="top-container">
          <h3 className="title">My Files</h3>
          <div className="sort-by" onClick={()=> setSortMenu(!sortMenu)}> 
            <div className="sort"> Sort: {sortStatus} <div className="triangle"></div> </div>
            <div className={`sort-menu ${sortMenu && 'active'}`}>
              {sortStatus !== 'Newest to Oldest' &&
                <div className="menu-item" onClick={()=>changeSortStatus('Newest to Oldest')}>Newest to Oldest</div>}
              {sortStatus !== 'Oldest to Newest' && 
                <div className="menu-item" onClick={()=>changeSortStatus('Oldest to Newest')}>Oldest to Newest</div>}
              {sortStatus !== 'A to Z' && 
                <div className="menu-item" onClick={()=>changeSortStatus('A to Z')}>A to Z</div>}
              {sortStatus !== 'Z to A' && 
                <div className="menu-item" onClick={()=>changeSortStatus('Z to A')}>Z to A</div>}
            </div>
          </div>
        </div>
        {isLoading && (<div className="center"><LoadingSpinner /></div>)}

        {!isLoading && loadedWorksheets && (<>
        <WorksheetList
          items={loadedWorksheets}
          worksheetDeletedHandler={worksheetDeletedHandler}
          handleDuplicate={ws.handleDuplicate}

        />
        {!onePage && 
          <div className="page-turner">
            <div className="first-last" >
              <div className="first" onClick={()=>setPageView(1)}>First</div>
              <div className="desktop-hidden" onClick={()=>setPageView(lastPage)}>Last</div>
            </div>
            <div className="button-container">
            <button className="arrow" onClick={pageBack}>{'<'}</button>
              <button onClick = {()=>setPageView(startingPage)} 
                className={`num-btn ${startingPage === activePage && 'active'}`}>{startingPage}</button>
            {startingPage + 1 <= lastPage && 
              <button onClick = {()=>setPageView(startingPage+1)} 
                className={`num-btn ${startingPage+1 === activePage && 'active'}`}>{startingPage+1}</button>
            }
            {startingPage + 2 <= lastPage && 
              <button onClick = {()=>setPageView(startingPage+2)} 
                className={`num-btn ${startingPage+2 === activePage && 'active'}`}>{startingPage+2}</button>
            }
            {startingPage + 3 <= lastPage && 
              <button onClick = {()=>setPageView(startingPage+3)} 
                className={`num-btn ${startingPage+3 === activePage && 'active'}`}>{startingPage+3}</button>
            }
            {/* Check that starting page & last page is high enough, and last page is more than 1 away */}
            {startingPage + 3 < lastPage && lastPage > 4 && (lastPage - (startingPage + 3) > 1) && 
              <p className="ellipsis">...</p>
            }
            {startingPage + 3 < lastPage && lastPage > 4 && <>
              <button onClick = {()=>setPageView(lastPage)} 
                className={`num-btn ${lastPage === activePage && 'active'}`}>{lastPage}</button></>
            }
            <button className="arrow" onClick={()=>pageForward(lastPage)}>{'>'}</button>
          </div>
          <div onClick={()=>setPageView(lastPage)} className="last mobile-hidden">Last</div>
          </div>
        }
        </>
        )}
      </div>
    </React.Fragment>
  );
};

export default UserWorksheets;
