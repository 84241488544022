import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";

import UserWorksheets from "../worksheet/user-worksheets/UserWorksheets";
import ResetPwd from "../user/ResetPwd";
import ForgotPwd from "../user/ForgotPwd";
import Activate from "../user/Activate";
import Plan from "../user/Plan";
import PlanInfo from "../user/account/PlanInfo";
import PurchaseTokensPage from "../user/account/PurchaseTokensPage";
import Payment from "../user/account/Payment";
import AccountSettings from "../user/account/AccountSettings";
import IndividualSettings from "../user/account/IndividualSettings";
import AdminSettings from "../user/account/AdminSettings";
// import Authenticate from "../user/Authenticate";
import Login from "../user/Login";
import Signup from "../user/Signup";
import { AuthContext } from "./context/auth-context";

const User = (props) => {
  const auth = useContext(AuthContext);

  // useEffect(() => {
  //   const storedData = JSON.parse(localStorage.getItem("userData"));
  //   if (
  //     storedData &&
  //     storedData.token &&
  //     new Date(storedData.expiration) > new Date()
  //   ) {
  //     auth.login(
  //       storedData
  //     );
  //   }
  // }, [auth.login]);
  return (
    <>
      {/* <Route path="/auth" exact>
        <Authenticate />
      </Route> */}
      <Route path="/login" exact>
        <Login />
      </Route>
      <Route path="/signup" exact>
        <Signup />
      </Route>
      <Route path="/auth/reset-password" exact>
        <ResetPwd />
      </Route>
      <Route path="/auth/forgot-password" exact>
        <ForgotPwd />
      </Route>
      <Route path="/auth/activate" exact>
        <Activate />
      </Route>


      {auth.user ? (
        <>
          <Route path="/account/settings" exact>
            <PurchaseTokensPage />
          </Route>
          {auth.user.authType !== "google" ? (
            <Route path="/account/change-password" exact>
              <AccountSettings />
            </Route>
          ) : null}

          <Route path="/account/purchase-tokens" exact>
            <PurchaseTokensPage />
          </Route>
  

          <Route path="/account/payment" exact>
            <Payment />
          </Route>

          <Route path="/myworksheets" exact>
            <UserWorksheets />
          </Route>
        </>
      ) : (
        <>
          <Route path="/account/*" exact>
            <Redirect to="/" />
          </Route>
          <Route path="/myworksheets" exact>
            <Redirect to="/" />
          </Route>
        </>
      )}
    </>
  );
};

export default User;



      //     <Route path="/account/plan/individual" exact>
      //       <Plan type="individual" currentPlan="Standard" />
      //     </Route>
      //     <Route path="/account/plan/admin" exact>
      //       <Plan type="admin" currentPlan="Standard" />
      //     </Route> 
      //     <Route path="/account/individual" exact>
      //       <IndividualSettings />
      //     </Route>
      //     <Route path="/account/admin" exact>
      //       <AdminSettings />
      //     </Route>
      //     <Route path="/account/plan" exact>
      //       <PlanInfo />
      //     </Route>
      //         <Route path="/auth/activated" exact><Activate/></Route> */}
      // {/* <Route path="/plan" exact>
      //   <Plan type="individual" />
      // </Route>
      // <Route path="/admin-plan" exact>
      //   <Plan type="admin" />
      // </Route>
    //   <Route path="/account/admin-plan" exact>
    //   <PlanInfo />
    // </Route>