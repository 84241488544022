import {answerChoicesKey, randInt, selectRand, shuffleArray} from '../general.js'
import { simplify } from "./simplify";
const { create, all } = require('mathjs')
const config = { number: 'Fraction'}
const math = create(all, config)

export const impFractionNumbers = (userSelection) => {
  const {specify} = userSelection
  let randProb = specify.numbers['10 to 20'] && specify.numbers['Up to 10'] ? randInt(0, 1) : specify.numbers['10 to 20'] ? 1 : 0

  let denom = randProb === 1 ? randInt(10, 20) : randInt(2, 10)

  // let denom 10 to 20 have a max of 90, 1-10 mult by 9
  let num = randInt(denom+1, Math.floor(randProb === 1 ? 90 : denom*9))

  // right now this isn't a selection for users so it is always true, 
  // later just add a check for denominator being == 1,that would be the only case we wouldn't want if they allow simplifying

  if(!specify.simplify){
    let simpFract = simplify(num, denom);
    // if fraction needed to be simplified -> try to prevent the need by adding +1 to the numerator
    if (num !== simpFract.num && denom !== simpFract.denom) {
      num = num+1
      simpFract = simplify(num, denom);
    }
    // go ahead and set simplified fraction, then check if we can still use these numbers
    num = simpFract.num
    denom = simpFract.denom
    if (denom===1 || (randProb === 1 && denom < 10)){
      return impFractionNumbers(userSelection)
    }
  }

return {num, denom}      
}
export const fixImproper = (num, denom, whole=0) => {
  let newNum = num
  denom=Number(denom)
  if (denom===0){ //only way it should be 0 is if it was a wrong answer choice meant to stay 0
    return {num, denom, whole}
  }
  if (num>=denom){
    whole = Math.floor(num/denom)+whole
    newNum = num%denom
  } 
  ({num, denom} = simplify(newNum, denom))
  
  return {num, denom, whole}
}
const openIMQuestArr = ['Write the improper fraction as a mixed number.', 'Convert the improper fraction to a mixed number.', 'Write a mixed number to represent the improper fraction.']
const acIMQuestArr = ['Which mixed number is equivalent to the improper fraction?', 'Which mixed number matches the improper fraction?']


export const impMix = (userSelection, gs) => {
  const {activity} = gs
  var {num, denom} = impFractionNumbers(userSelection)
  var {whole:ansWhole, num:ansNum, denom:ansDenom} = fixImproper(num, denom)
  const prob1 = {num:num, denom:denom, text:selectRand(openIMQuestArr),  improper:true}
  const answer = {num:ansNum, denom:ansDenom, whole:ansWhole, answer:true}
  const shAnswerKey = {num:ansNum, denom:ansDenom, whole:ansWhole}
  let wrong = [];
  let wrongCheck = ({num, denom, whole}) => {
    if (Number.isNaN(num) || Number.isNaN(denom)){
      return
    }
    if (denom !==0){
      let simpFract = simplify(num, denom)
      num = simpFract.num
      denom = simpFract.denom
    }
    let duplicate = false
    for (let i of wrong){
      if ((i.num === num && i.denom === denom && i.whole === whole) || (answer.num === num && answer.denom === denom && answer.whole === whole)){
        duplicate = true
      }
    }
    if(!duplicate){
        wrong.push({num, denom, whole})
    }
  }
  wrongCheck({whole:ansWhole, num:ansDenom, denom:ansNum})
  wrongCheck({whole:ansWhole+1, num:ansNum, denom:ansDenom})
  wrongCheck({whole:ansWhole, num:ansNum, denom: ansDenom+1})
  wrongCheck({whole:ansWhole, num:ansNum+randInt(2,3), denom:ansDenom})
  wrongCheck({whole:ansWhole, num:ansNum+1, denom:ansDenom})
  wrongCheck({whole:ansWhole, num, denom:ansDenom})

  ansWhole !== ansNum && wrongCheck({whole:ansNum, num:ansWhole, denom:ansDenom})
  ansWhole > 1 && wrongCheck({whole:ansWhole-1, num:ansNum, denom:ansDenom})
  ansNum > 1 && wrongCheck({whole:ansWhole, num:ansNum-1, denom:ansDenom})
  ansNum > 3 && wrongCheck({whole:ansWhole, num:ansNum-randInt(2,3), denom:ansDenom})
  wrong=shuffleArray(wrong)

  var AC = answerChoicesKey(answer, wrong[0], wrong[1], wrong[2])

  var problem = {prob: prob1,
      answerChoices: AC,
      correctAnswer: answer, shAnswerKey}
  return problem
}

export const mixImp = (userSelection, gs) => {
  const {activity} = gs
  var {num, denom} = impFractionNumbers(userSelection)
  var {whole:probWhole, num:probNum, denom:probDenom} = fixImproper(num, denom)
  let answer = {num, denom, answer:true,  improper:true}
  let shAnswerKey= {num, denom}
  let prob1 = {num:probNum, denom:probDenom, whole:probWhole, text:'Write the mixed number as an improper fraction'}

  let wrong = [];
  let wrongCheck = ({num, denom}) => {
    if (Number.isNaN(num) || Number.isNaN(denom)){
      return
    }
    if (denom !==0){
      let simpFract = simplify(num, denom)
      num = simpFract.num
      denom = simpFract.denom
    }
    let duplicate = false
    for (let i of wrong){
      if ((i.num === num && i.denom === denom) || (answer.num === num && answer.denom === denom)){
        duplicate = true
      }
    }
    if(!duplicate){
        wrong.push({num, denom, improper: true})
    }
  }
  wrongCheck({num:denom, denom:num,  improper:true})
  wrongCheck({num:num+1, denom, improper:true})
  wrongCheck({num:probWhole*probDenom-probNum, denom,  improper:true })
  wrongCheck({num:(probWhole+probDenom)*probNum, denom,  improper:true})
  wrongCheck({num:num+2, denom, improper:true})
  wrong = shuffleArray(wrong)
  var AC = answerChoicesKey(answer, wrong[0], wrong[1], wrong[2])
  var problem = {prob: prob1,
      answerChoices: AC,
      correctAnswer: answer, shAnswerKey}
  return problem
}

export const randMixedImp = (userSelection, gs) => {
  let probArray = []
  if(userSelection.specify.type['Mixed to Improper']) {
    probArray.push(mixImp)

  }
  if(userSelection.specify.type['Improper to Mixed']) {
    probArray.push(impMix)
  }

  var randProb = selectRand(probArray)
  return randProb(userSelection, gs)
}