export const errorValidation = (specify, validationList, gs, us, editing) => {
  let [maxQuantity, quantity] = gs ? [gs.maxQuantity, gs.quantity] : [null, null];
  let errorList = [];
  let tempList = us && JSON.parse(JSON.stringify(us));
  const oldQuantity = tempList && tempList[editing]?.specify.quantity

  if (editing > -1 && specify?.quantity > oldQuantity && quantity >= maxQuantity){
    errorList.push(true)
  } else if (!specify.quantity){
    errorList.push(true)
  }

 
  for (let i of validationList){
    if(i.type==='radio'){ // Radio button check - name: value; so just verify if it is there at all.
      if (!specify[i.name]){
        errorList.push(true)
      }
    } else if (i.type==='checkbox'){ //checkbox check - name: [value:true] verify at least one true value is inside array.
      if (!specify[i.name] || !Object.values(specify[i.name]).includes(true)) {
        errorList.push(true);
      } 
    }
  }
  return errorList.includes(true)
}