import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import Input from "../shared/components/FormElements/Input";
import Button from "../shared/components/FormElements/Button";
import LoadingSpinner from "../shared/components/UIElements/LoadingSpinner";
import ErrorModal from "../shared/components/UIElements/ErrorModal";
import {
  VALIDATOR_MINLENGTH,
  VALIDATOR_EMAIL,
  VALIDATOR_REQUIRE,
} from "../shared/util/validators";
import { useForm } from "../shared/hooks/form-hook";
import { useHttpClient } from "../shared/hooks/http-hook";
import UserCard from "./UserCard";
import wwLogoIcon from "../app-files/images/icon-only.png";
import GoogleLogin from "react-google-login";
import { AuthContext } from "../app/context/auth-context";

const Authenticate = () => {
  const auth = useContext(AuthContext);
  const history = useHistory();
  const [signupSuccess, setSignupSuccess] = useState(false);
  const { isLoading, error, sendRequest, clearError, setManualError } =
    useHttpClient();
  const [formState, inputHandler, setFormData] = useForm(
    //(initialInputs,initialInputValidity) returns [newState, inputHandler]
    {
      email: {
        value: "",
        isValid: false,
      },
      password: {
        value: "",
        isValid: false,
      },
      password2: {
        value: "",
        isValid: false,
      },
    },
    false
  );
  const authSubmitHandler = async (event) => {
    event.preventDefault();
    try {
      const responseData = await sendRequest(
        `/api/users/signup`,
        "POST",
        JSON.stringify({
          email: formState.inputs.email.value,
          name: formState.inputs.name.value,
          password: formState.inputs.password.value,
          signupDate: new Date(),
        }),
        {
          "Content-Type": "application/json",
        }
      );
      if (responseData.status === "success") {
        setSignupSuccess(true);
      }
      // auth.login(responseData);
      // history.push('/');
    } catch (err) {}
  };

  const handleGoogleLogin = async (googleData) => {
    try {
      const responseData = await sendRequest(
        "/api/users/googleauth",
        "POST",
        JSON.stringify({
          token: googleData.tokenId,
        }),
        {
          "Content-Type": "application/json",
        }
      );
      if (responseData.user && responseData.user.email) {
        auth.login(responseData);
        history.push("/");
      }
    } catch (err) {}
  };

  const handleGoogleLoginFail = (e) => {
    if (!(e && e.error === "idpiframe_initialization_failed")) {
      setManualError("There was an issue connecting with your Google account.");
    }
  };

  if (signupSuccess) {
    return (
      <React.Fragment>
        <div className="authenticate-container">
          <UserCard className="authentication" title={"Sign up"}>
            <div className="account-type" style={{ textAlign: "center" }}>
              <img
                src={wwLogoIcon}
                alt="Worksheet Wonderland"
                style={{ paddingTop: "20px", width: "250px" }}
              />
              <p
                className="blurb"
                style={{
                  color: "purple",
                  fontSize: "2.5em",
                  fontWeight: "bold",
                }}
              >
                Thank You for Registering!
              </p>
              <p
                className="blurb"
                style={{ padding: "20px 0 40px 0", fontWeight: 600 }}
              >
                An email has been sent with instructions to confirm your
                account.
              </p>
            </div>
          </UserCard>
        </div>
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <div className="authenticate-container">
        <ErrorModal error={error} onClear={clearError} />
        <UserCard className="authentication" title={"Sign up"}>
          {isLoading && <LoadingSpinner asOverlay />}

          <form onSubmit={authSubmitHandler} style={{ maxWidth: "100%" }}>
            <Input
              element="input"
              id="name"
              type="text"
              label="Name"
              validators={[VALIDATOR_REQUIRE()]}
              errorText="Please enter a name"
              onInput={inputHandler}
            />
            <Input
              id="email"
              element="input"
              type="text"
              label="Email"
              validators={[VALIDATOR_EMAIL()]}
              errorText="Please enter a valid email."
              onInput={inputHandler}
            />
            <Input
              id="password"
              element="input"
              type="text"
              label="Password"
              validators={[VALIDATOR_MINLENGTH(6)]}
              errorText="Please enter a valid password at least 6 characters."
              onInput={inputHandler}
              hideText={true}
              autocomplete="off"
            />
            <Input
              id="password2"
              element="input"
              type="text"
              label="Re-type Password"
              validators={[VALIDATOR_MINLENGTH(6)]}
              errorText="Please enter a valid password at least 6 characters."
              onInput={inputHandler}
              hideText={true}
              autocomplete="off"
            />

            <Button
              className="btn"
              type="submit"
              disabled={
                !formState.isValid ||
                formState.inputs.password.value !==
                  formState.inputs.password2.value
              }
            >
              {"SIGNUP"}
            </Button>
          </form>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingBottom: "10px",
            }}
          >
            <div
              style={{
                width: 100,
                height: 1,
                borderTop: "1px solid lightgray",
              }}
            />
            <div style={{ color: "lightgray", margin: "0 10px 3px 10px" }}>
              or
            </div>
            <div
              style={{
                width: 100,
                height: 1,
                borderTop: "1px solid lightgray",
              }}
            />
          </div>
          <GoogleLogin
            clientId="841851598822-0bjt2blds2h457dsgrihsrrtkmtr7he8.apps.googleusercontent.com"
            buttonText="Continue with Google"
            onSuccess={handleGoogleLogin}
            onFailure={handleGoogleLoginFail}
            cookiePolicy={"single_host_origin"}
          />
          <div className="switch" style={{ paddingTop: 10 }}>
            {"Already have an account?"}
            <Button sec size="small" to="/login">
              {"LOGIN"}
            </Button>
          </div>
        </UserCard>
      </div>
    </React.Fragment>
  );
};

export default Authenticate;

//include a useForm that renders email and password field, validation- util password at least 5 characters,
