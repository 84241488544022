import React, {useContext, useEffect, useState} from 'react';
import { Link, useLocation } from "react-router-dom";
import {specifyList} from '../user-worksheets/helperFunctions';
import { AuthContext } from "../../app/context/auth-context";
import Button from '../../shared/components/FormElements/Button';
import { WSContext } from '../../app/context/ws-context';
import DirectionsBingo from '../activity-select/DirectionsBingo';
import DirectionsTicTacBingo from '../activity-select/DirectionsTicTacBingo';
import DirectionsScavengerHunt from '../activity-select/DirectionsScavengerHunt';
import RecordingSheets from '../activity-select/RecordingSheets';
import { deviceType } from '../../app/App';
import NewTitle from './NewTitle';

const DisplayUserSelection = (props) => {
  const [activeDelete, setActiveDelete] = useState(null)
  const ws = useContext(WSContext);
  const auth = useContext(AuthContext);
  const {userSelection, generalSelection, createdWorksheetState} = ws
  const {activity, maxQuantity, quantity} = generalSelection
  const [newTitleModal, setNewTitleModal] = useState(false)
  const location = useLocation()
  const path = location.pathname
  const displayAssignment = path === '/display-assignment' 

  const handleDeleteConcept = (i) => {
    let temp = JSON.parse(JSON.stringify(userSelection));
    let tempGS = JSON.parse(JSON.stringify(generalSelection))
    const deleted = temp.splice(i, 1);
    let deletedQuant = deleted[0].specify.quantity
    tempGS.quantity -= deletedQuant
    tempGS.title = '' // reset the title in case deleting this changes the auto title created
    ws.setMainState(null, temp, tempGS)

    // if we delete the last concept in the user selection table, make sure that In progress is not still showing.
    if (temp.length === 0) {
      ws.handleClearSelections()
    }
    setActiveDelete(null)
  };

  const handleSetEditing = (i) => {
    let temp = JSON.parse(JSON.stringify(userSelection));
    let tempGS = JSON.parse(JSON.stringify(generalSelection))
    let edited = temp.slice(i, i+1)
    
    // wipe out settings put for premade so it has to re-check based on their changes
    // whether or not generic directions can be used
    // wipe the title so that if they edit AFTER creating a ws, the saved title will not be shown
    tempGS.premade = false
    tempGS.directions = ''
    tempGS.title = ''

    ws.setMainState(...edited, null, tempGS)
    ws.setEditing(i);
    ws.setPurchase(false); // for worksheets to reset for in progress - if editing we would want it to now show in progress
    localStorage.removeItem('ws') // if they choose to edit, then remove ws so that we must re-create problems based on whatever edits are made.

    props.history.push(`/concept-customization`);
  }
  const handleEditActivity = () => {
    props.history.push(`/activity-selection`);
  }
  const handleEditTitle = () => {
    setNewTitleModal(true)
  }

  const createConceptArray = () => {
    var conceptArray = [];
    for (var i=0; i<userSelection.length;i++) {
      let x = i //handleSelect needs it's own version of x so that index number stays the same
      let edit = x === ws.editing ? 'edit' : null
      conceptArray.push(
        <div className="row" key={i}>
          <div className={`item concept ${edit}`}>{userSelection[i].concept}</div>
          <div className={`item specify ${edit} mobile-hidden`}><ul>{specifyList(userSelection[i].specify, true)}</ul></div>
          <div className={`item quantity ${edit}`}>{userSelection[i].specify.quantity}</div>
          {!displayAssignment && activeDelete !== x &&
            <button aria-label="delete concept" className="trash-button" onClick={() => {setActiveDelete(x);}}></button>
          }
          {!displayAssignment && activeDelete === x &&
            <button aria-label="confirm delete concept" className="trash-button active" onClick={() => {handleDeleteConcept(x);}}></button>
          }
          {
            <button aria-label="edit concept" className="trash-button edit-button" onClick={() => {handleSetEditing(x);}}></button>
          }
        </div>
  
      );
     }
    return conceptArray
  }
  
  const tableFooter = <div className="table-footer">
    <div className="activity">
      <div className="title">Activity:</div>
      <div className="sub sub-activity">
        {activity}
      {
        <button aria-label="change activity" className="trash-button edit-button white" onClick={() => {handleEditActivity();}}></button>
      }
      </div>
    </div>
    <div className="questions">
      <div className="title">Questions:</div>
      <div className="sub">{quantity}{!displayAssignment ? '/'+maxQuantity : ''}</div>
    </div>
  </div>
  let tableFooterTitle 

  let directionsLink 
  if (displayAssignment){
    directionsLink = 
      activity === 'Bingo' ? <DirectionsBingo/> : 
      activity === 'Tic Tac Bingo' ? <DirectionsTicTacBingo/> :
      activity === 'Scavenger Hunt' ? <DirectionsScavengerHunt/> :
      activity === 'Task Cards' ? <RecordingSheets/> : ''
    tableFooterTitle = <div className="table-footer title-footer">
    <div className="activity">
      <div className="title">Title:</div>
      <div className="sub sub-activity">
        {generalSelection.title}
        {!createdWorksheetState.copy && (!ws.purchase || generalSelection.activity === 'Worksheet') && <button aria-label="change activity" className="trash-button edit-button white" onClick={handleEditTitle}></button>}
      </div>
    </div>
  </div>
  }
  return (
    <div className="display-us">
      <div className="us-table" >
        <div className="table-wrap">
          {userSelection.length > 0 && <>
          <div className="main-title">Assignment Concepts</div>
          <div className="sub-titles">
            <div className="title concept">Concept</div>
            <div className="title specify mobile-hidden">Specifications</div>
            <div className="title quantity">Qty</div>
          </div></>
          }
          {userSelection.length > 0 && <div className="row-group">{createConceptArray()}</div>}

        </div>
        {tableFooter}
        {tableFooterTitle}
        {directionsLink}
        {quantity > maxQuantity && 
        <div className="warning">You are now over the maximum limit for this activity. <br/>
        Please either remove questions or change activities. Otherwise the last questions added will be removed 
        to create the assignment
        </div>
        }
      </div>
      <div className="btn-container">
        {userSelection.length > 0 &&
          (path === "/concept-selection" || path === "/activity-selection") ? (
          <Button to="final-selections" className="btn" >
            Create Assignment
          </Button>
        ) : null}
      </div>
      <NewTitle handleStartPDF= {props.handleStartPDF} newTitleModal={newTitleModal} setNewTitleModal={setNewTitleModal} userSelection={userSelection} generalSelection={generalSelection}/>
      {!auth.isLoggedIn && <div style={{color: 'white', paddingBottom: '20px'}}><Link to="/signup" className="signup-freews" style={{color:'white', borderBottom: '1px solid #609285'}}>*Sign up</Link> to download worksheets for free, and get one free activity!</div>}
    </div>
  );
};

export default DisplayUserSelection