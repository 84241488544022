import * as g from "../general";
import {randInt, shuffleArray, selectRand, answerChoicesKey, wrongOptions, cap, fixNum} from '../general'
import {verticalDivide} from '../vertical-align'

export const divNumbers = (userSelection) => {
    let numSelect = userSelection.concept === 'Dividing Whole Numbers' ? userSelection.specify.numbers : userSelection.specify.divide
    var numArray = []
    if (numSelect['2 by 1 digit']) {
        numArray.push([randInt(5, 10), randInt(2, 9)],)
    } if (numSelect['3 by 1 digit']) {
        numArray.push(
            [randInt(50, 99), randInt(2, 9)],
            [randInt(25, 50), randInt(5, 9)],
            [randInt(101, 166), randInt(2, 6)],
            [randInt(166, 249), randInt(2, 4)],
        )
    } if (numSelect['4 by 1 digit']) {
        numArray.push(
            [randInt(500, 999), randInt(2, 9)],
            [randInt(250, 500), randInt(4, 9)],
            [randInt(1000, 1660), randInt(2, 6)],
            [randInt(1660, 2490), randInt(2, 4)],
        )
    } if (numSelect['3 by 2 digit']) { 
        numArray.push(
            [randInt(7, 41), randInt(15, 24)],
            [randInt(3, 19), randInt(35, 50)],
            [randInt(15, 28), randInt(21, 35)],
            [randInt(3, 12), randInt(50, 80)],
        )
    } if (numSelect['4 by 2 digit']) {
        numArray.push(
            [randInt(67, 416), randInt(15, 24)],
            [randInt(28, 199), randInt(35, 50)],
            [randInt(48, 285), randInt(21, 35)],
            [randInt(20, 124), randInt(50, 80)],
        )
    }
    var num = shuffleArray(numArray)
    var [answer, numberS] = num[0]
    var numberL = answer*numberS
    var wrong= shuffleArray(wrongOptions(answer, 'divide', numberL, numberS))

    return {answer, numberS, numberL, wrong}
}
export const divAlg = (userSelection, generalSelection) => {
    var {answer, numberS, numberL, wrong} = divNumbers(userSelection)

    var AC = answerChoicesKey(answer, ...wrong)
    if (userSelection.specify.probStyle==='Set Up'){
        var prob = {numberL, numberS}
    } else {
        prob = `${fixNum(numberL)} ÷ ${fixNum(numberS)} = `
    }
    var problem = {prob: prob,
                answerChoices: AC,
                correctAnswer:fixNum(answer),
                probStyle: userSelection.specify.probStyle
                }
    return problem
}
export const divWholeBasic = (userSelection, generalSelection) => {
    let {numberL, numberS, answer, wrong} = divNumbers(userSelection)
    let prob =`What is the quotient of ${fixNum(numberL)} and ${numberS}?`
    let prob2 =`How many equal groups of ${numberS} can be made with ${fixNum(numberL)}?`
    let prob3 =`How many groups of ${numberS} can be made with ${fixNum(numberL)}?`
    let max = answer > 99 ? 99 : answer
    let randNum = answer > 30 ? randInt(20, max-1) : randInt(1, max-1)
    let randProb = selectRand([prob, prob2, prob3])
    if (userSelection.specify.level === 'Multi-Step'){
        let temp1 =  `What is ${randNum} more than the quotient of ${fixNum(numberL)} and ${numberS}?`
        let temp2 =  `What is the sum of ${randNum} and the quotient of ${fixNum(numberL)} and ${numberS}?`
        let temp3 =  `What is ${randNum} less than the quotient of ${fixNum(numberL)} and ${numberS}?`
        let temp4 =  `What is the product of ${randNum} and the quotient of ${fixNum(numberL)} and ${numberS}?`
        randProb = selectRand([temp1, temp2, temp3, temp4])
        if (randProb === temp1 || randProb === temp2){
            answer = answer + randNum
            wrong = [randNum+numberL, numberL/numberS, numberL+randNum+numberS, answer+1, answer+2]

        } else if (randProb === temp3) {
            answer = answer - randNum
            wrong = [randNum+numberL, numberL/numberS, numberL/numberS+randNum, answer+1, answer+2]

        } else {
            answer = randNum*answer
            wrong = [randNum*numberL, numberL/numberS, answer+1, answer+2]
        }
        if (answer > 4){
            wrong.push(answer-1, answer-2)
        }
    }

    wrong = shuffleArray(g.removeDup(wrong))
    let AC = answerChoicesKey(answer, wrong[0], wrong[1], wrong[2])

    let problem = {prob: randProb,
                answerChoices: AC,
                correctAnswer: fixNum(answer),
                probType: 'Application'
                }

    return problem
}

export const randDivWhole = (userSelection, generalSelection) => {
    var probArray = []

    if (userSelection.specify.probType['Algorithm']){
        probArray.push(divAlg)
    } if (userSelection.specify.probType['Application']) {
        probArray.push(divWholeBasic)

    }
    var randProb = selectRand(probArray)
    return randProb(userSelection, generalSelection)
}