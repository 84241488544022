import { Link } from "react-router-dom";
import { React, useContext, useEffect, useRef, useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { WSContext } from "../../app/context/ws-context";
import { useHttpClient } from "../../shared/hooks/http-hook";
import DownloadModal from "../../shared/components/UIElements/DownloadModal";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import Button from "../../shared/components/FormElements/Button";
import { AuthContext } from "../../app/context/auth-context";
import DownloadContent from "./DownloadContent";
import { useHistory } from "react-router";
import IconReport from "../../app-files/images/icon-report-white.svg";
import IconAddToFiles from "../../app-files/images/icon-add-to-files.svg";
import IconFileSaved from "../../app-files/images/icon-file-saved.svg";
import ReportIssue from "../components/ReportIssue";
import CreateNewVersion from "./CreateNewVersion";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";

const DisplayAssignment = (props) => {
  const [downloadModalOpen, setDownloadModalOpen] = useState(false);
  const [closeLock, setCloseLock] = useState(false);
  const [tokenProcessing, setTokenProcessing] = useState(false);
  const [supportTicketModal, setSupportTicketModal] = useState(false);
  const [newVersionModal, setNewVersionModal] = useState(false)
  const [savingWS, setSavingWS] = useState(false)
  const [wsCopy, setWSCopy] = useState(false)
  const [wsSaved, setWSSaved] = useState(false)
  const ws = useContext(WSContext);
  const auth = useContext(AuthContext);
  const { sendRequest, error, clearError } = useHttpClient();
  const history = useHistory();
  const initialRender = useRef(true);
  const wsDataRef = useRef()
  wsDataRef.current = ws.createdWorksheetState;
  useEffect(() => {
    window.scrollTo(0, 0);

    setTimeout(()=> {
      if (!(wsDataRef.current?.questionAnswerList?.length > 0) && !localStorage.getItem("ws")){
        history.push('/activity-selection')
      }
    }, 500)
  }, []);

  useEffect(() => {
    let wsInProgress = JSON.parse(localStorage.getItem("ws"));
    let us = JSON.parse(localStorage.getItem("us"));
    let gs = JSON.parse(localStorage.getItem("gs"));

    // initial render & has a ws stored in local storage OR in the state
    if (initialRender.current && (ws.createdWorksheetState?.questionAnswerList?.length > 0 || wsInProgress)) {
      initialRender.current = false;
      if (wsInProgress) {

        ws.setGeneralSelection(gs);
        ws.setUserSelection(us);
        if (wsInProgress.copy){
          setWSCopy(true)
          props.handleStartPDF("copy", wsInProgress, us, gs);

        } else {
          setWSCopy(false)
          props.handleStartPDF("new-has-ws", wsInProgress, us, gs);
        }

      } else {
        localStorage.setItem("ws", JSON.stringify(ws.createdWorksheetState));
      }

      if (ws.createdWorksheetState?.copy){
        setWSCopy(true)
      }

      // NOT the initial render, but still has a ws in the state
    } else if (ws.createdWorksheetState?.questionAnswerList?.length > 0){
      if (ws.createdWorksheetState?.copy){
        setWSCopy(true)
        props.handleStartPDF("copy", ws.createdWorksheetState);

      } else {
        setWSCopy(false)
        props.handleStartPDF("new-has-ws", ws.createdWorksheetState, us, gs);

      }
    } 
  }, [ws.createdWorksheetState]);

  const addWorksheetToDatabase = async (purchased) => {
    let worksheetCreated = new Date().toLocaleString();
    let tempWS = JSON.parse(JSON.stringify(ws.createdWorksheetState))
    tempWS.copy = true
    localStorage.setItem("ws", JSON.stringify(tempWS))
    setSavingWS(true)
    try {
      let data = {
        generalSelection: ws.generalSelection,
        userSelection: ws.userSelection,
        creator: auth.user.userId,
        worksheetCopy: ws.createdWorksheetState,
        created: worksheetCreated,
        purchased // quick way to know whether this was an actual purchase or just a saved Worksheet
      };
      await sendRequest(
        `/api/worksheets/${auth.user.userId}`,
        "POST",
        JSON.stringify(data),
        {
          Authorization: auth.token,
          "Content-Type": "application/json",
        }
      );
      setSavingWS(false)
      setWSSaved(true)
    } catch (err) {
      setSavingWS(false)
    }
  };

  let purchaseWorksheet = () => {
    setDownloadModalOpen(true);
  };

  let unlockWorksheet = () => {
    ws.setPurchase(true);
    addWorksheetToDatabase(true);
  };

  let processToken = async () => {
    setTokenProcessing(true);
    try {
      let data = await sendRequest(
        `/api/users/spend-token`,
        "POST",
        JSON.stringify({
          userId: auth.user.userId,
        }),
        {
          Authorization: auth.token,
          "Content-Type": "application/json",
        }
      );
      if (data && data.user) {
        auth.updateUser(data);
      }
    } catch (err) {}
    setTokenProcessing(false);
  };
  const remakeAssignment = (handle, createdWorksheet, us, gs) => {
    setWSCopy(false)
    setWSSaved(false)
    props.handleStartPDF(handle, createdWorksheet, us, gs)
  }
  let showDownloadBtn = ws.purchase ? "hide-button" : null;
  const newAssignment = (
    <Link className="btn-link" to={ws.generalSelection.premade ? "/premade-concept-selection" : "/concept-selection"}>
      <button className="btn" onClick={props.handleClearSelections}>
        Start new assignment
      </button>
    </Link>
  );
  
  let saveWS = (savingWS ? <div className="save-ws"><LoadingSpinner className="small-white"/></div> : 
                wsSaved ? <div className="save-ws saved popover-container"><img src={IconFileSaved} alt=""/><div className="popover">Worksheet is saved to your files.</div></div> :
                <button className="save-ws save-btn popover-container" onClick={()=>addWorksheetToDatabase(false)}><img src={IconAddToFiles} alt=""/><div className="popover">Save this worksheet to your files.</div></button>
  )
  return (
    <div>
      <div className="display-assignment-container">
        {!auth.isLoggedIn && (
          <>
            <Link className="btn-link" to="/signup">
              <button className="btn">SIGN UP TO DOWNLOAD</button>
            </Link>
          </>
        )}
        {auth.isLoggedIn && (
          <>
            {ws.purchase ? <Button onClick={()=>setNewVersionModal(true)}>Remake assignment</Button> :
              <button className="btn" onClick={()=>remakeAssignment('new')}>
                Remake this assignment
              </button>
            }
            {!ws.purchase && (
              <button className={`btn`} onClick={purchaseWorksheet}>
                Purchase Activity
              </button>
            )}
          </>
        )}
        {(ws.purchase || !auth.isLoggedIn) && newAssignment}
        {ws.generalSelection.activity === 'Worksheet' && (!wsCopy) && auth.isLoggedIn && saveWS}
      </div>
      {/* {
        auth.user && auth.user.userId && !auth.user.active ? (<div style={{ padding: '0 0 15px 0' }}><div style={{ padding: '0 15px 15px 15px', color: 'pink' }}>
          Warning: You must have a confirmed email to save worksheets. Click below to send a confirmation email.
        </div><div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              // disabled={isFinished}
              size="small"
              onClick={(e) => {
                // emailHandler(e);
              }}
            >
              Send Account Confirmation Email
            </Button>
          </div></div>) : null
      } */}
      <DownloadModal
        show={downloadModalOpen}
        onClear={() => {
          if (!closeLock) {
            setDownloadModalOpen(false);
          }
        }}
      >
        <DownloadContent
          unlockWorksheet={unlockWorksheet}
          closeModal={() => {
            if (!closeLock) {
              setDownloadModalOpen(false);
            }
          }}
          setCloseLock={setCloseLock}
        />
      </DownloadModal>
      <ErrorModal error={error} onClear={clearError} />
      {newVersionModal && <CreateNewVersion handleStartPDF = {remakeAssignment} newVersionModal={newVersionModal} setNewVersionModal={setNewVersionModal} generalSelection={ws.generalSelection}/>}
      {props.children}
      {!ws.purchase && 
      <div 
        className="report-problem">
        <div className="report-btn" onClick={()=>setSupportTicketModal(true)}>
          <img className="menu-icon" src={IconReport} alt="report"/>
          Report Worksheet Issue
        </div>
      </div>
      }
      <ReportIssue supportTicketModal={supportTicketModal} setSupportTicketModal={setSupportTicketModal}/>

      </div>
  )
}

export default DisplayAssignment;
