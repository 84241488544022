import { specAlg, specify, specAC } from "../helper"



export const AddSubWholeNumbers = (props) => {
  const {probStyle, createLink, ac} = props
  const hz = probStyle === 'Horizontal'
  const [c1, c2] = ['Adding Whole Numbers', 'Subtracting Whole Numbers']
  const [dig1, dig2, dig3, dig4, dig5, dig6, dig7] = ['1 digit numbers', '2 digit numbers', '3 digit numbers', '4 digit numbers', '5 digit numbers', '6 digit numbers', '7 digit numbers']
  const [noRG, RGnoZ, RGZ] = ['No regrouping', 'Regroup without zero', 'Regroup with zero']

  const title = (mod1, mod2) => {
    return `Adding & Subtracting ${mod1} Digit Whole Numbers ${mod2}`
  }

  const createSpec = (spec1, spec2) => {
    let specC1 = [specify('numbers', spec1), specify('probStyle', probStyle), specAlg, specAC(ac)]
    let specC2 = [specify('numbers', spec1), specify('regroup', spec2), specify('probStyle', probStyle), specAlg, specAC(ac)]

    return {[c1]: specC1, [c2]: specC2}
  }
  return (<>
    <div className="buttonGroup">
      <div className="concept-topic">Adding & Subtracting Algorithm (Without Regrouping)</div>
      {createLink([c1, c2], title('1', ''), '', createSpec([dig1],[noRG]), hz ? 18 : 35)}
      {createLink([c1, c2], title('2', ''), '', createSpec([dig2],[noRG]), hz ? 18 : 35)}
      {createLink([c1, c2], title('3', ''), '', createSpec([dig3],[noRG]), hz ? 24 : 35)}
      {createLink([c1, c2], title('4', ''), '', createSpec([dig4],[noRG]), hz ? 24 : 35)}
      {createLink([c1, c2], title('5', ''), '', createSpec([dig5],[noRG]), hz ? 24 : 28)}
      {createLink([c1, c2], title('6', ''), '', createSpec([dig6],[noRG]), hz ? 24 : 28)}
      {createLink([c1, c2], title('7', ''), '', createSpec([dig7],[noRG]), hz ? 24 : 18)}
    </div>
    <div className="buttonGroup">
      <div className="concept-topic">Adding & Subtracting Algorithm (Regrouping)</div>
      {createLink([c1, c2], title('2', '(Regrouping)'), '', createSpec([dig2],[RGZ, RGnoZ]), hz ? 18 : 35)}
      {createLink([c1, c2], title('3', '(Regrouping)'), '', createSpec([dig3],[RGZ, RGnoZ]), hz ? 24 : 35)}
      {createLink([c1, c2], title('4', '(Regrouping)'), '', createSpec([dig4],[RGZ, RGnoZ]), hz ? 24 : 35)}
      {createLink([c1, c2], title('5', '(Regrouping)'), '', createSpec([dig5],[RGZ, RGnoZ]), hz ? 24 : 28)}
      {createLink([c1, c2], title('6', '(Regrouping)'), '', createSpec([dig6],[RGZ, RGnoZ]), hz ? 24 : 28)}
      {createLink([c1, c2], title('7', '(Regrouping)'), '', createSpec([dig7],[RGZ, RGnoZ]), hz ? 24 : 18)}
    </div>
    </>)
}