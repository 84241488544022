import React, { useContext, useState } from "react";
import { useHistory, Route, Link } from "react-router-dom";

import DisplayUserSelection from "../worksheet/components/DisplayUserSelection";
import DisplayAssignment from "../worksheet/display-assign/DisplayAssignment";
import ConceptSelection from "../worksheet/concept-select/ConceptSelection";
import ConceptCustomization from "../worksheet/concept-customize/ConceptCustomization";
import FinalSelections from "../worksheet/final-selections/FinalSelections";

import { handleCreateWorksheet } from "../worksheet/create-worksheet/createWorksheet";
import { Font } from "@react-pdf/renderer";

import { WSContext } from "./context/ws-context";
import { createStyledWS } from "../worksheet/create-worksheet/createStyledWS";
import ActivitySelections from "../worksheet/activity-select/ActivitySelection";
import warningIcon from '../app-files/images/icon-report-white.svg'

import createDoc from "./CreateDoc";
import uuid from 'react-uuid'

import WendyOne from '../app-files/fonts/WendyOne-Regular.ttf';
import Rancho from '../app-files/fonts/Rancho-Regular.ttf';
import Tenali from '../app-files/fonts/TenaliRamakrishna-Regular.ttf';
import Didact from '../app-files/fonts/DidactGothic-Regular.ttf';
import RumRaisin from '../app-files/fonts/RumRaisin-Regular.ttf';
import Acme from '../app-files/fonts/Acme-Regular.ttf'
import Samples from "../general/info/pages/Samples";
import PremadeConceptSelection from "../worksheet/concept-select/PremadeConceptSelection";
import PremadeWorksheets from '../worksheet/premade-worksheets/PremadeWorksheets'
import { AuthContext } from "./context/auth-context";
import PDFViewer from "./PDFViewer";

Font.register({ family: 'Wendy One', src: WendyOne, fontStyle: 'normal', fontWeight: 'bold'  })
Font.register({ family: 'Rancho', src: Rancho, fontStyle: 'normal', fontWeight: 'bold'  })
Font.register({ family: 'Tenali', src: Tenali, fontStyle: 'normal', fontWeight: 'bold'  })
Font.register({ family: 'Didact', src: Didact, fontStyle: 'normal', fontWeight: 'bold'  })
Font.register({ family: 'Rum Raisin', src: RumRaisin, fontStyle: 'normal', fontWeight: 'bold'  })
Font.register({ family: 'Acme', src: Acme, fontStyle: 'normal', fontWeight: 'bold'  })
let fontsLoaded = false
let forceUpdate = null

Promise.all([
  Font.load({fontFamily: 'Wendy One'}),
  Font.load({fontFamily: 'Rancho'}),
  Font.load({fontFamily: 'Tenali'}),
  Font.load({fontFamily: 'Didact'}),
  Font.load({fontFamily: 'Rum Raisin'}),
  Font.load({fontFamily: 'Acme'}),
]).then(() => {
  fontsLoaded = true;
  if (forceUpdate) forceUpdate()
})

const useForceUpdate = () => {
  const [value, setValue] = useState(0)
  return () => setValue(value => value+1)
}


const WS = ({}) => {
  const [draftPDFViewer, setDraftPDFViewer] = useState('')
  const [paidPDFViewer, setPaidPDFViewer] = useState(null)
  const [worksheetWarning, setWorksheetWarning] = useState({repeatAnswers: false, repeatQuestions: false})
  const ws = useContext(WSContext);
  const auth = useContext(AuthContext);
  const {userSelection, generalSelection} = ws
  const history = useHistory();

  forceUpdate = useForceUpdate()

  const createPDFViewer = (finalWorksheet, status, gs) => {
    const key=uuid()
    if (!fontsLoaded){
      forceUpdate()
    }

    return(
      // must keep the iframe wrapper here, seems to cause key issues when stored in teh pdf-viewer and then causes the previous worksheet to generate sometimes with the next ones styles or opposite.
      <div key={key} className={`iframe-wrapper ${gs.activity !== 'Worksheet' && gs.questionStyle !== 'Worksheet' ? 'horizontal-style': 'vertical-style'}`}>
        <PDFViewer pdfDoc={finalWorksheet[status]} status={status} gs={gs}/>
      </div>
    )
  }

  const handleStartPDF = (handle, createdWorksheet, us, gs) => {
    if (handle==='new'){ //need to set worksheet data
      ws.setPurchase(false)
      createWorksheetData(true, gs)

      //worksheet data is already set with copy state / was previously created inside handleDuplicate.
    } else{ 
      if (gs && gs.activity === 'Worksheet' && auth.isLoggedIn){
        ws.setPurchase(true)

      } else if (!gs && generalSelection && generalSelection.activity === 'Worksheet' && auth.isLoggedIn) {
        ws.setPurchase(true)
      }
      let styledWSQ = createStyledWS(createdWorksheet, us || userSelection, gs || generalSelection)
      let finalWorksheet = createDoc(styledWSQ, us || userSelection, gs || generalSelection)//<CreateDoc cws={styledWSQ}/>//createPDFDoc(styledWSQ);
      setPaidPDFViewer(createPDFViewer(finalWorksheet, 0, gs || generalSelection))
      setDraftPDFViewer(createPDFViewer(finalWorksheet, 1, gs || generalSelection))
      setWorksheetWarning(createdWorksheet.warning)
    }

    if (handle === 'copy') {
      localStorage.removeItem('ws')
      localStorage.removeItem('us')
      localStorage.removeItem('gs')
    }
  };

  // This is used for the initial data creation to create new worksheet questions. Sets both PDF viewers to be ready to display to the user.
  const createWorksheetData = (newWS, genSel) => {
    let gs = genSel || {...generalSelection}

    // create border colors/styles & save styles to gs so it can be saved to db so that it can be regenerated the same in the future.
    let styles = ws.createSVGBorder(newWS, gs)
    gs.styles = styles

    // the actual ws questions
    let createdWorksheetQ = handleCreateWorksheet(userSelection, gs);
    ws.setCreatedWorksheetState(createdWorksheetQ)
    setWorksheetWarning(createdWorksheetQ.warning)

    // ws questions styled for use in the pdf viewer
    let styledWSQ = createStyledWS(createdWorksheetQ, userSelection, gs)
    let tempGS = gs
    tempGS.styles.shuffledLetters = styledWSQ.shuffledLetters // grab these to store the BINGO card letters to save

    ws.setGeneralSelection(tempGS)
    ws.setCreatedWorksheetState(createdWorksheetQ)
    localStorage.setItem('ws', JSON.stringify(createdWorksheetQ))
    localStorage.setItem('gs', JSON.stringify({...tempGS}))
    localStorage.setItem('us', JSON.stringify([...userSelection]))
    
    let finalWorksheet = createDoc(styledWSQ, userSelection, gs)// <CreateDoc cws={styledWSQ}/> //createPDFDoc(styledWSQ);
    setPaidPDFViewer(createPDFViewer(finalWorksheet, 0, gs))
    setDraftPDFViewer(createPDFViewer(finalWorksheet, 1, gs))

    ws.trackGeneratedWorksheets(gs)
  }

  const displayUserSelection = <div className="user-selection"><DisplayUserSelection history={history} handleStartPDF={handleStartPDF}/></div>
  return(<>
      <Route path="/activity-selection" exact
        render={(props) => (
          <React.Fragment>
            <ActivitySelections handleStartPDF={handleStartPDF}/>
            {userSelection.length > 0  && displayUserSelection}
          </React.Fragment>
        )}
      />
      <Route path="/premade-activity-selection" exact
        render={(props) => (
          <React.Fragment>
            <ActivitySelections handleStartPDF={handleStartPDF}/>
            {userSelection.length > 0  && displayUserSelection}
          </React.Fragment>
        )}
      />
      <Route path="/concept-selection" exact><ConceptSelection  />
        {displayUserSelection}
      </Route>
      <Route path="/premade-concept-selection" exact><PremadeConceptSelection history={history}  />
      </Route>
      <Route path="/premade-worksheets"><PremadeWorksheets history={history}  />
      </Route>
      {/* <Route path="/premade-worksheets/:ws-concept"><PremadeWorksheets history={history}  />
      </Route> */}
      <Route path="/concept-customization"><ConceptCustomization/>
        {displayUserSelection}
      </Route>
      <Route exact path="/samples">
        <Samples handleStartPDF={handleStartPDF} />
      </Route>
      <Route path="/final-selections" exact
        render={(props) => (
          <React.Fragment>
            <FinalSelections handleStartPDF={handleStartPDF}/>
            {displayUserSelection}
          </React.Fragment>
        )}
      />
      <Route path="/display-assignment" exact
        render={(props) => (
          <React.Fragment>
            <DisplayAssignment handleStartPDF={handleStartPDF} handleClearSelections={ws.handleClearSelections}>
              {(worksheetWarning?.repeatAnswers || worksheetWarning?.repeatQuestions) &&
                <div className="ws-warning popover-container">
                  <img src={warningIcon} alt="warning"></img>
                  Warning!
                  <div className="popover">
                    <p>{`Due to the concepts and selections chosen we were unable to prevent ${worksheetWarning?.repeatQuestions ? 'repeat questions' : ''}${worksheetWarning?.repeatQuestions && worksheetWarning?.repeatAnswers ? ' and answers.' : worksheetWarning?.repeatAnswers ? 'repeat answers.' : '.'}`}</p>
                  {worksheetWarning?.repeatAnswers && <>
                    <p>Having repeated answers with a scavenger hunt activity may cause it to not go as smoothly. 
                      It can still be used but students may end up back at their original question early. 
                      If that happens, just have them select a problem they haven't done yet and continue. </p>
                    <p>You can also adjust your questions or activity type in the Assignment Concepts table.</p></>}
                  </div>
                </div>}
              <div className={`pdf-viewer-container ${!ws.purchase && ''}`} >
                {ws.purchase ? paidPDFViewer : draftPDFViewer }
              </div>
            </DisplayAssignment>
            {displayUserSelection}
          </React.Fragment>
        )}
      />
    </>
  )
}

export default WS