import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import './Button.css';

const Button = props => {
  const [mouseDown, setMouseDown] = useState(false);
  if (props.href) {
    return (
      <a
        className={`btn ${props.size || 'default'} ${props.sec &&
          'sec'} ${props.danger && 'button--danger'}`}
        href={props.href}
      >
        {props.children}
      </a>
    );
  }
  if (props.to) {
    return (
      <Link
        to={props.to}
        exact={props.exact}
        className={`btn ${props.size || 'default'} ${props.sec &&
          'sec'} ${props.danger && 'button--danger'}`}
        style={props.style ? props.style : {}}
      >
        {props.children}
      </Link>
    );
  }
  return (
    <button
      className={`btn ${props.size || 'default'} ${props.sec &&
        'sec'} ${props.danger && 'button--danger'} ${mouseDown ? 'button-pressed' : ''}`}
      type={props.type}
      onClick={props.onClick}
      disabled={props.disabled}
      style={props.style ? props.style : {}}
      onMouseDown={e => {
        setMouseDown(true);
      }}
      onMouseUp={e => {
        setMouseDown(false);
      }}
      onMouseLeave={() => {
        setMouseDown(false);
      }}
    >
      {props.children}
    </button>
  );
};

export default Button;
