import { specAlg, specify, specAC } from "../helper"

export const AddingWholeNumbers = (props) => {
  const {probStyle, createLink, ac} = props
  const hz = probStyle === 'Horizontal'
  const [c1] = ['Adding Whole Numbers']
  const [dig1, dig2, dig3, dig4, dig5, dig6, dig7] = ['1 digit numbers', '2 digit numbers', '3 digit numbers', '4 digit numbers', '5 digit numbers', '6 digit numbers', '7 digit numbers']
  const title = (mod1) => {
    return `Adding ${mod1} Digit Whole Numbers`
  }

  const createSpec = (spec1) => {
    let specC1 = [specify('numbers', spec1), specify('probStyle', probStyle), specAlg, specAC(ac)]

    return {[c1]: specC1}
  }
  return (<>
    <div className="buttonGroup">
      <div className="concept-topic">Adding Whole Numbers</div>
      {createLink([c1], title('1'), '', createSpec([dig1]), hz ? 18 : 35)}
      {createLink([c1], title('1-2'), '', createSpec([dig1, dig2]), hz ? 18 : 35)}
      {createLink([c1], title('2'), '', createSpec([dig2]), hz ? 18 : 35)}
      {createLink([c1], title('2-3'), '', createSpec([dig2, dig3]), hz ? 24 : 35)}
      {createLink([c1], title('3'), '', createSpec([dig3]), hz ? 24 : 35)}
      {createLink([c1], title('3-4'), '', createSpec([dig3, dig4]), hz ? 24 : 35)}
      {createLink([c1], title('4'), '', createSpec([dig4]), hz ? 24 : 35)}
      {createLink([c1], title('4-5'), '', createSpec([dig4, dig5]), hz ? 24 : 28)}
      {createLink([c1], title('5'), '', createSpec([dig5]), hz ? 24 : 28)}
      {createLink([c1], title('5-6'), '', createSpec([dig5, dig6]), hz ? 24 : 28)}
      {createLink([c1], title('6'), '', createSpec([dig6]), hz ? 24 : 28)}
      {createLink([c1], title('6-7'), '', createSpec([dig6, dig7]), hz ? 24 : 18)}
      {createLink([c1], title('7'), '', createSpec([dig7]), hz ? 24 : 18)}
    </div>
    </>
  )
}

