import {randAddWhole, randSubWhole} from "./problems/whole-numbers/add-sub";
import {randOrderOps} from "./problems/order-of-ops";
import {randAddDec, randSubDec} from './problems/decimals/add-sub-dec';
import {randMultDec} from './problems/decimals/multiply-dec';
import {randDivDec} from './problems/decimals/divide-dec';
import {randTable}from './problems/tables';
import {randAddFract, randSubFract} from './problems/fractions/add-sub-fract';
import {randDivFract} from './problems/fractions/divide-fract'
import {randMultFract} from './problems/fractions/multiply-fract'
import {randMultWhole}from './problems/whole-numbers/multiply';
import {randDivWhole} from './problems//whole-numbers/divide';
import { randArea, randPerimeter } from './problems/area-perimeter';
import { randEquation } from './problems/equations';
import { randAddInt, randSubInt } from "./problems/integers/add-sub-integers";
import { randMultInt } from "./problems/integers/multiply-integers";
import { randDivInt } from "./problems/integers/divide-integers";
import { randCOFract } from "./problems/fractions/co-fractions";
import { randSimplifyFract } from "./problems/fractions/simplify";
import { randCOWhole } from "./problems/whole-numbers/co-whole";
import { randCODec } from "./problems/decimals/co-dec";
import { randCOIntegers } from "./problems/integers/co-integers";
import { randMixedImp } from "./problems/fractions/mixed-improper";

export const conceptCheck = (userSelection) => {
  const result = ({
    'Adding Whole Numbers': randAddWhole,
    'Subtracting Whole Numbers': randSubWhole,
    'Multiplying Whole Numbers': randMultWhole,
    'Dividing Whole Numbers': randDivWhole,
    'Comparing Whole Numbers': randCOWhole,

    'Adding Decimals': randAddDec,
    'Subtracting Decimals': randSubDec,
    'Multiplying Decimals': randMultDec,
    'Dividing Decimals': randDivDec,
    'Comparing Decimals': randCODec,

    'Adding Fractions': randAddFract,
    'Subtracting Fractions': randSubFract,
    'Multiplying Fractions': randMultFract,
    'Dividing Fractions': randDivFract,
    'Comparing Fractions': randCOFract,
    'Simplifying Fractions': randSimplifyFract,
    'Mixed & Improper Fractions': randMixedImp,

    'Input Output Tables': randTable,
    'Area': randArea,
    'Perimeter': randPerimeter,
    'Order of Operations': randOrderOps,

    'Integer Equations': randEquation,
    'Decimal Equations': randEquation,
    'Whole Number Equations': randEquation,

    'Adding Integers' : randAddInt,
    'Subtracting Integers': randSubInt,
    'Multiplying Integers': randMultInt,
    'Dividing Integers': randDivInt,
    'Comparing Integers': randCOIntegers,


  })[userSelection.concept]

    return result

}