import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { AuthContext } from "../../app/context/auth-context";
import UserIcon from "../../shared/components/UIElements/UserIcon";

const AccountSidebar = (props) => {
  const [accType, setAccType] = useState("Teacher");
  let location = useLocation();
  const [active, setActive] = useState(location.pathname);
  const auth = useContext(AuthContext);

  const setPage = (page) => {
    setActive(page);
  };
  const checkPath = (path) => {
    return active === path ? "active" : null;
  };
  // let settings = accType === 'Admin' ?
  //   <Link className={`item ${checkPath('/account/admin')}`} to="/account/admin">Admin Settings</Link>:
  //   <Link className={`item ${checkPath('/account/individual')}`} to="/account/individual">Teacher Settings</Link>

  // let plan = accType === 'Admin' ?
  //   <Link className={`item ${checkPath('/account/admin-plan')}`} to="/account/admin-plan">Plan</Link> :
  //   <Link className={`item ${checkPath('/account/plan')}`} to="/account/plan">Plan</Link>
  return (
    <div className="account-sidebar">
      <UserIcon name={auth.user.name} className="icon medium" />
      <div className="item name">{auth.user.name}</div>
      <Link
        className={`item ${checkPath("/account/settings")}`}
        to="/account/settings"
      >
        Purchase Tokens
      </Link>
      {auth.user.authType !== "google" ? (
        <Link
          className={`item ${checkPath("/account/change-password")}`}
          to="/account/change-password"
        >
          Change Password
        </Link>
      ) : null}
      {/* <Link className={`item ${checkPath('/account/payment')}`} to="/account/payment">Payment Methods</Link> */}
      {/* <Link className={`item ${checkPath('/account/confirm-email')}`} to="/account/confirm-email">Confirm Email</Link> */}
    </div>
  );
};

export default AccountSidebar;
