
import { randInt, shuffleArray, wrongOptions, answerChoicesKey, selectRand, roundDec, fixNum } from "./general"
import { divNumbers } from "./whole-numbers/divide"
import { divNumbers as divDecNumbers} from './decimals/divide-dec'
import { divIntNumbers } from "./integers/divide-integers"
import { multNumbers } from './whole-numbers/multiply'
import { multNumbers as multDecNumbers} from './decimals/multiply-dec'
import { multIntNumbers } from "./integers/multiply-integers"
import { asNumbers } from "./whole-numbers/add-sub"
import { asNumbers as asDecNumbers} from './decimals/add-sub-dec'
import { asIntNumbers } from "./integers/add-sub-integers"


const numberCheck = (us, op) => { //op === operation required to solve equation.
  let [num, intNum, decNum] = op === 'div'? [divNumbers, divIntNumbers, divDecNumbers]:
                              op === 'mult' ? [multNumbers, multIntNumbers, multDecNumbers]: 
                              op === 'add'|| op === 'sub' ? [asNumbers, asIntNumbers, asDecNumbers] : null
  if (us.concept==='Integer Equations'){
    return intNum(us, op);
  } else if (us.concept==='Whole Number Equations'){
    return num(us, op)
  } else if (us.concept==='Decimal Equations'){
    return decNum(us, op)
  }
}
const eqMult = (us)=>{
  var {answer, numberS, numberL, wrong} = numberCheck(us, 'div')

  var AC = answerChoicesKey(answer, wrong[0], wrong[1], wrong[2])
  let prob = selectRand([`${fixNum(numberL)} = ${fixNum(numberS)}x `, `${fixNum(numberS)}x = ${fixNum(numberL)}  `])

  var problem = {prob: prob,
              answerChoices: AC,
              correctAnswer:fixNum(answer),
              }
  return problem


}
const eqDiv = (us)=>{
  var {answer, numberS, numberL, wrong} = numberCheck(us, 'mult')

  var AC = answerChoicesKey(answer, wrong[0], wrong[1], wrong[2])
  
  let prob = selectRand([`${fixNum(numberL)} = x ÷ ${fixNum(numberS)}   `, `x ÷ ${fixNum(numberS)} = ${fixNum(numberL)}   `])

  var problem = {prob: prob,
              answerChoices: AC,
              correctAnswer:fixNum(answer),
              }
  return problem
}
const eqSub = (us)=>{
  var {numberS, numberL, answer, wrong} =  numberCheck(us, 'add')

  var AC = answerChoicesKey(answer, wrong[0], wrong[1], wrong[2])
  let prob = selectRand([`${fixNum(numberL)} = x - ${fixNum(numberS)}   `, `x - ${fixNum(numberS)} = ${fixNum(numberL)}   `])

  var problem = {prob: prob,
              answerChoices: AC,
              correctAnswer:fixNum(answer),
              }
  return problem
}
const eqAdd = (us)=>{
  var {numberS, numberL, answer, wrong} = numberCheck(us, 'sub')

  var AC = answerChoicesKey(answer, wrong[0], wrong[1], wrong[2])
  //numberS - numberL for integers, since both could be whole, integer numbers are already mixed for basic problems where it wont matter 
  // (NumberL isn't always larger it's random), but for the ones where it's necessary (both are positive, we need this to be set the opposite)
  let num1 = us.concept==='Integer Equations' ? numberL : numberS
  let num2 = us.concept==='Integer Equations' ? numberS : numberL 

  let prob = selectRand([`${fixNum(num2)} = x + ${fixNum(num1)}   `, `x + ${fixNum(num1)} = ${fixNum(num2)}   `])

  var problem = {prob: prob,
              answerChoices: AC,
              correctAnswer:fixNum(answer),
              }
  return problem
}











export const randEquation = (us, generalSelection) => {
  let {concept} = us
  let {operation} = us.specify
  let probArray = []
    if (operation.Division)
      probArray.push(eqDiv)
    if (operation.Multiplication)
      probArray.push(eqMult)
    if (operation.Addition)
      probArray.push(eqAdd)
    if (operation.Subtraction)
      probArray.push(eqSub)
  

  let randProb = selectRand(probArray)
  return randProb(us, generalSelection)
}