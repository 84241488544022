import React, { useState, useContext } from "react";
import { useHttpClient } from "../../shared/hooks/http-hook";

import { AuthContext } from "../../app/context/auth-context";
import Card from "../../shared/components/UIElements/Card";
import Button from "../../shared/components/FormElements/Button";
import Modal from "../../shared/components/UIElements/Modal";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import WorksheetData from "./WorksheetData";

const WorksheetItem = (props) => {
  const auth = useContext(AuthContext);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const showDeleteWarningHandler = () => setShowConfirmModal(true);
  const cancelDeleteHandler = () => setShowConfirmModal(false);
  const confirmDeleteHandler = async () => {
    setShowConfirmModal(false);
    try {
      props.worksheetDeletedHandler(props.id);
      await sendRequest(
        `/api/worksheets/${props.id}`,
        "DELETE",
        {},
      );
    } catch (err) {
    }
  };

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      <Modal
        show={showConfirmModal}
        onCancel={cancelDeleteHandler}
        header="Are you sure?"
        hideForm={true}
        footerClass="worksheet-item__modal-actions"
        footer={
          <React.Fragment>
            <Button inverse onClick={cancelDeleteHandler}>
              CANCEL
            </Button>
            <Button danger onClick={confirmDeleteHandler}>
              DELETE
            </Button>
          </React.Fragment>
        }
      >
        <p style={{ margin: 0, padding: '20px'}}>
          Do you want to proceed and delete this worksheet from our database? Please note that it
          can't be undone.
        </p>
      </Modal>
      <li className="ws-item">
        <Card className="content">
          {isLoading && <LoadingSpinner asOverlay />}
          <div>
            <WorksheetData
              key={props.key}
              userSelection={props.userSelection}
              generalSelection={props.generalSelection}
              creatorId={props.creatorId}
              created={props.created}
              worksheetCopy={props.worksheetCopy}
              showDeleteWarningHandler={showDeleteWarningHandler}
              id = {props.id}
            />
          </div>

        </Card>
      </li>
    </React.Fragment>
  );
};

export default WorksheetItem;