import { specify, specAC } from "../helper"

export const AddingFractions = (props) => {
  const {probStyle, createLink, ac} = props
  const hz = probStyle === 'Horizontal'
  const c1 = 'Adding Fractions'
  const [fract, mix, c, uc] = ['Fractions', 'Mixed Numbers', 'Common', 'Uncommon']
  
  const title = (mod1, mod2, mod3) => {
    return `Adding ${mod1} with ${mod2} Denominators up to ${mod3}`
  }
  const createSpec = (spec1, spec2, spec3) => {
    let specAS = [specify('numbers', spec1), specify('denominator', spec2), specify('maxDenominator', spec3), specify('probStyle', probStyle), specAC(ac)]

    return {[c1]: specAS}
  }

  return (<>
      <div className="buttonGroup">
      <div className="concept-topic">Adding Fractions with Common Denominators</div>
      {createLink([c1], title(fract, '', '5') , title(fract, c, '5'), createSpec([fract], [c], '5'), 18)}
      {createLink([c1], title(fract, '', '10'), title(fract, c, '10'), createSpec([fract], [c], '10'), 18)}
      {createLink([c1], title(fract, '', '15'), title(fract, c, '15'), createSpec([fract], [c], '15'), 18)}
      <div className="sec"></div>
      {createLink([c1], title(fract+' & '+mix, '', '5'), title(fract+' & '+mix, c, '5'), createSpec([fract, mix], [c], '5'), 12)}
      {createLink([c1], title(fract+' & '+mix, '', '10'), title(fract+' & '+mix, c, '10'), createSpec([fract, mix], [c], '10'), 12)}
      {createLink([c1], title(fract+' & '+mix, '', '15'), title(fract+' & '+mix, c, '15'), createSpec([fract, mix], [c], '15'), 12)}
      <div className="sec"></div>
      {createLink([c1], title(mix, '', '5'), title(mix, c, '5'), createSpec([mix], [c], '5'), 12)}
      {createLink([c1], title(mix, '', '10'), title(mix, c, '10'), createSpec([mix], [c], '10'), 12)}
      {createLink([c1], title(mix, '', '15'), title(mix, c, '15'), createSpec([mix], [c], '15'), 12)}
    </div>
    <div className="buttonGroup">
      <div className="concept-topic">Adding Fractions with Uncommon Denominators</div>
      {createLink([c1], title(fract, uc, '5'), '', createSpec([fract], [uc], '5'), 18)}
      {createLink([c1], title(fract, uc, '10'), '', createSpec([fract], [uc], '10'), 18)}
      {createLink([c1], title(fract, uc, '15'), '', createSpec([fract], [uc], '15'), 18)}
      <div className="sec"></div>
      {createLink([c1], title(fract+' & '+mix, uc, '5'), '', createSpec([fract, mix], [uc], '5'), 12)}
      {createLink([c1], title(fract+' & '+mix, uc, '10'), '', createSpec([fract, mix], [uc], '10'), 12)}
      {createLink([c1], title(fract+' & '+mix, uc, '15'), '', createSpec([fract, mix], [uc], '15'), 12)}
      <div className="sec"></div>
      {createLink([c1], title(mix, uc, '5'), '', createSpec([mix], [uc], '5'), 12)}
      {createLink([c1], title(mix, uc, '10'), '', createSpec([mix], [uc], '10'), 12)}
      {createLink([c1], title(mix, uc, '15'), '', createSpec([mix], [uc], '15'), 12)}
    </div>
    {/* <div className="buttonGroup">
      <div className="concept-topic">Adding Fractions with Any Denominators (Set up)</div>
      {createLink([c1], title(fract, 'Any', '5'), '', createSpec([fract], [uc, c], '5'), 18)}
      {createLink([c1], title(fract, 'Any', '10'), '', createSpec([fract], [uc, c], '10'), 18)}
      {createLink([c1], title(fract, 'Any', '15'), '', createSpec([fract], [uc, c], '15'), 18)}
      <div className="sec"></div>
      {createLink([c1], title(fract+' & '+mix, 'Any', '5'), '', createSpec([fract, mix], [uc, c], '5'), 18)}
      {createLink([c1], title(fract+' & '+mix, 'Any', '10'), '', createSpec([fract, mix], [uc, c], '10'), 18)}
      {createLink([c1], title(fract+' & '+mix, 'Any', '15'), '', createSpec([fract, mix], [uc, c], '15'), 18)}
      <div className="sec"></div>
      {createLink([c1], title(mix, 'Any', '5'), '', createSpec([mix], [uc, c], '5'), 18)}
      {createLink([c1], title(mix, 'Any', '10'), '', createSpec([mix], [uc, c], '10'), 18)}
      {createLink([c1], title(mix, 'Any', '15'), '', createSpec([mix], [uc, c], '15'), 18)}

    </div>
    <div className="buttonGroup">
      <div className="concept-topic">Adding Fractions with Any Denominators (Horizontal)</div>
      {createLink([c1], title(fract, 'Any', '5'), '', createSpec([fract], [uc, c], '5', 'hz'), 18)}
      {createLink([c1], title(fract, 'Any', '10'), '', createSpec([fract], [uc, c], '10', 'hz'), 18)}
      {createLink([c1], title(fract, 'Any', '15'), '', createSpec([fract], [uc, c], '15', 'hz'), 18)}
      <div className="sec"></div>
      {createLink([c1], title(fract+' & '+mix, 'Any', '5'), '', createSpec([fract, mix], [uc, c], '5', 'hz'), 18)}
      {createLink([c1], title(fract+' & '+mix, 'Any', '10'), '', createSpec([fract, mix], [uc, c], '10', 'hz'), 18)}
      {createLink([c1], title(fract+' & '+mix, 'Any', '15'), '', createSpec([fract, mix], [uc, c], '15', 'hz'), 18)}
      <div className="sec"></div>
      {createLink([c1], title(mix, 'Any', '5'), '', createSpec([mix], [uc, c], '5', 'hz'), 18)}
      {createLink([c1], title(mix, 'Any', '10'), '', createSpec([mix], [uc, c], '10', 'hz'), 18)}
      {createLink([c1], title(mix, 'Any', '15'), '', createSpec([mix], [uc, c], '15', 'hz'), 18)}

    </div> */}
    </>
  )
}