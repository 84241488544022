import { React, useEffect } from "react";
import Education from "../../../app-files/images/education.svg";
import "aos/dist/aos.css";
import AOS from 'aos';
import Differentiate from "../Differentiate";
import Infinite from "../Infinite";
import Cover from "../Cover";
import Teacher from "../Teacher";
import Engage from "../Engage";
import Spiral from "../Spiral";

const Teachers = () => {
  useEffect(() => {
    AOS.init({
      duration : 3000,
      offset: 220,
      once: true,
    });
    window.scrollTo(0,0)

  }, []);
  //Sec        
  const diffPG = <p>Assignments can be <span className="bold MD">differentiated</span> and <span className="bold SB">customized</span> to meet the needs of your students</p>
  //Main
  const engagePG = <p>We create more than just worksheets, but also <span className="bold SB">engaging</span> activities such as scavenger hunts, bingo games, and task cards for you to use in the classroom.</p>
  //Sec
  const spiralPG = <p>Keep concepts fresh in student's minds by continually <span className="bold SD">spiraling</span> back previously taught concepts. With Workhsheet Wonderland you can <span className="bold MD">mix and match</span> any of our concepts into your activities.</p>
  //White
  const infinitePG = <p>We <span className="bold SB">randomly</span> generate all assignments so that you can make <span className="bold MD">endless</span> versions. Easily re-create a similar activity for a student who needs remediation or a re-assessment.</p>
  //Teachers- Main


  const coverPG = 'Let Worksheet Wonderland do all the work so you can save hundreds of hours of planning time searching for the perfect activity.'
  const coverIMG = <img src={Education} alt="education"></img>
  const coverTitle = 'Create custom assignments for your students!'

  return (
    <div className="main-general-container">
      <Cover paragraph={coverPG} image={coverIMG} title={coverTitle} />
      <Differentiate paragraph={diffPG}/>
      <Engage paragraph={engagePG} />
      <Spiral paragraph={spiralPG} />
      <Infinite paragraph= {infinitePG}/>
      <Teacher />
    </div>
  );
};

export default Teachers;
