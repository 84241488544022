import { React, useEffect } from "react";
import Teaching from "../../../app-files/images/teaching.svg";
import AOS from 'aos';
import "aos/dist/aos.css"
import Differentiate from "../Differentiate";
import Infinite from "../Infinite";
import WSExamples from "../WSExamples";
import Cover from "../Cover";

const Schools = () => {
  useEffect(() => {
    AOS.init({
      duration:2000,
      once: true,

    });
    window.scrollTo(0,0)


  }, []);
  const diffPG = <p>Problems are <span className="bold SB">aligned</span> to standards and teachers can <span className="bold MB">differentiate</span> assignments based on their student's needs</p>
  const infinitePG = <p style={{paddingLeft: '3%'}}>No more searching for the perfect resource. We <span className="bold SB">randomly</span> generate all assignments so your teachers will be able to make <span className="bold MD">infinite</span> versions of fully customized assignments.<span className="bold SB"></span> </p>

  const coverPG = 'Save your teachers hundreds of hours of planning time!'
  const coverIMG = <img src={Teaching} alt="teaching"></img>
  const coverTitle = 'Get Worksheet Wonderland Math for your school'

  return (
    <div className="main-general-container">
      <Cover paragraph={coverPG} image={coverIMG} title={coverTitle} buttonText='Request a Quote' />
      <Infinite image={<WSExamples/>} paragraph= {infinitePG}/>
      <Differentiate paragraph = {diffPG}/>
    </div>
  );
};

export default Schools;
