import React, {useEffect, useState} from 'react'
import { Link, useLocation } from 'react-router-dom'

import { useContext } from 'react'
import { WSContext } from '../../app/context/ws-context'
import { WorksheetLinks } from './WorksheetLinks'
import BackArrow from '../../app-files/images/back-arrow.svg'
import { specifyImages } from '../concept-customize/specifyImages'
import { cap } from '../create-worksheet/problems/general'
import SwitchPremade from '../components/SwitchPremade'

const PremadeWorksheets = (props) => {
  const ws = useContext(WSContext);
  const {activity} = ws.generalSelection
  const location = useLocation()
  const concept = location.pathname.split('s/')[1].split('-').map(word => word !== 'of' ? cap(word) : word).join(' ')
  const probStyleImgs = specifyImages[concept]
  const [probStyle, setProbStyle] = useState('Set Up')
  useEffect(()=>{
    
    window.scrollTo(0,0)
    let ps = localStorage.getItem('probStyle')
    if (ps) {
      setProbStyle(ps)
    }
  }, [])

  const handleEditActivity = () => {
    props.history.push(`/premade-activity-selection`);
  }

  // since we are using the URL this is in case the user inputs a wrong/uknown concept.
  const noConceptFound = () => {
    props.history.push('/premade-concept-selection')
  }

  const changeProbStyle = (style) => {
    setProbStyle(style)
    localStorage.setItem('probStyle', style)
  }
  return(
    <div className="main-container premade-ws premade-ws-selection">
      <div className="content">
        <div className='title-container'>
          <h1>
            <Link to='/premade-concept-selection'>
              <button className="back-btn" aria-label="Go Back" onClick= {()=>ws.resetMainState('input')}><img className="back-arrow" src={BackArrow} alt="back"></img></button>
            </Link>
            {concept}
            {/* / Only show the switch to premade button if there is a matching concept (Remove for joined concepts such as Adding & Subtracting. 
              // -in the future could find a way to join them in the customization or default to one of the concepts) */}
            {(concept === 'Mixed & Improper Fractions' || !concept.includes(' & ')) && <SwitchPremade path="/concept-customization" title="Customize concept" />}
          </h1>
          <div className="activity-label">
            <div className="activity-title">Activity: {activity} <button aria-label="change activity" className="trash-button edit-button" onClick={() => {handleEditActivity();}}></button></div>
            <div className="price">{activity === 'Worksheet' ? '(Free)' : '$1.50 or 1 Token'}</div>
          </div>
        </div>
        {probStyleImgs && <div className="prob-style-container">
        <div style={{padding: '10px'}}>Problem Style:</div>
          <div className={`prob-style ${probStyle === 'Set Up' ? 'active' : ''}`} onClick={()=> changeProbStyle('Set Up')}>
            <img src={probStyleImgs[0]} alt="Set up example" style={{height: concept.includes('Div') ? '38px' : concept.includes('Fract') ? '80px' : '50px'}}/>
          </div>
          <div className={`prob-style ${probStyle === 'Horizontal' ? 'active' : ''}`} onClick={()=> changeProbStyle('Horizontal')}>
            <img src={probStyleImgs[1]} alt="Horizontal example" style={{height: concept.includes('Fract')? '44px' :'28px'}}/>
          </div>
        </div>}

        <div className="concept-container ws-links">
          <WorksheetLinks concept={concept} noConceptFound={noConceptFound} probStyle={probStyleImgs ? probStyle : null}/>
        </div>
      </div>
    </div>

  )
}

export default PremadeWorksheets