
// Create the userSelection options based on the concept/specify passed through.
const getOptions = (concept, quantity, specifyArr=[]) => {
  // set default values for commonly used specifications, 
  let newSpecify = {}

  for (let specCategory of specifyArr){
    let {name, value} = specCategory

    if (typeof value !== 'object') {
      newSpecify[name] = value

    // value is an array of specifications to loop through to set each value to true
    } else if (value.length > 0){ 
      newSpecify[name] = newSpecify[name] ? newSpecify[name] : {} // set value if there is a default one in newSpecify (Eg Algorithm)
      for (let item of value) {
        newSpecify[name][item] = true
      }

    }
  }
  const specify = {quantity, ...newSpecify}

  return {concept, specify}
}

// Loops through concepts passed in and creates the userSelection containing all concepts & specifications
export const userSel = (concepts, quantity, specify) => {
  const userSelection = []
  let quantityLeft = quantity
  let conceptQty = Math.ceil(quantity/concepts.length)
  for(let i=0; i< concepts.length; i++){
    let concept = concepts[i]
    userSelection.push(getOptions(concept, quantityLeft > conceptQty ? conceptQty : quantityLeft, specify[concept]))
    quantityLeft-=conceptQty
  }
  return userSelection
}
// Directions
let mixImp = 'Write all of the following mixed numbers as improper fractions.'
let impMix = 'Write all of the following improper fractions as mixed numbers.'
let mixedIM = 'Write all of the improper fractions as mixed numbers, and all of the mixed numbers as improper fractions.'
let simp = 'Fully simplify all of the following fractions.'
let simpNo ='Fully simplify all of the following fractions that require simplifying. (Some may already be simplified.)'
let compare = 'Write a comparison symbol (<, >, or =) between the two numbers that creates a true comparison.'
let orderOps = 'Simplify the expressions below by following the correct order of operations.'
let equations = 'Solve for x.'

export const createDirections = (concept, spec) => {
  let directions = ''
  // for now do not use general directions with any that have answer choices since some AC questions may be "Select the one that is NOT"
  if (spec.ac) {
    return directions
  }
  if (concept === 'Mixed & Improper Fractions' && spec.type){
    directions = (spec.type['Mixed to Improper'] && spec.type['Improper to Mixed']) ? mixedIM : spec.type['Mixed to Improper'] ? mixImp : impMix
  } 
  if (concept === 'Simplifying Fractions' && spec.steps){
    directions = spec.steps['Some may not require simplifying'] ? simpNo : simp
  }
  if ((concept === 'Comparing Fractions' || (concept.includes('Compar') && spec.probType && !spec.probType['Ordering'])) && !spec.ac) {
    directions = compare
  }
  if (concept === 'Order of Operations') {
    directions = orderOps
  }
  if (concept.includes('Equat')) {
    directions = equations
  }
  return directions
}
// Creates general selection based on specified title / activity 
export const generalSel = (concepts, us, title, gs, quantity) => {

  // no columns if it has input output tables since there are more variety of question types that way
  let noColumns = concepts.filter(concept => concept === 'Input Output Tables' || concept === 'Perimeter' || concept === 'Area')
  let spec = us[0].specify
  let directions = createDirections(concepts[0], spec)
  // let savePaperMultiplying = (!concepts[0].includes('Multipl') || concepts[0].includes('Multipl') && !spec.numbers['3 by 2 digit'] && !spec.numbers['2 by 2 digit']) || (concepts[0].includes('Equation') && spec.operation.Multiplication && !spec.multiplying['3 by 2 digit'] && !spec.multiplying['2 by 2 digit'])
  // let savePaperDividing = (concepts[0].includes('Divid') && !spec.numbers['3 by 2 digit'] && !spec.numbers['2 by 2 digit']) || (concepts[0].includes('Equation') && spec.operation.Multiplication && !spec.multiplying['3 by 2 digit'] && !spec.multiplying['2 by 2 digit'])
  let savePaperASFractions = (concepts[0] !== 'Adding Fractions' && concepts[0] !=='Subtracting Fractions') || (!spec.denominator?.Uncommon && (concepts[0] === 'Adding Fractions' || concepts[0] === 'Subtracting Fractions'))
  let savePaperMDFractions = concepts[0] !== 'Dividing Fractions' && (concepts[0] !== 'Multiplying Fractions' || (concepts[0] === 'Multiplying Fractions' && spec.numbers && !spec.numbers['Mixed Numbers'] && !spec.numbers['Mixed Numbers with Whole Numbers']))
  let savePaper = gs.activity === 'Worksheet' && (
    concepts[0] !== 'Order of Operations' && savePaperASFractions && savePaperMDFractions
  )
  
  return {
    mixed: true,
    columns: noColumns.length === 0, 
    savePaper,
    title: title,
    activity: gs.activity,
    questionStyle: gs.questionStyle,
    styles: {colors:{
      sec:'#522b52', secLight: '#b3a0b3', secDark: '#381638',
      main:'#609285', mainLight:'#a8cac2',mainDark:'#405852'}
    }, //WW Colors opposite
    key: null,
    BGQuantity:30,
    ac: true,
    header: {name: true, date: true}, //name, date, period, score, number
    quantity: quantity,
    maxQuantity: gs.activity === 'Scavenger Hunt' ? 24 : gs.activity === 'Tic Tac Bingo' ? 18 : 36,
    premade: true,
    directions
  };
}
