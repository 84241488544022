import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import downloadIcon from '../../app-files/images/icon-download.svg'
import DirectionsBingo from './DirectionsBingo';
import DirectionsScavengerHunt from './DirectionsScavengerHunt';
import DirectionsTicTacBingo from './DirectionsTicTacBingo';
import RecordingSheets from './RecordingSheets';
import WorksheetImg from '../../app-files/images/activity-ws.png'
import TTBImg from '../../app-files/images/activity-ttb.png'
import BingoImg from '../../app-files/images/sample-addsubintegers.png'
import SHImg from '../../app-files/images/activity-sh.png'
import TaskCardsImg from '../../app-files/images/activity-tc.png'

const ActivityDescriptions = (props) => {
  const [activeActivity, setActiveActivity] = useState({'Tic-Tac-Bingo':false, 'Bingo':false,'Worksheet':false, 'Task Cards':false,'Scavenger Hunt':false});
  
  const toggleActiveActivity = (activity) => {
    let tempActiveActivity = {...activeActivity}
    tempActiveActivity[activity] = !tempActiveActivity[activity]
    setActiveActivity(tempActiveActivity)
  }

  const createMax = (max) => {
    return <div className="max-circle">
      <div className="max-title">Max</div>
      <div className="max">{max}</div>
    </div>
  }
  let BINGO = <div className="main-activity-modal">
    <div className="activity-group">
      <div className="title-section">
        <div className="activity-title" onClick={()=>toggleActiveActivity('Bingo')}>
          <img className="activity-icon" src={BingoImg} alt="Bingo"/>
          Bingo {createMax(36)} 
        </div>
        <DirectionsBingo/>
      </div>
      
      <div className={`description ${activeActivity['Bingo'] && 'active'}`}>
        <ul>
        <li>Standard Bingo game where the answers are printed on a game board for each student. </li>
        <li>Students attempt to complete a full row across. </li>
        <li>Depending on how many questions you select there may be "wrong" answers and/or free spaces on the board. </li>
        <li>You will select a problem style of either a worksheet or task cards. </li>
        <li>If you want to keep students on the same pace a good idea is to select task cards and display one task card at a time on your board /projector, <br/>
        otherwise hand the groups the task cards/worksheets and let them play!</li>
        <li>You can place the cards in laminate pockets and have them circle with expos, write on the paper, or use colored tokens.</li>
        <li>We recommend not using answer choices with the Bingo game since it will cause a lot of repeat answers.</li>
        </ul>
      </div>
    </div>
  </div>
  let TICTACBINGO = <div className="main-activity-modal">
    <div className="activity-group">
      <div className="title-section">
        <div className="activity-title" onClick={()=>toggleActiveActivity('Tic-Tac-Bingo')}>
          <img className="activity-icon" src={TTBImg} alt="Tic Tac Bingo"/>
          Tic-Tac-Bingo {createMax(18)}
        </div>
        <DirectionsTicTacBingo/>
      </div>
      <div className={`description ${activeActivity['Tic-Tac-Bingo'] && 'active'}`}>
        <ul>
          <li>This game is a combination of TIC-TAC-TOE where students play to get 3 X's / O's in a row, and Bingo.</li>
          <li>This is made to be played with partners but students can also play solo similar to a regular Bingo, or 3 students can partner with one in the middle who plays with both the other 2 students.</li>
          <li>Each partner pair shares a Bingo card.</li>
          <li>On each Bingo card 2 of each correct answer are placed. (Number, or letter answer if there are answer choices)</li>
          <li>The student who finishes their question first can have first dibs on selecting the answer on their board, or if your student are at different levels you may opt to just let them alternate who goes first.</li>
          <li>You can place the cards in laminate pockets and have them circle with expos, write on the paper, or use colored tokens.</li>
          <li>There can be duplicate answers (especially with answer choices), and that's okay because students will have to strategize more about where to mark their answer.</li>
          <li>Play continues until a student has gotten a straight across the board</li>
          <li>If Someone gets lucky and wins quickly- students can wipe their boards to try again, or go for who can get 2 straights.</li>
          <li>This activity has a maximum of 18 questions due to having to fit 2 of each answer on the board.</li>
        </ul>
      </div>
    </div>
  </div>
  let SCAVENGERHUNT = <div className="main-activity-modal">
    <div className="activity-group">
      <div className="title-section">
        <div className="activity-title" onClick={()=>toggleActiveActivity('Scavenger Hunt')}>
          <img className="activity-icon" src={SHImg} alt="Scavenger Hunt"/>
          Scavenger Hunt {createMax(24)}
        </div>
        <DirectionsScavengerHunt/>
      </div>
      
      <div className={`description ${activeActivity['Scavenger Hunt'] && 'active'}`}>
        <ul>
          <li>Problems are written on the bottom - the answer to that problem is on the top of another card.</li>
          <li>Students can be confident in their answers as they find them, or know the need to fix a question right away if they do not find their answer.</li>
          <li>Students randomly select one question to start with, it does not matter as they will eventaully loop around to all the cards.</li>
          <li>Questions can be printed full page and posted around the room.  </li>
          <li>Questions can be printed 4-8 per page and cut out to use similar to task cards except that students can connect the cards/ verify their answers as they work.</li>
          <li>If you are doing around the room it is a good idea to have students spread out or limit 2-3 per question.</li>
          <li>It can also be a good idea to print a couple versions as task cards/seat work so that students who are having issues around the room can sit as needed.</li>
          <li>**This activity is not compatible with comparing and ordering questions.</li>
        </ul>
      </div>
    </div>
  </div>
  let WORKSHEET = <div className="main-activity-modal">
    <div className="activity-group">
      <div className="title-section">
        <div className="activity-title" onClick={()=>toggleActiveActivity('Worksheet')}>
          <img className="activity-icon" src={WorksheetImg} alt="Worksheet"/>
          Worksheet (Free) {createMax(36)}
        </div>
      </div>
      <div className={`description ${activeActivity['Worksheet'] && 'active'}`}>
        <ul>
          <li>Questions can be styled with or without columns. Questions that are smaller such as math facts may end up with as many as 5 columns to fit more questions on the page.</li>
          <li>Great to use for independent work, homework, or assessments.</li>
          <li>You can customize your header to include Name, Date, Period, Number, or Score.</li>
        </ul>
      </div>
    </div>
  </div>
  let TASKCARDS = <div className="main-activity-modal">
      <div className="activity-group">
        <div className="title-section">
          <div className="activity-title" onClick={()=>toggleActiveActivity('Task Cards')}>
            <img className="activity-icon" src={TaskCardsImg} alt="Task Cards"/>
            Task Cards {createMax(36)}
          </div>
          <RecordingSheets/>
        </div>
        
        <div className={`description ${activeActivity['Task Cards'] && 'active'}`}>
          <ul>
            <li>Simple task card style where questions are printed 4 per page.</li>
            <li>Print and cut out 1 set of cards per group of students.</li>
            <li>Great to use with other games/ table races.</li>
            <li>A great way to give questions at small groups that can be easily adjusted for the students.</li>
          </ul>
        </div>
      </div>
    </div>
  return (<>
    {BINGO}
    {TICTACBINGO}
    {SCAVENGERHUNT}
    {TASKCARDS}
    {WORKSHEET}
    </>
  )
}

export default ActivityDescriptions