import React from 'react';


const UserCard = props => {
  return (
    <div className={`user-card ${props.className}`} style={props.style}>
      <div className="title">
      <h2>{props.title}</h2>
      </div>
      {props.children}
    </div>
  );
};

export default UserCard;
