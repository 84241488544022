import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

import Checkbox from '@material-ui/core/Checkbox';
import uuid from 'react-uuid'



import infoIcon from './../../app-files/images/icon-info.svg'
import { specifyImages } from './specifyImages';

const styles = theme => ({
  radio: {
    '&$checked': {
      color: '#4B8DF8'
    }
  },
  helper: {color: '#b122ec'}
})

export const conceptSpecify = (ws, name, specifyTitle, specifyArray, createValidationList, extraLabels=[]) => {
  let error= false
  createValidationList("checkbox", name)
  const specifyFor = (ws, name, arr) => {
    var newArray = [];
    const eq = ws.inputState?.concept === 'Whole Number Equations' || ws.inputState?.concept === 'Decimal Equations'
    for (var i = 0; i < arr.length; i++) {
      let value = arr[i]
      newArray.push(
        <div className="checkbox-app popover-container">
          <FormControlLabel  key={uuid()} control= {
          <Checkbox name={name} value={value} type="checkbox" checked={ws.inputState?.specify[name]?!!ws.inputState.specify[name][value]:false} 
                    onChange={ws.handleSpecifyInput}  color='primary'/>
          } label={<span className="checkbox">
            {extraLabels[i] ? value + ' ' + extraLabels[i] : value}
          </span>} /> <br />
          
          {arr[i]==='Application' && <div className="specify-info">
              <img src={infoIcon} alt="information"></img>
              <div className="popover">Application Problems are currently simple word problems that include basic vocabulary such as quotient, sum, difference, product, times, greater, less etc.</div>
            </div>}
        </div>
      );
    }

    return newArray;
  };
  if (ws.inputState?.specify[name]){
    error = !Object.values(ws.inputState.specify[name]).includes(true) ? true : false
  }

  return (
    <div className='customize-title-container'>
      <p className='customize-title'>{specifyTitle ? specifyTitle+':' : ''}</p>
      <FormControl FormHelperTextProps={{ style: styles.helper }} required error={error} component="fieldset">
        <FormGroup >
          {specifyFor(ws, name, specifyArray)}
        </FormGroup>
        {error ? <FormHelperText>Please select at least one</FormHelperText>:<div style={{height:'21.89px'}}></div>}
      </FormControl>
    </div>
  );
};
export const conceptAppLevel = (ws, name, probTitle, probStyleArray, createValidationList) => {
  let error = false;
  createValidationList('radio', name)
  let concept = ws.inputState?.concept
  let popoverText = concept?.includes('Add') ? 'Adding only' : concept?.includes('Sub')? 'Adding and Subtracting' : concept?.includes('Mult') ? 'Multiplying with either Adding or Subtracting' : 'Dividing with either Adding, Subtracting, or Multiplying'
  return (
    <div className='customize-title-container question-level-fieldset'>
      <p className='customize-title'>{probTitle}:</p>
      <FormControl component="fieldset">
        <RadioGroup defaultValue={ws.inputState?.specify[name]??null} 
          aria-label={name} name= {name} type='radio' value={ws.value} onChange={ws.handleSpecifyInput}>
            <FormControlLabel key={uuid()} color= 'secondary' className="one-step" value='One Step'  control= {<Radio color='primary'/>} label={probStyleArray[0]} />
            <div className="multi-step popover-container">
              <FormControlLabel className="multi-step" key={uuid()} value='Multi-Step' control= {<Radio color='primary'/>} label= {probStyleArray[1]}/>
              <div className="specify-info">
                <img src={infoIcon} alt="information"></img>
                <div className="popover">{popoverText}</div>      
              </div>
            </div>
        </RadioGroup>

      </FormControl> 
    </div>
  );
};

export const conceptProbStyle = (ws, name, probTitle, probStyleArray, createValidationList) => {
  let setUpLabel = ws.inputState?.concept.includes('Fraction') ? 'Stacked' : 'Set Up'
  let error = false;
  const {concept} = ws.inputState

  let images = specifyImages[concept]
  createValidationList('radio', name)
  return (
    <div className='customize-title-container'>
      <p className='customize-title'>{probTitle}:</p>
      <FormControl component="fieldset">
        <RadioGroup defaultValue={ws.inputState?.specify[name]??null} 
          aria-label={name} name= {name} type='radio' value={ws.value} onChange={ws.handleSpecifyInput}>
            <div style={{display:'flex',alignItems:'center'}}>
            <FormControlLabel key={uuid()} color= 'secondary' value= "Set Up"  control= {<Radio color='primary'/>} label={setUpLabel} />

              {images &&<img className="prob-style-ex" src={images[0]} alt={`${setUpLabel} example`} style={{height: concept.includes('Div') ? '38px' : concept.includes('Fract') ? '80px' : '50px'}}/>}
            </div>
            <div style={{display:'flex',alignItems:'center'}}>
            <FormControlLabel key={uuid()} value='Horizontal' control= {<Radio color='primary'/>} label= "Horizontal"  />

              {images && <img className="prob-style-ex" src={images[1]} alt="Horizontal example" style={{height: concept.includes('Fract')? '44px' :'32px'}}/>}

            </div>
        </RadioGroup>

      </FormControl> 
    </div>
  );
};
export const conceptLevel = (ws, levelTitle, levelArray, createValidationList) => {
  createValidationList('radio','level')
  return (
    <div className='customize-title-container'>
      <p className='customize-title'>{levelTitle}:</p>
      <FormControl component="fieldset">
        <RadioGroup defaultValue={ws.inputState?.specify.level??null} aria-label="level" name="level" type='radio' valueSelected={ws.value} onChange={ws.handleSpecifyInput}>
            <FormControlLabel key={uuid()} color= 'secondary' value='1'  control= {<Radio color='primary'/>} label={'1: '+levelArray[0]} />
            <FormControlLabel key={uuid()} value='2' control= {<Radio color='primary'/>} label={'2: '+levelArray[1]}  />
            <FormControlLabel key={uuid()} value='3' control= {<Radio color='primary'/>} label={'3: '+levelArray[2]}  />
          </RadioGroup>
      </FormControl> 
    </div>
  );
};
export const conceptRadio = (ws, name, title, array, createValidationList) => {
  createValidationList('radio', name)
  let newArray = []
  for (let value of array) {
    newArray.push(
      <FormControlLabel key={uuid()} color= 'secondary' value={value}  control= {<Radio color='primary'/>} label={value} />
    )
  }
  return (
    <div className='customize-title-container'>
      <p className='customize-title'>{title}:</p>
      <FormControl component="fieldset">
        <RadioGroup defaultValue={ws.inputState?.specify[name]??null} aria-label="level" name={name} type='radio' valueSelected={ws.value} onChange={ws.handleSpecifyInput}>
            {newArray}
          </RadioGroup>
      </FormControl> 
    </div>
  );
};