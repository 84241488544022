import { React, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { WSContext } from "../../app/context/ws-context";
import SwitchPremade from "../components/SwitchPremade";
import { conceptsCantBeOnSH } from "../create-worksheet/problems/general";

const useConceptButton = (concept) => {
  const ws = useContext(WSContext);
  if ( ws.generalSelection.activity === 'Scavenger Hunt' && conceptsCantBeOnSH.includes(concept)){
    return (
        <button name="concept" value={concept} className="concept-button disabled" disabled>
          {concept}
        </button>
      );
  }
  return (
      <button type="button" name="concept" value={concept} className="concept-button" onClick={(e) => ws.handleSpecifyInput(e)}>
        {concept}
      </button>
  );
};
const ConceptSelection = (props) => {
  const ws = useContext(WSContext)
  useEffect(()=>{
    window.scrollTo(0, 0);

  }, [])
  const setEditing = ws.setEditing

  useEffect(()=> {
    setEditing(-1);
  }, [setEditing])

  return (
    <div className="main-container concept-selection-container">
      <div className="title-container">
        <h1 className="concept-selection-title">Select Concept to Customize
          <SwitchPremade path="/premade-concept-selection" title="Switch to premade"/>
        </h1>
      </div>

      <div className="concept-container">
        <div className="buttonGroup">
          <div className="concept-topic">Whole Numbers</div>
          <Link className="concept-button-container" to="/concept-customization">
            {useConceptButton("Adding Whole Numbers")}
            {useConceptButton("Subtracting Whole Numbers")}
            {useConceptButton("Multiplying Whole Numbers")}
            {useConceptButton("Dividing Whole Numbers")}
            {useConceptButton("Comparing Whole Numbers")}

          </Link>
        </div>
        <div className="buttonGroup">
          <div className="concept-topic">Integers</div>
          <Link className="concept-button-container" to="/concept-customization">
            {useConceptButton("Adding Integers")}
            {useConceptButton("Subtracting Integers")}
            {useConceptButton("Multiplying Integers")}
            {useConceptButton("Dividing Integers")}
            {useConceptButton("Comparing Integers")}

          </Link>
        </div>
        <div className="buttonGroup">
          <div className="concept-topic">Decimals</div>
          <Link className="concept-button-container" to="/concept-customization">
            {useConceptButton("Adding Decimals")}
            {useConceptButton("Subtracting Decimals")}
            {useConceptButton("Multiplying Decimals")}
            {useConceptButton("Dividing Decimals")}
            {useConceptButton("Comparing Decimals")}

          </Link>
        </div>
        {/* </div> */}

        {/* <div className='concept-container'> */}
        <div className="buttonGroup">
          <div className="concept-topic">Fraction Operations</div>
          <Link className="concept-button-container" to="/concept-customization">
            {useConceptButton("Adding Fractions")}
            {useConceptButton("Subtracting Fractions")}
            {useConceptButton("Multiplying Fractions")}
            {useConceptButton("Dividing Fractions")}
          </Link>
        </div>
        <div className="buttonGroup">
          <div className="concept-topic">Fraction Basics</div>
          <Link className="concept-button-container" to="/concept-customization">
            {useConceptButton("Comparing Fractions")}
            {useConceptButton("Simplifying Fractions")}
            {/* {useConceptButton("GCF & LCM")} */}
            {useConceptButton("Mixed & Improper Fractions")}
          </Link>
        </div>
        {/* </div> */}
        {/* <div className='concept-container'> */}
        <div className="buttonGroup">
          <div className="concept-topic">Equations</div>
          <Link className="concept-button-container" to="/concept-customization">
            {useConceptButton("Whole Number Equations")}
            {useConceptButton("Integer Equations")}
            {useConceptButton("Decimal Equations")}
          </Link>
        </div>
        <div className="buttonGroup">
          <div className="concept-topic">Other Concepts</div>
          <Link className="concept-button-container" to="/concept-customization">
            {useConceptButton("Order of Operations")}
            {useConceptButton("Input Output Tables")}
            {useConceptButton("Area")}
            {useConceptButton("Perimeter")}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ConceptSelection;
