import { specHZ, specify, specAC } from "../helper"

export const ComparingFractions = (props) => {
  const {createLink, ac} = props
  const c1 = 'Comparing Fractions'
  const [fract, c, uc] = ['Fractions', 'Common', 'Uncommon']
  
  const title = (mod1, mod2, mod3) => {
    return `Comparing ${mod1} with ${mod2} Denominators up to ${mod3}`
  }

  const showNoAC = ac === false || ac === 'allowed'
  const showAC = ac === true || ac === 'allowed'

  const createSpec = (spec1, spec2) => {
    let specAS = [specify('denominator', spec1), specify('maxDenominator', spec2), specAC(ac)]

    return {[c1]: specAS}
  }

  return (<>
      <div className="buttonGroup">
      <div className="concept-topic">Comparing Fractions with Common Denominators</div>
      {createLink([c1], title(fract, c, '5') , '', createSpec([c], '5'), 12)}
      {createLink([c1], title(fract, c, '10'), '', createSpec([c], '10'), 12)}
      {createLink([c1], title(fract, c, '15'), '', createSpec([c], '15'), 12)}
    </div>
    <div className="buttonGroup">
      <div className="concept-topic">Comparing Fractions with Uncommon Denominators</div>
      {createLink([c1], title(fract, uc, '5'), '', createSpec([uc], '5'), 12)}
      {createLink([c1], title(fract, uc, '10'), '', createSpec([uc], '10'), 12)}
      {createLink([c1], title(fract, uc, '15'), '', createSpec([uc], '15'), 12)}
    </div>
    <div className="buttonGroup">
      <div className="concept-topic">Comparing Fractions with Any Denominators</div>
      {createLink([c1], title(fract, 'Any', '5'), '', createSpec([uc, c], '5'), 12)}
      {createLink([c1], title(fract, 'Any', '10'), '', createSpec([uc, c], '10'), 12)}
      {createLink([c1], title(fract, 'Any', '15'), '', createSpec([uc, c], '15'), 12)}
    </div>
    </>
  )
}