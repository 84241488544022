import { cap } from "../../create-worksheet/problems/general"
import { specAC, specHZ, specify } from "./helper"

export const OrderofOperations = (props) => {
  const {createLink, ac} = props
  const c1 = 'Order of Operations'
  const [wn, dec, int, s3, s4, s5] = ['Whole numbers', 'Decimals', 'Integers', '3 steps', '4 steps', '5 steps']
  
  const title = (mod1, mod2, mod3) => {
    return `Order of Operations with ${mod1.split(' ').map(w=>cap(w)).join(' ')} (${mod2} steps - Level ${mod3})`
  }
  const createSpec = (spec1, spec2, spec3) => {
    let specAS = [specify('numberType', spec1), specify('steps', spec2), specify('level', spec3), specAC(ac)]

    return {[c1]: specAS}
  }

  return (<>
    <div className="buttonGroup">
      <div className="concept-topic">Order of Operations with Whole Numbers</div>
      <div class="sec-title">Level 1 Smaller numbers</div>
      <div className="sec"></div>
      {createLink([c1], title(wn, '3', '1'), '', createSpec([wn], [s3], '1'), 12)}
      {createLink([c1], title(wn, '3-4', '1'), '', createSpec([wn], [s3, s4], '1'), 12)}
      {createLink([c1], title(wn, '4', '1'), '', createSpec([wn], [s4], '1'), 12)}
      {createLink([c1], title(wn, '4-5', '1'), '', createSpec([wn], [s4, s5], '1'), 12)}
      {createLink([c1], title(wn, '5', '1'), '', createSpec([wn], [s5], '1'), 12)}
      <div class="sec-title">Level 2 Medium numbers</div>
      <div className="sec"></div>
      {createLink([c1], title(wn, '3', '2'), '', createSpec([wn], [s3], '2'), 12)}
      {createLink([c1], title(wn, '3-4', '2'), '', createSpec([wn], [s3, s4], '2'), 12)}
      {createLink([c1], title(wn, '4', '2'), '', createSpec([wn], [s4], '2'), 12)}
      {createLink([c1], title(wn, '4-5', '2'), '', createSpec([wn], [s4, s5], '2'), 12)}
      {createLink([c1], title(wn, '5', '2'), '', createSpec([wn], [s5], '2'), 12)}
      <div class="sec-title">Level 3 Larger numbers</div>
      <div className="sec"></div> 
      {createLink([c1], title(wn, '3', '3'), '', createSpec([wn], [s3], '3'), 12)}
      {createLink([c1], title(wn, '3-4', '3'), '', createSpec([wn], [s3, s4], '3'), 12)}
      {createLink([c1], title(wn, '4', '3'), '', createSpec([wn], [s4], '3'), 12)}
      {createLink([c1], title(wn, '4-5', '3'), '', createSpec([wn], [s4, s5], '3'), 12)}
      {createLink([c1], title(wn, '5', '3'), '', createSpec([wn], [s5], '3'), 12)}'
    </div>
    <div className="buttonGroup">
      <div className="concept-topic">Order of Operations with some Decimals</div>
      <div class="sec-title">Level 1 Smaller numbers</div>
      <div className="sec"></div>
      {createLink([c1], title(dec, '3', '1'), '', createSpec([dec], [s3], '1'), 12)}
      {createLink([c1], title(dec, '3-4', '1'), '', createSpec([dec], [s3, s4], '1'), 12)}
      {createLink([c1], title(dec, '4', '1'), '', createSpec([dec], [s4], '1'), 12)}
      {createLink([c1], title(dec, '4-5', '1'), '', createSpec([dec], [s4, s5], '1'), 12)}
      {createLink([c1], title(dec, '5', '1'), '', createSpec([dec], [s5], '1'), 12)}
      <div class="sec-title">Level 2 Medium numbers</div>
      <div className="sec"></div>
      {createLink([c1], title(dec, '3', '2'), '', createSpec([dec], [s3], '2'), 12)}
      {createLink([c1], title(dec, '3-4', '2'), '', createSpec([dec], [s3, s4], '2'), 12)}
      {createLink([c1], title(dec, '4', '2'), '', createSpec([dec], [s4], '2'), 12)}
      {createLink([c1], title(dec, '4-5', '2'), '', createSpec([dec], [s4, s5], '2'), 12)}
      {createLink([c1], title(dec, '5', '2'), '', createSpec([dec], [s5], '2'), 12)}
      <div class="sec-title">Level 3 Larger numbers</div>
      <div className="sec"></div>
      {createLink([c1], title(dec, '3', '3'), '', createSpec([dec], [s3], '3'), 12)}
      {createLink([c1], title(dec, '3-4', '3'), '', createSpec([dec], [s3, s4], '3'), 12)}
      {createLink([c1], title(dec, '4', '3'), '', createSpec([dec], [s4]), '3', 12)}
      {createLink([c1], title(dec, '4-5', '3'), '', createSpec([dec], [s4, s5], '3'), 12)}
      {createLink([c1], title(dec, '5', '3'), '', createSpec([dec], [s5], '3'), 12)}
    </div>
    <div className="buttonGroup">
      <div className="concept-topic">Order of Operations with Integers</div>
      <div class="sec-title">Level 1 Smaller numbers</div>
      <div className="sec"></div>
      {createLink([c1], title(int, '3', '1'), '', createSpec([int], [s3], '1'), 12)}
      {createLink([c1], title(int, '3-4', '1'), '', createSpec([int], [s3, s4]), '1', 12)}
      {createLink([c1], title(int, '4', '1'), '', createSpec([int], [s4], '1'), 12)}
      {createLink([c1], title(int, '4-5', '1'), '', createSpec([int], [s4, s5], '1'), 12)}
      {createLink([c1], title(int, '5', '1'), '', createSpec([int], [s5], '1'), 12)}
      <div class="sec-title">Level 2 Medium numbers</div>
      <div className="sec"></div>
      {createLink([c1], title(int, '3', '2'), '', createSpec([int], [s3], '2'), 12)}
      {createLink([c1], title(int, '3-4', '2'), '', createSpec([int], [s3, s4], '2'), 12)}
      {createLink([c1], title(int, '4', '2'), '', createSpec([int], [s4], '2'), 12)}
      {createLink([c1], title(int, '4-5', '2'), '', createSpec([int], [s4, s5], '2'), 12)}
      {createLink([c1], title(int, '5', '2'), '', createSpec([int], [s5], '2'), 12)}
      <div class="sec-title">Level 3 Larger numbers</div>
      <div className="sec"></div>
      {createLink([c1], title(int, '3', '3'), '', createSpec([int], [s3], '3'), 12)}
      {createLink([c1], title(int, '3-4', '3'), '', createSpec([int], [s3, s4], '3'), 12)}
      {createLink([c1], title(int, '4', '3'), '', createSpec([int], [s4], '3'), 12)}
      {createLink([c1], title(int, '4-5', '3'), '', createSpec([int], [s4, s5]), '3', 12)}
      {createLink([c1], title(int, '5', '3'), '', createSpec([int], [s5], '3'), 12)}
    </div>
    </>
  )
}