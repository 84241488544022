import { specAC, specHZ, specify } from "../helper"

export const DividingFractions = (props) => {
  const {createLink, ac} = props
  const c1 = 'Dividing Fractions'
  const [unitW, fractW, fract, mix, mixW] = ["Unit Fractions with Whole Numbers", "Fractions with Whole Numbers", "Fractions",
  "Mixed Numbers", "Mixed Numbers with Whole Numbers"]
  
  const title = (mod1, mod2) => {
    return `Dividing ${mod1}`
  }
  const createSpec = (spec1, spec2) => {
    let specAS = [specify('numbers', spec1), specAC(ac)]
    return {[c1]: specAS}
  }

  return (<>
    <div className="buttonGroup">
      <div className="concept-topic">Dividing Fractions & Mixed Numbers</div>
      {createLink([c1], title(fract), '', createSpec([fract]), 18)}
      {createLink([c1], title(mix), '', createSpec([mix]), 12)}
      {createLink([c1], title(fract+' & '+mix), '', createSpec([fract, mix]), 12)}
      <div className="sec-title">Dividing Fractions & Whole numbers</div>
      <div className="sec"></div>
      {createLink([c1], title(unitW), '', createSpec([unitW]), 12)}
      {createLink([c1], title(fractW), '', createSpec([fractW]), 12)}
      {createLink([c1], title(mixW), '', createSpec([mixW]), 12)}
      {createLink([c1], title('Fractions/Mixed Numbers with Whole Numbers'), '', createSpec([unitW, fractW, mixW]), 12)}

    </div>

    </>
  )
}