import React from 'react';
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer">
      <div className="link-group">
        <div className="title">Account</div>
        <Link className="link" to={"/signup"}>
          Sign Up
        </Link>
        <Link className="link" to={"/login"}>
          Log in
        </Link>
      </div>

      <div className="link-group">
        <div className="title">Worksheets</div>
        {/* <Link className="link" to={"/new"}>
          New Stuff
        </Link> */}
        <Link className="link" to={"/activities"}>
          Activity Resources
        </Link>
        <Link className="link" to={"/concept-selection"}>
          Concept List
        </Link>
        <Link className="link" to={"/samples"}>
          Samples
        </Link>
        {/* <Link className="link" to={"/coming-soon"}>
          Coming Soon
        </Link> */}
      </div>

      <div className="link-group">
        <div className="title">Site Info</div>
        <Link className="link" to={"/faqs"}>
          FAQS
        </Link>
        <Link className="link" to={"/terms-of-service"}>
          Terms of Service
        </Link>
        <Link className="link" to={"/privacy-policy"}>
          Privacy Policy
        </Link>
      </div>
      <div className="group">
      <div className="title">Contact us:</div>
      <div className="email">contact@worksheetwonderland.com</div>
      </div>

    </div>
  )
}

export default Footer