import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useHttpClient } from "../shared/hooks/http-hook";
import UserCard from "./UserCard";
import ErrorModal from "../shared/components/UIElements/ErrorModal";
import Button from "../shared/components/FormElements/Button";
import wwLogoIcon from "../app-files/images/icon-only.png";

const Activate = (props) => {
  const location = useLocation();
  const [loaded, setLoaded] = useState(false);
  const [valid, setValid] = useState(false);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  useEffect(() => {
    initializeData();
  }, []);
  const initializeData = async () => {
    let token = null;
    if (location.search.match(/token=.+&id=.+/gi)) {
      token = location.search.split("token=")[1].split("&")[0];
      let userId = location.search.split("id=")[1];
      const responseData = await sendRequest(
        `/api/users/activate`,
        "PUT",
        JSON.stringify({
          token: token,
          userId: userId,
        }),
        {
          "Content-Type": "application/json",
        }
      );
      if (responseData.status === "valid") {
        setValid(true);
      }
    }
    setLoaded(true);
  };
  return (
    <div className="authenticate-container">
      <ErrorModal error={error} onClear={clearError} />
      {!loaded ? (
        <UserCard title="Activate Account">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "30px",
            }}
          >
            <CircularProgress />
          </div>
          <div
            style={{
              padding: "15px 0 15px 0",
              display: "flex",
              justifyContent: "center",
              height: "70px",
            }}
          ></div>
        </UserCard>
      ) : null}
      {!valid && loaded ? (
        <UserCard title="Activate Account">
          <p
            className="blurb"
            style={{
              color: "red",
              fontSize: "2.5em",
              fontWeight: "bold",
              textAlign: 'center',
            }}
          >
            ERROR!
          </p>
          <p
            className="blurb"
            style={{
              color: "purple",
              fontSize: "2em",
              fontWeight: "bold",
              textAlign: 'center',
            }}
          >
            This activation link is invalid or expired.
          </p>
          <p
            className="blurb"
            style={{
              fontSize: "1.2em",
              fontWeight: "bold",
              textAlign: 'center',
            }}
          >
            Log in to create a new account activation request.
          </p>
          <div style={{ display: "flex", justifyContent: "center" }}>
              <Button className="btn" to="/login">
                {"GO TO LOG IN"}
              </Button>
            </div>
          <div
            style={{
              padding: "15px 0 15px 0",
              display: "flex",
              justifyContent: "center",
              height: "70px",
            }}
          ></div>
        </UserCard>
      ) : null}
      {valid && loaded ? (
        <UserCard title="Activate Account">
          <div className="account-type" style={{ textAlign: "center" }}>
            <img
              src={wwLogoIcon}
              alt="Worksheet Wonderland"
              style={{ paddingTop: "20px", width: "250px" }}
            />
            <p
              className="blurb"
              style={{
                color: "purple",
                fontSize: "2.5em",
                fontWeight: "bold",
              }}
            >
              Your Account has been Activated!
            </p>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button className="btn" to="/login">
                {"GO TO LOGIN"}
              </Button>
            </div>
          </div>
          <div
            style={{
              padding: "15px 0 15px 0",
              display: "flex",
              justifyContent: "center",
              height: "70px",
            }}
          ></div>
        </UserCard>
      ) : null}
    </div>
  );
};

export default Activate;
