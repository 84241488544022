import { randInt, shuffleArray, wrongOptions, answerChoicesKey, selectRand, removeDup, fixNum } from "../general";

const wrongASInt = (answer, op, numberS, numberL) => {
  let wrong = [answer+randInt(1,2), answer-randInt(1,2)]
  let wrongCheck = (checkAns) => {
    let duplicate = false
    for (let i of wrong){
      if (i === checkAns || checkAns === answer){
        duplicate = true
      }
    }
    if(!duplicate){
        wrong.push(checkAns)
    }
  }
  wrongCheck(answer*-1) //inverse answer
  if (op === 'add'){
    wrongCheck(numberL-numberS) //opposite op
    wrongCheck((Math.abs(numberL)+Math.abs(numberS))*-1) // -5 + 2 = -7
  }
  if (op === 'sub'){
    wrongCheck(numberL+numberS) //opposite op -5 - -2 = -7
    wrongCheck(Math.abs(numberS) - Math.abs(numberL)) // -5 - -2 = 3
    wrongCheck((Math.abs(numberS) - Math.abs(numberL))*-1) // -5 - -2 = -3

  }
  return shuffleArray(wrong)
}

export const asIntNumbers = (userSelection, op) => {
  let numArray = [];
  const subInt = op === 'sub'
  let numSelect = userSelection.concept==='Subtracting Integers' || userSelection.concept==='Adding Integers' ? userSelection.specify.numbers : 
                  userSelection.specify.asNumbers

  // if op is subtracting both numbers can be negative as long as the larger number comes second.
  const subPos = (int1, int2, int3) => { // (small, large, difference in case equal)
    let [num1, num2] = [randInt(int1,int2), randInt(int1,int2)]
    if (num1===num2){ 
      if (num1===8 || num1===9)
        num1-=randInt(1,int3)
       else 
        num2+=randInt(1,int3)
    }
    numArray.push([num1, num2].sort((a,b)=> a-b))
  
  }
  if (numSelect['1 digit numbers']) {
    numArray.push(shuffleArray([randInt(-9,-1), randInt(-9,-1)]))
    numArray.push(shuffleArray([randInt(-9,-1), randInt(2, 9)]))

    if (subInt){ 
      subPos(2, 9, 2)
    }

  } if (numSelect['2 digit numbers']) {
    numArray.push(shuffleArray([randInt(-99,-10), randInt(-99,-10)]))
    numArray.push(shuffleArray([randInt(-99,-10), randInt(-99,-10)]))
    numArray.push(shuffleArray([randInt(-99,-10), randInt(10,99)]))

    if (subInt){
      subPos(10, 95, 5)
      subPos(10, 35, 5)
    }

    // allow mix of 1 & 2 digits 
    if (numSelect['1 digit numbers']) {
      numArray.push(shuffleArray([randInt(-99,-10), randInt(-9,-1)]))
      numArray.push(shuffleArray([randInt(-99,-10), randInt(2, 9)]))
      numArray.push(shuffleArray([randInt(10, 99), randInt(-9,-1)]))

      if (subInt) {
        subPos(2, 55, 5)
      }
    }

  } if (numSelect['3 digit numbers']) {
      numArray.push(shuffleArray([randInt(-999,-100), randInt(-999,-100)]))
      numArray.push(shuffleArray([randInt(-999,-100), randInt(100, 999)]))

      if (subInt){
        subPos(100, 950, 49)
      }

      // allow mix of 2 & 3 digits
      if (numSelect['2 digit numbers']) {
        numArray.push(shuffleArray([randInt(-999,-100), randInt(-99,-10)]))
        numArray.push(shuffleArray([randInt(-999,-100), randInt(-99,-10)]))
        numArray.push(shuffleArray([randInt(-999,-100), randInt(10, 99)]))
        numArray.push(shuffleArray([randInt(-99,-10), randInt(100, 999)]))
        
        if (subInt) {
          subPos(50, 300, 49)
          subPos(75, 300, 49)
        }
      }
  }
  
  const [numberS, numberL] = selectRand(numArray) //dont shuffle at this level bc subInt requires smaller number first, others can be randomized.
  const answer = subInt ? numberS - numberL : numberL+numberS
  const wrong = wrongASInt(answer, subInt? 'sub': 'add', numberS, numberL)
  const AC = answerChoicesKey(answer, wrong[0], wrong[1], wrong[2])
  return {numberS, numberL, answer, wrong, AC}
}

const addInt = (userSelection) => {
  const {numberS, numberL, answer, AC} = asIntNumbers(userSelection, 'add')

  const prob =`${numberL} + ${numberS} = `
  
  const problem = {prob: prob,
              answerChoices: AC,
              correctAnswer:answer,
              }
  return problem
}
export const addIntBasic =  (userSelection, generalSelection) => {
  var {numberS, numberL, answer, wrong} = asIntNumbers(userSelection, 'add')
  let randNum = numberL > 9 || numberL < -9  ? randInt(10,100) : randInt(2, 9)

  let prob = `What is the sum of ${numberL} and ${numberS}?`
  let prob2 = `What is the sum of ${numberS} and ${numberL}?`
  let prob3 = `What is the total of ${numberS} and ${numberL}?`
  let prob4 = `How much is ${numberS} and ${numberL} combined?`

  if (userSelection.specify.level === 'Multi-Step'){
    prob =  `What is ${randNum} more than the sum of ${numberL} and ${numberS}?`
    prob2 =  `What is ${numberL} more than the sum of ${randNum} and ${numberS}?`
    prob3 =  `The sum of ${randNum} and ${numberS} is added to ${numberL}. What is the total sum?`
    prob4 =  `What is the total of ${numberL} combined with the sum of ${randNum} and ${numberS}?`

    answer = answer + randNum
    wrong = [randNum+numberL, numberL+numberS, answer+1, answer+2, answer-1, answer-2, answer*-1]
  }
  let randProb = selectRand([prob, prob2, prob3, prob4])

  wrong = shuffleArray(removeDup(wrong))
  var AC = answerChoicesKey(answer, wrong[0], wrong[1], wrong[2])
  var problem = {prob: randProb,
              answerChoices: AC,
              correctAnswer:answer,
              probStyle: userSelection.specify.probStyle,
              probType: 'Application'
              }
  return problem
}

const subInt = (userSelection) => {
  const {numberS, numberL, answer, AC} = asIntNumbers(userSelection, 'sub')

  const prob =`${numberS} - ${numberL} = `
  
  const problem = {prob: prob,
              answerChoices: AC,
              correctAnswer:answer,
              }
  return problem
}

export const subIntBasic =  (userSelection, generalSelection) => {
  var {numberS, numberL, answer, wrong} = asIntNumbers(userSelection, 'sub')
  let randNum = numberL > 9 || numberL < -9 ? randInt(10,100) : randInt(2, 9)

  let prob = numberL > 0 ? `What is ${numberS} decreased by ${numberL}?` : numberS > numberL ? `How much greater is ${numberS} than ${numberL}?` : `What is ${numberS} subtracted by ${numberL} `

  if (userSelection.specify.level === 'Multi-Step'){
    let temp1 =  numberL > 0 ? `The number ${fixNum(numberS)} is decreased by ${fixNum(numberL)} and then increased by ${randNum}. What is the number now?` : 
    `The number ${fixNum(numberS)} is subtracted by ${fixNum(numberL)} and then increased by ${randNum}. What is the number now?`
    let temp2 = `What is ${numberS} subtracted by the combined total of ${fixNum(numberL)} and ${randNum}?`

    prob = selectRand([temp1, temp2])
    if (prob === temp1){
        answer = randNum+answer
        wrong = [randNum+numberL, numberL+numberS, answer+1, answer+2, answer-1, answer-2]
    } else {
        answer = numberS - (numberL+randNum)
        wrong = [numberL-numberS, numberL+numberS+randNum, answer+1, answer+2, answer-1, answer-2]
    }
  }

  wrong = shuffleArray(removeDup(wrong))

  var AC = answerChoicesKey(answer, wrong[0], wrong[1], wrong[2])
  var problem = {prob: prob,
              answerChoices: AC,
              correctAnswer:answer,
              probStyle: userSelection.specify.probStyle,
              probType: 'Application'
              }
  return problem
}



export const randAddInt = (userSelection, generalSelection) => {
  var probArray = []
  if (userSelection.specify.probType['Algorithm']) {
    probArray.push(addInt)
  } if (userSelection.specify.probType['Application']) {
      probArray.push(addIntBasic)
  }
  var randProb = selectRand(probArray)
  return randProb(userSelection, generalSelection)
}

export const randSubInt = (userSelection, generalSelection) => {
  var probArray = []
  if (userSelection.specify.probType['Algorithm']) {
      probArray.push(subInt)
  } if (userSelection.specify.probType['Application']) {
      probArray.push(subIntBasic)
  }
  var randProb = selectRand(probArray)
  return randProb(userSelection, generalSelection)
}