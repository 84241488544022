import React from 'react';
import AccountSidebar from './AccountSidebar';
import UserCard from '../UserCard';

const Payment = props => {

  return(
    <div className="account payment">
      <AccountSidebar page="payment"/>
     <UserCard title='Payment Methods'>
      <p className="blurb"></p>


    </UserCard>
  </div> 

  
    
)
}

export default Payment