import { specAlg, specify, specAC } from "../helper"

export const DividingDecimals = (props) => {
  const {createLink, probStyle, ac} = props
  const hz = probStyle === 'Horizontal'
  const c1 = 'Dividing Decimals'
  const [div1d, div1w, div2d, div2w] = ['1 digit decimal', '1 digit whole number', '2 digit decimal', '2 digit whole number']

  const title = (mod1, mod2, mod3) => {
    return `Dividing ${mod1} Digit Decimals by ${mod2} Digit ${mod3} Divisors`
  }
  const createSpec = (spec1, spec2) => {
    let spec = [specify('dividend', spec1), specify('divisor', spec2), specify('probStyle', probStyle), specAlg, specAC(ac)]
    if (hz) {
      spec.push(hz)
    }
    return {[c1]: spec}
  }

  return (<>
    <div className="buttonGroup">
      <div className="concept-topic">1 Digit Whole Number Divisor Algorithm</div>
      {createLink([c1], title('2', '1', 'Whole Number'), '', createSpec(['2 digits'], [div1w]), 18)}
      {createLink([c1], title('2-3', '1', 'Whole Number'), '', createSpec(['2 digits', '3 digits'], [div1w]), hz ? 12 : 18)}
      {createLink([c1], title('3', '1', 'Whole Number'), '', createSpec(['3 digits'], [div1w]), hz ? 12 : 18)}
      {createLink([c1], title('3-4', '1', 'Whole Number'), '', createSpec(['3 digits', '4 digits'], [div1w]), hz ? 12 : 18)}
      {createLink([c1], title('4', '1', 'Whole Number'), '', createSpec(['4 digits'], [div1w]), hz ? 12 : 18)}
      {/* {createLink([c1], title('2', '1-2', 'Whole Number'), '', createSpec(['2 digits'], [div1w, div2w]), 24)}
      {createLink([c1], title('2-3', '1-2', 'Whole Number'), '', createSpec(['2 digits', '3 digits'], [div1w, div2w]), 24)}
      {createLink([c1], title('3', '1-2', 'Whole Number'), '', createSpec(['3 digits'], [div1w, div2w]), 24)}
      {createLink([c1], title('3-4', '1-2', 'Whole Number'), '', createSpec(['3 digits', '4 digits'], [div1w, div2w]), 24)}
      {createLink([c1], title('4', '1-2', 'Whole Number'), '', createSpec(['4 digits'], [div1w, div2w]), 24)} */}
    </div>
  
    <div className="buttonGroup">
      <div className="concept-topic">2 Digit Whole Number Divisor Algorithm</div>
      {createLink([c1], title('2', '2', 'Whole Number'), '', createSpec(['2 digits'], [div2w]), 18)}
      {createLink([c1], title('2-3', '2', 'Whole Number'), '', createSpec(['2 digits', '3 digits'], [div2w]), hz ? 12 : 18)}
      {createLink([c1], title('3', '2', 'Whole Number'), '', createSpec(['3 digits'], [div2w]), hz ? 12 : 18)}
      {createLink([c1], title('3-4', '2', 'Whole Number'), '', createSpec(['3 digits', '4 digits'], [div2w]), hz ? 12 : 18)}
      {createLink([c1], title('4', '2', 'Whole Number'), '', createSpec(['4 digits'], [div2w]), hz ? 12 : 18)}
      {/* {createLink([c1], title('2', '1-2', 'Whole Number'), '', createSpec(['2 digits'], [div1w, div2w]), 24)}
      {createLink([c1], title('2-3', '1-2', 'Whole Number'), '', createSpec(['2 digits', '3 digits'], [div1w, div2w]), 24)}
      {createLink([c1], title('3', '1-2', 'Whole Number'), '', createSpec(['3 digits'], [div1w, div2w]), 24)}
      {createLink([c1], title('3-4', '1-2', 'Whole Number'), '', createSpec(['3 digits', '4 digits'], [div1w, div2w]), 24)}
      {createLink([c1], title('4', '1-2', 'Whole Number'), '', createSpec(['4 digits'], [div1w, div2w]), 24)} */}
    </div>

    <div className="buttonGroup">
      <div className="concept-topic">1 Digit Decimal Divisor Algorithm</div>
      {createLink([c1], title('2', '1', 'Decimal'), '', createSpec(['2 digits'], [div1d]), 18)}
      {createLink([c1], title('2-3', '1', 'Decimal'), '', createSpec(['2 digits', '3 digits'], [div1d]), hz ? 12 : 18)}
      {createLink([c1], title('3', '1', 'Decimal'), '', createSpec(['3 digits'], [div1d]), hz ? 12 : 18)}
      {createLink([c1], title('3-4', '1', 'Decimal'), '', createSpec(['3 digits', '4 digits'], [div1d]), hz ? 12 : 18)}
      {createLink([c1], title('4', '1', 'Decimal'), '', createSpec(['4 digits'], [div1d]), hz ? 12 : 18)}
      {/* {createLink([c1], title('2', '1-2', 'Decimal'), '', createSpec(['2 digits'], [div1d, div2d]), 24)}
      {createLink([c1], title('2-3', '1-2', 'Decimal'), '', createSpec(['2 digits', '3 digits'], [div1d, div2d]), 24)}
      {createLink([c1], title('3', '1-2', 'Decimal'), '', createSpec(['3 digits'], [div1d, div2d]), 24)}
      {createLink([c1], title('3-4', '1-2', 'Decimal'), '', createSpec(['3 digits', '4 digits'], [div1d, div2d]), 24)}
      {createLink([c1], title('4', '1-2', 'Decimal'), '', createSpec(['4 digits'], [div1d, div2d]), 24)} */}
    </div>
    <div className="buttonGroup">
      <div className="concept-topic">2 Digit Decimal Divisor Algorithm</div>
      {createLink([c1], title('2', '2', 'Decimal'), '', createSpec(['2 digits'], [div2d]), 18)}
      {createLink([c1], title('2-3', '2', 'Decimal'), '', createSpec(['2 digits', '3 digits'], [div2d]), hz ? 12 : 18)}
      {createLink([c1], title('3', '2', 'Decimal'), '', createSpec(['3 digits'], [div2d]), hz ? 12 : 18)}
      {createLink([c1], title('3-4', '2', 'Decimal'), '', createSpec(['3 digits', '4 digits'], [div2d]), hz ? 12 : 18)}
      {createLink([c1], title('4', '2', 'Decimal'), '', createSpec(['4 digits'], [div2d]), hz ? 12 : 18)}
      {/* {createLink([c1], title('2', '1-2', 'Decimal'), '', createSpec(['2 digits'], [div1d, div2d]), 24)}
      {createLink([c1], title('2-3', '1-2', 'Decimal'), '', createSpec(['2 digits', '3 digits'], [div1d, div2d]), 24)}
      {createLink([c1], title('3', '1-2', 'Decimal'), '', createSpec(['3 digits'], [div1d, div2d]), 24)}
      {createLink([c1], title('3-4', '1-2', 'Decimal'), '', createSpec(['3 digits', '4 digits'], [div1d, div2d]), 24)}
      {createLink([c1], title('4', '1-2', 'Decimal'), '', createSpec(['4 digits'], [div1d, div2d]), 24)} */}
    </div>

    <div className="buttonGroup">
      <div className="concept-topic">Mixed Whole Number & Decimal Divisor Algorithm</div>
      {/* {createLink([c1], title('2', '1', ''), '', createSpec(['2 digits'], [div1d, div1w]), 24)}
      {createLink([c1], title('2', '2', ''), '', createSpec(['2 digits'], [div2d, div2w]), 24)}
      {createLink([c1], title('2-3', '1', ''), '', createSpec(['2 digits', '3 digits'], [div1d, div1w]), 24)}
      {createLink([c1], title('2-3', '2', ''), '', createSpec(['2 digits', '3 digits'], [div2d, div2w]), 24)}
      {createLink([c1], title('3', '1', ''), '', createSpec(['3 digits'], [div1d, div1w]), 24)}
      {createLink([c1], title('3', '2', ''), '', createSpec(['3 digits'], [div2d, div2w]), 24)}
      {createLink([c1], title('3-4', '1', ''), '', createSpec(['3 digits', '4 digits'], [div1d, div1w]), 24)}
      {createLink([c1], title('3-4', '2', ''), '', createSpec(['3 digits', '4 digits'], [div2d, div2w]), 24)}
      {createLink([c1], title('4', '1', ''), '', createSpec(['4 digits'], [div1d, div2w]), 24)}
      {createLink([c1], title('4', '2', ''), '', createSpec(['4 digits'], [div2d, div2w]), 24)} */}
      {createLink([c1], title('2', '1-2', ''), '', createSpec(['2 digits'], [div1d, div1w, div2d, div2w]), 18)}
      {createLink([c1], title('2-3', '1-2', ''), '', createSpec(['2 digits', '3 digits'], [div1d, div1w, div2d, div2w]), hz ? 12 : 24)}
      {createLink([c1], title('3', '1-2', ''), '', createSpec(['3 digits'], [div1d, div1w, div2d, div2w]), hz ? 12 : 24)}
      {createLink([c1], title('3-4', '1-2', ''), '', createSpec(['3 digits', '4 digits'], [div1d, div1w, div2d, div2w]), hz ? 12 : 24)}
      {createLink([c1], title('4', '1-2', ''), '', createSpec(['4 digits'], [div1d, div1w, div2d, div2w]), hz ? 12 : 24)}
    </div>
    </>
  )
}