import { specAlg, specify, specAC } from "../helper"

export const DividingWholeNumbers = (props) => {
  const {probStyle, createLink, ac} = props
  const [c1] = ['Dividing Whole Numbers']
  const [dig2_1, dig3_1, dig4_1, dig3_2, dig4_2] = ['2 by 1 digit', '3 by 1 digit', '4 by 1 digit', '3 by 2 digit', '4 by 2 digit']

  const title = (mod1) => {
    return `Dividing ${mod1} Digit Whole Numbers`
  }

  const createSpec = (spec1) => {
    let specC1 = [specify('numbers', spec1), specify('probStyle', probStyle), specAlg, specAC(ac)]

    return {[c1]: specC1}
  }
  return (<>
    <div className="buttonGroup">
      <div className="concept-topic">Dividing Whole Numbers</div>
      {createLink([c1], title('2 by 1'), '', createSpec( [dig2_1]), 18)}
      {createLink([c1], title('2 by 1 & 3 by 1'), '', createSpec([dig2_1, dig3_1]), 18)}
      {createLink([c1], title('3 by 1'), '', createSpec([dig3_1]), 15)}
      {createLink([c1], title('3 by 1 & 4 by 1'), '', createSpec([dig3_1, dig4_1]), 12)}
      {createLink([c1], title('4 by 1'), '', createSpec([dig4_1]), 12)}
      {createLink([c1], title('4 by 1 & 3 by 2'), '', createSpec([dig4_1, dig3_2]), 12)}
      {createLink([c1], title('3 by 2'), '', createSpec([dig3_2]), 12)}
      {createLink([c1], title('4 by 1 & 4 by 2'), '', createSpec([dig4_2, dig4_1]), 12)}
      {createLink([c1], title('4 by 2'), '', createSpec([dig4_2]), 12)}
      {createLink([c1], title('4 by 2 & 3 by 2'), '', createSpec([dig3_2, dig4_2]), 12)}
    </div>
    {/* <div className="buttonGroup">
      <div className="concept-topic">Applications</div>

    </div> */}
    </>
  )
}

