import { specAC, specAlg, specify } from "../helper"

export const SubtractingWholeNumbers = (props) => {
  const {probStyle, createLink, ac} = props
  const hz = probStyle === 'Horizontal'
  const [c1] = ['Subtracting Whole Numbers']
  const [dig1, dig2, dig3, dig4, dig5, dig6, dig7] = ['1 digit numbers', '2 digit numbers', '3 digit numbers', '4 digit numbers', '5 digit numbers', '6 digit numbers', '7 digit numbers']
  const [noRG, RGnoZ, RGZ] = ['No regrouping', 'Regroup without zero', 'Regroup with zero']

  const title = (mod1, mod2) => {
    return `Subtracting ${mod1} Digit Whole Numbers ${mod2}`
  }

  const createSpec = (spec1, spec2) => {
    let specC1 = [specify('numbers', spec1), specify('regroup', spec2), specify('probStyle', probStyle), specAlg, specAC(ac)]

    return {[c1]: specC1}
  }
  //Add application && Set up vs HZ
  return (<>
    <div className="buttonGroup">
      <div className="concept-topic">Subtracting Algorithm (No Regrouping)</div>
        {createLink([c1], title('1',''), '', createSpec([dig1],[noRG]), hz ? 18 : 35)}
        {createLink([c1], title('2',''), '', createSpec([dig2],[noRG]), hz ? 18 : 35)}
        {createLink([c1], title('3',''), '', createSpec([dig3],[noRG]), hz ? 24 : 35)}
        {createLink([c1], title('4',''), '', createSpec([dig4],[noRG]), hz ? 24 : 35)}
        {createLink([c1], title('5',''), '', createSpec([dig5],[noRG]), hz ? 24 : 28)}
        {createLink([c1], title('6',''), '', createSpec([dig6],[noRG]), hz ? 24 : 28)}
        {createLink([c1], title('7',''), '', createSpec([dig7],[noRG]), hz ? 24 : 18)}

      </div>
      <div className="buttonGroup">
        <div className="concept-topic">Subtracting Algorithm (Regrouping With Zero)</div>
        {/* <div className="sec-title">Can include regrouping</div>
        <div className="sec"></div>
        {createLink([c1], title('2','(Some Regrouping)'), '', createSpec([dig2],[noRG, RGZ]), 35)}
        {createLink([c1], title('3','(Some Regrouping)'), '', createSpec([dig3],[noRG, RGZ]), 35)}
        {createLink([c1], title('4','(Some Regrouping)'), '', createSpec([dig4],[noRG, RGZ]), 35)}
        {createLink([c1], title('5','(Some Regrouping)'), '', createSpec([dig5],[noRG, RGZ]), 28)}
        {createLink([c1], title('6','(Some Regrouping)'), '', createSpec([dig6],[noRG, RGZ]), 28)}
        {createLink([c1], title('7','(Some Regrouping)'), '', createSpec([dig7],[noRG, RGZ]), 18)} */}
        <div className="sec-title">All require regrouping</div>
        <div className="sec"></div>
        {createLink([c1], title('2','(Regrouping)'), '', createSpec([dig2],[RGZ]), hz ? 18 : 35)}
        {createLink([c1], title('3','(Regrouping)'), '', createSpec([dig3],[RGZ]), hz ? 24 : 35)}
        {createLink([c1], title('4','(Regrouping)'), '', createSpec([dig4],[RGZ]), hz ? 24 : 35)}
        {createLink([c1], title('5','(Regrouping)'), '', createSpec([dig5],[RGZ]), hz ? 24 : 28)}
        {createLink([c1], title('6','(Regrouping)'), '', createSpec([dig6],[RGZ]), hz ? 24 : 28)}
        {createLink([c1], title('7','(Regrouping)'), '', createSpec([dig7],[RGZ]), hz ? 24 : 18)}
      </div> 
      <div className="buttonGroup">
        <div className="concept-topic">Subtracting Algorithm (Regrouping Without Zero)</div>
        <div className="sec-title">Can include regrouping</div>
        <div className="sec"></div>
        {createLink([c1], title('2','(Some Regrouping)'), '', createSpec([dig2],[noRG, RGnoZ]), hz ? 18 : 35)}
        {createLink([c1], title('3','(Some Regrouping)'), '', createSpec([dig3],[noRG, RGnoZ]), hz ? 24 : 35)}
        {createLink([c1], title('4','(Some Regrouping)'), '', createSpec([dig4],[noRG, RGnoZ]), hz ? 24 : 35)}
        {createLink([c1], title('5','(Some Regrouping)'), '', createSpec([dig5],[noRG, RGnoZ]), hz ? 24 : 28)}
        {createLink([c1], title('6','(Some Regrouping)'), '', createSpec([dig6],[noRG, RGnoZ]), hz ? 24 : 28)}
        {createLink([c1], title('7','(Some Regrouping)'), '', createSpec([dig7],[noRG, RGnoZ]), hz ? 24 : 18)}
        <div className="sec-title">All require regrouping</div>
        <div className="sec"></div>
        {createLink([c1], title('2','(Regrouping)'), '', createSpec([dig2],[RGnoZ]), hz ? 18 : 35)}
        {createLink([c1], title('3','(Regrouping)'), '', createSpec([dig3],[RGnoZ]), hz ? 24 : 35)}
        {createLink([c1], title('4','(Regrouping)'), '', createSpec([dig4],[RGnoZ]), hz ? 24 : 35)}
        {createLink([c1], title('5','(Regrouping)'), '', createSpec([dig5],[RGnoZ]), hz ? 24 : 28)}
        {createLink([c1], title('6','(Regrouping)'), '', createSpec([dig6],[RGnoZ]), hz ? 24 : 28)}
        {createLink([c1], title('7','(Regrouping)'), '', createSpec([dig7],[RGnoZ]), hz ? 24 : 18)}
      </div>
 
      <div className="buttonGroup">
        <div className="concept-topic">Subtracting Algorithm (Regrouping)</div>
        <div className="sec-title">Can include regrouping</div>
        <div className="sec"></div>
        {createLink([c1], title('2','(Some Regrouping)'), '', createSpec([dig2],[RGZ, RGnoZ, noRG]), hz ? 18 : 35)}
        {createLink([c1], title('3','(Some Regrouping)'), '', createSpec([dig3],[RGZ, RGnoZ, noRG]), hz ? 24 : 35)}
        {createLink([c1], title('4','(Some Regrouping)'), '', createSpec([dig4],[RGZ, RGnoZ, noRG]), hz ? 24 : 35)}
        {createLink([c1], title('5','(Some Regrouping)'), '', createSpec([dig5],[RGZ, RGnoZ, noRG]), hz ? 24 : 28)}
        {createLink([c1], title('6','(Some Regrouping)'), '', createSpec([dig6],[RGZ, RGnoZ, noRG]), hz ? 24 : 28)}
        {createLink([c1], title('7','(Some Regrouping)'), '', createSpec([dig7],[RGZ, RGnoZ, noRG]), hz ? 24 : 18)}
        <div className="sec-title">All require regrouping</div>
        <div className="sec"></div>
        {createLink([c1], title('2','(Regrouping)'), '', createSpec([dig2],[RGZ, RGnoZ]), hz ? 18 : 35)}
        {createLink([c1], title('3','(Regrouping)'), '', createSpec([dig3],[RGZ, RGnoZ]), hz ? 24 : 35)}
        {createLink([c1], title('4','(Regrouping)'), '', createSpec([dig4],[RGZ, RGnoZ]), hz ? 24 : 35)}
        {createLink([c1], title('5','(Regrouping)'), '', createSpec([dig5],[RGZ, RGnoZ]), hz ? 24 : 28)}
        {createLink([c1], title('6','(Regrouping)'), '', createSpec([dig6],[RGZ, RGnoZ]), hz ? 24 : 28)}
        {createLink([c1], title('7','(Regrouping)'), '', createSpec([dig7],[RGZ, RGnoZ]), hz ? 24 : 18)}

      </div>        

    </>)
}