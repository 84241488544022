import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../../app/context/auth-context";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import LinearProgress from "@material-ui/core/LinearProgress";

const LoadingWrapper = (props) => {
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    initializeData();
  }, []);
  const initializeData = async () => {
    const storedData = JSON.parse(localStorage.getItem("userData"));
    if (storedData && storedData.user && storedData.token) {
      try {
        const responseData = await sendRequest(
          `/api/users/verify-token`,
          "POST",
          "",
          {
            "Content-Type": "application/json",
          }
        );
        if (
          responseData &&
          responseData.valid &&
          responseData.token &&
          responseData.user
        ) {
          auth.login({ token: responseData.token, user: responseData.user });
        } else {
          auth.logout();
        }
        setLoaded(true);
      } catch (err) {
        console.log(err);
      }
    }
    setLoaded(true);
  };
  if (!loaded) {
    return (
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ width: "30%", minWidth: "150px", textAlign: "center" }}>
          <div
            style={{
              fontSize: "30px",
              color: "#fff",
              paddingBottom: "10px",
              fontWeight: "bold",
            }}
          >
            LOADING CONTENT
          </div>
          <LinearProgress />
        </div>
      </div>
    );
  }
  return <>{props.children}</>;
};

export default LoadingWrapper;
