import { Link } from "react-router-dom";
import { React, useEffect, useState, useContext } from "react";
import { WSContext } from "../../../app/context/ws-context";

import AOS from "aos";
import "aos/dist/aos.css";
import Differentiate from "../Differentiate";
import Infinite from "../Infinite";
import Teacher from "../Teacher";
// import wwLogo from '../../../app-files/images/newlogohome.png'
import wwLogo from "../../../app-files/images/logohome.svg";
import wwLogo2 from "../../../app-files/images/wwlogolow.png";

import wsEx from "../../../app-files/images/activity-ws.png";
import tcEx from "../../../app-files/images/activity-tc2.png";
import Engage from "../Engage";

const Home = (props) => {
  const ws = useContext(WSContext);

  useEffect(() => {
    AOS.init({
      duration: 2000,
      once: true,
    });
    window.scrollTo(0, 0);
  }, []);
  //Main
  const engagePG = (
    <p>
      We create more than just worksheets, but also{" "}
      <span className="bold SB">engaging</span> activities such as scavenger
      hunts, bingo games, and task cards for you to use in the classroom.
    </p>
  );
  //Sec
  const diffPG = (
    <p>
      Assignments can be <span className="bold MD">differentiated</span> and{" "}
      <span className="bold SB">customized</span> to meet the needs of your
      students
    </p>
  );
  //White
  const infinitePG = (
    <p>
      We <span className="bold SB">randomly</span> generate all assignments so
      that you can make <span className="bold MD">endless</span> versions.
      Easily re-create a similar activity for a student who needs remediation or
      a re-assessment.
    </p>
  );
  if (props.debugMode) {
    return (
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
      >
        <div
          style={{
            fontSize: "40px",
            fontWeight: "bold",
            color: "#fff",
          }}
        >
          UNDER CONSTRUCTION
        </div>
        {/* <div>
          <div style={{ color: '#609285'}}>WORKSHEET</div>
          <div style={{ color: '#673B69'}}>Wonderland</div>
        </div> */}
        <div
          style={{
            width: "20px",
            height: "20px",
            position: "absolute",
            left: 0,
            bottom: 0,
          }}
          onClick={() => {
            props.setDebugMode(false);
          }}
        />
      </div>
    );
  }
  return (
    <div className="general-main-container home-container">
      <div className="picture-background">
        <div className="title-banner">
          <div className="logo-img-container">
            <div className="img-wrap"><img className="worksheet" src={wsEx} alt="worksheet" /></div>
            <div className="img-wrap"><img className="logo" src={wwLogo} alt="Worksheet Wonderland" /></div>
            <div className="img-wrap"><img className="task-cards" src={tcEx} alt="task cards" /></div>
          </div>
          <div>
            <p>Create fully <span>customized</span> math worksheets & activities.</p>
            <p className="ws-free">Worksheets are FREE!</p>
          </div>
        </div>
        <div className="buttons-wrapper">
          <div className="button-container">
            <Link to="/premade-activity-selection">
              <button className="btn" onClick={ws.handleClearSelections}>
                Select premade
              </button>
            </Link>
            {/* <p>Choose from hundreds of premade concept selections to quickly create 1-2 concept assignments.</p> */}
          </div>
          <div className="or">OR</div>
          <div className="button-container">
            <Link to="/activity-selection">
              <button className="btn" onClick={ws.handleClearSelections}>
                Create custom
              </button>
            </Link>
            {/* <p>Customize your assignment including multiple concepts each exactly how you need them to meet the needs of your students.</p> */}
          </div>
        </div>
      </div>
      <Engage paragraph={engagePG} />
      <Differentiate paragraph={diffPG} />
      <Infinite paragraph={infinitePG} />
      <Teacher />
    </div>
  );
};

export default Home;
