import React from "react";
import {Text,  View,  Font, Image} from "@react-pdf/renderer";
import {styles} from './createWorksheetcss'
import {createBingo} from './createBingo';
import { createSH } from './createSH';
import { borderList } from "./tcBorders";
import { verticalDivide, verticalProbStyling } from "./problems/vertical-align";
import { createFraction, createProbFraction, horizontalFractions, styleFraction, verticalFractions } from "./problems/fractions/fract-shared";
import { tableStyling } from "./problems/tables";
import { randInt, showQText } from "./problems/general";
import { areaPerStyling } from "./problems/area-perimeter";
import  IconOnly from '../../app-files/images/icon-only.png'
import {isTooLight, LogoIcon} from '../../app-files/images/LogoIcon'

Font.registerHyphenationCallback(word => [word]); //makes words not break/hyphenate
// import font from '../../app-files/fonts/GamjaFlower-Regular.ttf'
// Font.register({ family: 'GamjaFlower', src: font});
// Font.register('../../app-files/fonts/GamjaFlower-Regular.ttf', { fontFamily: 'GamjaFlower' });

export const createStyledWS = (createdWorksheetQ, userSelection, generalSelection) => {
  let {questionAnswerList} = createdWorksheetQ
  const {activity, columns, questionStyle, savePaper} = generalSelection
  const {colors, styleNum} = generalSelection.styles
  const taskCards = activity === 'Task Cards' || questionStyle === 'Task Cards'
  const wsStyle = activity === 'Worksheet' || questionStyle === 'Worksheet'
  let [answerKey, questionList, newQAList, question] = [[],[],[],'']
  const border = taskCards && borderList.filter(border=>{return border.name===generalSelection.styles.border.name})[0].create(colors, styleNum)
  let smallAnswers
  // let wrongBGOptions = []
  const answerChoiceStyle = (style, ac, col, quest) => {
    const {A, B, C, D} = quest.answerChoices

    let answerContainer = taskCards ? 'tcAnswerContainer':'answerContainer'
    if (style==='text' && ac){
      let answerStyle = quest.smallAnswers || quest.concept==='Input Output Tables' ? 'smallAnswers' : taskCards ? 'tcAnswer': 'answer'
      return <View style={[styles[answerContainer], savePaper && {marginTop: 20}]}>
              <Text style={styles[answerStyle]}>{A} </Text> 
              <Text style={styles[answerStyle]}>{B} </Text> 
              <Text style={styles[answerStyle]}>{C} </Text> 
              {D && <Text style={styles[answerStyle]}>{D} </Text>}
            </View>
            
    } else if (style==='table' && activity !== 'Scavenger Hunt'){ //currently do not check if AC bc all tables require ac
      // let answerStyle = taskCards ? 'tcAnswer': 'viewAnswer'
      let direction = quest.direction
      let tableAnsLetter = direction==='horizontal' && wsStyle && !columns ? 'vertTableAnsLetter' : 'tableAnsLetter'
      
      let answerStyle = taskCards && direction==='vertical' ? 'tcViewAnswer' : wsStyle && !columns ? 'viewAnswerNoCol':'viewAnswerCol' 
      return <View style={[styles[answerContainer], savePaper && {marginTop: 20}, {padding: '0 0 35 0'}]}>
            <View style={[styles[answerStyle], wsStyle && !columns && {marginLeft: '-10'}]}>
              <View style={styles[tableAnsLetter]}><Text style={styles.letter}>{A.letter}</Text>
                {tableStyling(A, generalSelection, false, true, direction)}
              </View>
              <View style={styles[tableAnsLetter]}><Text style={styles.letter}>{B.letter}</Text>
                {tableStyling(B, generalSelection, false, true, direction)}
              </View>
            </View>
            <View style={[styles[answerStyle], wsStyle && !columns && {marginLeft: '-10'}]}>
              <View style={styles[tableAnsLetter]}><Text style={styles.letter}>{C.letter}</Text>
                {tableStyling(C, generalSelection, false, true, direction)}
              </View>
              {D && <View style={styles[tableAnsLetter]}><Text style={styles.letter}>{D.letter}</Text>
                {tableStyling(D, generalSelection, false, true, direction)}
              </View> }
            </View>
          </View>

    } else if ((style==='fraction' || style==='verticalFractTC')&& ac){
      // let answerStyle = taskCards ? 'tcAnswer': 'viewAnswer'
      answerContainer = style==='verticalFractTC' ? 'vertFractAnswerTCContainer' : answerContainer
      let answerStyle = questionStyle === 'Worksheet' && !columns && !savePaper ? 'wsViewAnswer' : 'viewAnswer'
      let answerLetter = style==='verticalFractTC' ? 'vertAnsLetter': 'ansLetter'
      let letter = style==='verticalFractTC' ? 'vertLetter': 'letter'

      return <View style={[styles[answerContainer], savePaper && {marginTop: 20}, 
        (activity === 'Scavenger Hunt' || taskCards) && {alignSelf: 'flex-start'}]}>
       <View style={styles[answerStyle]}>
        <View style={styles[answerLetter]}>
          <Text style={styles[letter]}>{A.letter}</Text>
          {createFraction(A, generalSelection, false, A.improper )[0]}
        </View>
        <View style={styles[answerLetter]}>
          <Text style={styles[letter]}>{B.letter}</Text>
          {createFraction(B, generalSelection, false, B.improper)[0]}
        </View>
      </View>
      <View style={styles[answerStyle]}>
        <View style={styles[answerLetter]}>
          <Text style={styles[letter]}>{C.letter}</Text>
          {createFraction(C, generalSelection, false, C.improper)[0]}
        </View>
        {D && <View style={styles[answerLetter]}>
          <Text style={styles[letter]}>{D.letter}</Text>
          {createFraction(D, generalSelection, false, D.improper)[0]}
        </View>}
      </View>  
      </View>
   

            
    } else if (style==='view' && ac && col){ ///maybe need a separate styling for columns ?? 
      let answerStyle = taskCards ? 'tcViewAnswer': 'viewAnswer'
        return <View style={[styles[answerContainer], savePaper && {marginTop: 20}]}>
            <View style={styles[answerStyle]}>{createFraction(A, generalSelection)[0]}</View>
            <View style={styles[answerStyle]}>{createFraction(B, generalSelection)[0]}</View>
            <View style={styles[answerStyle]}>{createFraction(C, generalSelection)[0]}</View>
            {D && <View style={styles[answerStyle]}>{createFraction(D, generalSelection)[0]}</View>}
          </View>
    } else {
      return null
    }
  }
         
  const createStyledQAList = () => {
    let qStyle = 'question'
    let qContainer = 'qContainer'
    const SH = activity === 'Scavenger Hunt'
    let fractText = taskCards ? 'fractTextTC' : activity === 'Scavenger Hunt' ? 'fractTextSH':'fractText'
    let shA = activity === 'Scavenger Hunt' ? 'shA' : false
    let minAnsHeight = activity!=='Scavenger Hunt' && {minHeight:32, maxHeight:32} //keep fraction and whole answers same height

    for (const question of questionAnswerList) {
      // wrongBGOptions.push(question.answerChoices[0]) //may be equal to answer but should be okay for BINGo

      const ac = question.ac && generalSelection.ac && !SH
      const {concept, probType} = question
      let answer = ac ? question.answerChoices.correctAnswer : question.correctAnswer
      smallAnswers = question.smallAnswers || smallAnswers ? true: false
      const bingoStyle = {justifyContent:'center', marginRight:'0'}
      if (SH){
        qStyle='shQuestion'
        qContainer='shContainer'
      }
      if (taskCards){
        qStyle="tcQuestion"
        qContainer= !ac ? 'tcContainerNoAC' : "tcContainer"
        if (probType === 'Application' || concept === 'Comparing Whole Numbers'|| concept === 'Comparing Decimals' || concept==='Order of Operations'){
          qStyle= question.prob.length < 160 ? "tcLongQuestion" : 'tcXLongQuestion'
        }
      }

      //Check for concept specific styling
      if (concept === 'Input Output Tables'){
        if (typeof question.prob === 'string') { // question is text, answer is table
          qStyle = questionStyle === 'Task Cards' ? 'tcTableTxtQStyle' : qStyle
          newQAList.push({
            question:<View style={styles[qContainer]}>
              <Text style={styles[qStyle]}>{question.prob} </Text> 
                          {answerChoiceStyle('table', ac, generalSelection.columns, question)}
                    </View>,
            answer: <Text>{answer.letter}</Text>,
            concept: question.concept, wrong: ac ? question.answerChoices.wrongLetter :question.answerChoices.wrongAnswer, probType: question.probType
        });
        } else { // question is table, answer is text
        qStyle= questionStyle === 'Worksheet'? 'tableQStyle': questionStyle ===  'Task Cards'? 'TCtableQStyle':'SHtableQStyle'

          newQAList.push({
            question: <View style={styles[qContainer]}>
              {/* <Text style={styles[fractText]}>{question.text.prob}</Text> */}
              <View style={styles[qStyle]}>{tableStyling(question.prob, generalSelection, false, false, question.direction)}</View> 
                        {answerChoiceStyle('text', ac, generalSelection.columns, question)}
              </View>,
            answer: <Text>{answer}</Text>, bgAnswer: answer,
            concept: question.concept, wrong: ac ? question.answerChoices.wrongLetter :question.answerChoices.wrongAnswer, probType: question.probType
          })
        }

        
      } else if (concept === 'Simplifying Fractions' || concept === 'Mixed & Improper Fractions'){
        qStyle = taskCards ? 'FractTCQ' : SH ? 'FractSHQ' : 'FractQ'
        newQAList.push({
          question: <View style={[styles[qContainer], (SH || taskCards) && {display: 'flex', flexDirection: 'column', alignItems: 'center'}]}>
            {showQText(generalSelection, userSelection) && <Text style={styles[fractText]}>{question.prob.text}</Text>}
            <View style={styles[qStyle]}>{createProbFraction(question.prob, generalSelection, false, question.prob?.improper)[0]}</View> 
                      {answerChoiceStyle('fraction', ac, false, question)}
            </View>,
          // answer: <View>{createFraction(answer, generalSelection, shA, answer.improper)[0]}</View>
          answer: <View style={[styles.ansLetter, minAnsHeight]}><Text style={ ac && styles.letter}>{ac && answer.letter}</Text>
            {createFraction(answer,generalSelection, shA, answer.improper)[0]}</View>,
          bgAnswer: ac ? answer.letter : <View style={[styles.ansLetter, minAnsHeight, bingoStyle]}>{createFraction(answer,generalSelection, shA, answer.improper)[0]}</View>,
          wrong:  ac ? question.answerChoices.wrongLetter : <View style={[styles.ansLetter, minAnsHeight, bingoStyle]}>{createFraction(question.answerChoices.wrongAnswer,generalSelection, shA, answer.improper)[0]}</View>,
          answerKey: shA && <View style={minAnsHeight}>{createFraction(answer, generalSelection, true, answer.improper)[0]}</View>, //set special styling for SH answer key vs SH answers
          concept: question.concept,  probType: question.probType, 
        })

      } else if ((concept === 'Adding Fractions' || concept === 'Subtracting Fractions') && question.probStyle === 'Set Up'){
        qStyle = taskCards ? 'FractTCQ' : SH? 'FractSHQ' : 'FractQ'
        let acStyle = taskCards  ? 'verticalFractTC' : 'fraction'
        newQAList.push({
          question: <View style={styles[qContainer]}>
            {question.prob.text && <Text style={styles[fractText]}>{question.prob.text}</Text>}
            
            <View style={styles[qStyle]}>
              {verticalFractions(question, generalSelection, ac)}
            </View> 
              {answerChoiceStyle(acStyle, ac, false, question)}
            </View>
            ,
          answer: <View style={[styles.ansLetter, minAnsHeight]}>
                    <Text style={ac && styles.ansKeyLetter}>{ac && answer.letter}</Text>

                    {createFraction(answer,generalSelection, shA)[0]}
                  </View>,
          bgAnswer: ac ? answer.letter : <View style={[styles.ansLetter, minAnsHeight, bingoStyle]}>{createFraction(answer,generalSelection, shA)[0]}</View>,
          wrong: ac ? question.answerChoices.wrongLetter : <View style={[styles.ansLetter, minAnsHeight, bingoStyle]}>{createFraction(question.answerChoices.wrongAnswer,generalSelection, shA)[0]}</View>,

          answerKey: shA && <View>{createFraction(answer, generalSelection, true)[0]}</View>,
          concept: question.concept, probType: question.probType
        })

      } else if (concept.includes('Fraction')){
        const compare = concept === 'Comparing Fractions'
        qStyle = taskCards ? 'FractTCQ' : SH ? 'FractSHQ' : 'FractQ'
        let acStyle = 'fraction'
        let styledAnswer = <View>{createFraction(answer, generalSelection, shA, compare)[0]}</View>
        let styledAnswerKey = <View>{createFraction(answer, generalSelection, true, compare)[0]}</View>
        if (answer.symbol){
          acStyle = 'fractionExpression'
          styledAnswer = <View>{horizontalFractions(answer,generalSelection, shA, compare)}</View>
          styledAnswerKey = <View>{horizontalFractions(answer,generalSelection, true, compare)}</View>
        } else if (typeof answer==='string'){
          acStyle = 'text'
          styledAnswer = <Text>{answer}</Text>
          styledAnswerKey = <Text>{answer}</Text>
        }
        newQAList.push({
          question: <View style={styles[qContainer]}>
            {question.prob.text && showQText(generalSelection, userSelection) && <Text style={styles[fractText]}>{question.prob.text}</Text>}
            
            <View style={styles[qStyle]}>
              {horizontalFractions(question, generalSelection, userSelection, concept==='Comparing Fractions')}</View> 
              {answerChoiceStyle(acStyle, ac, false, question)}
            </View>
            ,
          answer:<View style={[styles.ansLetter, minAnsHeight]}>
            <Text style={ac && styles.ansKeyLetter}>{ac && answer.letter}</Text>

            {styledAnswer}
            </View>,
          bgAnswer: ac ? answer.letter : <View style={[styles.ansLetter, minAnsHeight, bingoStyle]}>{createFraction(answer,generalSelection, shA)[0]}</View>,
          wrong: ac ? question.answerChoices.wrongLetter : <View style={[styles.ansLetter, minAnsHeight, bingoStyle]}>{createFraction(question.answerChoices.wrongAnswer,generalSelection, shA)[0]}</View>,

          answerKey: shA && styledAnswerKey, concept: question.concept, probType: question.probType
        })
      } else if ((concept=== 'Area' || concept==='Perimeter') && typeof question.prob==='object'){
        qStyle= questionStyle === 'Worksheet'? 'WSVerticalQStyle':'verticalQStyle'
        if(!ac){ //remove noAC styling bc tables need full space.
          qContainer="tcContainer"
        }
        newQAList.push({
          question: <View style={styles[qContainer]}>
            <View style={styles[qStyle]}>{areaPerStyling(question.prob, concept, generalSelection, ac)}</View> 
                      {answerChoiceStyle('text', ac, false, question)}
            </View>,
          answer: <Text>{answer}</Text>,
          concept: question.concept, wrong: ac ? question.answerChoices.wrongLetter : question.answerChoices.wrongAnswer, probType: question.probType
        })
      }

      else if (question.probStyle==='Set Up'){ //algorithm questions that need VIEW styling & have text answers
        qStyle= questionStyle === 'Worksheet'? 'WSVerticalQStyle':'verticalQStyle'

        newQAList.push({
          question: <View style={styles[qContainer]}>
            <View style={styles[qStyle]}>{verticalProbStyling(question.prob, concept, generalSelection, ac)}</View> 
                      {answerChoiceStyle('text', ac, false, question)}
            </View>,
          answer: <Text>{answer}</Text>,
          concept: question.concept, wrong: ac ? question.answerChoices.wrongLetter : question.answerChoices.wrongAnswer, probType: question.probType
        })
      } else {
        newQAList.push({
          question:<View style={styles[qContainer]}><Text style={styles[qStyle]}>{question.text ? question.text + question.prob : '' + question.prob}</Text> 
                        {answerChoiceStyle('text', ac, false, question)}
                  </View>,
          answer: <Text>{answer}</Text>,
          answerKey: concept.includes('Compar') && ac && <Text>{answer[1]}</Text>, //letter only
          concept: question.concept, wrong: ac ? question.answerChoices.wrongLetter : question.answerChoices.wrongAnswer, probType: question.probType
      });
    }

    }; 
  }

  createStyledQAList()
  let akStyle = smallAnswers ? 'smallAnswerKey' : 'answerKey'
  let bgAnswerKey = []
  const numberQuestions = () => {
    const smallQuest = !taskCards && userSelection.every(selection => {
      const {concept, specify} = selection
      //ac could could allowed with certain length of problems.
      const addSub =  ((concept === 'Adding Whole Numbers' || concept === 'Subtracting Whole Numbers') && 
        !specify.numbers['7 digit numbers'] && specify.probStyle === 'Set Up')
      
      let allSmall = !specify.ac && !specify.probType?.Application && (
        addSub || 
        ((concept === 'Multiplying Whole Numbers' || concept === 'Multiplying Decimals') && specify.probStyle === 'Set Up' && !specify.ac)
      )
      return(
        allSmall
      )
    })
    const medQuest = !taskCards && userSelection.every(selection => {
      const {concept, specify} = selection
      const addSub =  ((concept === 'Adding Whole Numbers' || concept === 'Subtracting Whole Numbers' || 
                        concept === 'Adding Decimals' || concept === 'Subtracting Decimals') && 
                        (specify.probStyle === 'Set Up' || (!specify.numbers['7 digit numbers'] && !specify.numbers['6 digit numbers'] && !specify.numbers['5 digit numbers'] && !specify.numbers['4 digit numbers'] && !specify.numbers['3 digit numbers'])))
      const addSubInt = ((concept === 'Adding Integers' || concept === 'Subtracting Integers') && !specify.numbers['3 digit numbers'])

      const multDivInt = ((concept === 'Multiplying Integers' || concept === 'Dividing Integers') && !specify.numbers['4 by 2 digit'] ) //&& !specify.numbers['3 by 2 digit']
      const mult = ((concept === 'Multiplying Whole Numbers' || concept === 'Multiplying Decimals') && 
        (specify.probStyle === 'Set Up' || (!specify.numbers['3 by 2 digit'] && !specify.numbers['4 by 1 digit'])))

      const dividing = ((concept === 'Dividing Whole Numbers') && 
        (specify.probStyle === 'Set Up' || (!specify.numbers['4 by 2 digit'] && !specify.numbers['4 by 1 digit'] && !specify.numbers['3 by 2 digit'] && !specify.numbers['3 by 1 digit'] )))
      const divDec = concept === 'Dividing Decimals' && (specify.probStyle === 'Set Up' || (!specify.dividend['4 digits'] && !specify.dividend['3 digits']))
      const fractions = concept.includes('Fraction') && concept !== 'Mixed & Improper Fractions' && concept !== 'Comparing Fractions' && 
        (specify.numbers && (!specify.numbers['Mixed Numbers'] && !specify.numbers['Mixed Numbers with Whole Numbers'] && !specify.numbers['Mixed Numbers With Regrouping'] && !specify.numbers['Fractions with Whole Numbers'] && !specify.numbers['Unit Fractions with Whole Numbers'])) && !specify.ac
      const equations = concept.includes('Equa')
      let allMed = !specify.probType?.Application && (addSub || addSubInt || mult || multDivInt || dividing || divDec || fractions || equations)

    return(
      allMed
    )
    })
    const colNum = taskCards ? 2 : wsStyle
    const BG = activity === 'Bingo' || activity === 'Tic Tac Bingo'
    const tcLogo = LogoIcon(generalSelection.styles,'small')

    for (var num=0; num<newQAList.length;num++) { 

      //numbers questions and adds content only to questionList and answers only to answerKey formatted for react-pdf. 

      if ((columns || taskCards) && activity!=='Scavenger Hunt') { 
        let tcStyle = taskCards
        const mainColor = !isTooLight(colors.mainDark) ? colors.mainDark : colors.sec !== '#fff' && !isTooLight(colors.sec) ? colors.sec : '#522b52'//'#4b4b4b'

        let numBGcolor = tcStyle ? {backgroundColor: mainColor} : ''

        const colQuestion = (num) => {
        const concept = newQAList[num].concept
        const smallMargin = concept === 'Input Output Tables' || concept === 'Area' || concept === 'Perimeter'

          return (
            <View key={num} style={[styles[tcStyle ? 'tcCardWrap' : 'questionWrap']]}>
              {taskCards && border}
              <View style={[styles[tcStyle ? 'tcCardBorder' : 'questionAnswer'], savePaper && styles.qaSavePaper, smallMargin && tcStyle && {padding: '50 0 0 5'}]}>
                <Text style={[styles[tcStyle ? 'tcNum' : 'numStyle'], numBGcolor, smallMargin && {top: 1, left: 1}]}>{num+1+(tcStyle?'':')')}</Text>
                <View style={styles[tcStyle ? 'tcColumnQuestion' : 'columnQuestion']}>
                  {newQAList[num].question}
                </View>
              </View>
              {/* <Image style={{height:'30', width: '30', position: 'absolute', bottom: '10', right: '10'}} src={IconOnly(colors, 'small')} /> */}
              {taskCards && tcLogo}
            </View>
          )
        }
        if ((taskCards || (!smallQuest && !medQuest)) && num+1>newQAList.length-1 ){ // //columns and odd # questions /if num goes above the length of the array, only add one question.
          questionList.push(<View key={num} wrap={false} style={[styles[tcStyle ? 'tcRowWrap' : 'rowWrap'], savePaper && {minHeight:50}, {position: 'relative', zIndex: 0}]}>
                              {colQuestion(num)}
                              {<View style={wsStyle ? styles.questionWrap : styles.tcQWrap}></View>}
                            </View>)
          answerKey.push(
          <View key={num} style={styles[akStyle]}>
            <Text style={styles.answerNum}>{num+1+')'}</Text>
            {newQAList[num].answerKey || newQAList[num].answer}
            </View>)
          BG && bgAnswerKey.push(<View key={num} style={styles.bingoAnswer}>
                    {newQAList[num].bgAnswer || newQAList[num].answerKey || newQAList[num].answer}</View>)

        }
        else{ //columns and at least 2 questions left/ even number of questions
          const len = newQAList.length-1
          const xtraCol = !taskCards && (smallQuest || medQuest)
          const col2 = (columns && wsStyle && num+1 <= len) || taskCards
          const [col3, col4, col5] = [(num+2 <= len && len > 5 && wsStyle), (num+3 <= len && len > 20 && wsStyle), (num+4 <= len && len > 29 && wsStyle)]
          questionList.push(<View key={num} wrap={false} style={[styles[tcStyle ? 'tcRowWrap' : 'rowWrap'], savePaper && {minHeight:smallQuest ? 50 : 100}, {position: 'relative', zIndex: 0}]}>
              {colQuestion(num)}
              {col2 ? colQuestion(num+1) : <View style={styles.questionWrap}></View>}
              {xtraCol && col3 ? colQuestion(num+2): xtraCol && len > 5 && wsStyle ? <View style={styles.questionWrap}></View>: <View></View>}
              {smallQuest && col4 ? colQuestion(num+3): smallQuest && len > 20 && wsStyle ? <View style={styles.questionWrap}></View>: <View></View>}
              {smallQuest && col5 ? colQuestion(num+4): smallQuest && len > 29 && wsStyle ? <View style={styles.questionWrap}></View>: <View></View>}
            </View>
            )
          answerKey.push(<View key={num} style={styles[akStyle]}><Text style={styles.answerNum}>{num+1+')'}</Text>
            {newQAList[num].answerKey || newQAList[num].answer}</View>)

          col2 &&
            answerKey.push(<View key={num+1} style={styles[akStyle]}><Text style={styles.answerNum}>{num+2+')'}</Text>
            {newQAList[num+1].answerKey || newQAList[num+1].answer}</View>)
            
          xtraCol && col3 && (
            answerKey.push(<View key={num+2} style={styles[akStyle]}><Text style={styles.answerNum}>{num+3+')'}</Text>
            {newQAList[num+2].answerKey || newQAList[num+2].answer}</View>)
            )
          smallQuest && col4 && (
            answerKey.push(<View key={num+3} style={styles[akStyle]}><Text style={styles.answerNum}>{num+4+')'}</Text>
            {newQAList[num+3].answerKey || newQAList[num+3].answer}</View>)
            )
          smallQuest && col5 && (
            answerKey.push(<View key={num+4} style={styles[akStyle]}><Text style={styles.answerNum}>{num+5+')'}</Text>
            {newQAList[num+4].answerKey || newQAList[num+4].answer}</View>)
            )

          BG && 
            bgAnswerKey.push(<View key={num} style={styles.bingoAnswer}>
              {newQAList[num].bgAnswer || newQAList[num].answerKey || newQAList[num].answer}</View>)
          col2 && BG && 
            bgAnswerKey.push(<View key={num+1} style={styles.bingoAnswer}>
              {newQAList[num+1].bgAnswer || newQAList[num+1].answerKey || newQAList[num+1].answer}</View>)
          xtraCol && col3 && BG && 
          bgAnswerKey.push(<View key={num+2} style={styles.bingoAnswer}>
            {newQAList[num+2].bgAnswer || newQAList[num+2].answerKey || newQAList[num+2].answer}</View>)
          smallQuest && col4 && BG && 
          bgAnswerKey.push(<View key={num+3} style={styles.bingoAnswer}>
            {newQAList[num+3].bgAnswer || newQAList[num+3].answerKey || newQAList[num+3].answer}</View>)
          smallQuest && col5 && BG && 
          bgAnswerKey.push(<View key={num+4} style={styles.bingoAnswer}>
            {newQAList[num+4].bgAnswer || newQAList[num+4].answerKey || newQAList[num+4].answer}</View>)


          // num+=smallQuest && len > 29 ? 4 : smallQuest && len > 20 ? 3 : (smallQuest && len > 8) || medQuest ? 2 : 1
          num+= smallQuest && col5 ? 4 : smallQuest && col4 ? 3 : xtraCol && col3 ? 2 : col2 ? 1 : 0
        }
      } else { //no columns
        questionList.push(<View key={num} wrap={false} style={[styles.questionAnswer, savePaper && styles.qaSavePaper,  savePaper ? {minHeight:smallQuest ? 50 : 100} : {minHeight: smallQuest ? 100 : 150}, {position: 'relative', zIndex: 0}]}>
          <Text style={styles.questionNum}>{num+1+')'}</Text>{newQAList[num].question}</View>)

        answerKey.push(<View key={num} style={styles[akStyle]}>
                      <Text style={styles.answerNum}>{num+1+')'}</Text>
                      {newQAList[num].answerKey || newQAList[num].answer}</View>)
        BG && 
          bgAnswerKey.push(<View key={num} style={styles.bingoAnswer}>
            {newQAList[num].bgAnswer || newQAList[num].answerKey || newQAList[num].answer}</View>)


      }
    } 
  }
  numberQuestions()
  let wsActivity, shuffledLetters
  if (activity === 'Bingo' || activity === 'Tic Tac Bingo'){
    wsActivity = createBingo(generalSelection, newQAList, bgAnswerKey)
  } else if (activity === 'Scavenger Hunt'){
    [questionList, answerKey, shuffledLetters] = createSH(newQAList, userSelection, generalSelection);
  }
  return {activity:wsActivity, questionList,answerKey, shuffledLetters}
}