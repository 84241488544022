import React from "react";
import ReactDOM from "react-dom";
import { CSSTransition } from "react-transition-group";
import Backdrop from "./Backdrop";
import IconClose from '../../../app-files/images/icon-close.svg'
const ModalOverlay = (props) => {
  const content = (
    <div className="modal-container">
    <div className={`modal ${props.className}`} style={props.style} onClick={(e)=>{e.stopPropagation()}}>
      <header
        className={`modal-header ${props.headerClass}`}
        style={props.hideTopPadding ? { paddingBottom: 0 } : {}}
      >
      <img src={IconClose} alt="close" className="close-modal" onClick={props.onCancel} />

        <h2>{props.header}</h2>
      </header>
      {props.hideForm ? (
        <>
          <div
            className={`modal-content ${props.contentClass}`}
            style={props.hideTopPadding ? { paddingTop: 0 } : {}}
          >
            {props.children}
          </div>
          {!props.hideFooter ? (
            <footer className={`modal-footer ${props.footerClass}`}>
              {props.footer}
            </footer>
          ) : null}
        </>
      ) : (
        <form
          onSubmit={
            props.onSubmit ? props.onSubmit : (event) => event.preventDefault()
          }
        >
          <div
            className={`modal-content ${props.contentClass}`}
            style={props.hideTopPadding ? { paddingTop: 0 } : {}}
          >
            {props.children}
          </div>
          {!props.hideFooter ? (
            <footer className={`modal-footer ${props.footerClass}`}>
              {props.footer}
            </footer>
          ) : null}
        </form>
      )}
      </div>
    </div>
  );
  return ReactDOM.createPortal(content, document.getElementById("modal-hook"));
};
// ...props sends all props passed through
const Modal = (props) => {
  return (
    <React.Fragment>
      {props.show && <Backdrop onClick={props.onCancel} />}
      <CSSTransition
        in={props.show}
        mountOnEnter
        unmountOnExit
        timeout={200}
        classNames="modal"
      >
        <ModalOverlay {...props} />
      </CSSTransition>
    </React.Fragment>
  );
};

export default Modal;
