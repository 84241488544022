import { specAC, specAlg, specHZ, specify } from "../helper"

export const MultDivIntegers = (props) => {
  const {createLink, ac} = props
  const [c1, c2] = ['Multiplying Integers', 'Dividing Integers']
  const [dig1_1, dig2_1, dig3_1, dig4_1, dig2_2, dig3_2, dig4_2] = ['1 by 1 digit', '2 by 1 digit', '3 by 1 digit', '4 by 1 digit', '2 by 2 digit', '3 by 2 digit', '4 by 2 digit']
  
  const title = (mod1) => {
    return `Multiplying & Dividing ${mod1} Integers`
  }
  const createSpec = (spec1, spec2) => {
    let specC1 = [specify('numbers', spec1), specAlg, specAC(ac)]
    let specC2 = [specify('numbers', spec2? spec2  : spec1), specAlg, specAC(ac)]

    return {[c1]: specC1, [c2]: specC2}
  }

  return (<>
    <div className="buttonGroup">
      <div className="concept-topic">Multiplying & Dividing Integer Algorithms</div>
      {createLink([c1, c2], title(dig1_1+' & '+dig2_1+''), '', createSpec([dig1_1], [dig2_1]), 18)}
      {createLink([c1, c2], title(dig2_1), '', createSpec([dig2_1]), 18)}
      {createLink([c1, c2], title(dig2_1+' & '+dig3_1), '', createSpec([dig2_1, dig3_1]), 18)}
      {createLink([c1, c2], title(dig3_1), '', createSpec([dig3_1]), 18)}
      {createLink([c1, c2], title(dig3_1+' & '+dig4_1), '', createSpec([dig3_1, dig4_1]), 18)}
      {createLink([c1, c2], title(dig4_1), '', createSpec([dig4_1]), 18)}
      {createLink([c1, c2], title(dig3_2), '', createSpec([dig3_2]), 18)}
    </div>
    </>
  )
}