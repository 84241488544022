import React from "react";
import { Route, Redirect } from "react-router-dom";
import Home from "../general/home/pages/Home";
import Parents from "../general/home/pages/Parents";
import Teachers from "../general/home/pages/Teachers";
import Schools from "../general/home/pages/Schools";
import User from "./RoutesUser";
import WS from "./RoutesWS";
import TermsofService from "../general/info/pages/TermsofService";
import PrivacyPolicy from "../general/info/pages/PrivacyPolicy";
import ComingSoon from "../general/info/pages/ComingSoon";
import New from "../general/info/pages/New";
import Samples from "../general/info/pages/Samples";
import Faqs from "../general/info/pages/Faqs";
import Activities from "../general/info/pages/Activities";

//combine routes before importing to App bc otherwise the routes below would not get rendered
const Routes = ({ debugMode, setDebugMode, mobileWarning }) => {
  return (
    <>
      <Route exact path="/">
        <Home debugMode={debugMode} setDebugMode={setDebugMode} />
      </Route>
      <Route exact path="/parents">
        <Parents />
      </Route>
      <Route exact path="/teachers">
        <Teachers />
      </Route>
      <Route exact path="/schools">
        <Schools />
      </Route>
      <Route exact path="/terms-of-service">
        <TermsofService />
      </Route>
      <Route exact path="/privacy-policy">
        <PrivacyPolicy/>
      </Route>
      {/* <Route exact path="/coming-soon">
        <ComingSoon />
      </Route> */}
      <Route exact path="/activities">
        <Activities />
      </Route>
      {/* <Route exact path="/new">
        <New />
      </Route> */}
 
      <Route exact path="/faqs">
        <Faqs />
      </Route>
      <WS mobileWarning={mobileWarning}/>
      <User />

    </>
  );
};

export default Routes;
