// import * as gen from './general.js'
import {randInt, shuffleArray, wrongOptions, answerChoicesKey,
        boyList, girlList, nameList, cityList, disasterList, sportList, candyList, forestAnimal, lakeAnimal, desertAnimal, selectRand, fixNum, removeDup} from '../general.js'
import { verticalAlignWhole} from '../vertical-align'

const createSubtractionNumbers = (smallNum, largeNum, regroup) => {
    largeNum = largeNum.toString().split('')
    smallNum = smallNum.toString().split('')
    const randNum = randInt(1, 9-largeNum[0])
    let [loopStart, zeroCount, regroupCount] = [largeNum.length-smallNum.length, 0, 0];
    const largeNumArray = []
    let smallNumI = 0
    
    // for now do not allow the first digits to be equal
    if (largeNum.length === smallNum.length && largeNum[0] === smallNum[0]){
        smallNum[0] = smallNum[0] === '9' ? randInt(1,8) : smallNum[0]

        // guarantee 1st is large number 
        largeNum[0] = largeNum[0] === '9' ? 9 : randInt(Number(smallNum[0])+1, 8)
    } 
    if (largeNum.length === smallNum.length) {
        loopStart = 1
        smallNumI = 1 //start both of their loops on the 1st index since 0 is already done.
    }
    let [rgLargeNum, rgZeroLargeNum, noRgLargeNum] = [[...largeNum], [...largeNum], [...largeNum]]

    for (let i = smallNumI, ls=loopStart; i < smallNum.length; i++, ls++){
        let digit = Number(smallNum[i])
        let [smallerDigit, largerDigit] = [digit-randInt(1, digit-1), digit+randInt(1, 9-digit)]
        if (regroup['No regrouping'])
            noRgLargeNum[ls] = digit+randInt(0, 9-digit)
        if (regroup['Regroup with zero']){
            let randRG = randInt(0,3)
            if (randRG<=1){ //RG with zero 50% change
                rgZeroLargeNum[ls] = 0
                smallNum[i] = randInt(1,9)
                zeroCount++
            } else if (randRG===2){ // regular RG without zero 25% 
                rgZeroLargeNum[ls] = smallerDigit
            } else { // non-regrouping 25%
                rgZeroLargeNum[ls] = digit === 9 ? 9 : largerDigit;
            }
            
            if (i===smallNum.length-1 && zeroCount === 0){ //ensure at least one digit is zero
                let randIdx = randInt(smallNumI, i)
                let randLS = randIdx + ls-i
                rgZeroLargeNum[randLS] = 0
                smallNum[randIdx] = randInt(1,9)
            }
        } 
        if (regroup['Regroup without zero']){
            let randRG = randInt(1,2)
            if (randRG === 1 || smallNum.length===2){//regrouping for this digit
                regroupCount++
                rgLargeNum[ls] = digit === 0 || digit === 1 ? randInt(1,4) : smallerDigit;
                smallNum[i] = digit === 0 || digit === 1 ? randInt(5,9) : digit //make sure small number is larger to enforce regrouping.
            } else { //not regrouping for this digit
                rgLargeNum[ls] = digit === 9 ? 9 : largerDigit; //if digit is 9, cannot add a digit
                smallNum[i] = digit === 9 ? randInt(0,8) : digit 
            }
            if (i===smallNum.length-1 && regroupCount === 0){//make sure at least one was regrouping
                rgLargeNum[ls] = digit === 0 || digit === 1 ? randInt(1,4) : smallerDigit;
                smallNum[i] = digit === 0 || digit === 1 ? randInt(5,8) : digit
            }
        }
    }
    regroup['No regrouping'] && largeNumArray.push(noRgLargeNum.join(''));
    regroup['Regroup with zero'] && largeNumArray.push(rgZeroLargeNum.join(''));
    regroup['Regroup without zero'] && largeNumArray.push(rgLargeNum.join(''));
    
    return [Number(selectRand(largeNumArray)), Number(smallNum.join(''))]
}
export const asNumbers = (userSelection, op) => {
    var numArray = []
    let numSelect = userSelection.concept === 'Adding Whole Numbers' || userSelection.concept==='Subtracting Whole Numbers'  ? userSelection.specify.numbers : 
    userSelection.concept === 'Tables' || userSelection.concept==='Perimeter'? userSelection.specify.wholeNumbers : userSelection.specify.asNumbers
    if (numSelect['1 digit numbers']){
        numArray.push({main:[randInt(1,9), randInt(1,9)], med: randInt(1,9)});
        
    } if (numSelect['2 digit numbers']){
        numArray.push({main:[randInt(numSelect['1 digit numbers'] ? 1 : 10, 99), randInt(10,99)], med: randInt(10, 99)});

    } if (numSelect['3 digit numbers']) {
        numArray.push({main: [randInt(numSelect['2 digit numbers'] ? 10 : 100, 999), randInt(100, 999)], med: randInt(100, 999)});

    } if (numSelect['4 digit numbers']){
        numArray.push({main: [randInt(numSelect['3 digit numbers'] ? 100 : 1000, 9999), randInt(1000, 9999)], med: randInt(1000, 9999)});

    } if (numSelect['5 digit numbers']){
        let small = numSelect['3 digit numbers'] ? 100 : numSelect['4 digit numbers'] ? 1000 : 10000
        numArray.push({main: [randInt(small, 99999), randInt(10000, 99999)], med: randInt(10000, 99999)});

    } if (numSelect['6 digit numbers']){
        let small = numSelect['4 digit numbers'] ? 1000 : numSelect['5 digit numbers'] ? 10000 : 100000
        numArray.push({main: [randInt(small, 999999),randInt(100000, 999999)], med: randInt(100000, 999999)});

    } if (numSelect['7 digit numbers']) {
        let small = numSelect['5 digit numbers'] ? 10000 : numSelect['6 digit numbers'] ? 100000 : 1000000

        numArray.push({main: [randInt(small, 9999999), randInt(1000000, 9999999)], med: randInt(1000000, 9999999)});
    }
    let selectedNumList =  selectRand(numArray)
    var numList = selectedNumList.main.sort((a,b) => a-b)
    
    if (userSelection.concept==='Subtracting Whole Numbers'){
        var [largeNum, smallNum] = createSubtractionNumbers(numList[0], numList[1], userSelection.specify.regroup)

    }
    let answer, wrong
    let numberS = smallNum || numList[0]
    let numberM = selectedNumList.med
    let numberL = largeNum || numList[1]
    if (op === 'add'){
        answer = numberS + numberL
        wrong = wrongOptions(answer, 'add', numberL, numberS) 
    } else {
        answer = numberL - numberS
        wrong = wrongOptions(answer, 'sub', numberL, numberS) 
    }
return {numberS, numberM, numberL, answer, wrong}
}  
var addWord = ['altogether', 'in total', 'combined']

export const addAlg = (userSelection, generalSelection) => {
    var {numberS, numberL, answer, wrong} = asNumbers(userSelection, 'add')

    var AC = answerChoicesKey(answer, wrong[0], wrong[1], wrong[2])
    if (userSelection.specify.probStyle==='Set Up') {
        var prob = {numberL, numberS, op:'+'}
    } else {
        prob =`${numberL.toLocaleString()} + ${numberS.toLocaleString()} = `
    }
    var problem = {prob: prob,
                answerChoices: AC,
                correctAnswer:answer.toLocaleString(),
                probStyle: userSelection.specify.probStyle
                }
    return problem
}

export const subAlg = (userSelection, generalSelection) => {
    var {numberS, numberL, wrong, answer} = asNumbers(userSelection, 'sub')

    var AC = answerChoicesKey(answer, wrong[0], wrong[1], wrong[2])
    if (userSelection.specify.probStyle==='Set Up') {
        var prob = {numberL, numberS, op:'-'}
    } else {
        prob =`${numberL.toLocaleString()} - ${numberS.toLocaleString()} = `
    }
    var problem = {prob: prob,
                answerChoices: AC,
                correctAnswer:answer.toLocaleString(),
                probStyle: userSelection.specify.probStyle
                }

    return problem
}

export const addBasic =  (userSelection, generalSelection) => {
    var {numberS, numberL, answer, wrong} = asNumbers(userSelection, 'add')
    let randNum = numberL>10 ? randInt(10,99) : randInt(2, 9)

    let prob = `What is the sum of ${fixNum(numberL)} and ${fixNum(numberS)}?`
    let prob2 = `What is the sum of ${fixNum(numberS)} and ${fixNum(numberL)}?`
    let prob3 = `What is the total of ${fixNum(numberS)} and ${fixNum(numberL)}?`
    let prob4 = `How much is ${fixNum(numberS)} and ${fixNum(numberL)} combined?`


    if (userSelection.specify.level === 'Multi-Step'){
        prob =  `What is ${randNum} more than the sum of ${fixNum(numberL)} and ${fixNum(numberS)}?`
        prob2 =  `What is ${fixNum(numberL)} more than the sum of ${randNum} and ${fixNum(numberS)}?`
        prob3 =  `The sum of ${fixNum(randNum)} and ${fixNum(numberS)} is added to ${fixNum(numberL)}. What is the total sum?`
        prob4 =  `What is the total of ${fixNum(numberL)} combined with the sum of ${randNum} and ${fixNum(numberS)}?`

        answer = answer + randNum
        wrong = [randNum+numberL, numberL+numberS, answer+1, answer+2]
        if (answer > 4) {
            wrong.push(answer-1, answer-2)
        }
    }

    wrong = shuffleArray(removeDup(wrong))
    prob = selectRand([prob, prob2, prob3, prob4])
    var AC = answerChoicesKey(answer, wrong[0], wrong[1], wrong[2])
    var problem = {prob: prob,
                answerChoices: AC,
                correctAnswer: fixNum(answer),
                probType: 'Application'
                }
    return problem
}

export const subBasic =  (userSelection, generalSelection) => {
    var {numberS, numberL, answer, wrong} = asNumbers(userSelection, 'sub')
    let randNum = numberL > 100000 ? randInt(10000, 100000) : numberL > 10000 ? randInt(1000, 10000) : numberL > 1000 ? randInt(100, 1000) : numberL>10 ? randInt(10,100) : randInt(2, 9)

    let prob = `What is the difference between ${fixNum(numberL)} and ${fixNum(numberS)}?`
    if (userSelection.specify.level === 'Multi-Step'){
        let temp1 =  `What is ${fixNum(randNum)} more than the difference between ${fixNum(numberL)} and ${fixNum(numberS)}?`
        // subtract randNum from numL since it needs to be added in the problem, this ensures that the final step of subtraction remains with the original numbers keeping selections for regrouping accurate at that step.
        let temp2 =  `How much greater is ${fixNum(randNum)} and ${fixNum(numberL-randNum)} combined than ${fixNum(numberS)}?`
        prob = selectRand([temp1, temp2])

        if (prob === temp1){
            answer = randNum+answer
            wrong = [randNum+numberL, numberL+numberS, answer+1, answer+2, answer-1, answer-2]
        } else {
            wrong = [numberL+numberS+randNum, numberL+numberS, numberL, answer+1, answer+2, answer-1, answer-2]
            if (numberL-numberS-randNum > -1) {
                wrong.push(numberL-numberS-randNum)
            }
            if ( numberL-randNum + numberS - randNum > -1) {
                wrong.push( numberL-randNum + numberS - randNum)
            }
        } 
    }

    wrong = shuffleArray(removeDup(wrong))

    var AC = answerChoicesKey(answer, wrong[0], wrong[1], wrong[2])
    var problem = {prob: prob,
                answerChoices: AC,
                correctAnswer: fixNum(answer),
                probType: 'Application'
                }
    return problem
}


export const subWhole = (userSelection) => { //sport
    var name = shuffleArray(nameList)
    var sport = sportList[randInt(0, sportList.length-1)]
    var {numberS, numberL, answer, wrong} = asNumbers(userSelection, 'sub')

    if (numberL < 100) {
        var time = 'yesterday'
    } else if (numberL <1000) {
        time = 'last week'
    } else if (numberL < 10000) {
        time = 'last year'
    } else if (numberL > 9999) {
        time = 'total'
    }

    var AC = answerChoicesKey(answer, wrong[0], wrong[1], wrong[2])
    var prob1 = (`${name[0]} played ${sport} for ${numberS.toLocaleString()} minutes ${time}. ${name[1]} ` +
    `played ${sport} for ${numberL.toLocaleString()} minutes ${time}. How many more minutes did `+
    `${name[1]} play than ${name[0]}?`)
    var prob2 = (`${name[0]} played ${sport} for ${numberS.toLocaleString()} minutes ${time}. ${name[1]} `+
    `played ${sport} for ${numberL.toLocaleString()} minutes ${time}. What is the difference between ` +
    `the number of minutes ${name[1]} played and the number of minutes ${name[0]} played?`)
    var prob3 = (`${name[0]} loves ${sport} and watched ${numberS.toLocaleString()} minutes ${time}. ${name[1]} `+
    `also likes ${sport} and has watched ${numberL.toLocaleString()} minutes ${time}. What is the difference between ` +
    `the number of minutes ${name[1]} and ${name[0]} watched ${sport}?`)
    var randProb = [prob1, prob2, prob3][randInt(0,2)]
    var problem = {prob: randProb,
        answerChoices: AC,
        correctAnswer: answer.toLocaleString(), probType: 'Application'}
    return problem

}
export const addWhole = (userSelection) => { //sports 
    var name = shuffleArray(nameList)
    var sport = sportList[randInt(0, sportList.length-1)]
    let newAddWord = selectRand(['total','altogether'])
    var {numberS, numberL, answer, wrong} = asNumbers(userSelection, 'add')

    if (numberL < 100) {
        var time = 'yesterday'
    } else if (numberL <1000) {
        time = 'last week'
    } else if (numberL < 10000) {
        time = 'last year'
    } else if (numberL > 9999) {
        time = 'total'
    }
 
    var AC = answerChoicesKey(answer, wrong[0], wrong[1], wrong[2])
    var prob1 = (`${name[0]} loves playing ${sport} with ${name[1]}. He played ${(numberL).toLocaleString()} `+
        `minutes ${time}. ${name[1]} played ${sport} for ${(numberS).toLocaleString()} minutes `+
        `more than ${name[0]} ${time}. How many minutes did ${name[1]} play ${time}?`)
    var prob2 = (`${name[0]} played ${sport} for ${(numberS).toLocaleString()} minutes ${time}. `+
        `${name[1]} played ${sport} for ${(numberL).toLocaleString()} minutes ${time}. How many minutes did ` +
        `${name[0]} and ${name[1]} play ${newAddWord}?`)

        // (`${name[0]} loves playing ${sport} with his brother. He played ${(numberL).toLocaleString()} `+
        // `minutes ${time}. His brother ${name[1]} played ${sport} for ${(numberS).toLocaleString()} minutes `+
        // `more than ${name[0]} ${time}. How many minutes did ${name[0]} and ${name[1]} play altogether?`) // good for multi-step 
        var randProb = selectRand([prob1, prob2])
        var problem = {prob: randProb,
        answerChoices: AC,
        correctAnswer: answer.toLocaleString(), probType: 'Application'
        }

    return problem        
}
export const subWhole2 = (userSelection) => { //candy
    var name = nameList[randInt(0, nameList.length-1)]
    var disaster = disasterList[randInt(0, disasterList.length-1)]
    var girl = shuffleArray(girlList)
    var boy = shuffleArray(boyList)

    var candy = shuffleArray(candyList)
    var {numberS, numberM, numberL, answer, wrong} = asNumbers(userSelection, 'sub')

    var AC = answerChoicesKey(answer, wrong[0], wrong[1], wrong[2])
    var probArray = []
    const packages = numberL < 100 ? 'packages of' : '';
    probArray.push(`In a jar there are three different types of candies. There are ${numberS.toLocaleString()} ${candy[0]} `+
        `, ${numberM.toLocaleString()} ${candy[1]}, and ${numberL.toLocaleString()} ${candy[2]}. What is the difference between `+ 
        `the number of ${candy[0]} and the number of ${candy[2]} in the jar?`)
    probArray.push(`${name} has a basket of candies. There are ${numberS} ${candy[0]}, `+
        `${numberS.toLocaleString()} ${candy[1]}, and ${numberL.toLocaleString()} ${candy[2]}. How many more `+
        `${candy[2]} are in the basket than ${candy[0]} ?`)
    probArray.push(`${girl[0]} loves ${candy[0]} so much that she ate ${numberS.toLocaleString()} in the past month. `+
        `This brought her total number of ${candy[0]} eaten in her life to ${numberL.toLocaleString()}. How much was `+
        `her total ${candy[0]} eaten before this past month?`)
    probArray.push(`${boy[0]} is obsessed with ${candy[0]} so he decides to stock up in case of a ${disaster} coming soon.`+
        `He buys ${numberL.toLocaleString()} ${packages} ${candy[0]} but then on the way home he got really hungry so he ate `+
        `${numberS.toLocaleString()} ${packages} ${candy[0]}. How many ${packages} ${candy[0]} does he have now?`)
    probArray.push(`${boy[0]} loves ${candy[0]} so much that he decides to stock up by purchasing ${numberL.toLocaleString()} ${packages} ${candy} in case of `+
        `a ${disaster} coming soon. He decided that he had way too many and ended up giving ${numberS.toLocaleString()} of the ${packages} ${candy[0]} `+
        `to his friend ${boy[1]}. How many ${packages} ${candy[0]} does he have left?`)
    var randProb = selectRand(probArray)
    var problem = {prob: randProb,
        answerChoices: AC,
        correctAnswer: answer.toLocaleString(), probType: 'Application'}

    return problem
            


}
export const addWhole2 = (userSelection) => { //candy
    const name = shuffleArray(nameList)
    const girl = shuffleArray(girlList)
    const boy = shuffleArray(boyList)
    const disaster = disasterList[randInt(0, disasterList.length-1)]

    const candy = shuffleArray(candyList)
    const {numberS, numberM, numberL, answer, wrong} = asNumbers(userSelection, 'add')
    const packages = numberL < 100 ? 'packages of' : '';

    const AC = answerChoicesKey(answer, wrong[0], wrong[1], wrong[2])
    const probArray = []
    probArray.push(`There are three different types of candies in a jar. There are ${numberS.toLocaleString()} ${candy[0]}`+
    `, ${numberM.toLocaleString()} ${candy[1]}, and ${numberL.toLocaleString()} ${candy[2]}. How many total ${candy[0]} and ${candy[2]} are in the jar?`)
    probArray.push(`${name[0]} has a jar with three different types of candies inside. There are ${numberS.toLocaleString()} `+
    `${candy[0]},  ${numberM.toLocaleString()} ${candy[1]}, and ${numberL.toLocaleString()} ${candy[2]}. `+
    ` How many ${candy[2]} and ${candy[0]} are in the jar altogether?`)
    probArray.push(`${girl[0]} loves ${candy[0]} so much that she has already eaten ${numberL.toLocaleString()} ${packages} ${candy[0]} in her life. `+
    `If she eats ${numberS.toLocaleString()} more by the end of the year, how many total ${packages} ${candy[0]} will she have eaten?`)
    probArray.push(`${boy[0]} is obsessed with ${candy[0]} so he already has ${numberS.toLocaleString()} in his pantry at home. `+
    `He begins to worry about a ${disaster} coming soon, so he decides `+
    `to stock up by purchasing ${numberL.toLocaleString()} ${packages} ${candy[0]} just in case. How many ${packages} ${candy[0]} does he have now?`)
    var randProb = selectRand(probArray)
    var problem = {
        prob: randProb,
        answerChoices: AC,
        correctAnswer: answer.toLocaleString(), probType: 'Application'}
    return problem        
}

export const subWhole3 = (userSelection) => { //animals
    var randForestAnimal = shuffleArray(forestAnimal)
    var randDesertAnimal = shuffleArray(desertAnimal)
    var randLakeAnimal = shuffleArray(lakeAnimal)
    var randAnimal = shuffleArray([randDesertAnimal, randForestAnimal, randLakeAnimal])
    var {numberS, numberL, answer, wrong} = asNumbers(userSelection, 'sub')


    var place
    if (randAnimal[0] === randDesertAnimal) {
        place = 'desert'
    }else if (randAnimal[0] === randForestAnimal) {
        place = 'forest'
    }else if (randAnimal[0] === randLakeAnimal) {
        place = 'lake'
    }
  
    var AC = answerChoicesKey(answer, wrong[0], wrong[1], wrong[2])
    var probArray = []
    probArray.push(`There are ${numberL.toLocaleString()} ${randAnimal[0][0]}s and ${randAnimal[0][1]}s in the `+
        `${place}. If there are ${numberS.toLocaleString()} ${randAnimal[0][0]}s in the ${place}, `+
        `how many ${randAnimal[0][1]}s are in the ${place}?`)
    probArray.push(`In the ${place} there are ${numberS.toLocaleString()} ${randAnimal[0][1]}s and `+
        `${numberL.toLocaleString()} ${randAnimal[0][0]}s. What is the difference beween the number of `+
        `${randAnimal[0][0]}s and ${randAnimal[0][1]}s in the ${place}?`)
    probArray.push(`Researchers are surveying the ${place}. They found a total of ${numberL.toLocaleString()} `+ 
        `${randAnimal[0][1]}s, and a total of ${numberS.toLocaleString()} ${randAnimal[0][0]}s. How many more `+
        `${randAnimal[0][1]}s are there in the ${place} than ${randAnimal[0][0]}s?`)
    var randProb = selectRand(probArray)
    var problem = {prob: randProb,
        answerChoices: AC,
        correctAnswer: answer.toLocaleString(), probType: 'Application'}
    return problem

            
}
export const addWhole3 = (userSelection) => { //animals
    var randForestAnimal = shuffleArray(forestAnimal)
    var randDesertAnimal = shuffleArray(desertAnimal)
    var randLakeAnimal = shuffleArray(lakeAnimal)
    var randAnimal = shuffleArray([randDesertAnimal, randForestAnimal, randLakeAnimal])
    var {numberS, numberL, answer, wrong} = asNumbers(userSelection, 'add')
    let newAddWord = selectRand(addWord)

    var place
    if (randAnimal[0] === randDesertAnimal) {
        place = 'desert'
    }else if (randAnimal[0] === randForestAnimal) {
        place = 'forest'
    }else if (randAnimal[0] === randLakeAnimal) {
        place = 'lake'
    }
  
    var AC = answerChoicesKey(answer, wrong[0], wrong[1], wrong[2])
    var probArray = []
    probArray.push(`There are ${numberL.toLocaleString()} ${randAnimal[0][0]}s in the ${place}. If there are ${numberS.toLocaleString()} ${randAnimal[0][1]}s `+
        `in the ${place} , how many total ${randAnimal[0][0]}s and ${randAnimal[0][1]} are in the ${place}?`)
    probArray.push(`In the ${place} there are ${numberS.toLocaleString()} ${randAnimal[0][1]}s and ${numberL.toLocaleString()} ${randAnimal[0][0]}s. `+ 
        `How many ${randAnimal[0][0]}s and ${randAnimal[0][1]}s are in the ${place} ${newAddWord}?`)
    probArray.push(`Researchers are surveying the ${place}. They found a total of ${numberL.toLocaleString()} `+
        `${randAnimal[0][1]}s, and a total of ${numberS.toLocaleString()} ${randAnimal[0][0]}s. How many animals did the researchers `+
        `find at the ${place} ${newAddWord}?`)
    var randProb = selectRand(probArray)
    var problem = {prob: randProb,
        answerChoices: AC,
        correctAnswer: answer.toLocaleString(), probType: 'Application'}

    return problem

            
}


export const addWhole4 = (userSelection) => { //youtube
    var boy = shuffleArray(boyList)
    var girl = shuffleArray(girlList)
    var {numberS, numberL, answer, wrong} = asNumbers(userSelection, 'add')


    var videoAdj = ['hilarious', 'funny', 'silly', 'popular', 'viral'][randInt(0,4)]
    var videoTopic = ['dog', 'fashion', 'cat', 'music', 'tutorial', 'cooking' ][randInt(0,6)]

    var AC = answerChoicesKey(answer, wrong[0], wrong[1], wrong[2])
    var probArray = []

    probArray.push(`${boy[0]} had ${numberL.toLocaleString()} subscribers on his youtube channel as of last month. This month so far he has gained `+
                `${numberS.toLocaleString()} subscribers after making a ${videoAdj} video. How many subscribers does his channel have?`)
    probArray.push(`${girl[0]} had ${numberL.toLocaleString()} subscribers on her youtube channel as of last month. This month her subscriber count has increased by `+
                `${numberS.toLocaleString()} after she made a very popular ${videoTopic} video. How many subscribers does her channel have now?`)
    probArray.push(`${boy[0]} got ${numberL.toLocaleString()} views on his latest ${videoTopic} video. His previous video got ${numberS.toLocaleString()} views. How `+
                `many combined views did the two videos get?`)
    probArray.push(`${girl[0]} currently has ${numberS.toLocaleString()} subscribers on her channel. Her goal is to reach ${numberL.toLocaleString()} subscribers by `+
                `the end of the year. How many more subscribers will she need to get in order to reach her goal?`)
    var randProb = selectRand(probArray)
    var problem = {prob: randProb,
        answerChoices: AC,
        correctAnswer: answer.toLocaleString(), probType: 'Application'}

    // return <div><p>{problem} </p></div>
    return problem        
}

export const subWhole4 = (userSelection) => { //youtube
    var boy = shuffleArray(boyList)
    var girl = shuffleArray(girlList)
    var {numberS, numberL, wrong, answer} = asNumbers(userSelection, 'sub')

    var reason = [`hasn't been making new videos`, `hasn't been actively making videos`, 
                `has been inactive recently`, `made a video that the subscribers did not like`][randInt(0,3)]
 
    var AC = answerChoicesKey(answer, wrong[0], wrong[1], wrong[2])
    var probArray = []

    probArray.push(`${boy[0]} had ${numberL.toLocaleString()} subscribers on his youtube channel as of last month. This month so far he has lost `+
                `${numberS.toLocaleString()} subscribers because he ${reason}. How many subscribers does his channel have now?`)
    probArray.push(`${girl[0]} had ${numberL.toLocaleString()} subscribers on her youtube channel as of last month. This month she has lost `+
                `${numberS.toLocaleString()} subscribers because she ${reason}. How many subscribers does her channel have?`)
    probArray.push(`${boy[0]} got ${numberL.toLocaleString()} views on his latest video. His previous video got ${numberS.toLocaleString()} views. How `+
                `many more views did his latest video get than his previous video?`)
    probArray.push(`${girl[0]} got ${numberS.toLocaleString()} views on her latest video. Her other video went viral last week and got ${numberL.toLocaleString()} views. How `+
                `many more views did her viral video get than her latest video?`)
    var randProb = selectRand(probArray)
    var problem = {prob: randProb,
        answerChoices: AC,
        correctAnswer: answer.toLocaleString(), probType: 'Application'}
    return problem        
}
export const addWhole7dig = (userSelection) => { //population
    var city = shuffleArray(cityList)
    var [numberS, numberM, numberL] = [randInt(10000, 30000), city[1].pop, city[0].pop];
    if (userSelection.specify.level === "2") {
        [numberS, numberM, numberL] = [randInt(100000, 300000), randInt(300000, 500000), city[0].pop];
    
        }
    var year = randInt(2015,2019)
    var answer = (numberL+numberS)
    var wrong= wrongOptions(answer, 'add', numberL, numberS)    
    var AC = answerChoicesKey(answer, wrong[0], wrong[1], wrong[2])
    var probArray = []

    probArray.push(`${city[0].city} had a population of ${numberL.toLocaleString()} as of the end of the year ${year}. Since `+
                `then the city has increased in population by ${numberS.toLocaleString()}. What is the current population of ${city[0].city}?`)
    probArray.push(`The population of ${city[0].city} has increased by ${numberS.toLocaleString()} since the end of the year ${year}. `+
                `the population before then was ${numberL.toLocaleString()}. What is the new population of ${city[0].city}?`)
    probArray.push(`Since the beginning of the year ${year} the population of ${city[0].city} has increased by ${numberS.toLocaleString()}. `+
                `If the population started off ${year} at ${numberL.toLocaleString()}, how much is the current population?`)
    var randProb = selectRand(probArray)
    var problem = {prob: randProb,
        answerChoices: AC,
        correctAnswer: answer.toLocaleString(), probType: 'Application'}
    return problem        
}

export const subWhole7dig = (userSelection) => { //population
    var city = shuffleArray(cityList)
    var [numberS, numberM, numberL] = [randInt(10000, 30000), city[1].pop, city[0].pop];
    if (userSelection.specify.level === "2") {
        [numberS, numberM, numberL] = [randInt(100000, 300000), randInt(300000, 500000), city[0].pop];
    
        }
    var year = randInt(2015,2019)
    var answer = (numberL-numberS)
    var wrong= wrongOptions(answer, 'sub', numberL, numberS)    
    var AC = answerChoicesKey(answer, wrong[0], wrong[1], wrong[2])
    var probArray = []

    probArray.push(`${city[0].city} had a population of ${numberL.toLocaleString()} as of the end of the year ${year}. Since `+
                `then the city has decreased in population by ${numberS.toLocaleString()}. What is the current population of ${city[0].city}?`)
    probArray.push(`The population of ${city[0].city} has decreased by ${numberS.toLocaleString()} since the end of the year ${year}. `+
                `the population before then was ${numberL.toLocaleString()}. What is the new population of ${city[0].city}`)
    probArray.push(`Since the beginning of the year ${year} the population of ${city[0].city} has decreased by ${numberS.toLocaleString()}. `+
                `If the population started off ${year} at ${numberL.toLocaleString()}, how much is the current population?`)
    var randProb = selectRand(probArray)
    var problem = {prob: randProb,
        answerChoices: AC,
        correctAnswer: answer.toLocaleString(), probType: 'Application'}

    // return <div><p>{problem} </p></div>
    return problem        
}

var badguyList = ['orcs','ogres', 'trolls','goblins', 'undead']
var goodguyList = ['elves','hobbits','humans']

export const subWhole5 = (userSelection) => { //elves and goblins
    var badguys = selectRand(badguyList)
    var goodguys = selectRand(goodguyList)
    var {numberS, numberL, answer, wrong} = asNumbers(userSelection, 'sub')
 
    var AC = answerChoicesKey(answer, wrong[0], wrong[1], wrong[2])
    var probArray = []
    if (numberL > 99) {
        probArray.push(`An army of ${numberS} ${goodguys} had to defeat an army of ${numberL} ${badguys} `+
        `that were trying to invade their city. How many more ${badguys} were there than ${goodguys}?`)
       probArray.push(`An army of ${numberL} ${badguys} were trying to invade a city of ${numberS} ${goodguys} ` +
               `How many more ${badguys} were there than ${goodguys}?`)
       probArray.push(`An army of ${numberL} ${badguys} were trying to invade a city of ${numberS} ${goodguys}. ` +
               `How many more ${badguys} were there than ${goodguys}?`
       )
    } else {
        probArray.push(`A group of ${numberL} ${badguys} stumbled upon on a group of ${numberS} ${goodguys} ` +
        `at their campsite. How many more ${badguys} were there than ${goodguys}?`)
        probArray.push(`While walking through the forest a group of ${numberS} ${goodguys} found a group of ` +
        `${numberL} ${badguys}. How many more ${badguys} were there than ${goodguys}?`)
    }
    // multi-step = two armies combined against orcs
    var randProb = selectRand(probArray)
    var problem = {prob: randProb,
        answerChoices: AC,
        correctAnswer: answer.toLocaleString(), probType: 'Application'}
    return problem        
}
export const addWhole5 = (userSelection) => { //elves and goblins 
    var badguys = selectRand(badguyList)
    var goodguys = shuffleArray(goodguyList)
    var {numberS, numberL, answer, wrong} = asNumbers(userSelection, 'sub')
    let newAddWord = selectRand(addWord)
  
    var AC = answerChoicesKey(answer, wrong[0], wrong[1], wrong[2])
    var probArray = []
    if (numberL > 99) {
        [numberL,numberS] = [numberL.toLocaleString(), numberS.toLocaleString()]
        probArray.push(`An army of ${numberL} ${goodguys[0]} and ${numberS} ${goodguys[1]} had to defeat an army of ${badguys} `+
        `that were trying to invade their city. How many ${goodguys[0]} and ${goodguys[1]} were there ${newAddWord}?`)
       probArray.push(`An army of ${badguys} were trying to invade a city of ${numberS} ${goodguys[0]} ` +
               `and ${numberL} ${goodguys[1]}. How many ${goodguys[0]} and ${goodguys[1]} were there ${newAddWord} in the forest?`)
    } else {
        [numberL,numberS] = [numberL.toLocaleString(), numberS.toLocaleString()]
        probArray.push(`A group of ${badguys} stumbled upon on a group of ${numberL} ${goodguys[0]} ` +
        `and ${numberS} ${goodguys[1]} at their campsite. How many ${goodguys[0]} and ${goodguys[1]} were there ${newAddWord}?`)
        probArray.push(`While walking through the forest a group of ${numberS} ${goodguys[0]} found a group of ` +
        `${numberL} ${goodguys[1]}. How many ${goodguys[0]} and ${goodguys[1]} were there ${newAddWord} in the forest?`)
    }
    var randProb = selectRand(probArray)
    var problem = {prob: randProb,
        answerChoices: AC,
        correctAnswer: answer.toLocaleString(), probType: 'Application'}

    // return <div><p>{problem} </p></div>
    return problem        
}
//Problem ideas: space distance? elves army of goblins, factory producing products, movies making money, tourists that visit a city/country, city = smaller numbers, country = larger. 
// pts in a video




export const addDec = (userSelection) => { //sport
    var name = nameList[randInt(0, nameList.length-1)]
    var sport = sportList[randInt(0, sportList.length-1)]
    var [numberS, numberL] = [(Math.random()*49999+10011).toFixed(1),  (Math.random()*999999+500011).toFixed(2)];
    // var f = 'format'
    var answer= (numberL + numberS);
    var wrong= wrongOptions(answer, 'decimal', numberL, numberS)
    var AC = answerChoicesKey(answer, wrong[0], wrong[1], wrong[2])
    // var wrong = gen.shuffleArray(gen.wrongOptions(answer, 'add', numberL, numberS))
    // var tempAC = [answer, wrong[0], wrong[1], wrong[2]]
    // var AC = gen.shuffleArray(tempAC)
    var randProb 
    var problem = { prob: randProb,
                answerChoices: AC,
                correctAnswer:answer.toLocaleString(),
                }

    return problem
    
}

export const subDec = (userSelection) => { //sport
    var name = nameList[randInt(0, nameList.length-1)]
    var sport = sportList[randInt(0, sportList.length-1)]
    // var f = 'format'
    var [numberS, numberL] = [(Math.random()*49999+10011).toFixed(1),  (Math.random()*999999+500011).toFixed(2)];

    var answer= (numberL - numberS);
    var wrong = shuffleArray(wrongOptions(answer, 'sub', numberL, numberS))
    var AC = answerChoicesKey(answer, wrong[0], wrong[1], wrong[2])
    var sub1 = (`${name[0]} played ${sport} for ${(numberS).toString()} minutes last year. ${name[1]} `+
    `played ${sport} for ${(numberL).toString()} minutes last month. How many more minutes did `+
     `${name[1]} play than ${name[0]}?`)
    var sub2 = (`${name[0]} played ${sport} for ${(numberS).toString()} minutes last year. ${name[1]} `+
     `played ${sport} for ${(numberL).toString()} minutes last month. What is the difference between `+
    `the number of minutes ${name[1]} played and the number of minutes ${name[0]} played?`)

    var randProb = selectRand([sub1, sub2])
    var problem = {prob: randProb,
                answerChoices: AC,
                correctAnswer: answer.toLocaleString(), probType: 'Application'
            }
    return problem
}
export const randAddWhole = (userSelection, generalSelection) => {
    var probArray = []
    if (userSelection.specify.probType['Algorithm']) {
        probArray.push(addAlg)
        // probArray.push(addAlg,addAlg,addAlg, addAlg, addAlg)
    } if (userSelection.specify.probType['Application']) {
        probArray.push(addBasic)
        // if (userSelection.specify.numbers['7 digit number']){
        //     probArray.push(addWhole7dig)
        // }
        // probArray.push(addWhole, addWhole2, addWhole3, addWhole4, addWhole5)
    }
    var randProb = selectRand(probArray)
    return randProb(userSelection, generalSelection)
}
export const randSubWhole = (userSelection, generalSelection) => {
    var probArray = []
    if (userSelection.specify.probType['Algorithm']) {
        probArray.push(subAlg)
        // probArray.push(subAlg, subAlg, subAlg, subAlg, subAlg)
    } if (userSelection.specify.probType['Application']) {
        probArray.push(subBasic)
        // if (userSelection.specify.numbers['7 digit numbers']){
        //     probArray.push(subWhole7dig)
        // }
        // probArray.push(subWhole, subWhole2, subWhole3, subWhole4, subWhole5 )
    }
    var randProb = selectRand(probArray)
    return randProb(userSelection, generalSelection)
}