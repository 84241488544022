import { specAC, specHZ, specify } from "./helper"

export const Tables = (props) => {
  const {createLink, ac} = props
  const c1 = 'Input Output Tables'
  const [wn, dec, s1, s2, sm, med, lg] = ['Whole numbers', 'Decimals', 'One-step', 'Two-steps', 'Small', 'Medium', 'Large']
  
  const title = (mod1, mod2, mod3) => {
    return `${mod1}-step Input Output Tables with ${mod2} ${mod3}`
  }
  const createSpec = (spec1, spec2, spec3) => {
    let specAS = [specify('numberType', spec1), specify('numberSize', spec2), specify('steps', spec3), specAC(true)]

    return {[c1]: specAS}
  }

  return (<>
    <div className="buttonGroup">
      <div className="concept-topic">1 step Input Output Tables </div>
      <div className="sec-title">Whole Numbers</div>
      <div className="sec"></div>
      {createLink([c1], title('1', sm, wn), '', createSpec([wn], [sm], [s1]), 9)}
      {createLink([c1], title('1', med, wn), '', createSpec([wn], [med], [s1]), 9)}
      {createLink([c1], title('1', lg, wn), '', createSpec([wn], [lg], [s1]), 9)}

      <div className="sec-title">Decimals</div>
      <div className="sec"></div>
      {createLink([c1], title('1', sm, dec), '', createSpec([dec], [sm], [s1]), 9)}
      {createLink([c1], title('1', med, dec), '', createSpec([dec], [med], [s1]), 9)}
      {createLink([c1], title('1', lg, dec), '', createSpec([dec], [lg], [s1]), 9)}

    </div>
    <div className="buttonGroup">
      <div className="concept-topic">2 step Input Output Tables </div>
      <div className="sec-title">Whole Numbers</div>
      <div className="sec"></div>
      {createLink([c1], title('2', sm, wn), '', createSpec([wn], [sm], [s2]), 9)}
      {createLink([c1], title('2', med, wn), '', createSpec([wn], [med], [s2]), 9)}
      {createLink([c1], title('2', lg, wn), '', createSpec([wn], [lg], [s2]), 9)}

      <div className="sec-title">Decimals</div>
      <div className="sec"></div>
      {createLink([c1], title('2', sm, dec), '', createSpec([dec], [sm], [s2]), 9)}
      {createLink([c1], title('2', med, dec), '', createSpec([dec], [med], [s2]), 9)}
      {createLink([c1], title('2', lg, dec), '', createSpec([dec], [lg], [s2]), 9)}

    </div>
    </>
  )
}