import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import {Link} from "react-router-dom"
import { AuthContext } from "../../app/context/auth-context";

import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {specifyList} from './helperFunctions'
import { WSContext } from "../../app/context/ws-context";
import IconDownload from '../../app-files/images/icon-download.svg'
import IconCopy from '../../app-files/images/icon-copy.svg'
import IconReport from '../../app-files/images/icon-report.svg'
import ReportIssue from "../components/ReportIssue";
import CreateNewVersion from "../display-assign/CreateNewVersion";


const WorksheetData = (props) => {
  const auth = useContext(AuthContext);
  const ws = useContext(WSContext)
  const [dropdownDisplay, setDropdownDisplay] = useState(null);
  const [expandWorksheetData, setExpandWorksheetData] = useState(false);
  const [newVersionModal, setNewVersionModal] = useState(false)
  const [supportTicketModal, setSupportTicketModal] = useState(false)
  const history = useHistory()

  const handleClick = (e) => {
    e.stopPropagation()
    setDropdownDisplay(e.currentTarget);
  };
  
  const handleDelete = () => {
    setDropdownDisplay(null);
    props.showDeleteWarningHandler()
  }
  const handleClose = (e) => {
    e.stopPropagation()
    setDropdownDisplay(null);
  }

  const handleExpandWorksheetData = () => {
    setExpandWorksheetData(!expandWorksheetData)
  }

  if (props.userSelection.length===0)
    return null
  
  var displayArray = [];

  for (var i = 0; i < props.userSelection.length; i++) {
    displayArray.push(
      <div className="concept-container" key= {i}>
        <p className="concept">{props.userSelection[i].concept}</p>
        <p className="specify"><ul>{specifyList(props.userSelection[i].specify, true)}</ul></p>
        <p className="quantity">{props.userSelection[i].specify.quantity}</p>
      </div>
    );
  }


  
  let subtitle
  const {activity, questionStyle} = props.generalSelection
  if (activity === 'Bingo'){
    subtitle = questionStyle === 'Task Cards' ? 'Bingo - Task Cards' : 'Bingo - Worksheet'
  } else if (activity === 'Tic Tac Bingo'){
    subtitle = questionStyle === 'Task Cards' ? 'Tic Tac Bingo - Task Cards' : 'Tic Tac Bingo - Worksheet'
  } else{
    subtitle = activity
  }
  
  let questTotal = props.userSelection.map(concept => concept.specify.quantity)
   .reduce((a,b) => {return Number(a) + Number(b);});
  const date = new Date(props.created)?.toLocaleDateString('en',{day:'numeric',month:'numeric', year:'2-digit'}) +' '+ new Date(props.created)?.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' })

  var table = (
    <React.Fragment>
      <div className="ws-data" onClick={handleExpandWorksheetData}>
        <div className="general-ws-info">
          <h3 className="main-title" >
            <p className="ws-title">{props.generalSelection.title}</p>
            <p className="sub-title">{subtitle}</p>
          </h3>
          <div className="total-Q">
            <div>Total Q<span className="questions">uestions</span>: </div>
            <div className="quantity">{questTotal}{" "}</div>
          </div>
          <p className="created-at">Created:<br/> {date}</p>

          <button aria-label="Worksheet Menu" onClick={handleClick} className="dropdown">
            <MoreVertIcon />
          </button>
          {auth.user.userId === props.creatorId && (
            <React.Fragment>
              <Menu id="simple-menu" key={props.key} keepMounted
                anchorEl={dropdownDisplay}
                open={Boolean(dropdownDisplay)}
                onClose={handleClose}
                getContentAnchorEl={null}
                disableScrollLock={true}

              >
                <MenuItem onClick={(e)=>{
                  e.stopPropagation();
                  history.push('/display-assignment')
                  ws.handleDuplicate('copy', props.userSelection, props.generalSelection, props.worksheetCopy)
                  }}>
                    <img className="menu-icon download" src={IconDownload} alt="download"/>
                    Download
                </MenuItem>
                <MenuItem onClick={(e) => {
                    e.stopPropagation();
                    setNewVersionModal(true);
                    handleClose(e);
                  }}
                >
                  <img className="menu-icon" src={IconCopy} alt="copy"/>
                  Create a new version
                </MenuItem>
                <MenuItem onClick={(e) => {
                  e.stopPropagation();
                  setSupportTicketModal(true);
                  handleClose(e);
                }}
                >
                  <img className="menu-icon" src={IconReport} alt="report"/>
                  Report Issue
                </MenuItem>
              </Menu>
            </React.Fragment>
          )}
        </div>

        <div className={`selection-table ${expandWorksheetData ? 'active' : ''}`}>
          <div className="sub-title-container">
            <p className="concept">Concept</p>
            <p className="specify">Specify</p>
            <p className="quantity">Quantity</p>
          </div>
          <div className="content-container">{displayArray}</div>
        </div>
        <CreateNewVersion newVersionModal={newVersionModal} setNewVersionModal={setNewVersionModal} userSelection={props.userSelection} generalSelection={props.generalSelection}/>
        <ReportIssue supportTicketModal={supportTicketModal} setSupportTicketModal={setSupportTicketModal} purchased={true} id={props.id}/>

      </div>

    </React.Fragment>
  );
  return table;
};

export default WorksheetData;