import React, { useState, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";

import Modal from '../../shared/components/UIElements/Modal';
import { WSContext } from "../../app/context/ws-context";
import Button from "../../shared/components/FormElements/Button";
import { TextField } from "@material-ui/core";
import { AuthContext } from "../../app/context/auth-context";

const NewTitle = (props) => {
  //use generalSelection & userSelection from props since it may be passed by user worksheets
  const {newTitleModal, setNewTitleModal} = props
  const [titleUpdated, setTitleUpdated] = useState(false)
  const ws = useContext(WSContext)
  const auth = useContext(AuthContext);
  const history = useHistory()
  const location = useLocation()

  const onChangeTitle = (e) => { 
    setTitleUpdated(true)
    const { name, value } = e.target;
    let tempGS = {...props.generalSelection}
    tempGS[name] = value
    ws.setGeneralSelection(tempGS)
    localStorage.setItem('gs', JSON.stringify({...tempGS}))
  };

  const createNewTitle = () => {
    setNewTitleModal(false)

    if (location.pathname !== '/display-assignment'){
      history.push('/display-assignment')
    }
    ws.setPurchase(ws.generalSelection.activity === 'Worksheet' && auth.isLoggedIn) //set based on activity type
    
    let createdWorksheetState = JSON.parse(localStorage.getItem('ws'))
    if (props.handleStartPDF){
      props.handleStartPDF("copy", createdWorksheetState, props.userSelection, ws.generalSelection)
    } else{
      ws.handleDuplicate("copy", props.userSelection, ws.generalSelection, createdWorksheetState);
    }

  }
  return(
    <Modal show={newTitleModal} onCancel={(e)=> {e.stopPropagation(); setNewTitleModal(false); setTitleUpdated(false)}}
    header="New Assignment Title"
    footerClass="worksheet-item__modal-actions"
    className="new-ws-modal"
    footer={<Button danger onClick={createNewTitle}>CHANGE TITLE</Button>}
  >
    <div className="ws-title-container new-version">
      <TextField variant="filled" id="filled-basic" placeholder={props.generalSelection.title} required className="doc-title-input"
        label={<span className="">New Assignment Title</span>}
        name="title" type="text"
        value={ws.generalSelection.title}
        onChange={onChangeTitle}
        maxLength={75} autoFocus
        InputProps={{
          inputProps: { 
            type:'text',
            maxLength:75
          }
        }}
      />
    </div>
  </Modal>
  )
}

export default NewTitle