import {answerChoicesKey, randInt, selectRand, shuffleArray} from '../general.js'
import {cell_style_fraction, ak_cell_style, tstyles, horizontalFractions, divMultFractNumbers, MixedNumber, createFraction, akTStyles } from './fract-shared'

import Table from '../Table.js'
import {Text,View} from "@react-pdf/renderer";
import { fixImproper } from './mixed-improper.js';
import { simplify } from './simplify.js';
  const { create, all } = require('mathjs')
const config = { number: 'Fraction'}
const math = create(all, config)

const multWrong = (number1, number2) => {
  let {num, denom, whole} = number1;
  let {num:num2, denom:denom2, whole:whole2} = number2
  let wrong = []
  const pushWrong = (num, denom, whole) => {
    wrong.push(createFraction({num, denom, whole}))
  }
  if (Number.isInteger(number1)) {
    if(number2.whole){
      pushWrong(num2, denom2, whole2*number1) //only multiply whole numbers
      pushWrong(num2*number1, denom2*number1, whole2*number1) //number1 times everything
      pushWrong(num2*number1, denom2*number1, whole2) //number1 times everything

    }
  }
}
const getMultFractAnswer = (fract1, fract2, number1, number2, gs) => {
  var answerFract = math.format(math.multiply(fract1, fract2)) 
  var extraWrong = math.format(math.divide(fract1, fract2)) 

  var splitAnswer = answerFract.split('/')
  var answer = {num:Number(splitAnswer[0]), denom:Number(splitAnswer[1]), answer:true} 
  var shAnswerKey =  {num:splitAnswer[0], denom:splitAnswer[1]}
  
  var splitWrong = extraWrong.split('/')
  let wrong = []
  let wrongCheck = ({num, denom}) => {
    if (denom !==0){
      let simpFract = simplify(num, denom)
      num = simpFract.num
      denom = simpFract.denom
    }
    let duplicate = false
    for (let i of wrong){
      if ((i.num === num && i.denom === denom) || (answer.num === num && answer.denom === denom)){
        duplicate = true
      }
    }
    if(!duplicate){
        wrong.push({num, denom})
    }
  }
  wrongCheck({num:answer.num+1, denom:answer.denom})
  wrongCheck({num:answer.num+2, denom:answer.denom+1})
  answer.num !== answer.denom && wrongCheck({num:answer.denom, denom:answer.num})
  answer.num !== answer.denom && wrongCheck({num:answer.num+1, denom:answer.denom+1})
  wrongCheck({num:splitWrong[0], denom:splitWrong[1]})

  Number.isInteger(number1) && wrongCheck({num:number1*number2.num, denom:number1*number2.denom})
  wrong.length < 3 && wrongCheck({num:1, denom:3})
  wrong.length < 3 && wrongCheck({num:1, denom:4})

  return {answer, shAnswerKey, wrong:shuffleArray(wrong)}
}
export const multFract = (userSelection, gs) => {
  var {number1, number2} = divMultFractNumbers(userSelection, gs)
  let [styledNumber1, fract1] = createFraction(number1, gs);
  let [styledNumber2, fract2] = createFraction(number2, gs);

  var prob1 = {number1, number2, op:'×'} 
  var prob2 = {number1:number2, number2:number1, op:'×'}
  var randProb = [prob1, prob2][randInt(0,1)]
  let {answer, shAnswerKey, wrong} = getMultFractAnswer(fract1, fract2, number1, number2,gs)

  var AC = answerChoicesKey(answer, wrong[0], wrong[1], wrong[2])

  var problem = {prob: randProb,
      answerChoices: AC,
      correctAnswer: answer, shAnswerKey}
  return problem
}

export const randMultFract = (userSelection, gs) => {
  var probArray = [multFract]
  var randProb = selectRand(probArray)
  return randProb(userSelection, gs)
}