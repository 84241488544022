import React, {useContext, useEffect} from 'react'
import { Link, useHistory } from 'react-router-dom'
import Fractions from '../../../app-files/images/sample-addsubfractions.png'
import Integers from '../../../app-files/images/sample-addsubintegers.png'
import MixConcepts from '../../../app-files/images/sample-mixedconcepts.png'
import WholeOps from '../../../app-files/images/sample-mixedwholeops.png'
import star from "../../../app-files/images/icon-star.svg";

import downloadIcon from '../../../app-files/images/icon-download.svg'
import { useHttpClient } from '../../../shared/hooks/http-hook'
import { WSContext } from '../../../app/context/ws-context'
import { initialGenValues } from '../../../app/context/ws-store'


const Samples = () => {
  const history = useHistory()
  const ws = useContext(WSContext);

  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const sampleCount = async(ws) => {
    try {
      await sendRequest(
        `/api/worksheets/sample-count/${ws}-sample`, 'POST',
        '',
        {
          "Content-Type": "application/json",
        }
      );
    } catch (err) {

    }
    history.push('/samples')
  }
  const setActivity=()=> {
    let tempGS = {...initialGenValues}
    tempGS.activity = 'Worksheet'
    ws.handleClearSelections()
    ws.setGeneralSelection(tempGS)
  }
  useEffect(()=>{
    window.scrollTo(0,0)
  }, [])
  return(
    <div className="main-container info samples">
      <div className="content">
        <div className="content-title">
          <h2>Free Samples</h2>
        </div>
        <h3 className="ws-free"><img src={star} alt=""/>All Worksheets are free!<img src={star} alt=""/></h3>
        <div className="blurb">Try making one now!</div>
        <div className="worksheets-group">
          <div className="ws-container">
            <Link to="/concept-selection">
              <button className="btn" onClick={setActivity}>Custom</button>
            </Link>

          </div>
          <div className="ws-container">
            <Link to="/premade-concept-selection">
              <button className="btn" onClick={setActivity}>Premade</button>
            </Link>            
          </div>
        </div>
        <div className="subtitle">Below are some free samples of our activities and worksheets made with our generator!</div>
        {/* <div className="subtitle">Below are some free samples that show our activities and how our worksheets can be customized with multiple concepts.</div> */}
        {/* <div className="subtitle">Below are some free sample activities and a multi-concept worksheet made using our generator!</div> */}
        <div className="sample-container">
          <div className="sample" > 
            <div className="title-container">
              <h3 className="title">Mixed Whole Numbers <div className="activity">Scavenger Hunt</div></h3>
              <Link to="/samples/WorksheetWonderland-Sample-MixOperationsWholeNumbers.pdf" target="_blank" download>
                <img src={downloadIcon} alt="download" onClick={()=>sampleCount('MixOperationsWholeNum')}/></Link>

            </div>
            <p>Basic multiplying & dividing facts, and 3 digit adding & subtracting.</p>
            <img className="preview hz" src={WholeOps} alt="Whole Numbers"/>
          </div>

          <div className="sample" > 
            <div className="title-container">
              <h3 className="title">Add & Subtract Fractions <div className="activity">Task Cards</div></h3>
              <Link to="/samples/WorksheetWonderland-Sample-AddSubFractions.pdf" target="_blank" download>
                <img src={downloadIcon} alt="download" onClick={()=>sampleCount('AddSubFractions')}/></Link>
            </div>
            <p>Common denominators, fractions only.</p>

            <img className="preview hz" src={Fractions} alt="Fractions"/>

          </div>
          <div className="sample" > 
            <div className="title-container">
              <h3 className="title">Adding Integers <div className="activity">Bingo</div></h3>
              <Link to="/samples/WorksheetWonderland-Sample-AddingIntegers.pdf" target="_blank" download>
                <img src={downloadIcon} alt="download" onClick={()=>sampleCount('AddingIntegers')}/></Link>

            </div>
            <p>1 digit numbers, all 4 operations.</p>
            <img className="preview" src={Integers} alt="Integers"/>
          </div>
          <div className="sample" > 
            <div className="title-container">
              <h3 className="title">Mixed Concepts<div className="activity">Worksheet</div></h3>
              <Link to="/samples/WorksheetWonderland-Sample-MixedConcepts.pdf" target="_blank" download>
                <img src={downloadIcon} alt="download" onClick={()=>sampleCount('MixedConcepts')}/></Link>

            </div>
            <p>Multiplying & dividing whole numbers with fractions, order of operations, input output tables, comparing multiplying, and dividing decimals, area with decimals, equations.</p>

            <img className="preview" src={MixConcepts} alt="Mixed"/>
          </div>

        </div>
      </div>
    </div>

  )
}

export default Samples