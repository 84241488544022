import { Image } from "@react-pdf/renderer";
import borderCircles from './ws-border-circles.png'
export const wsBorderCircles = (top, right, bottom, left, rotate, size) => {
  let styles = {position: 'absolute', width: '16', height: '16'}
  if (top){
    styles.top = top
  }
  if (right) {
    styles.right = right
  }
  if (bottom) {
    styles.bottom = bottom
  }
  if (left) {
    styles.left = left
  }
  styles.transform = `rotate(${rotate}deg)`
  return (
    <Image style={styles} src={borderCircles}/>

    // display more smoothly at all zoom levels but does not display at all using react-pdf(not-renderer) viewer, it displays as large grey squares instead
      // <Svg style = {styles} id="Group_104" width='16' height='16' viewBox='0 0 16 16' fixed>
      //   <G id="Ellipse_180" transform="translate(8, 0)" fill="#707070" stroke="#707070" strokeWidth="1">
      //     <Circle cx="4" cy="4" r="4" stroke="none"/>
      //     <Circle cx="4" cy="4" r="3.5" fill="none"/>
      //   </G>
      //   <G id="Ellipse_182" transform="translate(11, 11)" fill="#707070" stroke="#707070" strokeWidth="1">
      //     <Circle cx="2.5" cy="2.5" r="2.5" stroke="none"/>
      //     <Circle cx="2.5" cy="2.5" r="2" fill="none"/>
      //   </G>
      //   <G id="Ellipse_183" fill="#707070" stroke="#707070" strokeWidth="1">
      //     <Circle cx="2.5" cy="2.5" r="2.5" stroke="none"/>
      //     <Circle cx="2.5" cy="2.5" r="2" fill="none"/>
      //   </G>
      // </Svg> 

  )
}


