import { withTheme } from "@material-ui/core";
import {Text,
  StyleSheet,
} from "@react-pdf/renderer";
export const styles = StyleSheet.create({
  //individual question styling
  qContainer:{

  },
  shContainer:{//question view wrapper may need styling
    position:"relative",
  },
  tcContainer: {
    
  },
  tcContainerNoAC:{
    margin: '30 10 10 10'
  },
  question: {
    // marginBottom:30,
    fontSize: 12,
    textAlign: 'left',
    position:"relative",
  },
  shQuestion:{ //do not set SH fontsize here bc it needs to be set within the createSH file 
   position:"relative",
  },
  fractprob:{
    textAlign:'center',
    // fontSize:12
  },
  fractTextSH:{
    textAlign:'center',
    fontSize: 35

  },
  fractText:{
    fontSize:12
  },
  fractTextTC:{
    textAlign:'center',
    fontSize: 12
  },

  tcQuestion: {
    fontSize:18, //18
    textAlign:'center',
    minHeight:60,

    //testing out for centering vertically when no answer choices
    padding: 10
    // margin:'30 10 10 -15'

  },

  tcLongQuestion: { //styling for a question that needs smaller font bc it's too long- (ex- ordering 5+ digits)
    marginTop:3,
    marginBottom:10,
    fontSize:16,
    textAlign:'left',
    minHeight:60

  },
  tcXLongQuestion: {
    marginTop:3,
    marginBottom:10,
    fontSize:14,
    textAlign:'left',
    minHeight:60

  },
  // shTableTxtQStyle: {

  // },
  tcTableTxtQStyle: {
    marginBottom:10,
    fontSize:12, //18
    textAlign:'center',
  },

  verticalQStyle: {
    marginBottom:10,
    minHeight:75,
    maxHeight:75
  },
  WSVerticalQStyle: {
    marginBottom:10,
    fontSize:12,
  },
  tableQStyle: {
    marginBottom:10,
    fontSize:12,
  },
  TCtableQStyle: {
    marginBottom:10,
    fontSize:12,
    minHeight:95,
    maxHeight:95,
  },
  SHtableQStyle: {
    marginBottom:10,
    minHeight:95,
    maxHeight:95,
  },
  FractTCQ: {
    marginBottom:10,
  },
  FractQ:{
    fontSize:13
  },

  questionAnswer: { //questionwrap for plain ws / columns
    display:'flex',
    flexDirection:'row',
    alignItems:'flexStart',
    // height: '100%',
    flexGrow: 1,
    margin: 20, //margins between problems /edge of page.    //20
  },
  qaSavePaper: {
    margin: '5 20 5'
  },


// Styles for columns & task cards
//view wrapper inside column styling for individual question (with answer choices included already). 
//(Question individual styling is tcQuestion)
  columnQuestion: {
    display:'flex',
    flexDirection:'column',
    marginRight:20,
  },
  tcColumnQuestion:{
    display:'flex',
    flexDirection:'column',
    paddingLeft:50, //space for border
    paddingRight:50,
    // background:'white'
  },



  //style for 2 task cards /questions side by side
  rowWrap: {
    display:'flex',
    flexDirection:'row',
    flexGrow:1, // makes questions take up space vertically given on paper
    minHeight:70,
    maxHeight:250,
    marginTop: 10,
    marginRight:20,
    justifyContent: 'center',
  },
  tcRowWrap:{
    flexDirection:'row',
    justifyContent: 'center',
    height: 260,
    marginBottom:5,
  },
  //style for individual card/question
  questionWrap:{
    width:'50%',
    flexGrow: 1
  },
  tcCardWrap:{   
    width: '410',
    height:'100%',
    borderRadius: 20,
    margin: 5,
    position:"relative",
  },
  tcQWrap: {
    width: '410',
    margin: 5
  },

  tcCardBorder:{ //maybe dont need anymore****************************************
    height: '100%',
    position:'relative',
    paddingTop:65, //gives actual question padding at top.
  },
  tcImage:{//used in tcBorders.js
    position:'absolute',
    height: '100%',
    width:'100%',
  },

  //Number styles
  answerNum: {
    marginRight:10,
    minWidth: 20,//try to fix letter alignment
    fontSize:13
  },
  questionNum: {
    marginRight: 10,
    fontSize:13
  },
  //column numbers
  numStyle: {
    width:25,
    marginLeft:10,
    fontSize:13,

  },

  tcNum: {
    position: 'absolute',
    top: 10,
    left: 10,
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    textAlign:'center',
    paddingTop:12,
    paddingBottom:12,
    width:50, 
    fontWeight:'bold',
    fontSize:25,
    marginLeft:8,
    marginTop:8,
    borderRadius: '50%',
    color:'white',
  },

  //container wrapping all 4 answer choices
  answerContainer: {
    marginTop:30,
    fontSize:12 //added 1022 for tables

  },
  vertFractAnswerTCContainer:{ 
    padding: "0 0 45 10", // 0 0 35 20 -fixed for certain borders
    fontSize:14,
    display:'flex',
    flexDirection:'row'
  },
  tcAnswerContainer:{
    padding: "0 0 45 20", // 0 0 35 20 -fixed for certain borders
    fontSize:14
  },
  //each specific answer choice inside of question
  answer: {
    fontSize:12,
    marginLeft:10,
   
  },
  tcAnswer: {
    marginBottom:10,
  },
  tcViewAnswer: {
    marginBottom:10,
    minHeight:28, //ensure whole numbers and fractions are lined up correctly with same height
    maxHeight:28
  },
  smallAnswers: {
    fontSize:11,
    marginLeft:-10,
    letterSpacing:-0.5,
    marginBottom:5
  },
  //specific table/view answer
  viewAnswer: {
    display:'flex',
    flexDirection:'row',
    padding: '5 0 5 0',
    margin: '5 0 5 0',
    minHeight:28, //ensure whole numbers and fractions are lined up correctly with same height
    maxHeight:28
  },
  wsViewAnswer: {
    display:'flex',
    flexDirection:'column',
    padding: '0 0 0 5',
  },
  tcViewAnswer: {
    display:'flex',
    flexDirection:'row',
    padding: 5,
    margin:10,
    fontSize:11
  },
  shViewAnswer: {
    display:'flex',
    flexDirection:'row',
    padding: 5,
    margin: 10,
    // zIndex: 1
  },

  viewAnswerCol: {
    display:'flex',
    flexDirection:'column',
    fontSize:11
  },

  viewAnswerNoCol: {
    display: 'flex',
    flexDirection: 'row',
    fontSize:11,
    // marginRight: 5
  },

  ansLetter:{
    display:'flex',
    flexDirection: 'row',
    alignItems:'center',
    marginRight:'10',
    minWidth:'60', //was 80 messed up letter styling
    minHeight:30 //ensures that height of all answer choices is equal (whole vs fraction)

  },

  vertAnsLetter: {
    display:'flex',
    flexDirection: 'row',
    alignItems:'center',
    marginRight:'10',
  },
  tableAnsLetter: {
    display:'flex',
    flexDirection: 'row',
    alignItems:'center',
    marginRight:'20',
    minWidth:'60',
    marginBottom:10
  },
  vertTableAnsLetter: {
    display:'flex',
    flexDirection: 'column',
    alignItems:'flex-start',
    marginRight:'10',
    minWidth:'60',
    marginBottom:10,
    margin: '0 10 10 0'
  },
  letter:{
    marginRight:5,
    paddingRight:5
  },
  vertLetter:{
    marginRight:5
  },
  ansKeyLetter:{
    // marginLeft:5,
    // paddingLeft:15,
    marginRight:3,
  },


  answerKey: {
    display:'flex',
    flexDirection:'row',
    alignItems:'center',
    marginTop: 7,
    marginLeft:20,
    minHeight:28, //ensure whole numbers and fractions are lined up correctly with same height
    maxHeight:28
  },
  smallAnswerKey: { //all answers made smaller if 1+ are very longer 
    display:'flex',
    flexDirection:'row',
    alignItems:'center',
    marginTop: 5,
    fontSize:11,
    letterSpacing:-0.5,
    minHeight:28, //ensure whole numbers and fractions are lined up correctly with same height
    maxHeight:28
  },

  bingoAnswer: {
    display:'flex',
    flexDirection:'row',
    alignItems:'center',
    justifyContent:'center',
    minHeight:28, //ensure whole numbers and fractions are lined up correctly with same height
    maxHeight:28
  },

  answerchoices: {
    padding:50,
  },

  //general answer choices for tables

  genAnswerLetter: {
    marginRight:10
  },
  viewList: {
    display:'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
  },
  viewQuestion: {
    flexGrow: 1,
  }
});