import React from 'react';
import WSExamples from './WSExamples';

const Teacher = props => {

  return(
    <div className="teacher section-container">
      <div className="teacher description-container">
        <p>Problems are thoughtfully created with <span className="bold MB">teachers</span> and <span className="bold SB">students</span> in mind</p>
      </div>
      <WSExamples />
    </div>
  )
}

export default Teacher;